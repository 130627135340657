import { Card, CardContent } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { ReactNode } from "react"
import { useIsMobile } from "rentzz"

export interface CustomCardProps {
    onClick?: (e: any) => void
    children: ReactNode
    alignItems?: string
    width?: string
    height?: number | string
    flexGrow?: number
    flex?: number
    px?: number
    py?: number
    isCardClickable?: boolean
    padding?: number
    marginRight?: number
    minHeight?: number
    backgroundColor?: string
    className?: string
    minWidth?: string | number
    noHover?: boolean
}

export default function CustomCard({
    onClick,
    children,
    alignItems,
    width,
    height,
    flexGrow,
    flex,
    px,
    isCardClickable,
    padding,
    py,
    marginRight,
    minHeight,
    backgroundColor,
    className,
    minWidth,
    noHover,
}: CustomCardProps) {
    const isMobile = useIsMobile()
    const theme = useTheme()

    return (
        <Card
            className={className}
            sx={{
                display: "flex",
                justifyContent: "center",
                transition: isMobile || noHover ? undefined : "transform 0.5s",
                cursor: isCardClickable ? "pointer" : undefined,
                ":hover": {
                    backgroundColor: isMobile || noHover ? "" : theme.palette.action.hover,
                    transform: isMobile || noHover ? undefined : "scale(1.02)",
                },
                alignItems,
                width,
                flexGrow,
                flex,
                height,
                marginRight,
                minHeight,
                backgroundColor,
                minWidth,
            }}
        >
            <CardContent
                sx={{
                    justifyContent: "center",
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    px: px,
                    py: py,
                    flexDirection: "column",
                    p: padding,
                    color: theme.palette.grey["700"],
                    alignSelf: "center",
                }}
                onClick={onClick}
            >
                {children}
            </CardContent>
        </Card>
    )
}
