import { ComplexQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import * as jsonpatch from "fast-json-patch"
import { AddInventorySectionRequest } from "../sections/propertyDetails/inventory/AddOrEditInventorySectionForm"
import { AddInventorySectionItemRequest } from "../sections/propertyDetails/inventory/AddOrEditInventorySectionItemForm"
import { useSelector } from "../redux/store"
import { InventoryProcessType } from "../redux/slices/AddProperty"
import { ExportInventoryProcessRequest } from "./inventory-types"

export const addInventorySectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddInventorySectionRequest }) => Api.addInventorySection(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySections])
            }
        },
    })
}

export const addInventorySectionItemMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data, inventorySectionId }: { data: AddInventorySectionItemRequest; inventorySectionId: number }) =>
            Api.addInventorySectionItem(data, inventorySectionId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySectionItems, ComplexQueryIDs.InventorySections])
            }
        },
    })
}

export const deleteInventorySectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ inventorySectionId }: { inventorySectionId: number }) => Api.deleteInventorySection({ inventorySectionId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySections])
            }
        },
    })
}

export const deleteInventorySectionItemMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ inventorySectionId, inventoryItemId }: { inventorySectionId: number; inventoryItemId: number }) =>
            Api.deleteInventorySectionItem({ inventorySectionId, inventoryItemId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySections, ComplexQueryIDs.InventorySectionItems])
            }
        },
    })
}

export const updateInventorySectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ inventorySectionId, operations }: { inventorySectionId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateInventorySection({
                inventorySectionId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySections])
            }
        },
    })
}

export const updateInventorySectionItemMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            inventorySectionId,
            inventoryItemId,
            operations,
        }: {
            inventorySectionId: number
            inventoryItemId: number
            operations: jsonpatch.Operation[]
        }) =>
            Api.updateInventorySectionItem({
                inventorySectionId,
                inventoryItemId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InventorySectionItems])
                await invalidateQueries([ComplexQueryIDs.InventorySections])
            }
        },
    })
}

export const startInventoryProcess = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: (type: InventoryProcessType) => Api.startInventoryProcess(currentRentingPeriodId!, type),
        onSettled: async (id, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RentingPeriodInventoryProcesses])
            }
        },
    })
}

export const deleteInventoryProcess = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ inventoryProcessId }: { inventoryProcessId: number }) =>
            Api.deleteInventoryProcess({ inventoryProcessId, rentingPeriodId: currentRentingPeriodId! }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RentingPeriodInventoryProcesses])
            }
        },
    })
}

export const updateInventoryProcessSectionItemMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({
            inventoryProcessId,
            inventorySectionId,
            inventoryItemId,
            operations,
        }: {
            inventoryProcessId: number
            inventorySectionId: number
            inventoryItemId: number
            operations: jsonpatch.Operation[]
        }) =>
            Api.updateInventoryProcessItem({
                inventorySectionId,
                inventoryItemId,
                operations,
                rentingPeriodId: currentRentingPeriodId!,
                inventoryProcessId,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RentingPeriodInventoryProcesses])
                await invalidateQueries([ComplexQueryIDs.RentingPeriodInventoryProcessSectionItems])
            }
        },
    })
}

export const updateInventoryProcessSectionMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({
            inventoryProcessId,
            inventorySectionId,
            operations,
        }: {
            inventoryProcessId: number
            inventorySectionId: number
            operations: jsonpatch.Operation[]
        }) =>
            Api.updateInventoryProcessSection({
                inventorySectionId,
                operations,
                rentingPeriodId: currentRentingPeriodId!,
                inventoryProcessId,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RentingPeriodInventoryProcesses])
                await invalidateQueries([ComplexQueryIDs.RentingPeriodInventoryProcessSectionItems])
            }
        },
    })
}

export const updateInventoryProcessMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ inventoryProcessId, operations }: { inventoryProcessId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateInventoryProcess({
                operations,
                rentingPeriodId: currentRentingPeriodId!,
                inventoryProcessId,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.RentingPeriodInventoryProcesses])
                await invalidateQueries([ComplexQueryIDs.RentingPeriodInventoryProcessSectionItems])
            }
        },
    })
}

export const exportInventoryProcess = () => {
    return useMutation({
        mutationFn: (data: ExportInventoryProcessRequest) => Api.downloadInventoryProcessExport(data),
    })
}
