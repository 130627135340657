import EntityFileManagerDialog from "../../../sections/files/EntityFileManagerDialog"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppContext, DetailsSheet, ExpenseAssignee, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import EntityFileManagerForm from "../../../sections/files/EntityFileManagerForm"
import { InsuranceType, PropertyFileType } from "../../../redux/slices/AddProperty"
import { getIncomeOrPaymentStatusColor } from "../../../utils/helpers"

export default function EntityFileManagerGuard() {
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()

    const handleClose = useCallback(() => {
        if (
            isMobile &&
            editingItem?.type !== PropertyFileType.MeterValue &&
            editingItem?.type !== PropertyFileType.TaskComment &&
            !editingItem.isFromCalendar
        ) {
            if (editingItem?.type === PropertyFileType.Expense) {
                dispatch(setModalOpen(AppModals.ExpenseDetails))
            }
            if (editingItem?.type === PropertyFileType.Income) {
                if (context === AppContext.Owner) {
                    dispatch(setModalOpen(AppModals.IncomeDetails))
                } else {
                    dispatch(setModalOpen(AppModals.PaymentDetails))
                }
            }
            if (editingItem?.type === PropertyFileType.Insurance) {
                dispatch(setModalOpen(AppModals.InsuranceDetails))
            }
            if (editingItem?.type === PropertyFileType.Note) {
                dispatch(setModalOpen(AppModals.NoteDetails))
            }
            if (editingItem?.type === PropertyFileType.Journal) {
                dispatch(setModalOpen(AppModals.JournalDetails))
            }
            if (editingItem?.type === PropertyFileType.TaskFile) {
                dispatch(setModalOpen(AppModals.TaskDetails))
            }
            if (editingItem?.type === PropertyFileType.RevisionObservation) {
                dispatch(setModalOpen(AppModals.ObservationDetails))
            }
            if (editingItem?.type === PropertyFileType.InventoryItem) {
                dispatch(setModalOpen(AppModals.InventorySectionItemDetails))
            }
            if (editingItem?.type === PropertyFileType.SignatureRequest) {
                dispatch(setModalOpen(AppModals.SignatureRequestDetails))
            }
        } else if (isMobile && editingItem?.type === PropertyFileType.MeterValue) {
            dispatch(setModalOpen(AppModals.MeterValuesHistory))
            dispatch(setEditingItem({ ...editingItem, readingId: undefined }))
        } else if (isMobile && editingItem?.type === PropertyFileType.TaskComment) {
            dispatch(setModalOpen(AppModals.TaskComments))
            dispatch(setEditingItem({ ...editingItem, taskCommentId: undefined }))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [editingItem, dispatch, isMobile])

    const getHeaderColor = useCallback(() => {
        if (editingItem?.type === PropertyFileType.Expense) {
            if (editingItem?.assignee === ExpenseAssignee.ME) return theme.palette.primary.main
            return editingItem?.backgroundColor
        }
        if (editingItem?.type === PropertyFileType.Income) return getIncomeOrPaymentStatusColor(editingItem?.status, theme)
        if (editingItem?.type === PropertyFileType.MeterValue)
            return editingItem?.readThisMonth ? theme.palette.success.main : theme.palette.warning.main
        if (editingItem?.type === PropertyFileType.Insurance) {
            if (editingItem?.insuranceType === InsuranceType.Mandatory) return theme.palette.warning.main
            return theme.palette.info.main
        }
    }, [editingItem, theme])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("entityDialogTitle")}
                isOpen={isMobile && modalOpen === AppModals.EntityFileManager}
                onClose={handleClose}
                headerColor={getHeaderColor()}
            >
                <EntityFileManagerForm />
            </DetailsSheet>
            <EntityFileManagerDialog isVisible={!isMobile && modalOpen === AppModals.EntityFileManager} handleClose={handleClose} />
        </>
    )
}
