import { DetailsSheet, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import React, { useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { InventoryProcessStatus } from "../../../../redux/slices/AddProperty"
import { PlayArrow } from "@mui/icons-material"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box } from "@mui/material"
import { renderActionSheetInfo } from "../../utils"
import { DateTime } from "luxon"
import { useRentingPeriodInventoryProcessesQueryFn } from "../../../../queries/inventory"
import { getInventoryProcessStatusColor, getInventoryProcessTypeColor } from "../../../../sections/expenses/utils"

const InventoryProcessDetailsSheet = () => {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const { data: allRentingPeriodProcesses, isLoading: areRentingPeriodInventoryProcessesLoading } = useRentingPeriodInventoryProcessesQueryFn()
    const ourProcess = useMemo(() => {
        return allRentingPeriodProcesses?.find((i) => i.id === editingItem?.id)
    }, [allRentingPeriodProcesses, editingItem])

    const options = useMemo((): CustomSheetOption[] => {
        if (ourProcess == null) return []
        return [
            {
                icon: <PlayArrow color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "inventory_process_fill",
                hidden: ourProcess.status === InventoryProcessStatus.Completed,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.FillInventoryProcess))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteInventoryProcess))
                },
            },
        ]
    }, [ourProcess, dispatch, context])

    if (editingItem == null) return null

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.InventoryProcessDetails}
            onClose={() => {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            }}
            isLoading={areRentingPeriodInventoryProcessesLoading}
            title={translate(`inventory-process-type-${ourProcess?.type}`)}
            subtitle={ourProcess?.createdAt.toLocaleString(DateTime.DATE_SHORT)}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:info",
                    `${translate(`inventory_process_status-${ourProcess?.status}`)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                    true,
                    "",
                    getInventoryProcessStatusColor(ourProcess?.status),
                )}
                {renderActionSheetInfo(
                    "mdi:info",
                    `${translate(`inventory-process-type-${ourProcess?.type}`)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                    true,
                    "",
                    getInventoryProcessTypeColor(ourProcess?.type),
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-month",
                    `${translate("notes.addedAt")} ${ourProcess?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-edit-outline",
                    `${translate("notes.updatedAt")} ${ourProcess?.lastModifiedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}

export default InventoryProcessDetailsSheet
