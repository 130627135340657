import { Box, Button, ButtonGroup, Divider } from "@mui/material"
import { AppContext, LightTooltip, useIsMobile, useLocales } from "rentzz"
import HelpComponent from "../../components/HelpComponent"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"
import React, { useCallback } from "react"
import { expensesFilterState, expensesPageAtom, ExpensesPages } from "../../utils/atom"
import { useDispatch, useSelector } from "../../redux/store"
import { useAtom, useAtomValue } from "jotai"
import { RoleName, UserFlags } from "../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import ExportButton from "./export/ExportButton"
import { ExportType } from "../../utils/actions"
import HeaderExpenses from "./HeaderExpenses"
import { AppModals, setModalOpen } from "../../redux/slices/App"
import RoleLockedButton from "../../components/RoleLockedButton"

interface CustomToolbarProps {
    onAddClick?: () => void
    numberOfItems?: number
    canExport: boolean
    isAddDisabled?: boolean
    tooltipTitle?: string
}

const CustomExpenseToolbar = ({ onAddClick, numberOfItems, canExport, isAddDisabled, tooltipTitle }: CustomToolbarProps) => {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { canWrite } = usePermissions(PermissionType.Expenses)
    const { context, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const [activePage, setActivePage] = useAtom(expensesPageAtom)
    const areExpenseProvidersEnabled = useFeatureIsOn(UserFlags.ExpenseProviders.toString())
    const filterModel = useAtomValue(expensesFilterState)
    const isExpensesAIExtractorOn = useFeatureIsOn(UserFlags.ExpensesAIExtractor.toString())
    const dispatch = useDispatch()

    const handleChange = useCallback(
        (newValue: ExpensesPages) => {
            switch (newValue) {
                case ExpensesPages.ADDED:
                    setActivePage(ExpensesPages.ADDED)
                    break
                case ExpensesPages.PENDING_EXPENSES:
                    setActivePage(ExpensesPages.PENDING_EXPENSES)
                    break
                case ExpensesPages.RECURRING_EXPENSES:
                    setActivePage(ExpensesPages.RECURRING_EXPENSES)
                    break
            }
        },
        [setActivePage],
    )

    return (
        <Box display={"flex"} flexDirection={"column"} width='100%'>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: currentRentingPeriodId ? "flex-end" : "space-between",
                    width: "100%",
                    gap: 2,
                    marginBottom: 2,
                }}
            >
                {context === AppContext.Owner && !currentRentingPeriodId && !currentGroupId ? (
                    <Box justifyContent={isMobile ? "center" : "flex-start"} display={"flex"}>
                        <ButtonGroup aria-label='outlined primary button group'>
                            <Button
                                variant={activePage === ExpensesPages.ADDED ? "contained" : "outlined"}
                                onClick={() => handleChange(ExpensesPages.ADDED)}
                            >
                                {translate("expenses.expenses")}
                            </Button>
                            {areExpenseProvidersEnabled && (
                                <Button
                                    variant={activePage === ExpensesPages.PENDING_EXPENSES ? "contained" : "outlined"}
                                    onClick={() => handleChange(ExpensesPages.PENDING_EXPENSES)}
                                >
                                    {translate("expenses.pending-expenses")}
                                </Button>
                            )}
                            <Button
                                variant={activePage === ExpensesPages.RECURRING_EXPENSES ? "contained" : "outlined"}
                                onClick={() => handleChange(ExpensesPages.RECURRING_EXPENSES)}
                            >
                                {translate("expenses.recurring_expenses")}
                            </Button>
                        </ButtonGroup>
                    </Box>
                ) : (
                    <Box />
                )}
                <Box display='flex' flexDirection='row' gap={1}>
                    {isExpensesAIExtractorOn && context === AppContext.Owner && (
                        <RoleLockedButton
                            blockedRoles={[RoleName.BRONZE, RoleName.FREE, RoleName.SILVER]}
                            onClick={() => {
                                dispatch(setModalOpen(AppModals.ExpenseAIExtractorUpload))
                            }}
                            buttonText={translate("ai_recognition")}
                            isDisabled={isAddDisabled}
                        />
                    )}
                    {canExport && context === AppContext.Owner && filterModel.items?.length === 0 && !currentGroupId && (
                        <ExportButton isDisabled={numberOfItems == null || numberOfItems === 0} type={ExportType.Expense} />
                    )}
                    {canWrite && activePage !== ExpensesPages.PENDING_EXPENSES && (
                        <HelpComponent helpPage={"https://rentzz.app/docs"}>
                            <LightTooltip title={tooltipTitle} arrow>
                                <span>
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={onAddClick}
                                        sx={{ textTransform: "none" }}
                                        disabled={isAddDisabled}
                                    >
                                        {translate(
                                            activePage === ExpensesPages.RECURRING_EXPENSES
                                                ? "recurringExpenses.add_recurring_expense"
                                                : "expenses.addExpense",
                                        )}
                                    </Button>
                                </span>
                            </LightTooltip>
                        </HelpComponent>
                    )}
                </Box>
            </Box>
            {activePage === ExpensesPages.ADDED && (
                <>
                    <HeaderExpenses />
                    <Divider />
                </>
            )}
        </Box>
    )
}

export default CustomExpenseToolbar
