import { DetailsSheet, ExpenseAssignee, LoadingIcon, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import React, { useCallback, useMemo } from "react"
import { useSingleRecurringExpenseQuery } from "../../../../queries/expenses"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import DeleteIcon from "@mui/icons-material/Delete"
import { renderActionSheetInfo } from "../../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { useTheme } from "@mui/material/styles"
import { LabelType, UserFlags, useUserLabelsQuery, useUserPropertiesQuery } from "../../../../queries/userData"
import { getAssigneeLabelColor, getRecurringTypeLabelText } from "../../utils"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../queries/currency"
import { useIntl } from "react-intl"
import { getLabelColor } from "../../../propertyDetails/Tenants/utils"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { useFeatureValue } from "@growthbook/growthbook-react"

const RecurringExpenseDetailsSheet = () => {
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate, currentLang } = useLocales()
    const intl = useIntl()
    const { data: labels } = useUserLabelsQuery(LabelType.Expense)
    const { data: currencyData } = useCurrencyQueryFn()
    const { data: properties } = useUserPropertiesQuery()
    const { data: currentRecurringExpense } = useSingleRecurringExpenseQuery(editingItem?.id, [{ field: "createdAt", sort: "desc" }])
    const { canWrite } = usePermissions(PermissionType.RecurringExpense, currentRecurringExpense?.propertyId)
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)

    const handleOnClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [])

    const sheetOptions = useMemo((): CustomSheetOption[] => {
        if (currentRecurringExpense == null) return []
        if (!canWrite) return []
        return [
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !currentRecurringExpense.canDelete,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteRecurringExpense))
                },
            },
        ]
    }, [currentRecurringExpense, dispatch])

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""

            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData],
    )

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.RecurringExpenseDetails}
            onClose={handleOnClose}
            isLoading={currentRecurringExpense == null}
            title={currentRecurringExpense?.name}
            options={sheetOptions}
            headerColor={currentRecurringExpense?.isActive ? theme.palette.success.main : theme.palette.warning.main}
        >
            {currentRecurringExpense == null ? (
                <LoadingIcon />
            ) : (
                <>
                    {renderActionSheetInfo(
                        "mdi:cash",
                        `${getAmount(currentRecurringExpense?.value ?? 0, currentRecurringExpense?.currencyId)}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                        true,
                        `${translate(`expenses.assignee-${currentRecurringExpense.assignee}${customPaidBy ? "-" + customPaidBy : ""}`)}`,
                        currentRecurringExpense ? getAssigneeLabelColor(currentRecurringExpense.assignee) : "default",
                    )}
                    {renderActionSheetInfo(
                        "mdi:calendar-month",
                        `${translate("recurring_expenses.startDate")} ${currentRecurringExpense?.startDate?.toLocaleString(DateTime.DATE_SHORT)}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                    {renderActionSheetInfo(
                        "mdi:calendar-clock",
                        `${translate("expirationDate")}: ${currentRecurringExpense?.expiresAt?.toLocaleString(DateTime.DATE_SHORT) ?? "-"}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                    {renderActionSheetInfo(
                        "mdi:refresh-auto",
                        translate(getRecurringTypeLabelText(currentRecurringExpense!.recurringType, currentLang.value, true)),
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                    {currentRecurringExpense.assignee !== ExpenseAssignee.ME &&
                        renderActionSheetInfo(
                            currentRecurringExpense?.shouldNotifyOtherParty ? "mdi:bell" : "mdi:bell-off",
                            translate(`recurring-should-notify-${currentRecurringExpense?.shouldNotifyOtherParty}`),
                            theme.palette.primary.main,
                            theme.palette.grey["600"],
                        )}
                    {renderActionSheetInfo(
                        currentRecurringExpense?.isActive ? "mdi:radiobox-marked" : "mdi:close-circle-outline",
                        translate(`recurring-isActive-${currentRecurringExpense?.isActive}`),
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                    {currentRecurringExpense?.labelId &&
                        renderActionSheetInfo(
                            "mdi:label",
                            labels?.find((label) => label.id === currentRecurringExpense.labelId)?.value,
                            `#${getLabelColor(currentRecurringExpense.labelId)}`,
                            theme.palette.grey["600"],
                        )}
                    {renderActionSheetInfo(
                        "mdi:map-marker",
                        properties?.find((p) => p.id === currentRecurringExpense?.propertyId)?.label,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                    {currentRecurringExpense.invoiceCompany &&
                        renderActionSheetInfo(
                            "mdi:receipt-text",
                            `${currentRecurringExpense.invoiceCompany}, ${currentRecurringExpense.invoiceClient}, ${
                                currentRecurringExpense.invoicingSeries
                            }, ${currentRecurringExpense.invoiceLanguage},${getAmount(
                                currentRecurringExpense?.value ?? 0,
                                currentRecurringExpense?.invoiceCurrencyId,
                            )}, ${currentRecurringExpense.invoiceTVA}, ${currentRecurringExpense.invoiceProductName}, ${
                                currentRecurringExpense.invoiceProductDescription
                            }, ${translate("invoicing_period")}: ${currentRecurringExpense.invoicingPeriodStart} - ${
                                currentRecurringExpense.invoicingPeriodEnd
                            }`,
                            theme.palette.primary.main,
                            theme.palette.grey["600"],
                        )}
                </>
            )}
        </DetailsSheet>
    )
}

export default RecurringExpenseDetailsSheet
