import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import React, { SyntheticEvent, useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import DeleteIcon from "@mui/icons-material/Delete"
import { renderActionSheetInfo } from "../../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { getBankAccountConsentStatusColor } from "./utils"
import { getBankAccountConsentByIdQueryFn } from "../../../../queries/bankAccountConsents"

export default function BankAccountConsentDetailsSheet() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()

    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const currentBankAccountConsent = getBankAccountConsentByIdQueryFn(editingItem?.id)

    const options = useMemo((): CustomSheetOption[] => {
        if (currentBankAccountConsent == null) return []

        return [
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteBankAccountConsent))
                },
            },
        ]
    }, [currentBankAccountConsent, dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.BankAccountConsentDetails}
            onClose={(event: MouseEvent | TouchEvent | PointerEvent | SyntheticEvent<object, Event> | undefined) => {
                event?.stopPropagation()
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={false}
            title={`${translate(`bank-name-${currentBankAccountConsent?.bankCode as number}`)}`}
            subtitle={`${currentBankAccountConsent?.createdAt.toLocaleString(DateTime.DATE_SHORT)}`}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            {renderActionSheetInfo(
                "mdi:bank-transfer",
                `${translate(`consentStatus-${currentBankAccountConsent?.consentStatus as number}`)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
                true,
                "",
                getBankAccountConsentStatusColor(currentBankAccountConsent?.consentStatus),
            )}
            {renderActionSheetInfo(
                "mdi:calendar-clock",
                `${translate("createdAt")}: ${currentBankAccountConsent?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:calendar-clock",
                `${translate("lastModifiedAt")}: ${currentBankAccountConsent?.lastModifiedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
