import React, { ReactNode, useEffect, useMemo, useState } from "react"
import Api from "./api/Api"
import { logoAtom, ThemeProvider } from "rentzz"
import { UserFlags } from "./queries/userData"
import { useAtom } from "jotai/index"
import { useFeatureIsOn, useFeatureValue, useGrowthBook } from "@growthbook/growthbook-react"
import * as Sentry from "@sentry/react"
import { createRoutesFromChildren, matchRoutes, useLocation } from "react-router-dom"
import { useNavigationType } from "react-router"

interface ConfigurationProviderProps {
    children: ReactNode
}
const ConfigurationProvider = ({ children }: ConfigurationProviderProps) => {
    const [logoAtomValue, setLogoAtom] = useAtom(logoAtom)
    const themeInfoColor = useFeatureValue(UserFlags.ThemeInfo, {} as any)
    const themeSuccessColor = useFeatureValue(UserFlags.ThemeSuccess, {} as any)
    const themeWarningColor = useFeatureValue(UserFlags.ThemeWarning, {} as any)
    const themeSecondaryColor = useFeatureValue(UserFlags.ThemeSecondary, {} as any)
    const themeErrorColor = useFeatureValue(UserFlags.ThemeError, {} as any)
    const themePrimaryColor = useFeatureValue(UserFlags.ThemePrimary, {} as any)
    const logoDimensions = useFeatureValue(UserFlags.Logo, {} as any)
    const backendPath = useFeatureValue(UserFlags.BackendPath, {} as any)
    const isOpenReplayEnabled = useFeatureIsOn(UserFlags.OpenReplay as string)
    const growthbook = useGrowthBook()
    const [isReady, setIsReady] = useState(false)

    useEffect(() => {
        if (
            Object.keys(themeInfoColor).length > 0 &&
            Object.keys(themeSuccessColor).length > 0 &&
            Object.keys(themeWarningColor).length > 0 &&
            Object.keys(themeErrorColor).length > 0 &&
            Object.keys(themeSecondaryColor).length > 0 &&
            Object.keys(themePrimaryColor).length > 0
        ) {
            window.themeInfoColor = themeInfoColor
            window.themeSuccessColor = themeSuccessColor
            window.themeWarningColor = themeWarningColor
            window.themeErrorColor = themeErrorColor
            window.themeSecondaryColor = themeSecondaryColor
            window.themePrimaryColor = themePrimaryColor
            setIsReady(true)
        }
    }, [themeInfoColor, themeSuccessColor, themeWarningColor, themeErrorColor, themeSecondaryColor, themePrimaryColor])

    const style = useMemo(() => {
        return `/* Track */
            /* Handle */
            ::-webkit-scrollbar-thumb {
                background: ${themePrimaryColor.main};
                border-radius: 10px;
            }
            
            ::-webkit-scrollbar-track {
                background: ${themePrimaryColor.lighter};
                border-radius: 10px;
            }

            /* Handle on hover */
            ::-webkit-scrollbar-thumb:hover {
                background: ${themePrimaryColor.dark};
            }
            `
    }, [themePrimaryColor])

    useEffect(() => {
        // Load features asynchronously when the app renders
        growthbook?.loadFeatures()
    }, [])

    useEffect(() => {
        if (import.meta.env.PROD) {
            Api.API = backendPath as string
            if (isOpenReplayEnabled)
                Sentry.init({
                    dsn: "https://e990fcb5c0079abbea12d0df351b9025@stry.rentzz.app/2",
                    // This enables automatic instrumentation (highly recommended)
                    // If you only want to use custom instrumentation:
                    // * Remove the BrowserTracing integration
                    // * add Sentry.addTracingExtensions() above your Sentry.init() call
                    integrations: [
                        Sentry.browserTracingIntegration(),
                        Sentry.browserProfilingIntegration(),
                        Sentry.replayIntegration({
                            maskAllText: false,
                            blockAllMedia: false,
                        }),
                        // Or, if you are using react router, use the appropriate integration
                        // See docs for support for different versions of react router
                        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                        Sentry.reactRouterV6BrowserTracingIntegration({
                            useEffect: React.useEffect,
                            useLocation,
                            useNavigationType,
                            createRoutesFromChildren,
                            matchRoutes,
                        }),
                    ],

                    // For finer control of sent transactions you can adjust this value, or
                    // use tracesSampler
                    tracesSampleRate: 1.0,
                    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
                    replaysOnErrorSampleRate: 1.0,
                    profilesSampleRate: 1.0,
                    release: import.meta.env.VITE_COMMIT_HASH,
                })
        } else {
            Api.API = import.meta.env.VITE_API_URL
        }
    }, [backendPath, isOpenReplayEnabled])

    useEffect(() => {
        if (!logoDimensions.logoMenuWidth) return
        setLogoAtom({
            loading: {
                width: Number(logoDimensions.logoLoadingWidth),
                height: Number(logoDimensions.logoLoadingHeight),
            },
            menu: {
                width: Number(logoDimensions.logoMenuWidth),
                height: Number(logoDimensions.logoMenuHeight),
            },
        })
    }, [logoDimensions])

    if (!growthbook?.ready || logoAtomValue.loading == null || !isReady) return null

    return (
        <ThemeProvider>
            <style>{style}</style>
            {children}
        </ThemeProvider>
    )
}

export default ConfigurationProvider
