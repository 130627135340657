import { Tab } from "@mui/material"
import React, { useCallback, useEffect } from "react"
import { useParams } from "react-router"
import { useNavigate } from "react-router-dom"
import { CustomTabs, useLocales } from "rentzz"
import { useAtom } from "jotai"
import { useSelector } from "../../../redux/store"
import { bankAccountsPage, BankAccountsPages } from "../../../utils/atom"
import { a11yProps } from "../../../utils/A11yProps"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags, useUserDataQueryFn } from "../../../queries/userData"

export default function BankAccountsPageTabs() {
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { page } = useParams()
    const navigate = useNavigate()
    const { translate } = useLocales()
    const [propertyPage, setPropertyPage] = useAtom(bankAccountsPage)
    const isConsentsTabVisible = useFeatureIsOn(UserFlags.SmartAccountsSync.toString())
    const { data: userData } = useUserDataQueryFn()

    useEffect(() => {
        switch (page) {
            case "details":
                setPropertyPage(BankAccountsPages.BANK_ACCOUNTS)
                break
            case "consents":
                setPropertyPage(BankAccountsPages.CONSENTS)
                break
            default:
                setPropertyPage(BankAccountsPages.BANK_ACCOUNTS)
        }
    }, [page])

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: BankAccountsPages) => {
            switch (newValue) {
                case BankAccountsPages.BANK_ACCOUNTS:
                    navigate("/nomenclature/bank-accounts/details")
                    break
                case BankAccountsPages.CONSENTS:
                    navigate("/nomenclature/bank-accounts/consents")
                    break
            }
        },
        [navigate, currentPropertyId],
    )

    return (
        <CustomTabs value={propertyPage} onChange={handleChange} allowScrollButtonsMobile scrollButtons='auto' variant='scrollable'>
            <Tab iconPosition='end' label={translate("bank_accounts")} {...a11yProps(0)} />
            {isConsentsTabVisible && userData?.isNonWhiteLabelOrWhiteLabelAdminUser && (
                <Tab iconPosition='end' label={translate("consents")} {...a11yProps(1)} />
            )}
        </CustomTabs>
    )
}
