import { FormProvider, useIsMobile, useLocales } from "rentzz"
import { PropertyIncome } from "../../../redux/slices/AddProperty"
import React, { useCallback } from "react"
import { useForm } from "react-hook-form"
import { AddIncomeRequest } from "./types"
import { yupResolver } from "@hookform/resolvers/yup"
import { IncomeSchema } from "../../../validations/income"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import AddIncomeTypeExpenseForm from "./AddIncomeTypeExpenseForm"
import { useUserDataQueryFn } from "../../../queries/userData"
import { PropertyExpense } from "../../../queries/expenses"

interface AddIncomeContentProps {
    onClose: () => void
    defaultValues: PropertyIncome | PropertyExpense | undefined | null
    onSave: (data: AddIncomeRequest) => Promise<void>
    isLoading: boolean
}
export default function AddIncomeContent({ onSave, defaultValues, onClose, isLoading }: AddIncomeContentProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { data: userData } = useUserDataQueryFn()

    const methods = useForm<AddIncomeRequest>({
        resolver: yupResolver(IncomeSchema),
        mode: "onChange",
        defaultValues: {
            currencyId: userData?.currency.id,
        },
    })

    const { handleSubmit, resetField } = methods

    const onSubmit = useCallback(
        async (data: AddIncomeRequest) => {
            await onSave(data)
        },
        [onSave],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <>
                <AddIncomeTypeExpenseForm defaultValues={defaultValues as PropertyIncome} />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button
                        color={"primary"}
                        disabled={isLoading}
                        onClick={() => {
                            if (defaultValues) {
                                onClose()
                            } else {
                                resetField("referenceId")
                                resetField("value")
                                resetField("paymentMethod")
                                resetField("date")
                            }
                        }}
                        fullWidth={isMobile}
                    >
                        {translate(defaultValues ? "cancel" : "back")}
                    </Button>
                    <LoadingButton color={"primary"} type='submit' variant='contained' disabled={isLoading} loading={isLoading} fullWidth={isMobile}>
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </>
        </FormProvider>
    )
}
