import { useDispatch } from "../../../redux/store"
import { CustomDataGrid, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import React, { useMemo } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteIcon from "@mui/icons-material/Delete"
import { Box, Card, CardContent } from "@mui/material"
import { UserBankAccount, useUserPropertiesQuery } from "../../../queries/userData"
import NoDataPage from "../../../components/NoDataPage"
import Header from "../../expenses/expense-details/Header"

interface DesktopBankAccountPropertiesList {
    id: number
    name: number
    county: string
    city: string
}
interface Props {
    currentBankAccount: GridRowParams<UserBankAccount>
}
export default function BankAccountProperties({ currentBankAccount }: Props) {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const theme = useTheme()
    const { data: properties } = useUserPropertiesQuery()

    const currentProperties = useMemo(() => {
        if (currentBankAccount == null || properties == null) return

        return currentBankAccount.row.properties.map((id) => {
            const match = properties.find((property) => property.id == id)
            return {
                id: match!.id,
                name: match!.label,
                county: match!.county,
                city: match!.city,
            }
        })
    }, [properties, currentBankAccount])

    const propertiesColumns = useMemo(() => {
        const columns: GridColDef<DesktopBankAccountPropertiesList>[] = [
            {
                field: "name",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("properties"),
            },
            {
                field: "county",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("county"),
                valueFormatter: (value) => {
                    return value ?? "-"
                },
            },
            {
                field: "city",
                type: "string",
                flex: 1,
                minWidth: 180,
                headerAlign: "center",
                align: "center",
                headerName: translate("city"),
                valueFormatter: (value) => {
                    return value ?? "-"
                },
            },
        ]

        return columns
    }, [translate])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.5,
            minWidth: 180,
            type: "actions",
            disableReorder: true,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    tooltipText: translate("delete"),
                    key: "delete",
                    element: (
                        <GridActionsCellItem
                            key='delete'
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeletePropertyFromBankAccount))
                                dispatch(setEditingItem({ propertyId: params.row.id, bankAccountId: currentBankAccount.id }))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
            ],
        }),
        [translate],
    )

    return (
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
            <Card
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "90%",
                    height: "100%",
                }}
            >
                <CardContent
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        color: theme.palette.grey["700"],
                        alignSelf: "center",
                        gap: 2,
                        paddingBottom: 0,
                        "&.MuiCardContent-root:last-child": { paddingBottom: 0 },
                    }}
                >
                    <>
                        <Header currentItem={currentBankAccount.row} itemType='bankAccount' />
                        {currentProperties && currentProperties.length > 0 ? (
                            <CustomDataGrid
                                isLoading={false}
                                gridId={"BankAccountProperties"}
                                columns={propertiesColumns}
                                rows={currentProperties ?? []}
                                idKey={"id"}
                                totalCount={currentProperties?.length}
                                actionsColumn={[actionsColumn]}
                                gridHeight={270}
                                paginationMode={"client"}
                                hidePagination={currentProperties && currentProperties.length < 4}
                            />
                        ) : (
                            <NoDataPage icon={""} noDataText={"no_property_assigned_to_bank_account"} paddingY={3} />
                        )}
                    </>
                </CardContent>
            </Card>
        </Box>
    )
}
