import React, { useCallback } from "react"
import { FormProvider } from "rentzz"
import { Box, inputBaseClasses } from "@mui/material"
import * as jsonpatch from "fast-json-patch"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { updateRentingPeriodMutation } from "../../../../mutations/tenants"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import { PropertyInternalIdSchema } from "../../../../validations/kanban"
import RHFEditableInput from "../../../../components/RHFEditableInput"

interface RentingPeriodInternalIdCellProps {
    propertyId: number
    currentInternalId?: string
    rentingPeriodId: number
}

const RentingPeriodInternalIdCell = ({ propertyId, currentInternalId, rentingPeriodId }: RentingPeriodInternalIdCellProps) => {
    const { mutateAsync: updateRentingPeriod, isPending: isPropertyUpdating } = updateRentingPeriodMutation()
    const { canWrite } = usePermissions(PermissionType.Expenses, propertyId)

    const methods = useForm<{ internalId: string }>({
        resolver: yupResolver(PropertyInternalIdSchema),
        defaultValues: {
            internalId: currentInternalId == null || currentInternalId === "" ? "-" : currentInternalId,
        },
    })

    const { getValues, handleSubmit, setValue } = methods

    const onSubmit = useCallback(async () => {
        if (propertyId == null) return
        if (currentInternalId === getValues("internalId") || getValues("internalId") === "" || getValues("internalId") == null) return
        const operations = jsonpatch.compare({ internalId: currentInternalId }, { internalId: getValues("internalId") })

        await updateRentingPeriod({
            operations,
            propertyId,
            tenantId: rentingPeriodId,
        })
    }, [propertyId, currentInternalId, rentingPeriodId])

    return (
        <Box display='flex' justifyContent='center' width='100%' alignItems='center'>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <RHFEditableInput
                    name={"internalId"}
                    onBlur={onSubmit}
                    disabled={isPropertyUpdating || !canWrite}
                    onFocus={() => {
                        if (getValues("internalId") === "-") setValue("internalId", "")
                    }}
                    sx={{
                        [`& .${inputBaseClasses.input}`]: {
                            typography: "body2",
                            textAlign: "center",
                            cursor: "pointer",
                        },
                        [`&.${inputBaseClasses.root}`]: {
                            py: 0.75,
                            borderRadius: 1,
                            borderWidth: 2,
                            borderStyle: "solid",
                            borderColor: "transparent",
                            transition: (theme) => theme.transitions.create(["padding-left", "border-color"]),
                            [`&.${inputBaseClasses.focused}`]: {
                                borderColor: "text.primary",
                            },
                        },
                    }}
                />
            </FormProvider>
        </Box>
    )
}

export default RentingPeriodInternalIdCell
