import { PersonalDataSchema, PersonType } from "rentzz"
import * as yup from "yup"
import { array, number, object, string } from "yup"

export const PropertyOwnerBaseSchema = () =>
    PersonalDataSchema().shape({
        id: string().optional(),
        lastModifiedAt: string().optional(),
    })

export const PropertyOwnerConfigSchema = (isAssociatingProperties: boolean, isEditing: boolean) =>
    PropertyOwnerBaseSchema().shape({
        identifierType: number().optional(),
        customIdentifierType: string().optional(),
        // CI fields with conditional validation
        identificationDocumentSeries: yup.string().when(["owner", "owner.personType"], {
            is: (owner: any, personType: PersonType) => personType === PersonType.Natural,
            then: () => yup.string().required("required"),
            otherwise: () => yup.string().optional(),
        }),
        identificationDocumentNumber: yup.string().when(["owner", "owner.personType"], {
            is: (owner: any, personType: PersonType) => personType === PersonType.Natural,
            then: () => yup.string().required("required"),
            otherwise: () => yup.string().optional(),
        }),
        identificationDocumentIssuer: yup.string().when(["owner", "owner.personType"], {
            is: (owner: any, personType: PersonType) => personType === PersonType.Natural,
            then: () => yup.string().required("required"),
            otherwise: () => yup.string().optional(),
        }),
        identificationDocumentIssuedDate: yup.string().when(["owner", "owner.personType"], {
            is: (owner: any, personType: PersonType) => personType === PersonType.Natural,
            then: () => yup.string().required("required"),
            otherwise: () => yup.string().optional(),
        }),
        properties: array()
            .of(
                object()
                    .shape({
                        propertyId: number()
                            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
                            .required("required")
                            .test("unique-propertyId", "unique_property_id", (_, parent) => {
                                const propertyIds = parent.from
                                    ?.at(1)
                                    ?.value.properties?.map((property: { propertyId: number }) => property.propertyId)
                                return propertyIds?.length === new Set(propertyIds).size
                            }),
                        percentage: number().required("required").min(0, "required").max(100, "100_max"),
                    })
                    .required("required"),
            )
            .required("required")
            .min(isEditing ? 0 : 1, "required"),
    })

// Individual CI field schemas with proper typing
export const PropertyOwnerRepresentativeSchema = PropertyOwnerBaseSchema().shape({
    startDate: yup.string().required("required"),
    endDate: yup.string().required("required"),

    // CI fields with conditional validation
    identificationDocumentSeries: yup.string().when(["owner", "owner.personType"], {
        is: (owner: any, personType: PersonType) => personType === PersonType.Natural,
        then: () => yup.string().required("required"),
        otherwise: () => yup.string().optional(),
    }),

    identificationDocumentNumber: yup.string().when(["owner", "owner.personType"], {
        is: (owner: any, personType: PersonType) => personType === PersonType.Natural,
        then: () => yup.string().required("required"),
        otherwise: () => yup.string().optional(),
    }),

    identificationDocumentIssuer: yup.string().when(["owner", "owner.personType"], {
        is: (owner: any, personType: PersonType) => personType === PersonType.Natural,
        then: () => yup.string().required("required"),
        otherwise: () => yup.string().optional(),
    }),

    identificationDocumentIssuedDate: yup.string().when(["owner", "owner.personType"], {
        is: (owner: any, personType: PersonType) => personType === PersonType.Natural,
        then: () => yup.string().required("required"),
        otherwise: () => yup.string().optional(),
    }),
})
