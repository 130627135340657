import { GeneratedStatus } from "../../../queries/invoicing-configuration"

export const getDryRunGeneratedStatusColor = (value: GeneratedStatus) => {
    switch (value) {
        case GeneratedStatus.OK:
            return "success"
        case GeneratedStatus.NotConfigured:
            return "primary"
        case GeneratedStatus.SkippedDueToDeactivation:
            return "info"
    }
}
