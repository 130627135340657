import { array, number, object, string } from "yup"

export const AddGroupSchema = object().shape({
    name: string().required("required").min(3, "min_3_characters"),
    description: string().required("required").min(3, "min_3_characters"),
    properties: array().required().min(1, "required"),
    createdAt: string().optional(),
})

export const GroupNameSchema = object().shape({
    name: string().required("required").min(3, "min_3_characters"),
})

export const GroupDescriptionSchema = object().shape({
    description: string().required("required").min(3, "min_3_characters"),
})

export const PropertyPercentageSchema = object().shape({
    percentage: number().required("required"),
})

export const NewGroupAccessSchema = () =>
    object().shape({
        email: string().email("must_be_email").required("required"),
        managerType: number().required("required"),
    })

export const NewGroupDashboardConfigurationSchema = () =>
    object().shape({
        name: string().required("required").min(3, "min_3_characters"),
    })
