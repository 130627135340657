import { useDispatch, useSelector } from "../../../redux/store"
import { DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteSheetContent from "../../../sections/DeleteSheetContent"
import DeleteDialog from "../../../dialogs/DeleteDialog"
import { deleteGroupDashboardConfigurationMutation } from "../../../mutations/groups"
import { dashboardConfigurationAtom } from "../../../utils/atom"
import { useUserGroupsQueryFn } from "../../../queries/groups"
import { useAtom } from "jotai/index"

export default function DeleteDashboardConfiguration() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync, isPending: isDeletingDashboardConfiguration } = deleteGroupDashboardConfigurationMutation()
    const { currentGroupId } = useSelector((state) => state.appState)
    const [dashboardPreferences, setDashboardPreferences] = useAtom(dashboardConfigurationAtom)
    const { data: groups } = useUserGroupsQueryFn()
    const currentGroup = useMemo(() => groups?.find((group) => group.id === currentGroupId), [groups, currentGroupId])
    const currentDashboardConfig = useMemo(() => {
        return (
            currentGroup?.dashboardSharingSettings.find((s) => s.id == dashboardPreferences[currentGroup.id]) ??
            currentGroup?.dashboardSharingSettings.at(0)
        )
    }, [currentGroup, dashboardPreferences])

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const handleDelete = useCallback(async () => {
        if (currentDashboardConfig && currentGroup) {
            setDashboardPreferences((prev) => {
                localStorage.setItem(
                    "dashboard_preferences",
                    JSON.stringify({
                        ...prev,
                        [Number(currentGroup.id)]: Number(currentGroup.dashboardSharingSettings?.find((g) => g.id != currentDashboardConfig.id)?.id),
                    }),
                )

                return {
                    ...prev,
                    [Number(currentGroup.id)]: Number(currentGroup.dashboardSharingSettings?.find((g) => g.id != currentDashboardConfig.id)?.id),
                }
            })

            await mutateAsync({
                dashboardConfigurationId: currentDashboardConfig.id,
                groupId: currentGroup.id,
            })
            dispatch(setModalOpen(undefined))
        }
    }, [editingItem, mutateAsync, currentGroup, currentDashboardConfig])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("delete_group_dashboard_configuration")}
                isOpen={isMobile && modalOpen === AppModals.DeleteGroupDashboardConfiguration}
                onClose={closeModal}
            >
                <DeleteSheetContent
                    content={translate("delete_group_dashboard_configuration_content")}
                    onClose={closeModal}
                    isDeleteLoading={isDeletingDashboardConfiguration}
                    colorSubmitButton={"error"}
                    onAccept={handleDelete}
                    submitText={"delete"}
                />
            </DetailsSheet>

            <DeleteDialog
                isVisible={!isMobile && modalOpen === AppModals.DeleteGroupDashboardConfiguration}
                isDeleteLoading={isDeletingDashboardConfiguration}
                title={translate("delete_group_dashboard_configuration")}
                content={translate("delete_group_dashboard_configuration_content")}
                onClose={closeModal}
                onAccept={handleDelete}
                submitText={"delete"}
                acceptButtonColor={"error"}
            />
        </>
    )
}
