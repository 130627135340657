import { C168AddressConfig, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { Box, Button } from "@mui/material"
import Api from "../../../../../api/Api"

// Props for the Owner Details step
export interface OwnerDetailsStepProps {
    onNext: () => void
}

// Step 1: Owner Details Component
const OwnerDetailsStep = ({ onNext }: OwnerDetailsStepProps) => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Box display='flex' flexDirection='column' gap={2}>
            <C168AddressConfig api={Api} showCompleteInfo={false} isLoading={false} hideButtons prefix='' />

            <Box display='flex' justifyContent='flex-end'>
                <Button variant='contained' color='primary' onClick={onNext} fullWidth={isMobile}>
                    {translate("next")}
                </Button>
            </Box>
        </Box>
    )
}

export default OwnerDetailsStep
