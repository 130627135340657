import { ComplexQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import { AddSigningRequest } from "../sections/propertyDetails/signature-requests/types"

export const addSignatureRequestMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddSigningRequest }) => Api.addSignatureRequest(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.SignatureRequests])
            }
        },
    })
}

export const deleteSignatureRequestMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ signatureRequestId }: { signatureRequestId: number }) => Api.deleteSignatureRequest({ signatureRequestId }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.SignatureRequests])
            }
        },
    })
}
