import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { useSelector } from "../redux/store"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import { AppContext } from "rentzz"
import Api from "../api/Api"
import { SignatureRequest } from "../redux/slices/AddProperty"
import { PaginatedData } from "./expenses"
import { sum } from "lodash"

export const useSignatureRequestsQueryFn = (
    propertyId: number | undefined,
    page: number,
    pageSize: number,
    sortingColumns: GridSortModel,
    filterModel: GridFilterModel,
) => {
    const { context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.SignatureRequests)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.SignatureRequests,
            {
                propertyId,
                page,
                pageSize,
                sortingColumns,
                filterModel,
            },
        ],
        queryFn: Api.fetchSignatureRequests,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!propertyId,
        select: (data) => {
            return {
                count: data.count,
                items: data.items.map((i) => ({
                    ...i,
                    canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                })),
            }
        },
    })
}

export const useInfiniteSignatureRequestsQuery = (propertyId: number | undefined, sortingColumns?: GridSortModel) => {
    const { context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.SignatureRequests)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [
            ComplexQueryIDs.SignatureRequests,
            {
                propertyId,
                sortingColumns,
            },
        ],
        queryFn: Api.fetchInfiniteSignatureRequests,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!propertyId,
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}

export const getSignatureRequestByIdQueryFn = (
    signatureRequestId?: number,
): (SignatureRequest & { canWrite: boolean; canDelete: boolean }) | undefined => {
    const queryClient = useQueryClient()
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.SignatureRequests)

    const revisionsPages = queryClient.getQueriesData({
        queryKey: [ComplexQueryIDs.SignatureRequests],
        stale: false,
    })

    const arrayOfRevisions = (
        (revisionsPages?.flatMap((revisionWithKey) => revisionWithKey[1]) as { pages: PaginatedData<SignatureRequest> }[]).flatMap((i) =>
            i?.pages != null ? i.pages : i,
        ) as PaginatedData<SignatureRequest>[]
    )?.flatMap((r) => r?.items) as (SignatureRequest & {
        canWrite: boolean
        canDelete: boolean
    })[]

    const revision = arrayOfRevisions?.find((revision) => revision?.id == signatureRequestId)

    if (revision) {
        return {
            ...revision,
            canWrite: editablePropertiesIds?.includes(revision.propertyId) ?? false,
            canDelete: deletablePropertiesIds?.includes(revision.propertyId) ?? false,
        }
    } else return revision ?? undefined
}

export const useSignCodeDetails = (code?: string) => {
    return useQuery({
        queryKey: [
            ComplexQueryIDs.SignCodeDetails,
            {
                code,
            },
        ],
        queryFn: () => Api.getSignCodeDetails(code!),
        staleTime: 100000,
        enabled: !!code,
    })
}
