import { Box, Button } from "@mui/material"
import ChooseCardPage from "../../income/add-income/ChooseCardPage"
import React from "react"
import { useIsMobile, useLocales } from "rentzz"

interface DocumentTypePickerProps {
    onChange: (type: "presigned" | "signRequest") => void
    onClose: () => void
}

const DocumentTypePicker = ({ onChange, onClose }: DocumentTypePickerProps) => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    return (
        <Box display='flex' flexDirection='column' gap={2}>
            <>
                <ChooseCardPage
                    title={translate("presigned_document")}
                    icon={"mdi:file-document-plus-outline"}
                    onClick={() => onChange("presigned")}
                />
                <ChooseCardPage title={translate("signRequest_document")} icon={"mdi:document-sign"} onClick={() => onChange("signRequest")} />
            </>

            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingX: 0, paddingTop: 2 }}>
                <Button color={"primary"} onClick={onClose} variant={isMobile ? "contained" : "text"} fullWidth={isMobile}>
                    {translate("cancel")}
                </Button>
            </Box>
        </Box>
    )
}

export default DocumentTypePicker
