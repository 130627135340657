import { Box, CircularProgress, Typography, useTheme } from "@mui/material"
import { useSummaryCardQuery } from "../queries"
import React, { useCallback, useMemo } from "react"
import { AppContext, Iconify, LightTooltip, useIsMobile, useLocales } from "rentzz"
import { useUserDataQueryFn } from "../queries/userData"
import { getCurrencyFromId, useCurrencyQueryFn } from "../queries/currency"
import { useIntl } from "react-intl"
import { DashboardDataType, DashboardReferencedType, DashboardSentiment } from "../redux/slices/AddProperty"
import { DateTime } from "luxon"
import { useDispatch, useSelector } from "../redux/store"
import { AppModals, setModalOpen } from "../redux/slices/App"
import { arrayOfNumbersToPeriod } from "../utils/dateMagic"
import { useNavigate } from "react-router-dom"
import CustomCard from "./CustomCard"
import { useRentingPeriodsWithPaginationQueryFn } from "../queries/tenants"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { rentingPeriodPageAtom, RentingPeriodsPages } from "../utils/atom"
import { useSetAtom } from "jotai"

interface SummaryCardProps {
    cardId: number
    isPreview?: boolean
    isPublic?: boolean
}

const SummaryCard = ({ cardId, isPreview, isPublic }: SummaryCardProps) => {
    const { context, currentPropertyId } = useSelector((state) => state.appState)
    const { data: user } = isPublic ? {} : useUserDataQueryFn()
    const { data: tenants } = isPublic
        ? { data: { count: 0, items: [] } }
        : useRentingPeriodsWithPaginationQueryFn(0, 0, [{ field: "moveInDate", sort: "desc" }], undefined)
    const { data: cardData, isFetching } = useSummaryCardQuery(cardId, context !== AppContext.Owner && user ? currentPropertyId : null)
    const { data: currencies } = useCurrencyQueryFn()
    const intl = useIntl()
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { canWrite } = isPublic ? { canWrite: false } : usePermissions(PermissionType.Properties)
    const setRentingPeriodPage = useSetAtom(rentingPeriodPageAtom)

    const isIncomeDisabled = useMemo(() => tenants?.count === 0 && context === AppContext.Owner, [tenants, context])

    const isCardClickable = useCallback(() => {
        if (!cardData || isPreview || isPublic) return false

        if (isIncomeDisabled && cardData.referencedType === DashboardReferencedType.Income) return false
        if (context === AppContext.Owner) {
            return true
        }

        if (
            context === AppContext.Tenant &&
            [DashboardReferencedType.Expense, DashboardReferencedType.Income, DashboardReferencedType.Meter].includes(cardData.referencedType)
        ) {
            return true
        }
    }, [cardData, isIncomeDisabled, context, isPublic])

    const getCurrency = useCallback(
        (value: string | undefined) => {
            const currency = user?.currency.id ?? 1 // RON
            return intl.formatNumber(Number(value), {
                style: "currency",
                currency: getCurrencyFromId(currencies, currency).code,
            })
        },
        [user, intl, getCurrencyFromId, currencies],
    )

    const getTheRightValue = useCallback(() => {
        if (!cardData) return ""

        if (cardData.dataType === DashboardDataType.Missing && !canWrite) {
            return getCurrency("0")
        }
        if (cardData.dataType === DashboardDataType.Money) {
            return getCurrency(cardData.value)
        } else if (cardData.dataType === DashboardDataType.Date) {
            return DateTime.fromISO(cardData.value).toLocaleString(DateTime.DATE_SHORT)
        } else if (cardData.dataType === DashboardDataType.Number) {
            return intl.formatNumber(Number(cardData.value))
        } else if (cardData.dataType === DashboardDataType.NumberArray) {
            return arrayOfNumbersToPeriod(JSON.parse(cardData.value)).join(", ")
        } else return cardData.value
    }, [cardData, getCurrency, intl, arrayOfNumbersToPeriod, canWrite])

    const getCurrencyPreviousValue = useCallback(() => {
        if (!cardData) return null

        const currency = user?.currency.id ?? 1 // RON
        const differenceExpense = Number(cardData.value) - Number(cardData.previousValue)
        return intl.formatNumber(differenceExpense, {
            style: "currency",
            currency: getCurrencyFromId(currencies, currency).code,
        })
    }, [cardData, user, intl, getCurrencyFromId, currencies])

    const getTheRightPreviousValue = useCallback(() => {
        if (!cardData || cardData.previousValue == null) return "0"

        if (cardData.dataType === DashboardDataType.Money) {
            return getCurrencyPreviousValue()
        }
        return cardData.previousValue
    }, [cardData, getCurrencyPreviousValue])

    const getTranslation = useCallback(
        (value: DashboardReferencedType) => {
            if (user?.summaryCards.map((card) => card.summaryCardId).includes(cardId)) {
                return translate("expenses.no_expenses_this_month")
            }

            switch (value) {
                case DashboardReferencedType.Property:
                    return translate("add_property")
                case DashboardReferencedType.Expense:
                    return translate("expenses.addExpense")
                case DashboardReferencedType.Income:
                    return translate("income-table.addIncome")
                case DashboardReferencedType.Tenant:
                    return translate("tenant-data.addTenant")
                case DashboardReferencedType.Meter:
                    return translate("add_meter")
                default:
                    return ""
            }
        },
        [user, translate],
    )

    const handleCardClick = useCallback(() => {
        if (!cardData || !isCardClickable()) return
        switch (cardData.referencedType) {
            case DashboardReferencedType.Property:
                if (cardData.dataType === DashboardDataType.Missing) dispatch(setModalOpen(AppModals.AddProperty))
                else navigate("/properties")
                break
            case DashboardReferencedType.Expense:
                if (cardData.dataType === DashboardDataType.Missing && canWrite) {
                    if (context === AppContext.Owner) dispatch(setModalOpen(AppModals.AddExpense))
                } else {
                    if (currentPropertyId && context === AppContext.Owner) navigate(`/properties/${currentPropertyId}/expenses`)
                    else navigate("/expenses")
                }

                break
            case DashboardReferencedType.Income: {
                if (isIncomeDisabled) return
                if (cardData.dataType === DashboardDataType.Missing && canWrite) {
                    dispatch(setModalOpen(AppModals.AddIncome))
                } else {
                    if (currentPropertyId) navigate(`/properties/${currentPropertyId}/income`)
                    else navigate("/income")
                }
                break
            }
            case DashboardReferencedType.Tenant:
                if (cardData.dataType === DashboardDataType.Missing && canWrite) {
                    dispatch(setModalOpen(AppModals.CreateRentingPeriod))
                } else {
                    if (currentPropertyId) navigate(`/properties/${currentPropertyId}/tenants`)
                    else navigate("/tenants")
                }
                break
            case DashboardReferencedType.Meter:
                if (cardData.dataType === DashboardDataType.Missing && canWrite) {
                    dispatch(setModalOpen(AppModals.AddMeter))
                } else {
                    if (currentPropertyId && context === AppContext.Owner) navigate(`/properties/${currentPropertyId}/meters`)
                    else if (currentPropertyId && context === AppContext.Tenant) navigate("/meters")
                }
                break
            case DashboardReferencedType.EndingSoon:
                navigate("/tenants")
                setRentingPeriodPage(RentingPeriodsPages.EndingSoon)
                break
            case DashboardReferencedType.ProvidersWithoutExpenseThisMonth:
                dispatch(setModalOpen(AppModals.ProvidersWithoutExpenseThisMonth))
                break
            default:
        }
    }, [cardData, isCardClickable, navigate, dispatch, currentPropertyId, context, isIncomeDisabled, canWrite])

    const tooltipTitle = useCallback(() => {
        if (cardData == null) return ""

        switch (cardData.referencedType) {
            case DashboardReferencedType.Expense:
                if (cardData.sentiment === DashboardSentiment.Negative) {
                    return translate("you_spent_with", { value: getTheRightPreviousValue() })
                }
                return translate("still_good_with_expenses", { value: getTheRightPreviousValue() })
            default:
                return ""
        }
    }, [cardData, translate, getTheRightPreviousValue])

    if (user == null && !isPublic) return null

    return (
        <CustomCard
            onClick={handleCardClick}
            alignItems={"center"}
            flex={1}
            flexGrow={1}
            width={"100%"}
            minWidth={250}
            height='100%'
            isCardClickable={isCardClickable()}
        >
            <Box>
                {(!cardData || isFetching) && (
                    <Box display={"flex"} justifyContent={"center"}>
                        <CircularProgress size={"2rem"} />
                    </Box>
                )}
                {!isFetching && cardData && (cardData.dataType !== DashboardDataType.Missing || !canWrite) && (
                    <Box display={"flex"} justifyContent={isMobile ? "" : "center"} marginTop={1}>
                        <Iconify
                            icon={cardData?.icon || "mdi:help-circle-outline"}
                            width={26}
                            height={26}
                            alignSelf={"center"}
                            marginRight={2}
                            color={theme.palette.primary.dark}
                        />
                        <Box>
                            <LightTooltip title={currentPropertyId ? "" : tooltipTitle()}>
                                <Typography fontWeight={"bold"}>{getTheRightValue()}</Typography>
                            </LightTooltip>

                            <Typography color={theme.palette.grey["600"]} fontSize={14}>
                                {translate(`${cardData?.title}`)}
                            </Typography>
                        </Box>
                    </Box>
                )}
                {!isFetching && cardData && cardData.dataType === DashboardDataType.Missing && canWrite && (
                    <Box>
                        <LightTooltip
                            arrow
                            title={
                                isIncomeDisabled && cardData.referencedType === DashboardReferencedType.Income
                                    ? translate("tenant_required_income")
                                    : ""
                            }
                        >
                            <span
                                style={{
                                    display: "flex",
                                    justifyContent: isMobile ? "flex-start" : "center",
                                    flexDirection: isMobile ? "row" : "column",
                                }}
                            >
                                <Iconify
                                    icon={
                                        user?.summaryCards.map((card) => card.summaryCardId).includes(cardId)
                                            ? "mdi:check-circle"
                                            : "mdi:plus-circle-outline"
                                    }
                                    width={26}
                                    height={26}
                                    alignSelf={"center"}
                                    marginRight={2}
                                    color={theme.palette.primary.dark}
                                />

                                <Typography marginTop={isMobile ? 0 : 1} alignSelf={"center"} textAlign={"center"}>
                                    {getTranslation(cardData.referencedType)}
                                </Typography>
                            </span>
                        </LightTooltip>
                    </Box>
                )}
            </Box>
        </CustomCard>
    )
}

export default SummaryCard
