import { useDispatch, useSelector } from "../../../redux/store"
import { AppContext, DetailsSheet, useLocales } from "rentzz"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box, CircularProgress } from "@mui/material"
import * as React from "react"
import { useCallback, useMemo } from "react"
import { useRevisionObservationsQueryFn, useTenantRevisionObservationsQueryFn } from "../../../queries/revision"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { useTheme } from "@mui/material/styles"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import { DateTime } from "luxon"

export default function ObservationsListGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const { data: ownerObservations, isFetching: areObservationsLoading } = useRevisionObservationsQueryFn(editingItem?.revisionId)
    const { data: tenantObservations, isFetching: areTenantObservationsLoading } = useTenantRevisionObservationsQueryFn(editingItem?.revisionId)

    const observations = useMemo(
        () => (context === AppContext.Tenant ? tenantObservations : ownerObservations),
        [context, tenantObservations, ownerObservations],
    )

    const onClose = useCallback(() => {
        dispatch(setModalOpen(AppModals.RevisionDetails))
    }, [dispatch])

    const options = useMemo((): CustomSheetOption[] => {
        return [
            {
                icon: <AddCircleOutlineIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "add_observation",
                hidden: !editingItem?.canWrite,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.AddObservation))
                },
            },
        ]
    }, [dispatch, context, editingItem])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.ObservationsList}
            onClose={onClose}
            options={options}
            isLoading={false}
            title={translate("observations")}
        >
            <>
                {areObservationsLoading || areTenantObservationsLoading ? (
                    <Box display={"flex"} justifyContent={"center"} height={300}>
                        <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                    </Box>
                ) : (
                    <Box sx={{ overflowY: "auto", maxHeight: 500 }}>
                        <Box gap={1} display={"flex"} flexDirection={"column"} px={1} py={2}>
                            {observations?.map((obs) => (
                                <CustomMobileCard
                                    key={obs.id}
                                    item={obs}
                                    onClick={() => {
                                        dispatch(setModalOpen(AppModals.ObservationDetails))
                                        dispatch(setEditingItem({ ...editingItem, observationId: obs.id }))
                                    }}
                                    icon={"mdi:file-document-edit"}
                                    iconColor={theme.palette.primary.main}
                                    title={obs.title}
                                    subtitle={obs.description}
                                    rightFirstItem={obs.observationDate.toLocaleString(DateTime.DATE_SHORT)}
                                />
                            ))}
                        </Box>
                    </Box>
                )}
            </>
        </DetailsSheet>
    )
}
