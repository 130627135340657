import {
    Annotation,
    Inject,
    Magnification,
    Navigation,
    PdfViewerComponent,
    Print,
    TextSearch,
    TextSelection,
    Toolbar,
    ToolbarSettingsModel,
} from "@syncfusion/ej2-react-pdfviewer"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { blobToBase64 } from "../utils/actions"
import { useCopyToClipboard, useIsMobile, useLocales } from "rentzz"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../queries/userData"
import { nanoid } from "@reduxjs/toolkit"

export interface CustomPDFViewerProps {
    url?: string
    height: string | number
    width?: string | number
    fileName?: string
}

const CustomPDFViewer = ({ url, height, width, fileName }: CustomPDFViewerProps) => {
    const pdfRef = useRef<PdfViewerComponent>(null)
    const isMobile = useIsMobile()
    const backendPath = useFeatureValue(UserFlags.BackendPath, "")
    const { translate } = useLocales()
    const { copy } = useCopyToClipboard()

    const menuItems = useMemo(() => {
        return [
            {
                text: translate("copy"),
                id: "copy",
                iconCss: "e-icons e-copy",
            },
        ]
    }, [translate])

    const onMenuSelect = useCallback(async () => {
        if (pdfRef.current == null) return

        for (let i = 0; i < pdfRef.current.textSelectionModule.selectionRangeArray.length; i++) {
            const content = pdfRef.current.textSelectionModule.selectionRangeArray[i].textContent
            if (pdfRef.current.textSelectionModule.isTextSelection && /\S/.test(content)) {
                await copy(content)
            }
        }
    }, [])

    useEffect(() => {
        if (url) {
            fetch(url)
                .then((res) => res.blob())
                .then((blob) => blobToBase64(blob))
                .then((r) => {
                    if (pdfRef.current == null) return
                    pdfRef.current.load(r, "")
                    pdfRef.current.addCustomMenu(menuItems, true)
                })
        } else {
            pdfRef.current?.unload()
        }
    }, [url])

    const toolbarItems = useMemo((): ToolbarSettingsModel => {
        if (isMobile) {
            return {
                toolbarItems: ["DownloadOption", "PrintOption"],
            }
        }
        return {
            toolbarItems: ["PageNavigationTool", "MagnificationTool", "SearchOption", "DownloadOption", "PrintOption"],
        }
    }, [isMobile])

    return (
        <PdfViewerComponent
            ref={pdfRef}
            toolbarSettings={toolbarItems}
            style={{ height, width }}
            // enableTextSelection={!isMobile}
            enableDownload
            enablePrint
            customContextMenuSelect={onMenuSelect}
            enableMagnification
            retryTimeout={10}
            retryCount={5}
            readOnly
            downloadFileName={fileName ?? nanoid(12)}
            textSelectionEnd={onMenuSelect}
            enableCommentPanel={false}
            enableTextSearch
            serviceUrl={`${backendPath}/pdf-viewer`}
            ajaxRequestSettings={{
                ajaxHeaders: [],
                withCredentials: true,
            }}
        >
            <Inject services={[Toolbar, Magnification, Navigation, Annotation, Print, TextSelection, TextSearch]} />
        </PdfViewerComponent>
    )
}

export default CustomPDFViewer
