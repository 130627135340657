import { Box, Button, CircularProgress } from "@mui/material"
import { GenerateQueuedInvoiceResponse, stopInvoiceGeneration } from "../../../mutations/invoicingConfiguration"
import { InvoiceStatus, useQueuedInvoiceStatus } from "../../../queries/invoicing-configuration"
import CancelIcon from "@mui/icons-material/Cancel"
import { Launch } from "@mui/icons-material"
import { useLocales } from "rentzz"
import React from "react"
import { useTheme } from "@mui/material/styles"
import StopCircleIcon from "@mui/icons-material/StopCircle"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../queries/userData"

export interface GeneratedInvoiceCellProps {
    data: GenerateQueuedInvoiceResponse
}

const GeneratedInvoiceCell = ({ data }: GeneratedInvoiceCellProps) => {
    const { data: invoiceData } = useQueuedInvoiceStatus(data.queryId)
    const theme = useTheme()
    const { translate } = useLocales()
    const { mutate } = stopInvoiceGeneration()
    const backendPath = useFeatureValue(UserFlags.BackendPath, "")

    if (data.message) {
        return data.message
    }

    if (invoiceData == null || invoiceData.status === InvoiceStatus.Pending) {
        return (
            <Box display='flex' flexDirection='row' gap={2} alignItems='center' justifyContent='center' width='100%'>
                <CircularProgress size={"1rem"} />
                {data.queryId && (
                    <Button
                        startIcon={<StopCircleIcon sx={{ fontSize: MOBILE_ICON_SIZE, color: theme.palette.error.main }} />}
                        size='small'
                        onClick={() => {
                            if (data.queryId == null) return

                            mutate({
                                queryId: data.queryId,
                            })
                        }}
                    >
                        {translate("stop")}
                    </Button>
                )}
            </Box>
        )
    }

    if (invoiceData.status === InvoiceStatus.Failed) {
        return <CancelIcon />
    }

    if (invoiceData.downloadUrl) {
        return (
            <Button
                startIcon={<Launch />}
                variant={"text"}
                color='primary'
                onClick={() => {
                    window.open(new URL(invoiceData.downloadUrl!, backendPath), "_blank")
                }}
            >
                {invoiceData.seriesName} {invoiceData.invoiceNumber}
            </Button>
        )
    }
}

export default GeneratedInvoiceCell
