import { CustomDataGrid, Label, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { Box, Button } from "@mui/material"
import { invoicingTypeAtom } from "../../../utils/atom"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { dispatch, useSelector } from "../../../redux/store"
import { InvoicingDryRun, useInvoicingDryRunInvoices } from "../../../queries/invoicing-configuration"
import NoDataPage from "../../../components/NoDataPage"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import { DateTime } from "luxon"
import { getDryRunGeneratedStatusColor } from "./utils"
import { useSetAtom } from "jotai"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { PlayArrow } from "@mui/icons-material"
import { updateRentingPeriodMutation } from "../../../mutations/tenants"
import CloseIcon from "@mui/icons-material/Close"
import { useNavigate } from "react-router-dom"

export default function DryRunInvoicingView() {
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const setInvoicingType = useSetAtom(invoicingTypeAtom)
    const { data: dryRunInvoices, isLoading: areDryRunInvoicesLoading } = useInvoicingDryRunInvoices(editingItem?.invoiceCompanyId)
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()
    const isMobile = useIsMobile()
    const { mutate: updateRentingPeriod, isPending: isUpdatingRentingPeriod } = updateRentingPeriodMutation()
    const navigate = useNavigate()

    const closeModal = useCallback(() => {
        setInvoicingType(undefined)

        if (isMobile) {
            dispatch(setModalOpen(AppModals.InvoicingCompanyDetailsSheet))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [modalOpen, dispatch, isMobile])

    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData, intl],
    )

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            type: "actions",
            width: 50,
            disableReorder: true,
            headerName: "",
            getActions: (params: GridRowParams<InvoicingDryRun>) => [
                ActionWithTooltip({
                    tooltipText: params.row.isInvoicingOn
                        ? translate("grid_actions.deactivate_invoicing")
                        : translate("grid_actions.activate_invoicing"),
                    element: (
                        <GridActionsCellItem
                            key='update'
                            color='primary'
                            icon={
                                params.row.isInvoicingOn ? (
                                    <CloseIcon color='error' sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                ) : (
                                    <PlayArrow sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                                )
                            }
                            onClick={(_: React.MouseEvent<HTMLElement>) => {
                                updateRentingPeriod({
                                    tenantId: params.row.rentingPeriodId,
                                    operations: [
                                        {
                                            path: "/createInvoice",
                                            value: !params.row.isInvoicingOn,
                                            op: "replace",
                                        },
                                    ],
                                })
                            }}
                            label={translate("grid_actions.update_company")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
            ],
        }),
        [translate, dispatch],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<InvoicingDryRun>[] = [
            {
                field: "propertyName",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("propertyName"),
                renderCell: (row) => {
                    return (
                        <Button
                            color='primary'
                            onClick={() => {
                                dispatch(setEditingItem(undefined))
                                dispatch(setModalOpen(undefined))
                                navigate(`/properties/${row.row.propertyId}`)
                            }}
                        >
                            {row.value}
                        </Button>
                    )
                },
            },
            {
                field: "rentingPeriodStartDate",
                type: "string",
                width: 220,
                headerAlign: "center",
                align: "center",
                sortable: false,
                headerName: translate("dry_run_invoicing_lease_period"),
                valueGetter: (_, row) =>
                    row.rentingPeriodStartDate.toLocaleString(DateTime.DATE_SHORT) +
                    " - " +
                    row.rentingPeriodEndDate.toLocaleString(DateTime.DATE_SHORT),
                renderCell: (row) => {
                    return (
                        <Button
                            color='primary'
                            onClick={() => {
                                dispatch(setEditingItem(undefined))
                                dispatch(setModalOpen(undefined))
                                navigate(`/tenants/${row.row.rentingPeriodId}`)
                            }}
                        >
                            {row.value}
                        </Button>
                    )
                },
            },
            {
                field: "clientName",
                type: "string",
                minWidth: 140,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("clientName"),
            },
            {
                field: "productName",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("productName"),
            },
            {
                field: "productDescription",
                type: "string",
                minWidth: 160,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("productDescription"),
            },
            {
                field: "value",
                type: "number",
                width: 160,
                headerAlign: "center",
                align: "center",
                headerName: translate("value"),
                valueFormatter: (value, row) => {
                    return getAmount(value, row.currencyId as number)
                },
            },
            {
                field: "date",
                type: "date",
                width: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT) ?? "-",
            },
            {
                field: "dueDate",
                type: "date",
                width: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("due.date"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime)?.toLocaleString(DateTime.DATE_SHORT) ?? "-",
            },
            {
                field: "status",
                type: "string",
                width: 200,
                headerAlign: "center",
                align: "center",
                headerName: translate("status"),
                renderCell: (v) => {
                    return (
                        <Label color={getDryRunGeneratedStatusColor(v.value)} style={{ textTransform: "none" }}>
                            {translate(`dry-run-status-${v.value as number}`)}
                        </Label>
                    )
                },
            },
        ]

        return columns
    }, [translate, getAmount])

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "flex-end",
                paddingX: 0,
                paddingTop: 2,
                gap: 2,
                flexDirection: "column",
            }}
        >
            <CustomDataGrid
                columns={columns}
                gridId='DryRunInvoicingView'
                rows={dryRunInvoices ?? []}
                idKey={"propertyName"}
                getRowId={(row) => row.propertyName}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={""} noDataText={"no_dry_run_configuration"} />,
                }}
                isLoading={areDryRunInvoicesLoading || isUpdatingRentingPeriod}
                rowHeight={50}
                actionsColumn={[actionsColumn]}
                gridHeight={500}
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end", paddingX: 0, paddingTop: 2 }}>
                <Button color={"primary"} onClick={closeModal} fullWidth={isMobile} variant={isMobile ? "contained" : "text"}>
                    {translate("cancel")}
                </Button>
            </Box>
        </Box>
    )
}
