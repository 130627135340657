import { Box, Fab, Typography } from "@mui/material"
import { RoleName, useNotificationsTemplatesQuery, useUserDataQueryFn } from "../../../../queries/userData"
import { Iconify, LoadingIcon, useLocales } from "rentzz"
import JournalHeader from "../../../../guards/alertsGuard/rentingPeriod/journal/JournalHeader"
import React from "react"
import CustomMobileCard from "../../../../components/CustomMobileCard"
import CustomAddCard from "../../../../components/CustomAddCard"
import { useTheme } from "@mui/material/styles"
import { useSelector } from "../../../../redux/store"
import { useNavigate } from "react-router-dom"

export default function MobileNotificationsList() {
    const navigate = useNavigate()
    const theme = useTheme()
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()
    const { data: notificationsTemplates } = useNotificationsTemplatesQuery()
    const { modalOpen } = useSelector((state) => state.appState)

    if (notificationsTemplates == null) return <LoadingIcon />

    return (
        <>
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {notificationsTemplates.items.length > 0 && modalOpen == null && user?.roles.roleName !== RoleName.FREE && (
                    <Fab
                        onClick={() => navigate("/document-templates/notification-templates/new")}
                        sx={{ position: "fixed", bottom: 10, right: 10 }}
                        color='primary'
                        aria-label='add'
                    >
                        <Iconify icon={"mdi:plus"} width={25} height={25} />
                    </Fab>
                )}
                <JournalHeader />

                {notificationsTemplates.items.map((notification) => (
                    <CustomMobileCard
                        key={notification.id}
                        item={notification}
                        onClick={(e) => {
                            e.stopPropagation()
                            navigate(`${notification.id}`)
                        }}
                        icon={"mdi:note-alert-outline"}
                        iconColor={theme.palette.primary.main}
                        title={notification.name}
                        subtitle={
                            notification.numberOfRentingPeriods == null || notification.numberOfRentingPeriods === 0 ? (
                                translate("no_usage")
                            ) : (
                                <Typography variant={"caption"}>
                                    {translate("used_in")}
                                    {notification.numberOfRentingPeriods} {translate("contracts_lowercase")}
                                </Typography>
                            )
                        }
                    />
                ))}

                {notificationsTemplates.items.length === 0 && (
                    <CustomAddCard
                        onCardClick={() => navigate("/document-templates/notification-templates/new")}
                        text={"new_notification"}
                        icon={"mdi:file-document-add-outline"}
                        marginRight={2}
                    />
                )}
            </Box>
        </>
    )
}
