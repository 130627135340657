import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import AddSignatureRequestForm from "../../../sections/propertyDetails/signature-requests/AddSignatureRequestForm"
import { signingRequestTypeAtom } from "../../../utils/atom"
import { Box } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useAtom } from "jotai/index"

export default function AddSignatureRequestGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const [signingRequestType, setSigningRequestType] = useAtom(signingRequestTypeAtom)
    const { modalOpen } = useSelector((state) => state.appState)
    const theme = useTheme()

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
        setSigningRequestType(undefined)
    }, [dispatch])

    const getTitleBySigningRequestType = useCallback(() => {
        switch (signingRequestType) {
            case "presigned":
                return "upload_presigned_document"
            case "signRequest":
                return "add_signature_request"
            default:
                return "choose_document_type"
        }
    }, [signingRequestType])

    return (
        <>
            <CustomDialog
                title={translate(getTitleBySigningRequestType())}
                open={!isMobile && modalOpen === AppModals.AddSignatureRequest}
                onClose={closeModal}
                maxWidth={signingRequestType == "signRequest" ? "lg" : "sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddSignatureRequestForm onClose={closeModal} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(getTitleBySigningRequestType())}
                isOpen={isMobile && modalOpen === AppModals.AddSignatureRequest}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <Box p={2}>
                    <AddSignatureRequestForm onClose={closeModal} />
                </Box>
            </DetailsSheet>
        </>
    )
}
