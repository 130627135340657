import { boolean, number, object, string } from "yup"
import { isValidIBAN } from "ibantools"

export const AddNewInvoicingConfigurationSchema = object().shape({
    invoiceProvider: number().required("required"),
    user: string().required("required").min(3, "minimum_characters"),
    password: string().required("required").min(3, "minimum_characters"),
    configurationCIF: string().required("required").min(3, "minimum_characters"),
})

export const AddNewInvoicingClientSchema = object().shape({
    cif: string().required("required").min(3, "minimum_characters"),
    name: string().required("required").min(3, "minimum_characters"),
    county: string().required("required").min(3, "minimum_characters"),
    city: string().required("required").min(3, "minimum_characters"),
    email: string().email("must_be_email").required("required"),
    invoicingCompanyId: number().required("required"),
    country: string(),
    phone: string(),
    address: string(),
    bank: string(),
    iban: string()
        .optional()
        .test("test", "iban_validation", (value) => {
            if (value && value.trim() !== "") {
                const noSpaceIban = value.replace(/\s/g, "")
                return isValidIBAN(noSpaceIban)
            }
            return true
        }),
    isTaxPayer: boolean().required("required"),
})

export const InvoicingSchema = object().shape({
    invoicingLanguage: string().required("required"),
    invoicingCompany: string().required("required"),
    createInvoiceDay: number().optional(),
    invoicingClientId: number().required("required"),
    invoicingSeries: string().required("required"),
    defaultInvoicingTemplate: string().required("required"),
    invoicingCurrencyId: number().required("required"),
    defaultInvoicingText: string().required("required"),
    invoiceMaxDays: number().required("required"),
    sendToSPV: boolean().optional(),
    removeCIF: boolean().required("required"),
    createInvoice: boolean().required("required"),
    invoicingPeriodStart: number()
        .required("required")
        .nullable()
        .test("is-between-1-and-31", "invoicingDate must be a number between 1 and 31", (value) => {
            if (value != null) {
                return value >= 1 && value <= 31
            }
            return false
        }),
    invoicingPeriodEnd: number()
        .required("required")
        .nullable()
        .test("is-between-1-and-31", "invoicingDate must be a number between 1 and 31", (value) => {
            if (value != null) {
                return value >= 1 && value <= 31
            }
            return false
        }),
    reinvoicingSeries: string().optional(),
    reinvoicingVAT: number().test("test", "required", function (value, context) {
        const reinvoiceSeries = context.parent.reinvoicingSeries
        if (reinvoiceSeries && reinvoiceSeries.length > 0) {
            return !!value
        }
        return true
    }),
    lateFeesVAT: number().optional(),
    lateFeesSeries: number().optional(),
    invoicingVAT: number().optional(),
    reinvoicingPercentage: number().test("test", "required", function (value, context) {
        const reinvoiceSeries = context.parent.reinvoicingSeries
        if (reinvoiceSeries && reinvoiceSeries.length > 0) {
            if (value == null) return false

            return value > 0
        }
        return true
    }),
    defaultReinvoicingText: string().test("test", "required", function (value, context) {
        const reinvoiceSeries = context.parent.reinvoicingSeries
        if (reinvoiceSeries && reinvoiceSeries.length > 0) {
            return !!value
        }
        return true
    }),
})

export const NewInvoicingConfigurationAccessSchema = () =>
    object().shape({
        email: string().email("must_be_email").required("required"),
        accessType: number().required("required"),
    })
