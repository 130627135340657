import { CustomDialog, DetailsSheet, useIsMobile } from "rentzz"
import { useDispatch } from "../../../redux/store"
import { useCallback } from "react"
import { useSingleExpenseQuery } from "../../../queries/expenses"
import { useSearchParams } from "react-router-dom"
import AutomaticallyGenerateLateFeesExpenseSwitch from "../../../sections/expenses/expense-details/AutomaticallyGenerateLateFeesExpenseSwitch"
import { Box } from "@mui/material"

export default function UpdateExpenseLateFeesPopup() {
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const [params, setParams] = useSearchParams()
    const { data: currentExpense } = useSingleExpenseQuery(Number(params.get("expenseId")))

    const closeModal = useCallback(() => {
        setParams({})
    }, [dispatch])

    if (currentExpense == null) return null

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={currentExpense.name}
                isOpen={isMobile && params.get("action") === "update-expense-late-fees"}
                onClose={closeModal}
            >
                <Box width='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column' py={3}>
                    <AutomaticallyGenerateLateFeesExpenseSwitch expense={currentExpense} />
                </Box>
            </DetailsSheet>
            <CustomDialog
                title={currentExpense.name}
                titleAlign={"center"}
                isFullScreen={false}
                maxWidth={"sm"}
                open={!isMobile && params.get("action") === "update-expense-late-fees"}
                onClose={closeModal}
            >
                <Box width='100%' display='flex' justifyContent='center' alignItems='center' flexDirection='column' py={3}>
                    <AutomaticallyGenerateLateFeesExpenseSwitch expense={currentExpense} />
                </Box>
            </CustomDialog>
        </>
    )
}
