import { FormProvider, LightTooltip, RHFAutocomplete, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { AddInventorySectionSchema } from "../../../validations/inventory"

export interface AddInventorySectionRequest {
    propertyId: number
    sectionName: string
    id?: number
}

interface Props {
    onClose: () => void
    onSave: (data: AddInventorySectionRequest) => void
    isLoading: boolean
    defaultValues?: AddInventorySectionRequest
}

export default function AddOrEditInventorySectionForm({ onClose, onSave, isLoading, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { editableProperties } = usePermissions(PermissionType.Revisions)

    const methods = useForm<AddInventorySectionRequest>({
        resolver: yupResolver<AddInventorySectionRequest>(AddInventorySectionSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset(defaultValues)
        }

        if (defaultValues == null) {
            reset({
                propertyId: currentPropertyId ?? (editableProperties ?? [])[0]?.id,
                sectionName: "",
            })
        }
    }, [defaultValues, reset])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <RHFTextField
                        name={"sectionName"}
                        label={translate("title")}
                        placeholder={translate("inventory_section_name_placeholder")}
                        size={"small"}
                        required
                    />

                    <LightTooltip title={currentPropertyId != null || defaultValues != null ? translate("can_not_edit_field") : ""} arrow>
                        <span style={{ width: "100%" }}>
                            <RHFAutocomplete
                                autocompleteProps={{
                                    sx: {
                                        width: "100%",
                                    },
                                    noOptionsText: translate("no_value_found"),
                                }}
                                matchId
                                name={"propertyId"}
                                options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                                label={translate("property")}
                                required={currentPropertyId == null}
                                disabled={currentPropertyId != null || defaultValues != null}
                                freeSolo={false}
                            />
                        </span>
                    </LightTooltip>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
