import AddBankAccountConsentGuard from "./AddBankAccountConsentGuard"
import DeleteBankAccountConsentGuard from "./DeleteBankAccountConsentGuard"

export default function BankAccountConsentGuard() {
    return (
        <>
            <AddBankAccountConsentGuard />
            <DeleteBankAccountConsentGuard />
        </>
    )
}
