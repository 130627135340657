import React from "react"
import AddOrEditLabelGuard from "./labels/AddOrEditLabelGuard"
import AddOrEditNomenclatureProviderGuard from "./providers/AddOrEditNomenclatureProviderGuard"
import DeleteProviderGuard from "./providers/DeleteProviderGuard"
import DeleteLabelGuard from "./labels/DeleteLabelGuard"
import LabelDetailsSheet from "../../../../sections/nomenclature/labels/LabelDetailsSheet"
import CustomProvidersDetailsSheet from "../../../../sections/nomenclature/providers/CustomProvidersDetailsSheet"
import AddOrEditSectionGuard from "./sections/AddOrEditSectionGuard"
import DeleteSectionGuard from "./sections/DeleteSectionGuard"
import SectionDetailsSheet from "../../../../sections/nomenclature/sections/SectionDetailsSheet"
import AddOrEditInvoicingConfigurationGuard from "./invoicing-configuration/AddOrEditInvoicingConfigurationGuard"
import DeleteInvoicingConfigurationGuard from "./invoicing-configuration/DeleteInvoicingConfigurationGuard"
import InvoicingConfigurationDetailsSheet from "../../../../sections/nomenclature/invoicing-configuration/configurations/InvoicingConfigurationDetailsSheet"
import DeletePropertyOwner from "./propertyOwners/DeletePropertyOwner"
import AddOrEditPropertyOwner from "./propertyOwners/AddOrEditPropertyOwner"
import AddOrEditBankAccountGuard from "./bankAccounts/AddOrEditBankAccountGuard"
import DeleteBankAccountGuard from "./bankAccounts/DeleteBankAccountGuard"
import BankAccountDetailsSheet from "../../../../sections/nomenclature/bankAccounts/BankAccountDetailsSheet"
import DeletePropertyFromBankAccount from "./bankAccounts/DeletePropertyFromBankAccount"
import PropertiesFromBankAccountList from "../../../../sections/nomenclature/bankAccounts/MobilePropertiesFromBankAccountList"
import InvoicingGuard from "./invoicing-configuration/InvoicingGuard"
import UploadBankStatement from "../../../../sections/bank-statements/UploadBankStatement"
import InvoicingCompanyDetailsSheet from "../../../../sections/nomenclature/invoicing-configuration/companies/InvoicingCompanyDetailsSheet"
import InvoicingClientDetailsSheet from "../../../../sections/nomenclature/invoicing-configuration/clients/InvoicingClientDetailsSheet"
import LinkBankAccountToPropertyGuard from "./bankAccounts/LinkBankAccountToPropertyGuard"
import AddOrEditInvoicingClientGuard from "./invoicing-client/AddOrEditInvoicingClientGuard"
import DeleteInvoicingClientGuard from "./invoicing-client/DeleteInvoicingClientGuard"
import BankAccountConsentDetailsSheet from "../../../../sections/nomenclature/bankAccounts/SmartAccountsUserConsents/BankAccountConsentDetailsSheet"
import BankAccountConsentGuard from "./bankAccountConsents/BankAccountConsentGuard"
import DeletePropertyFromOwner from "./propertyOwners/DeletePropertyFromOwner"
import DeletePropertyOwnerRepresentativeGuard from "./property-owners/DeletePropertyOwnerRepresentativeGuard"
import AddPropertyOwnerRepresentativeGuard from "./property-owners/AddPropertyOwnerRepresentativeGuard"
import RepresentativeDetailsSheetGuard from "./property-owners/RepresentativeDetailsSheetGuard"
import PropertyOwnershipPercentageDetailsSheetGuard from "./property-owners/PropertyOwnershipPercentageDetailsSheetGuard"
import PropertyOwnerPropertyDetailsSheetGuard from "./property-owners/PropertyOwnerPropertyDetailsSheetGuard"

export default function NomenclatureGuard() {
    return (
        <>
            <AddOrEditLabelGuard />
            <DeleteLabelGuard />
            <LabelDetailsSheet />

            <AddOrEditNomenclatureProviderGuard />
            <DeleteProviderGuard />
            <CustomProvidersDetailsSheet />

            <AddOrEditSectionGuard />
            <DeleteSectionGuard />
            <SectionDetailsSheet />

            <AddOrEditInvoicingConfigurationGuard />
            <DeleteInvoicingConfigurationGuard />
            <InvoicingConfigurationDetailsSheet />
            <InvoicingCompanyDetailsSheet />
            <InvoicingClientDetailsSheet />

            <AddOrEditInvoicingClientGuard />
            <DeleteInvoicingClientGuard />

            <DeletePropertyOwner />
            <AddOrEditPropertyOwner />
            <DeletePropertyFromOwner />

            <DeletePropertyOwnerRepresentativeGuard />
            <AddPropertyOwnerRepresentativeGuard />
            <RepresentativeDetailsSheetGuard />
            <PropertyOwnershipPercentageDetailsSheetGuard />
            <PropertyOwnerPropertyDetailsSheetGuard />

            <AddOrEditBankAccountGuard />
            <DeleteBankAccountGuard />
            <BankAccountDetailsSheet />
            <LinkBankAccountToPropertyGuard />
            <DeletePropertyFromBankAccount />
            <PropertiesFromBankAccountList />
            <BankAccountConsentDetailsSheet />
            <BankAccountConsentGuard />

            <UploadBankStatement />

            <InvoicingGuard />
        </>
    )
}
