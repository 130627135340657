import { IncomeStatus, PaymentSubtype, PaymentType, PropertyFileDto } from "../../redux/slices/AddProperty"
import { DateTime } from "luxon"
import { ExpenseAssignee } from "rentzz"

export interface PaginatedData<T> {
    count: number
    items: T[]
}

export enum BalanceBehavior {
    RealValue,
    ZeroValue,
}
export enum ExpenseStatus {
    Pending = 0,
    Accepted = 1,
}

export interface PropertyExpenseJournalDetailsBase {
    id: number
    assignee: ExpenseAssignee
    name: string
    value: number
    currencyId: number
    providerName: string
}

export interface PropertyExpenseJournalDetailsDto extends PropertyExpenseJournalDetailsBase {
    date: string
    dueDate: string
}

export interface PropertyExpenseJournalDetails extends PropertyExpenseJournalDetailsBase {
    date: DateTime
    dueDate: DateTime
}

export interface PropertyIncomeJournalDetailsBase {
    id: number
    associatedExpenses?: {
        id: number
        name: string
    }[]
    value: number
    currencyId: number
    status: IncomeStatus
    type: PaymentType
    isAutoAccepted: boolean
}

export interface PropertyIncomeJournalDetailsDto extends PropertyIncomeJournalDetailsBase {
    date: string
}

export interface PropertyIncomeJournalDetails extends PropertyIncomeJournalDetailsBase {
    date: DateTime
}

export interface JournalItemChanged<T> {
    old: T
    new: T
}
export interface PropertyExpenseDto extends PropertyExpenseBase {
    date: string
    dueDate: string
    dateAutomaticallySentToTenant?: string
    providerInvoiceDate?: string
    propertyIncomes: ExpenseAssignedIncomeDto[]
}

export interface PropertyExpense extends PropertyExpenseBase {
    date: DateTime
    dueDate: DateTime
    dateAutomaticallySentToTenant?: DateTime
    providerInvoiceDate?: DateTime
    propertyIncomes: ExpenseAssignedIncome[]
}

export interface ExpenseAssignedIncomeBase {
    id: number
    value: number
    currencyId?: number
    valueInExpenseCurrency: number
    status: IncomeStatus
}

export interface ExpenseAssignedIncomeDto extends ExpenseAssignedIncomeBase {
    date: string
}
export interface ExpenseAssignedIncome extends ExpenseAssignedIncomeBase {
    date: DateTime
}

export interface PropertyExpenseBase {
    id: number
    assignee: ExpenseAssignee
    name: string
    description?: string
    value: number
    currencyId: number
    files: PropertyFileDto[]
    propertyId: number
    labelId?: number
    associatedProviderId?: number
    providerName?: string
    propertyIncomesCount: number
    status: ExpenseStatus
    paymentType: PaymentType
    paymentSubtype: PaymentSubtype
    labelColor?: string
    labelText?: string
    canAddInvoice?: boolean
    expenseBalanceBehavior: BalanceBehavior
    providerInvoiceNumber?: string
    invoiceValue?: string
    isAutomaticExpense: boolean
    canPayOnline?: boolean
    canCreateLateFees: boolean
    rentingPeriodId?: number
    canReverseInvoice: boolean
    shouldGenerateLateFees: boolean
}

export interface RecurringExpenseBase {
    id: string
    assignee: ExpenseAssignee
    name: string
    value: number
    currencyId: number
    files: PropertyFileDto[]
    propertyId: number
    labelId?: number
    associatedProviderId?: number
    recurringType: RecurringType
    paymentDay: number
    shouldNotifyOtherParty: boolean
    isActive: boolean
    invoiceCompany: string
    invoiceClient: string
    invoiceLanguage: string
    invoiceCurrencyId: number
    invoiceTVA: string
    invoiceProductName: string
    invoiceProductDescription: string
    invoicingSeries: string
    invoicingPeriodStart: number
    invoicingPeriodEnd: number
    paymentType: PaymentType
    paymentSubtype: PaymentSubtype
}

export enum RecurringType {
    Daily = 0,
    Weekly = 1,
    Monthly = 2,
}

export interface RecurringExpenseDto extends RecurringExpenseBase {
    startDate: string
    createdAt: string
    modifiedAt: string
    expiresAt?: string
}

export interface RecurringExpense extends RecurringExpenseBase {
    startDate: DateTime
    createdAt: DateTime
    modifiedAt: DateTime
    expiresAt?: DateTime
}
