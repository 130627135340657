import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import InventoryProcessForm from "./InventoryProcessForm"

const GenerateInventoryProcessGuard = () => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    return (
        <>
            <DetailsSheet
                isLoading={false}
                title={translate("generate_inventory_process")}
                isOpen={isMobile && modalOpen === AppModals.GenerateInventoryProcess}
                onClose={onClose}
                headerColor={theme.palette.primary.main}
            >
                <InventoryProcessForm />
            </DetailsSheet>

            <CustomDialog
                isFullScreen={false}
                title={translate("generate_inventory_process")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.GenerateInventoryProcess}
                maxWidth='xs'
                closeButtonColor={"primary"}
                titleAlign={"center"}
            >
                <InventoryProcessForm />
            </CustomDialog>
        </>
    )
}

export default GenerateInventoryProcessGuard
