import { GridColDef } from "@mui/x-data-grid-pro"
import { Box, Card, CardContent } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useMemo } from "react"
import { SignatureRequest, SignatureRequestParty } from "../../../redux/slices/AddProperty"
import { CustomDataGrid, Label, useLocales } from "rentzz"
import useCustomSortModel from "../../../hooks/useCustomSortModel"
import { getSigningPartyStatusColor } from "../../expenses/utils"

export interface DetailsPanelProps {
    currentSignatureRequest: SignatureRequest & { canWrite: boolean; canDelete: boolean }
}

const SignatureRequestDetailsPanel = ({ currentSignatureRequest }: DetailsPanelProps) => {
    const { translate, allLangs } = useLocales()
    const { sortModel, setSortModel } = useCustomSortModel("DesktopSignatureRequestDetails", [{ field: "createdAt", sort: "desc" }])
    const theme = useTheme()

    const columns = useMemo(() => {
        const columns: GridColDef<SignatureRequestParty>[] = [
            {
                field: "name",
                type: "string",
                flex: 0.5,
                minWidth: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("name"),
            },
            {
                field: "email",
                minWidth: 90,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("email"),
            },
            {
                field: "status",
                type: "number",
                width: 130,
                headerAlign: "center",
                align: "center",
                headerName: translate("status"),
                filterable: true,
                renderCell: (params) => {
                    return (
                        <Label color={getSigningPartyStatusColor(params.row.status)} style={{ textTransform: "none" }}>
                            {translate(`signature_request_party_status-${params.row.status}`)}
                        </Label>
                    )
                },
            },
            {
                field: "language",
                minWidth: 90,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("language"),
                valueFormatter: (v) => allLangs.find((l) => l.value === v)?.label,
            },
        ]

        return columns
    }, [translate])

    return (
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
            <Card
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "90%",
                    height: "100%",
                    paddingBottom: 3,
                }}
            >
                <CardContent
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        color: theme.palette.grey["700"],
                        alignSelf: "center",
                        gap: 2,
                        paddingBottom: 0,
                        "&.MuiCardContent-root:last-child": { paddingBottom: 0 },
                    }}
                >
                    {currentSignatureRequest.isPresigned ? (
                        <Box display='flex' width='100%' justifyContent='center'>
                            {translate("presigned_document_details_panel")}
                        </Box>
                    ) : (
                        <CustomDataGrid
                            columns={columns}
                            sortModel={sortModel}
                            onSortModelChange={setSortModel}
                            gridId={"DesktopSignatureRequestDetails"}
                            actionsColumn={[]}
                            rows={currentSignatureRequest.parties ?? []}
                            infoColumn={[]}
                            getRowId={(r) => r.email}
                            idKey={"email"}
                            isLoading={false}
                            rowHeight={50}
                        />
                    )}
                </CardContent>
            </Card>
        </Box>
    )
}

export default SignatureRequestDetailsPanel
