import { Page, useIsMobile, useLocales } from "rentzz"
import { useUserPropertiesQuery } from "../../../queries/userData"
import { useSelector } from "../../../redux/store"
import React from "react"
import NoPropertyPage from "../../../guards/no-property/NoPropertyPage"
import DesktopSignatureRequestsList from "./DesktopSignatureRequestsList"
import MobileSignatureRequestsList from "./MobileSignatureRequestsList"

export default function SignatureRequests() {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { data: userProperties } = useUserPropertiesQuery()
    const { currentPropertyId } = useSelector((state) => state.appState)

    if (userProperties?.length === 0) return <NoPropertyPage />

    return (
        <Page
            title={translate("signature_requests")}
            propertyName={userProperties?.find((p) => p.id === currentPropertyId)?.label}
            px={currentPropertyId ? 0 : 2}
            paddingBottom={2}
            paddingTop={1}
        >
            {isMobile ? <MobileSignatureRequestsList /> : <DesktopSignatureRequestsList />}
        </Page>
    )
}
