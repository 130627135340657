import { array, boolean, number, object, string } from "yup"

export const AddSignatureSchema = (documentType: "presigned" | "signRequest" | undefined) =>
    object().shape({
        id: number().optional(),
        propertyId: number()
            .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
            .required("required"),
        files: array().min(1, "required").required("required"),
        name: string().required("required"),
        internalId: string().optional(),
        rentingPeriodId: number().optional(),
        isPresigned: boolean().optional(),
        acceptTerms: boolean().required("required").isTrue("mandatory_terms"),
        recipients: array()
            .of(
                object().shape({
                    name: string().required("required"),
                    language: string().required("required"),
                    email: string().email("required").required("required"),
                    fields: array().optional(),
                }),
            )
            .min(documentType === "presigned" ? 0 : 1)
            .required("required"),
    })
