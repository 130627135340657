import { Box, LinearProgress, LinearProgressProps, Typography } from "@mui/material"
import { useIntl } from "react-intl"

interface CustomProps {
    minValue: number
    maxValue: string
    isPaid: boolean | null | undefined
}
export default function CustomLinearProgress(props: LinearProgressProps & CustomProps & { value: number }) {
    const { minValue, maxValue, value, isPaid, ...rest } = props
    const intl = useIntl()

    return (
        <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 1 }}>
            <Box sx={{ width: "100%", mr: 1 }}>
                <LinearProgress
                    variant='determinate'
                    value={value > 100 ? 100 : value}
                    {...rest}
                    color={value === 0 ? "error" : isPaid ? "primary" : "warning"}
                />
            </Box>
            <Box justifyContent={"flex-end"} display={"flex"}>
                <Typography variant='caption' color='text.secondary'>
                    {`${intl.formatNumber(minValue, { style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2 })} / ${maxValue}`}
                </Typography>
            </Box>
        </Box>
    )
}
