import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import EditRentingPeriodLabelForm from "../../../sections/propertyDetails/Tenants/EditRentingPeriodLabelForm"

export default function EditRentingPeriodLabelGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen } = useSelector((state) => state.appState)

    const onClose = useCallback(() => {
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [])

    return (
        <>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.EditRentingPeriodLabel}
                title={translate("edit_label")}
                titleAlign={"center"}
                onClose={onClose}
                showCloseIcon={true}
                maxWidth={"xs"}
                isFullScreen={false}
            >
                <EditRentingPeriodLabelForm onClose={onClose} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.EditRentingPeriodLabel}
                onClose={onClose}
                isLoading={false}
                title={translate("edit_label")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <EditRentingPeriodLabelForm onClose={onClose} />
            </DetailsSheet>
        </>
    )
}
