import { DashboardChartType, SummaryCardType } from "../../queries/userData"
import { LoadingIcon } from "rentzz"
import { useDispatch } from "../../redux/store"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import SummaryCard from "../../components/SummaryCard"
import { DashboardItemType } from "../user/dashboard-preview/DashboardItemsPreview"
import CustomDragAndDropBox from "../../components/CustomDragAndDropBox"

interface Props {
    summaryCards: SummaryCardType[]
    isPublic?: boolean
}
const SummaryCardsRow = ({ summaryCards, isPublic }: Props) => {
    const dispatch = useDispatch()

    const handleAddSummaryCard = useCallback(() => {
        dispatch(setModalOpen(AppModals.DashboardItemsPreview))
        dispatch(setEditingItem({ itemType: DashboardItemType.SummaryCard }))
    }, [dispatch])

    const renderCard = useCallback(
        (card: SummaryCardType | DashboardChartType) => <SummaryCard cardId={(card as SummaryCardType).summaryCardId} isPublic={isPublic} />,
        [isPublic],
    )

    if (!summaryCards) return <LoadingIcon />

    return (
        <CustomDragAndDropBox
            items={summaryCards}
            onAdd={handleAddSummaryCard}
            renderItems={renderCard}
            droppableId={"summary-card-row"}
            isPublic={isPublic}
        />
    )
}

export default SummaryCardsRow
