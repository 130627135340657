import { useTheme } from "@mui/material/styles"
import { Iconify, Label, LoadingIcon, useLocales } from "rentzz"
import { Box, Fab } from "@mui/material"
import React from "react"
import CustomMobileCard from "../../../../components/CustomMobileCard"
import CustomAddCard from "../../../../components/CustomAddCard"
import { RoleName, useUserDataQueryFn } from "../../../../queries/userData"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../redux/store"
import { DateTime } from "luxon"
import { getBankAccountConsentStatusColor } from "./utils"
import { useInfiniteBankAccountConsentsQuery } from "../../../../queries/bankAccountConsents"

export default function MobileNomenclatureBankAccountConsents() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: bankAccountConsents, isLoading: areBankAccountConsentsLoading } = useInfiniteBankAccountConsentsQuery([
        { field: "createdAt", sort: "desc" },
    ])
    const { data: userData } = useUserDataQueryFn()
    const { modalOpen } = useSelector((state) => state.appState)
    const { translate } = useLocales()

    if (bankAccountConsents == null || areBankAccountConsentsLoading) return <LoadingIcon />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
            {bankAccountConsents.pages.some((p) => p.items.length > 0) && modalOpen == null && userData?.roles.roleName === RoleName.GOLD && (
                <Fab
                    onClick={() => dispatch(setModalOpen(AppModals.AddBankAccountConsent))}
                    sx={{ position: "fixed", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}

            <Box display={"flex"} flexDirection={"column"} gap={1}>
                {bankAccountConsents.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.items.map((consent) => (
                            <CustomMobileCard
                                key={consent.id}
                                item={consent}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.BankAccountConsentDetails))
                                    dispatch(setEditingItem({ id: consent.id }))
                                }}
                                icon={"mdi:bank-transfer"}
                                iconColor={theme.palette.primary.main}
                                title={`${translate(`bank-name-${consent.bankCode as number}`)}`}
                                subtitle={
                                    <Label color={getBankAccountConsentStatusColor(consent.consentStatus)} style={{ textTransform: "none" }}>
                                        {`${translate(`consentStatus-${consent.consentStatus as number}`)}`}
                                    </Label>
                                }
                                firstDate={`${translate("createdAt")}: ${consent.createdAt.toLocaleString(DateTime.DATE_SHORT)}`}
                            />
                        ))}
                    </React.Fragment>
                ))}
            </Box>

            {bankAccountConsents.pages.some((p) => p.items.length === 0) && (
                <CustomAddCard
                    onCardClick={() => dispatch(setModalOpen(AppModals.AddBankAccountConsent))}
                    icon={"mdi:add-circle-outline"}
                    text={"add_bank_account"}
                    isAddDisabled={
                        userData?.roles.roleName === RoleName.FREE ||
                        userData?.roles.roleName === RoleName.BRONZE ||
                        userData?.roles.roleName === RoleName.SILVER
                    }
                    tooltipTitle={
                        userData?.roles.roleName === RoleName.FREE ||
                        userData?.roles.roleName === RoleName.BRONZE ||
                        userData?.roles.roleName === RoleName.SILVER
                            ? "permission_denied_action"
                            : "upgrade_for_functionality"
                    }
                />
            )}
        </Box>
    )
}
