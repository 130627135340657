import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import React, { useMemo } from "react"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { useUserBankAccountsQuery, useUserPropertiesQuery } from "../../../queries/userData"
import { Box } from "@mui/material"

export default function PropertiesFromBankAccountList() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: bankAccounts } = useUserBankAccountsQuery()
    const { data: properties } = useUserPropertiesQuery()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const currentBankAccount = useMemo(
        () => bankAccounts?.items.find((bankAccount) => bankAccount.id === editingItem?.id),
        [bankAccounts, editingItem],
    )

    const options = useMemo((): CustomSheetOption[] => {
        return [
            {
                icon: <AddCircleOutlineIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "add_property",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditBankAccount))
                    dispatch(setEditingItem({ id: currentBankAccount?.id, isAssociatingProperties: true }))
                },
            },
        ]
    }, [dispatch, currentBankAccount])

    const currentProperties = useMemo(() => {
        if (currentBankAccount == null || properties == null) return

        return currentBankAccount.properties.map((id) => {
            const match = properties.find((property) => property.id == id)
            return {
                id: match!.id,
                name: match!.label,
                county: match!.county,
                city: match!.city,
            }
        })
    }, [properties, currentBankAccount])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.BankAccountProperties}
            onClose={() => {
                dispatch(setModalOpen(AppModals.BankAccountDetails))
            }}
            isLoading={false}
            title={translate("assigned_properties")}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            <Box display={"flex"} flexDirection={"column"} gap={1} paddingY={1}>
                {currentProperties?.map((property) => (
                    <CustomMobileCard
                        key={property.id}
                        item={property}
                        onClick={undefined}
                        icon={"mdi:house"}
                        iconColor={theme.palette.primary.main}
                        title={property.name}
                        subtitle={`${property.county ?? ""}, ${property.city ?? ""} `}
                        onDelete={() => {
                            dispatch(setModalOpen(AppModals.DeletePropertyFromBankAccount))
                            dispatch(setEditingItem({ ...editingItem, propertyId: property.id, bankAccountId: currentBankAccount?.id }))
                        }}
                    />
                ))}
            </Box>
        </DetailsSheet>
    )
}
