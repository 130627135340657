import { GridSortModel } from "@mui/x-data-grid-pro"
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { sum } from "lodash"
import { PaginatedData } from "./expenses"
import { UserBankAccountConsent, UserFlags } from "./userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { AppContext } from "rentzz"
import { useSelector } from "../redux/store"

export const useUserBankAccountConsentsQuery = (page?: number, pageSize?: number, sortingColumns?: GridSortModel) => {
    const { context } = useSelector((state) => state.appState)
    const isSmartAccountEnabled = useFeatureIsOn(UserFlags.SmartAccountsSync as string)

    return useQuery({
        queryKey: [ComplexQueryIDs.BankAccountConsents, { page, pageSize, sortingColumns }],
        queryFn: Api.fetchUserBankAccountConsents,
        staleTime: Infinity,
        enabled: isSmartAccountEnabled && context === AppContext.Owner,
    })
}

export const useUserConsentBankAccountsQuery = (internalConsentId: number) => {
    const { context } = useSelector((state) => state.appState)
    const isSmartAccountEnabled = useFeatureIsOn(UserFlags.SmartAccountsSync as string)

    return useQuery({
        queryKey: [ComplexQueryIDs.ConsentBankAccounts, { internalConsentId }],
        queryFn: Api.fetchUserConsentBankAccounts,
        staleTime: Infinity,
        enabled: isSmartAccountEnabled && context === AppContext.Owner,
    })
}

export const getBankAccountConsentByIdQueryFn = (consentId?: number): UserBankAccountConsent | undefined => {
    const queryClient = useQueryClient()

    const bankAccountConsentsPages = queryClient.getQueriesData({
        queryKey: [ComplexQueryIDs.BankAccountConsents],
        stale: false,
    })

    const arrayOfConsents = (
        (bankAccountConsentsPages?.flatMap((consentWithKey) => consentWithKey[1]) as { pages: PaginatedData<UserBankAccountConsent> }[]).flatMap(
            (i) => (i?.pages != null ? i.pages : i),
        ) as PaginatedData<UserBankAccountConsent>[]
    )?.flatMap((r) => r?.items) as UserBankAccountConsent[]

    const consent = arrayOfConsents?.find((revision) => revision?.id == consentId)

    if (consent) {
        return {
            ...consent,
        }
    } else return consent ?? undefined
}

export const useInfiniteBankAccountConsentsQuery = (sortingColumns?: GridSortModel) => {
    const { context } = useSelector((state) => state.appState)
    const isSmartAccountEnabled = useFeatureIsOn(UserFlags.SmartAccountsSync as string)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.BankAccountConsents, { sortingColumns }],
        queryFn: Api.fetchInfiniteBankAccountConsents,
        staleTime: Infinity,
        enabled: isSmartAccountEnabled && context === AppContext.Owner,
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}
