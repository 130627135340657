import { AppModals, setEditingItem, setErrorMessage, setModalOpen } from "../../../redux/slices/App"
import { CustomDialog, DetailsSheet, ExpenseAssignee, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useState } from "react"
import { useSingleExpenseQuery } from "../../../queries/expenses"
import { useDispatch, useSelector } from "../../../redux/store"
import AddExpenseContent from "./AddExpenseContent"
import { useTheme } from "@mui/material/styles"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../queries/userData"
import { AddExpenseSteps } from "./AddExpenseSteps"

export default function AddOrEditExpenseGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const [activeStep, setActiveStep] = useState<AddExpenseSteps>(AddExpenseSteps.General)
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currentExpense } = useSingleExpenseQuery(editingItem?.id)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    const closeModal = useCallback(() => {
        dispatch(setErrorMessage(undefined))
        setActiveStep(AddExpenseSteps.General)

        if (isMobile && modalOpen === AppModals.EditExpense) {
            dispatch(setModalOpen(AppModals.ExpenseDetails))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [modalOpen, dispatch, isMobile, setActiveStep])

    return (
        <>
            <CustomDialog
                title={translate(currentExpense && modalOpen !== AppModals.PendingExpenseStatus ? "expenses.editExpense" : "expenses.addExpense")}
                open={!isMobile && (modalOpen === AppModals.AddExpense || modalOpen === AppModals.EditExpense)}
                onClose={closeModal}
                maxWidth={areInvoicingConfigurationOn ? "md" : "sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddExpenseContent currentExpense={currentExpense} closeModal={closeModal} activeStep={activeStep} setActiveStep={setActiveStep} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(currentExpense ? "expenses.editExpense" : "expenses.addExpense")}
                isOpen={isMobile && (modalOpen === AppModals.AddExpense || modalOpen === AppModals.EditExpense)}
                onClose={closeModal}
                headerColor={
                    modalOpen === AppModals.AddExpense || currentExpense?.assignee === ExpenseAssignee.ME
                        ? theme.palette.primary.main
                        : editingItem?.backgroundColor
                }
            >
                <AddExpenseContent currentExpense={currentExpense} closeModal={closeModal} activeStep={activeStep} setActiveStep={setActiveStep} />
            </DetailsSheet>
        </>
    )
}
