import React, { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setModalOpen } from "../redux/slices/App"
import { Box, Button } from "@mui/material"
import { getRangeAddress, SpreadsheetComponent } from "@syncfusion/ej2-react-spreadsheet"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../queries/userData"
import { processDownloadData } from "../utils/actions"

export default function ExcelViewer() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const spreadsheetRef = React.useRef<SpreadsheetComponent>(null)
    const backendPath = useFeatureValue(UserFlags.BackendPath, "")
    const { translate } = useLocales()

    useEffect(() => {
        if (modalOpen === AppModals.ExcelViewer && editingItem != null) {
            const file = editingItem.file as File
            setTimeout(() => {
                spreadsheetRef.current?.open({ file })
            }, 500)
        }
    }, [modalOpen, editingItem])

    const onOpenComplete = useCallback(() => {
        const sheets = spreadsheetRef.current?.sheets
        const protectSettings = {
            selectCells: true,
            formatCells: false,
        }
        sheets?.forEach((sheet) => {
            spreadsheetRef?.current?.protectSheet(sheet.name, protectSettings)
            const address = getRangeAddress([0, 0, sheet.usedRange?.rowIndex ?? 0, sheet.usedRange?.colIndex ?? 0])
            spreadsheetRef.current?.lockCells(sheet.name + "!" + address, true)
            spreadsheetRef.current?.autoFit(address)
        })
    }, [])

    const handleDownload = useCallback(() => {
        if (editingItem == null) return
        processDownloadData({ blob: editingItem?.file }, "export", "xlsx")
    }, [editingItem])

    return (
        <>
            {!isMobile && (
                <CustomDialog
                    open={modalOpen === AppModals.ExcelViewer}
                    isFullScreen={isMobile}
                    maxWidth={"md"}
                    title={""}
                    onClose={() => {
                        dispatch(setModalOpen(editingItem?.previousModal))
                    }}
                >
                    <Box height={750} display='flex' flexDirection='column' gap={1}>
                        <Button variant='contained' color={"primary"} onClick={handleDownload}>
                            {translate("download")}
                        </Button>
                        <SpreadsheetComponent
                            ref={spreadsheetRef}
                            allowOpen
                            isProtected={true}
                            openComplete={onOpenComplete}
                            openUrl={`${backendPath}/user/open-excel`}
                            showSheetTabs={false}
                            showRibbon={false}
                            showFormulaBar={false}
                        />
                    </Box>
                </CustomDialog>
            )}
            {isMobile && (
                <DetailsSheet
                    isLoading={false}
                    title={translate("preview")}
                    isOpen={modalOpen === AppModals.ExcelViewer}
                    onClose={() => dispatch(setModalOpen(editingItem?.previousModal))}
                >
                    <Box height={"75vh"} display='flex' flexDirection='column' gap={1}>
                        <Button variant='contained' color={"primary"} onClick={handleDownload}>
                            {translate("download")}
                        </Button>
                        <SpreadsheetComponent
                            ref={spreadsheetRef}
                            allowOpen
                            isProtected={true}
                            openComplete={onOpenComplete}
                            openUrl={`${backendPath}/user/open-excel`}
                            showSheetTabs={false}
                            showRibbon={false}
                            showFormulaBar={false}
                        />
                    </Box>
                </DetailsSheet>
            )}
        </>
    )
}
