import React, { useCallback, useMemo, useState } from "react"
import { LightTooltip, RoleName, useIsMobile, useLocales } from "rentzz"
import ExportButton from "../../../expenses/export/ExportButton"
import { ExportType } from "../../../../utils/actions"
import { Box, Button } from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useRentingPeriodDetails } from "../../../../queries/tenants"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { AccountType, UserFlags, useUserDataQueryFn } from "../../../../queries/userData"
import { LoadingButton } from "@mui/lab"
import Api from "../../../../api/Api"
import { RentingPeriodStatusCode } from "../../../../redux/slices/AddProperty"
import RoleLockedButton from "../../../../components/RoleLockedButton"

const Buttons = () => {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: userData } = useUserDataQueryFn()
    const dispatch = useDispatch()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())
    const showPaymentNotifications = useFeatureIsOn(UserFlags.ShowPaymentNotifications.toString())
    const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false)
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel.toString())
    const isInventoryProcessOn = useFeatureIsOn(UserFlags.InventoryProcess.toString())

    const handleInventoryProcessExport = useCallback(() => {
        dispatch(setEditingItem({ rentingPeriodId: rentingPeriodDetails?.id }))
        dispatch(setModalOpen(AppModals.GenerateInventoryProcess))
    }, [rentingPeriodDetails])

    const isCreateInvoiceButtonDisabled = useMemo(() => {
        if (rentingPeriodDetails == null) return
        return (
            rentingPeriodDetails.invoicingCompany == null ||
            rentingPeriodDetails.invoicingSeries == null ||
            rentingPeriodDetails.invoicingClientId == null ||
            rentingPeriodDetails.invoicingCurrencyId === undefined ||
            rentingPeriodDetails.invoiceMaxDays == null ||
            rentingPeriodDetails.invoicingLanguage == null ||
            rentingPeriodDetails.defaultInvoicingText == null ||
            rentingPeriodDetails.defaultInvoicingTemplate == null ||
            (rentingPeriodDetails.invoicingCompany.isVatPayer && rentingPeriodDetails.invoicingVAT == null)
        )
    }, [])

    const variablesAreNotConfigured = useMemo(
        () => rentingPeriodDetails?.paymentNotificationVariables.some((v) => v.value == null || v.value === ""),
        [rentingPeriodDetails],
    )

    const sendNotificationTooltipTitle = useMemo(() => {
        if (
            rentingPeriodDetails?.users.length === 0 &&
            rentingPeriodDetails?.invitedUsers.length === 0 &&
            rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.Accepted
        )
            return translate("invite_one_tenant_to_send_notification")
        if (userData?.roles.roleName != null && [RoleName.FREE, RoleName.BRONZE, RoleName.SILVER].includes(userData?.roles.roleName)) {
            return ""
        }
        if (
            rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.Accepted ||
            rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.PartiallyAccepted
        )
            return ""
        else return translate("have_to_be_accepted")
    }, [userData, rentingPeriodDetails, translate, rentingPeriodDetails])

    if (isMobile) return null

    return (
        <Box display='flex' gap={2}>
            <ExportButton isDisabled={false} type={ExportType.Balance} text={translate("generate_balance_report")} />
            {isInventoryProcessOn && (
                <Button variant='contained' color='primary' onClick={handleInventoryProcessExport}>
                    {translate("generate_inventory_process")}
                </Button>
            )}

            {areInvoicingConfigurationOn && (!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                <LightTooltip title={isCreateInvoiceButtonDisabled ? translate("please_fill_invoice_data") : ""} arrow>
                    <span>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                dispatch(setModalOpen(AppModals.CreateInvoice))
                                dispatch(
                                    setEditingItem({
                                        id: currentRentingPeriodId,
                                    }),
                                )
                            }}
                            disabled={isCreateInvoiceButtonDisabled}
                        >
                            {translate("create_invoice")}
                        </Button>
                    </span>
                </LightTooltip>
            )}

            {showPaymentNotifications && (!isWhitelabel || (isWhitelabel && userData?.accountType === AccountType.WHITELABEL_ADMIN_USER)) && (
                <LightTooltip
                    title={
                        !variablesAreNotConfigured && rentingPeriodDetails?.paymentNotificationTemplateId
                            ? ""
                            : translate("please_fill_payment_notification_data")
                    }
                    arrow
                >
                    <span>
                        <LoadingButton
                            variant='contained'
                            color='primary'
                            onClick={async () => {
                                if (currentRentingPeriodId == null) return
                                setIsPreviewLoading(true)
                                const notificationPreview = await Api.fetchGeneralRentingPeriodNotificationPreview(currentRentingPeriodId, false)
                                const url = window.URL.createObjectURL(new Blob([notificationPreview.blob]))
                                dispatch(
                                    setEditingItem({
                                        fileName: notificationPreview.fileName,
                                        url: url,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.PdfViewer))
                                setIsPreviewLoading(false)
                            }}
                            disabled={variablesAreNotConfigured || !rentingPeriodDetails?.paymentNotificationTemplateId}
                            loading={isPreviewLoading}
                        >
                            {translate("generate_payment_notification")}
                        </LoadingButton>
                    </span>
                </LightTooltip>
            )}
            <LightTooltip title={sendNotificationTooltipTitle} arrow>
                <span>
                    <RoleLockedButton
                        blockedRoles={[RoleName.FREE, RoleName.BRONZE, RoleName.SILVER]}
                        buttonText={translate("send_notification")}
                        isDisabled={
                            (rentingPeriodDetails?.users.length === 0 &&
                                rentingPeriodDetails?.invitedUsers.length === 0 &&
                                rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.Accepted) ||
                            !(
                                rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.Accepted ||
                                rentingPeriodDetails?.rentingPeriodStatus === RentingPeriodStatusCode.PartiallyAccepted
                            )
                        }
                        onClick={() => {
                            dispatch(setModalOpen(AppModals.SendTenantNotification))
                        }}
                    />
                </span>
            </LightTooltip>
        </Box>
    )
}

export default Buttons
