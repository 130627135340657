import { DetailsSheet } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useEffect, useMemo, useRef } from "react"
import { Box, CircularProgress } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomAddCard from "../../../components/CustomAddCard"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { DateTime } from "luxon"
import { RoleName, useUserDataQueryFn } from "../../../queries/userData"
import { getInventorySectionByIdQueryFn, useInfiniteInventorySectionsItemsQuery } from "../../../queries/inventory"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import AddIcon from "@mui/icons-material/Add"

export default function MobileInventoryItemsListDetailsSheet() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: user } = useUserDataQueryFn()
    const ref = useRef<HTMLDivElement>(null)
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const {
        data: inventorySectionsItems,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteInventorySectionsItemsQuery(editingItem?.inventorySectionId, [{ field: "createdAt", sort: "desc" }])
    const inventorySection = getInventorySectionByIdQueryFn(editingItem?.inventorySectionId)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    const options = useMemo((): CustomSheetOption[] => {
        return [
            {
                icon: <AddIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "add_inventory_section_item",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.AddInventorySectionItem))
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            canDelete: inventorySection?.canDelete,
                            canWrite: inventorySection?.canWrite,
                        }),
                    )
                },
                hidden: !inventorySection?.canDelete,
            },
        ]
    }, [inventorySection, dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.InventorySectionItemList}
            onClose={() => {
                dispatch(setModalOpen(AppModals.InventorySectionDetails))
            }}
            isLoading={false}
            title={inventorySection?.sectionName}
            options={inventorySectionsItems?.pages.some((p) => p.items.length === 0) && inventorySection?.canWrite ? [] : options}
        >
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {inventorySectionsItems?.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.items.map((inventoryItem) => (
                            <CustomMobileCard
                                key={inventoryItem.id}
                                item={inventoryItem}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.InventorySectionItemDetails))
                                    dispatch(setEditingItem({ ...editingItem, inventoryItemId: inventoryItem.id }))
                                }}
                                icon={"mdi:box-outline"}
                                iconColor={theme.palette.primary.main}
                                title={inventoryItem.itemName}
                                subtitle={inventoryItem.createdAt.toLocaleString(DateTime.DATE_SHORT)}
                            />
                        ))}
                    </React.Fragment>
                ))}
                {hasNextPage && (
                    <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                        <CircularProgress />
                    </div>
                )}
                {inventorySectionsItems?.pages.some((p) => p.items.length === 0) && inventorySection?.canWrite && (
                    <CustomAddCard
                        onCardClick={() => dispatch(setModalOpen(AppModals.AddInventorySectionItem))}
                        icon={"mdi:box-add-outline"}
                        text={"add_inventory_section_item"}
                        isAddDisabled={user?.roles.roleName === RoleName.FREE || user?.roles.roleName === RoleName.BRONZE}
                        tooltipTitle={"permission_denied_action"}
                    />
                )}
            </Box>
        </DetailsSheet>
    )
}
