import { useDispatch, useSelector } from "../../../redux/store"
import { RoleName, useUserDataQueryFn } from "../../../queries/userData"
import { Iconify, LoadingIcon } from "rentzz"
import { Box, Fab } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomAddCard from "../../../components/CustomAddCard"
import React from "react"
import { useAllSectionsQueryFn } from "../../../queries/kanban"
import SectionCard from "./SectionCard"

export default function MobileSectionsList() {
    const dispatch = useDispatch()
    const { data: allSections, isLoading: areAllSectionsLoading } = useAllSectionsQueryFn()
    const { data: userData } = useUserDataQueryFn()
    const { modalOpen } = useSelector((state) => state.appState)

    if (allSections == null || areAllSectionsLoading) return <LoadingIcon />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
            {allSections.length !== 0 && modalOpen == null && userData?.roles.roleName !== RoleName.FREE && (
                <Fab
                    onClick={() => dispatch(setModalOpen(AppModals.AddSection))}
                    sx={{ position: "fixed", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}

            <Box display={"flex"} flexDirection={"column"} gap={1}>
                {allSections.map((section) => (
                    <SectionCard
                        key={section.taskSectionId}
                        onClick={() => {
                            dispatch(setEditingItem({ id: section.taskSectionId }))
                            dispatch(setModalOpen(AppModals.SectionDetails))
                        }}
                        createdAt={section.createdAt}
                        name={section.name}
                        order={section.order}
                    />
                ))}
            </Box>

            {allSections.length === 0 && (
                <CustomAddCard
                    onCardClick={() => dispatch(setModalOpen(AppModals.AddSection))}
                    icon={"mdi:add-circle-outline"}
                    text={"add_section"}
                    isAddDisabled={userData?.roles.roleName === RoleName.FREE}
                    tooltipTitle={"permission_denied_action"}
                />
            )}
        </Box>
    )
}
