import { AppNotifications } from "../../../redux/slices/AddProperty"

export const allPossibleOwnerNotifications = [
    AppNotifications.IncomeWithExpenseAddedByTenant,
    AppNotifications.IncomeAddedByTenant,
    AppNotifications.InsuranceEnding,
    AppNotifications.PendingExpenseCreatedNotification,
    AppNotifications.TaskCreated,
    AppNotifications.TaskDeleted,
    AppNotifications.NewTaskComment,
    AppNotifications.TaskStatusChanged,
    AppNotifications.MonthlyPropertyBalanceNotification,
    AppNotifications.RentingPeriodEndingSoonOwner,
    AppNotifications.MaintenanceComingSoon,
    AppNotifications.MaintenanceOverdue,
    AppNotifications.ProviderIncomeAcceptedForOwnerNotification,
    AppNotifications.MeterReadingAddedByTenant,
]