import { Box, InputAdornment, MenuItem } from "@mui/material"
import React from "react"
import { Furniture, RHFSelect, RHFTextField, RHFUnitInput, TVType, useLocales } from "rentzz"
import { availableStories } from "../../../../../config"
import OtherDetails from "./OtherDetails"
import { useCurrencyQueryFn } from "../../../../../queries/currency"
import { Partition } from "./utils"

interface Details {
    isDisabled?: boolean
}

export default function Details({ isDisabled }: Details) {
    const { translate } = useLocales()
    const { data: currencies } = useCurrencyQueryFn()

    return (
        <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Box display={"flex"} gap={2}>
                <RHFUnitInput
                    data={currencies ?? []}
                    unitName='currencyId'
                    unitType='currency'
                    name='rentPrice'
                    label={translate("marketing.rentPrice")}
                    required
                    size='small'
                    unitSelectSize={"small"}
                    disabled={isDisabled}
                />
                <RHFTextField
                    name={"size"}
                    label={translate("size")}
                    size='small'
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position='end'>
                                m<sup>2</sup>
                            </InputAdornment>
                        ),
                    }}
                    disabled={isDisabled}
                />
                <RHFSelect name={"partition"} label={translate("partition")} size='small' disabled={isDisabled}>
                    <MenuItem value={""} style={{ textTransform: "none" }}>
                        {translate("unspecified_detail")}
                    </MenuItem>
                    <MenuItem value={Partition.DETACHED} style={{ textTransform: "none" }}>
                        {translate(`partition-${Partition.DETACHED}`)}
                    </MenuItem>
                    <MenuItem value={Partition.NONDETACHED} style={{ textTransform: "none" }}>
                        {translate(`partition-${Partition.NONDETACHED}`)}
                    </MenuItem>
                </RHFSelect>
            </Box>
            <Box display={"flex"} gap={2}>
                <RHFSelect name='floor' label={translate("floor")} sx={{ textAlign: "start" }} size='small' disabled={isDisabled}>
                    {availableStories.map((option) => (
                        <MenuItem key={option.value} value={option.value} style={{ textTransform: "none" }}>
                            {option.value === 0 ? translate("ground") : translate("floor_with_number", { floor: option.value })}
                        </MenuItem>
                    ))}
                </RHFSelect>

                <RHFSelect name={"furniture"} label={translate("furniture")} size='small' disabled={isDisabled}>
                    <MenuItem value={""} style={{ textTransform: "none" }}>
                        {translate("unspecified_detail")}
                    </MenuItem>
                    <MenuItem value={Furniture.NONE} style={{ textTransform: "none" }}>
                        {translate(`furniture-${Furniture.NONE}`)}
                    </MenuItem>
                    <MenuItem value={Furniture.SUMAR} style={{ textTransform: "none" }}>
                        {translate(`furniture-${Furniture.SUMAR}`)}
                    </MenuItem>
                    <MenuItem value={Furniture.FULL} style={{ textTransform: "none" }}>
                        {translate(`furniture-${Furniture.FULL}`)}
                    </MenuItem>
                </RHFSelect>
            </Box>
            <Box display={"flex"} gap={2}>
                <RHFTextField name={"rooms"} label={translate("rooms")} size='small' disabled={isDisabled} />
                <RHFTextField name={"bathrooms"} label={translate("bathrooms")} size='small' disabled={isDisabled} />
                <RHFSelect name={"tv"} label={translate("tv")} size='small' disabled={isDisabled}>
                    <MenuItem value={""} style={{ textTransform: "none" }}>
                        {translate("unspecified_detail")}
                    </MenuItem>
                    <MenuItem value={TVType.NONE} style={{ textTransform: "none" }}>
                        {translate(`tvType-${TVType.NONE}`)}
                    </MenuItem>
                    <MenuItem value={TVType.REGULAR} style={{ textTransform: "none" }}>
                        {translate(`tvType-${TVType.REGULAR}`)}
                    </MenuItem>
                    <MenuItem value={TVType.SMART} style={{ textTransform: "none" }}>
                        {translate(`tvType-${TVType.SMART}`)}
                    </MenuItem>
                </RHFSelect>
            </Box>
            <OtherDetails isDisabled={isDisabled} />
        </Box>
    )
}
