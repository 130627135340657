import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { Iconify, LoadingIcon, useLocales } from "rentzz"
import { RoleName, useUserDataQueryFn } from "../../../../../queries/userData"
import React from "react"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import NoDataPage from "../../../../../components/NoDataPage"
import { Box, Fab } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import CustomMobileCard from "../../../../../components/CustomMobileCard"
import CustomAddCard from "../../../../../components/CustomAddCard"
import { useRentingPeriodInventoryProcessesQueryFn } from "../../../../../queries/inventory"
import { DateTime } from "luxon"

const MobileInventoryProcessesList = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()
    const { modalOpen, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)
    const { data: rentingPeriodInventoryProcesses, isLoading: areRentingPeriodInventoryProcessesLoading } =
        useRentingPeriodInventoryProcessesQueryFn()

    if (rentingPeriodInventoryProcesses?.length === 0 && !canWrite) return <NoDataPage noDataText={"no_inventory_processes"} />

    if (areRentingPeriodInventoryProcessesLoading == null) return <LoadingIcon />

    return (
        <>
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {rentingPeriodInventoryProcesses?.length !== 0 &&
                    modalOpen == null &&
                    canWrite &&
                    rentingPeriodDetails?.canAddInventoryProcess &&
                    user?.roles.roleName !== RoleName.BRONZE &&
                    user?.roles.roleName !== RoleName.FREE && (
                        <Fab
                            onClick={() => dispatch(setModalOpen(AppModals.StartInventoryProcess))}
                            sx={{ position: "fixed", bottom: 10, right: 10 }}
                            color='primary'
                            aria-label='add'
                        >
                            <Iconify icon={"mdi:box-add-outline"} width={25} height={25} />
                        </Fab>
                    )}
                {rentingPeriodInventoryProcesses?.map((inventoryProcess) => (
                    <CustomMobileCard
                        key={inventoryProcess.id}
                        item={inventoryProcess}
                        onClick={() => {
                            dispatch(setModalOpen(AppModals.InventoryProcessDetails))
                            dispatch(setEditingItem({ id: inventoryProcess.id }))
                        }}
                        icon={"mdi:box-outline"}
                        iconColor={theme.palette.primary.main}
                        title={translate(`inventory-process-type-${inventoryProcess.type}`)}
                        subtitle={translate(`inventory_process_status-${inventoryProcess.status}`)}
                        firstDate={inventoryProcess.createdAt.toLocaleString(DateTime.DATE_SHORT)}
                    />
                ))}
                {rentingPeriodInventoryProcesses?.length === 0 && canWrite && (
                    <CustomAddCard
                        onCardClick={() => dispatch(setModalOpen(AppModals.StartInventoryProcess))}
                        icon={"mdi:box-add-outline"}
                        text={"start_inventory_process"}
                        isAddDisabled={
                            user?.roles.roleName === RoleName.FREE ||
                            user?.roles.roleName === RoleName.BRONZE ||
                            !rentingPeriodDetails?.canAddInventoryProcess
                        }
                        tooltipTitle={
                            !rentingPeriodDetails?.canAddInventoryProcess ? "make_sure_you_have_inventory_sections" : "permission_denied_action"
                        }
                    />
                )}
            </Box>
        </>
    )
}

export default MobileInventoryProcessesList
