import { AppContext, C168AddressBase } from "rentzz"
import { DateTime } from "luxon"
import { useSelector } from "../redux/store"
import { useQuery } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import Api from "../api/Api"
import { useUserDataQueryFn } from "./userData"
import { useMemo } from "react"
import { IdentifierType } from "../mutations/property-owners"

export interface PropertyOwnerDto extends C168AddressBase {
    lastModifiedAt: string
    createdAt: string
    properties: { propertyId: number; ownershipPercentage: number }[]
}

export interface PropertyOwner extends C168AddressBase {
    lastModifiedAt: DateTime
    createdAt: DateTime
    properties: { propertyId: number; ownershipPercentage: number }[]
}

export interface PropertyOwnerDetailsDto extends C168AddressBase {
    lastModifiedAt: string
    createdAt: string
    properties: { propertyId: number; ownershipPercentage: number }[]
    representatives: PropertyOwnerRepresentativeDto[]
    identifierType: IdentifierType
    customIdentifierType?: string
    identificationDocumentSeries?: string
    identificationDocumentNumber?: string
    identificationDocumentIssuer?: string
    identificationDocumentIssuedDate?: string
}

export interface PropertyOwnerDetails extends C168AddressBase {
    lastModifiedAt: DateTime
    createdAt: DateTime
    properties: { propertyId: number; ownershipPercentage: number }[]
    representatives: PropertyOwnerRepresentative[]
    identifierType: IdentifierType
    customIdentifierType?: string
    identificationDocumentSeries?: string
    identificationDocumentNumber?: string
    identificationDocumentIssuer?: string
    identificationDocumentIssuedDate?: DateTime
}

export interface PropertyOwnerRepresentativeDto extends C168AddressBase {
    lastModifiedAt: string
    createdAt: string
    startDate: string
    endDate: string
    id: string
    identificationDocumentSeries?: string
    identificationDocumentNumber?: string
    identificationDocumentIssuer?: string
    identificationDocumentIssuedDate?: string
    identifierType: IdentifierType
    customIdentifierType?: string
}

export interface PropertyOwnerRepresentative extends C168AddressBase {
    lastModifiedAt: DateTime
    createdAt: DateTime
    startDate: DateTime
    endDate: DateTime
    id: string
    identifierType: IdentifierType
    customIdentifierType?: string
    identificationDocumentSeries?: string
    identificationDocumentNumber?: string
    identificationDocumentIssuer?: string
    identificationDocumentIssuedDate?: DateTime
}

export const usePropertyOwnersQuery = () => {
    const { context } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()

    return useQuery({
        queryKey: [SimpleQueryIDs.PropertyOwners],
        queryFn: Api.fetchPropertyOwners,
        staleTime: Infinity,
        enabled: !!userData && context === AppContext.Owner && userData.isNonWhiteLabelOrWhiteLabelAdminUser,
    })
}

export const usePropertyOwnerDetailsQuery = (selectedOwnerId?: number) => {
    const { currentPropertyOwnerId, context } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.PropertyOwnerDetails, { id: currentPropertyOwnerId || selectedOwnerId }],
        queryFn: Api.fetchPropertyOwnerDetails,
        staleTime: Infinity,
        enabled: !!currentPropertyOwnerId && context === AppContext.Owner,
    })
}

export const usePropertyOwnerByIdQuery = (propertyOwnerId?: string) => {
    const { data: owners } = usePropertyOwnersQuery()

    return useMemo(() => {
        return owners?.items.find((i) => i.id === propertyOwnerId)
    }, [owners, propertyOwnerId])
}
