import Api from "../../../../../api/Api"
import { AddFormDataRequest, C168Form, Currency, DefaultCountry, Page, PersonalData, PersonType, useLocales } from "rentzz"
import React, { useCallback, useMemo, useState } from "react"
import { RoleName, useUserDataQueryFn, useUserPropertiesQuery } from "../../../../../queries/userData"
import { useCurrencyQueryFn } from "../../../../../queries/currency"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { DateTime } from "luxon"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { usePropertyOwnersQuery } from "../../../../../queries/property-owners"

const transformToApiModel = (data: AddFormDataRequest, currencies: Currency[]) => {
    const formData = new FormData()
    addPersonalDataToFormData(formData, data.owner, "owner")
    data.tenants.forEach((tenant, index) => {
        addPersonalDataToFormData(formData, tenant, `tenants[${index}]`)
    })
    if (data.contract.number) {
        formData.set("contract.number", data.contract.number.toString())
    }
    if (data.contract.numberDate) {
        formData.set("contract.numberDate", DateTime.fromISO(data.contract.numberDate.toString()).toISODate()!)
    }
    if (data.contract.value) {
        formData.set("contract.value", data.contract.value.toString())
    }
    if (data.contract.currency) {
        formData.set("contract.currency", currencies.find((c) => c.id === data.contract.currency)!.code)
    }
    if (data.contract.from) {
        formData.set("contract.from", DateTime.fromISO(data.contract.from.toString()).toISODate()!)
    }
    if (data.contract.to) {
        formData.set("contract.to", DateTime.fromISO(data.contract.to.toString()).toISODate()!)
    }
    if (data.contract.county) {
        formData.set("contract.county", data.contract.county.toString())
    }
    if (data.contract.city) {
        formData.set("contract.city", data.contract.city.toString())
    }
    if (data.contract.street) {
        formData.set("contract.street", data.contract.street.toString())
    }
    if (data.contract.postalCode) {
        formData.set("contract.postalCode", data.contract.postalCode.toString())
    }
    if (data.contract.streetNumber) {
        formData.set("contract.streetNumber", data.contract.streetNumber.toString())
    }
    if (data.contract.files) {
        data.contract.files.forEach((f) => formData.append("contract.files", f))
    }
    if (data.contract.buildingNumber) {
        formData.set("contract.buildingNumber", data.contract.buildingNumber.toString())
    }
    if (data.contract.stair) {
        formData.set("contract.stair", data.contract.stair.toString())
    }
    if (data.contract.floor) {
        formData.set("contract.floor", data.contract.floor.toString())
    }
    if (data.contract.apartment) {
        formData.set("contract.apartment", data.contract.apartment.toString())
    }

    if (data.declarant.firstName) {
        formData.set("userData.firstName", data.declarant.firstName.toString())
    }
    if (data.declarant.lastName) {
        formData.set("userData.lastName", data.declarant.lastName.toString())
    }
    if (data.declarant.position) {
        formData.set("userData.position", data.declarant.position.toString())
    }

    return formData
}

const addPersonalDataToFormData = (formData: FormData, data: PersonalData, key: string) => {
    if (data.personType) {
        formData.set(`${key}.personType`, data.personType.toString())
    }
    if (data.firstName) {
        formData.set(`${key}.firstName`, data.firstName.toString())
    }
    if (data.lastName) {
        formData.set(`${key}.lastName`, data.lastName.toString())
    }
    if (data.identifier && data.personType === PersonType.Natural) {
        formData.set(`${key}.cnp`, data.identifier.toString())
    }
    if (data.identifier && data.personType === PersonType.Company) {
        formData.set(`${key}.cui`, data.identifier.toString())
    }
    if (data.country) {
        formData.set(`${key}.country`, data.country.toString())
    }
    if (data.county) {
        formData.set(`${key}.county`, data.county.toString())
    }
    if (data.city) {
        formData.set(`${key}.city`, data.city.toString())
    }
    if (data.street) {
        formData.set(`${key}.street`, data.street.toString())
    }
    if (data.streetNumber) {
        formData.set(`${key}.streetNumber`, data.streetNumber.toString())
    }
    if (data.postalCode) {
        formData.set(`${key}.postalCode`, data.postalCode.toString())
    }
    if (data.buildingNumber) {
        formData.set(`${key}.buildingNumber`, data.buildingNumber.toString())
    }
    if (data.stair) {
        formData.set(`${key}.stair`, data.stair.toString())
    }
    if (data.floor) {
        formData.set(`${key}.floor`, data.floor.toString())
    }
    if (data.apartment) {
        formData.set(`${key}.apartment`, data.apartment.toString())
    }
    if (data.phoneNumber) {
        formData.set(`${key}.phoneNumber`, data.phoneNumber.toString())
    }
    if (data.faxNumber) {
        formData.set(`${key}.faxNumber`, data.faxNumber.toString())
    }
    if (data.email) {
        formData.set(`${key}.email`, data.email.toString())
    }
    if (data.companyName) {
        formData.set(`${key}.companyName`, data.companyName.toString())
    }
}

const C168 = () => {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: userProperties } = useUserPropertiesQuery()
    const [isError, setIsError] = useState<boolean>(false)
    const { data: propertyOwners } = usePropertyOwnersQuery()

    const generateDeclaration = useCallback(async (data: AddFormDataRequest) => {
        const result = await Api.downloadC168(transformToApiModel(data, currencies ?? []))
        if (result.size === 0) {
            setIsError(true)
            return
        }
        const url = window.URL.createObjectURL(new Blob([result]))
        if (url) {
            dispatch(
                setEditingItem({
                    url: url,
                    fileName: "declaration.pdf",
                }),
            )
            dispatch(setModalOpen(AppModals.PdfViewer))
        }
    }, [])

    // @ts-expect-error not all data has default values
    const buildDefaultFormData: Partial<AddFormDataRequest> = useMemo(() => {
        const property = userProperties?.find((p) => p.id === rentingPeriodDetails?.propertyId)
        if (property == null && userProperties != null) throw new Error("Invalid property")
        return {
            owner: {
                personType: PersonType.Natural,
                country: DefaultCountry,
                email: userData?.email,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
            },
            tenants: [
                {
                    personType: PersonType.Natural,
                    country: DefaultCountry,
                },
            ],
            contract: {
                currency: userData?.currency.id,
                from: rentingPeriodDetails?.moveInDate.toISO(),
                to: rentingPeriodDetails?.moveOutDate.toISO(),
                number: 1,
                numberDate: rentingPeriodDetails?.moveInDate.toISO(),
                value: rentingPeriodDetails?.value,
                files: [],
            },
        }
    }, [rentingPeriodDetails, userData, userProperties])

    if (userData == null || currencies == null || propertyOwners == null) return null

    return (
        <Page
            title={currentRentingPeriodId ? translate("tenants") : "C186"}
            propertyName={currentRentingPeriodId ? "C168" : ""}
            paddingBottom={2}
            paddingTop={1}
            sx={userData.roles.roleName === RoleName.FREE ? { pointerEvents: "none", opacity: "0.4" } : {}}
        >
            <C168Form
                onSubmit={generateDeclaration}
                api={Api}
                currencies={currencies ?? []}
                defaultFormValues={buildDefaultFormData}
                isError={isError}
                showCompleteInfo={true}
                c168Addresses={propertyOwners}
            />
        </Page>
    )
}

export default C168
