import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import * as jsonpatch from "fast-json-patch"
import { addInventorySectionItemMutation, updateInventorySectionItemMutation } from "../../../mutations/inventory"
import { getInventorySectionItemByIdQueryFn } from "../../../queries/inventory"
import AddOrEditInventorySectionItemForm, {
    AddInventorySectionItemRequest,
} from "../../../sections/propertyDetails/inventory/AddOrEditInventorySectionItemForm"
import { DateTime } from "luxon"

export default function AddOrEditInventorySectionItemGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addInventoryItem, isPending: isAddingNewInventoryItem } = addInventorySectionItemMutation()
    const { mutateAsync: updateInventorySection, isPending: isUpdatingInventorySection } = updateInventorySectionItemMutation()
    const inventorySectionItem = getInventorySectionItemByIdQueryFn(editingItem?.inventorySectionId, editingItem?.inventoryItemId)

    const closeModal = useCallback(() => {
        if (isMobile) {
            if (modalOpen == AppModals.AddInventorySectionItem) {
                dispatch(setModalOpen(AppModals.InventorySectionItemList))
            } else {
                dispatch(setModalOpen(AppModals.InventorySectionItemDetails))
            }
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, modalOpen, isMobile])

    const onSave = useCallback(
        async (data: AddInventorySectionItemRequest) => {
            if (inventorySectionItem != null && editingItem != null) {
                const operations = jsonpatch.compare(
                    { ...inventorySectionItem, files: [], purchaseDate: inventorySectionItem.purchaseDate.toISODate() },
                    { ...data, files: [], purchaseDate: DateTime.fromISO(data.purchaseDate).toISODate() },
                )

                if (!data.id) return
                await updateInventorySection({
                    inventorySectionId: editingItem.inventorySectionId,
                    inventoryItemId: data.id,
                    operations,
                })
            } else {
                await addInventoryItem({ data, inventorySectionId: editingItem?.inventorySectionId })
            }
            closeModal()
        },
        [addInventoryItem, editingItem, inventorySectionItem, updateInventorySection, closeModal],
    )

    return (
        <>
            <CustomDialog
                title={translate(modalOpen === AppModals.AddInventorySectionItem ? "add_inventory_section_item" : "edit_inventory_section_item")}
                open={!isMobile && (modalOpen === AppModals.AddInventorySectionItem || modalOpen === AppModals.EditInventorySectionItem)}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddOrEditInventorySectionItemForm
                    onClose={closeModal}
                    onSave={onSave}
                    isLoading={isAddingNewInventoryItem || isUpdatingInventorySection}
                    defaultValues={inventorySectionItem}
                />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(modalOpen === AppModals.AddInventorySectionItem ? "add_inventory_section_item" : "edit_inventory_section_item")}
                isOpen={isMobile && (modalOpen === AppModals.AddInventorySectionItem || modalOpen === AppModals.EditInventorySectionItem)}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <Box p={2}>
                    <AddOrEditInventorySectionItemForm
                        onClose={closeModal}
                        onSave={onSave}
                        isLoading={isAddingNewInventoryItem || isUpdatingInventorySection}
                        defaultValues={inventorySectionItem}
                    />
                </Box>
            </DetailsSheet>
        </>
    )
}
