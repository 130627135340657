import { Page, useIsMobile, useLocales } from "rentzz"
import React from "react"
import { Box, Container } from "@mui/material"
import { bankAccountsPage, BankAccountsPages } from "../../../utils/atom"
import { ContentStyle, TabPanel } from "../../../pages/PropertyDetails"
import { useAtomValue } from "jotai/index"
import BankAccountsPageTabs from "./BankAccountsPageTabs"
import DesktopNomenclatureBankAccounts from "./DesktopNomenclatureBankAccounts"
import MobileNomenclatureBankAccounts from "./MobileNomenclatureBankAccounts"
import DesktopBankAccountsConsents from "./SmartAccountsUserConsents/DesktopBankAccountsConsents"
import MobileNomenclatureBankAccountConsents from "./SmartAccountsUserConsents/MobileNomenclatureBankAccountConsents"
import { UserFlags, useUserDataQueryFn } from "../../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function BankAccountsPage() {
    const { translate } = useLocales()
    const value = useAtomValue(bankAccountsPage)
    const isMobile = useIsMobile()
    const isConsentsTabVisible = useFeatureIsOn(UserFlags.SmartAccountsSync.toString())
    const { data: userData } = useUserDataQueryFn()
    return (
        <Page title={translate("nomenclature")} propertyName={translate("bank_accounts")}>
            <Container maxWidth={false}>
                <ContentStyle>
                    <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderColor: "divider" }}>
                            <BankAccountsPageTabs />
                        </Box>
                        <TabPanel value={value} index={BankAccountsPages.BANK_ACCOUNTS}>
                            {isMobile ? <MobileNomenclatureBankAccounts /> : <DesktopNomenclatureBankAccounts />}
                        </TabPanel>
                        {isConsentsTabVisible && userData?.isNonWhiteLabelOrWhiteLabelAdminUser && (
                            <TabPanel value={value} index={BankAccountsPages.CONSENTS}>
                                {isMobile ? <MobileNomenclatureBankAccountConsents /> : <DesktopBankAccountsConsents />}
                            </TabPanel>
                        )}
                    </Box>
                </ContentStyle>
            </Container>
        </Page>
    )
}
