import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import React, { useCallback } from "react"
import { useTheme } from "@mui/material/styles"
import { useDeletePropertyOwnerRepresentativeMutation } from "../../../../../mutations/property-owners"
import DeleteContent from "../../../../../components/DeleteContent"

export default function DeletePropertyOwnerRepresentativeGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteRepresentative, isPending } = useDeletePropertyOwnerRepresentativeMutation()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const handleDelete = useCallback(async () => {
        await deleteRepresentative({
            propertyOwnerId: editingItem.id,
            representativeId: editingItem.representativeId,
        })
        onClose()
    }, [deleteRepresentative, editingItem, onClose])

    // Use the reusable DeleteContent component
    const content = useCallback(() => (
        <DeleteContent
            onClose={onClose}
            onAccept={handleDelete}
            deleteText={translate("delete_representative_confirmation")}
            isLoading={isPending}
        />
    ), [onClose, handleDelete, translate, isPending]);

    return (
        <>
            <CustomDialog
                title={translate("delete_representative")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.DeletePropertyOwnerRepresentative}
                maxWidth="sm"
                isFullScreen={false}
                titleAlign="center"
            >
                {content()}
            </CustomDialog>

            <DetailsSheet
                title={translate("delete_representative")}
                isOpen={isMobile && modalOpen === AppModals.DeletePropertyOwnerRepresentative}
                onClose={onClose}
                isLoading={false}
                headerColor={theme.palette.error.main}
            >
                {content()}
            </DetailsSheet>
        </>
    )
} 