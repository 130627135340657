import { useTheme } from "@mui/material/styles"
import React, { useCallback, useEffect, useMemo } from "react"
import { Box, CircularProgress, InputAdornment } from "@mui/material"
import { DetailsSheet, FormProvider, RHFTextField, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { PropertyPercentageSchema } from "../../../../../validations/group"
import PercentIcon from "@mui/icons-material/Percent"
import { LoadingButton } from "@mui/lab"
import { updatePropertyOwnerPropertyPercentageMutation } from "../../../../../mutations/property-owners"
import { useUserPropertiesQuery } from "../../../../../queries/userData"
import { usePropertyOwnerDetailsQuery } from "../../../../../queries/property-owners"

interface PropertyPercentageRequest {
    percentage: number
}

export default function PropertyOwnershipPercentageDetailsSheetGuard() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutate: updatePercentage, isPending: isUpdatePercentageLoading } = updatePropertyOwnerPropertyPercentageMutation()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: propertyOwnerDetails } = usePropertyOwnerDetailsQuery(editingItem?.propertyOwnerId)
    const property = useMemo(() => {
        return userProperties?.find((p) => p.id === editingItem?.propertyId)
    }, [userProperties, editingItem])
    const propertyOwnership = useMemo(() => {
        return propertyOwnerDetails?.properties.find((p) => p.propertyId === property?.id)
    }, [propertyOwnerDetails, editingItem])

    const methods = useForm<PropertyPercentageRequest>({
        resolver: yupResolver(PropertyPercentageSchema),
        mode: "onBlur",
        defaultValues: {
            percentage: propertyOwnership?.ownershipPercentage || 0,
        },
    })

    const { handleSubmit, setValue } = methods

    const handleClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    useEffect(() => {
        if (propertyOwnership) {
            setValue("percentage", propertyOwnership.ownershipPercentage)
        }
    }, [propertyOwnership])

    const onSave = useCallback(
        async (data: PropertyPercentageRequest) => {
            if (!propertyOwnership || !property) return

            updatePercentage(
                {
                    operations: [{ op: "replace", path: "ownershipPercentage", value: data.percentage }],
                    propertyOwnerId: Number(editingItem?.propertyOwnerId),
                    propertyId: property?.id,
                },
                {
                    onSuccess: () => {
                        handleClose()
                    },
                },
            )
        },
        [property, editingItem, updatePercentage, handleClose],
    )

    if (!property || !propertyOwnership) return null

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.PropertyOwnershipPercentageDetails}
            onClose={handleClose}
            isLoading={false}
            title={translate("update_ownership_percentage")}
            subtitle={property.label || translate("property")}
            headerColor={theme.palette.primary.main}
        >
            <Box p={2}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                    <Box display='flex' flexDirection='column' gap={2}>
                        <RHFTextField
                            name='percentage'
                            type='number'
                            label={translate("ownership_percentage")}
                            disabled={isUpdatePercentageLoading}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position='end'>
                                        {isUpdatePercentageLoading ? <CircularProgress size='1rem' /> : <PercentIcon fontSize='small' />}
                                    </InputAdornment>
                                ),
                                sx: { color: theme.palette.grey["700"] },
                            }}
                        />
                        <LoadingButton
                            type='submit'
                            variant='contained'
                            color='primary'
                            fullWidth
                            loading={isUpdatePercentageLoading}
                            disabled={isUpdatePercentageLoading}
                        >
                            {translate("update")}
                        </LoadingButton>
                    </Box>
                </FormProvider>
            </Box>
        </DetailsSheet>
    )
}
