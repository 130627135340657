import { Box, Button, MenuItem, Typography } from "@mui/material"
import { FormProvider, Iconify, LightTooltip, RHFCheckbox, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { LoadingButton } from "@mui/lab"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { RentingPeriodConfigurationSchema } from "../../../../validations/addTenant"
import { DESKTOP_ICON_SIZE } from "../../../../utils/helpers"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { LabelType, UserFlags, useUserDataQueryFn } from "../../../../queries/userData"
import { useTheme } from "@mui/material/styles"
import { AddTenantRequest, RentingPeriodConfiguration } from "../../../types/AddTenant"
import { useCurrencyQueryFn } from "../../../../queries/currency"
import RHFLabelInput from "../../../../components/RHFLabelInput"

interface MetersProps {
    onNext: (data: RentingPeriodConfiguration) => void
    onBack: () => void
    isLoading: boolean
    defaultValues?: AddTenantRequest
}

export default function Configuration({ onNext, onBack, isLoading, defaultValues }: MetersProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const [isRentExpenseAutomatic, setIsRentExpenseAutomatic] = useState(false)
    const { data: user } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const areRevisionsHidden = useFeatureIsOn(UserFlags.HideTenantRevisions.toString())
    const isAcceptOnTenantBehalfEnabled = useFeatureIsOn(UserFlags.AcceptOnTenantBehalf.toString())
    const isInternalIdOn = useFeatureIsOn(UserFlags.PropertyInternalId.toString())
    const [isCreatingTenantAccount, setIsCreatingTenantAccount] = useState(false)
    const methods = useForm<RentingPeriodConfiguration>({
        resolver: yupResolver(RentingPeriodConfigurationSchema()),
        reValidateMode: "onChange",
    })
    const showPaymentNotifications = useFeatureIsOn(UserFlags.ShowPaymentNotifications.toString())
    const { handleSubmit, watch, reset } = methods

    useEffect(() => {
        if (defaultValues?.configuration) {
            reset({
                ...defaultValues?.configuration,
            })
        } else {
            reset({
                isRentExpenseAutomatic: false,
                acceptPartialPayments: false,
                shouldSendC168: true,
                scheduleMaintenanceAtHalfPeriod: areRevisionsHidden ? false : true,
                createTenantAccount: false,
                automaticRentExpenseCurrencyId: user?.currency.id,
            })
        }
    }, [defaultValues, areRevisionsHidden, user])

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "isRentExpenseAutomatic") {
                setIsRentExpenseAutomatic(value.isRentExpenseAutomatic ?? false)
            }
            setIsCreatingTenantAccount(value.createTenantAccount ?? false)
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const onSubmit = useCallback(
        (data: RentingPeriodConfiguration) => {
            onNext(data)
        },
        [onNext],
    )

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
            }}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box display='flex' width='100%' marginTop={1} gap={2} justifyContent='space-around'>
                    <Box display='flex' flexDirection='column' width={"100%"} gap={2} marginY={2}>
                        {isInternalIdOn && <RHFTextField name='internalId' label={translate("internal_id_header")} placeholder='10/01.01.2023' />}
                        <RHFLabelInput name='labelId' type={LabelType.RentingPeriod} />
                        {showPaymentNotifications && (
                            <RHFCheckbox name={"autoGeneratePaymentNotification"} label={translate("auto_generate_payment_notification")} />
                        )}
                        <RHFCheckbox name={"shouldSendC168"} label={translate("shouldSendC168")} />
                        {!areRevisionsHidden && (
                            <Box display={"flex"} alignItems={"center"}>
                                <RHFCheckbox name={"scheduleMaintenanceAtHalfPeriod"} label={translate("scheduleMaintenanceAtHalfPeriod_title")} />
                                <LightTooltip title={translate("scheduleMaintenanceAtHalfPeriod")} arrow>
                                    <Iconify icon={"mdi:information"} color={theme.palette.info.main} width={DESKTOP_ICON_SIZE} />
                                </LightTooltip>
                            </Box>
                        )}
                        <Box display={"flex"} alignItems={"center"} flexDirection='column' width='100%' gap={1}>
                            <Box display={"flex"} alignItems={"center"} width='100%' gap={1}>
                                <RHFCheckbox name={"isRentExpenseAutomatic"} label={translate("automatic_expense")} />
                                <LightTooltip title={translate("automatic_expense_tooltip")} arrow>
                                    <Iconify icon={"mdi:information"} color={theme.palette.info.main} width={DESKTOP_ICON_SIZE} />
                                </LightTooltip>
                            </Box>
                            {isRentExpenseAutomatic && (
                                <Box display={"flex"} alignItems={"center"} width='100%' justifyContent='space-between'>
                                    <Typography variant='body2'>{translate("automatic_expense_currency")}</Typography>

                                    <RHFSelect sx={{ width: 100 }} name='automaticRentExpenseCurrencyId' defaultValue={user?.currency}>
                                        {currencies?.map((c) => (
                                            <MenuItem key={c.id} value={c.id}>
                                                {c.code}
                                            </MenuItem>
                                        ))}
                                    </RHFSelect>
                                </Box>
                            )}
                        </Box>
                        <Box display={"flex"} flexDirection={"column"} width='100%'>
                            <Box display={"flex"} alignItems={"center"}>
                                <RHFCheckbox name={"acceptPartialPayments"} label={translate("tenant_can_add_partial_payments")} />
                            </Box>
                        </Box>
                        <Box>
                            {isAcceptOnTenantBehalfEnabled && (
                                <LightTooltip title={defaultValues?.details?.mails.length === 0 ? translate("disabled_when_no_mails") : ""} arrow>
                                    <span>
                                        <RHFCheckbox
                                            name={"createTenantAccount"}
                                            disabled={defaultValues?.details?.mails.length === 0}
                                            label={translate("create_tenant_account")}
                                        />
                                    </span>
                                </LightTooltip>
                            )}
                        </Box>

                        {isCreatingTenantAccount && (
                            <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={isMobile ? 1 : 5}>
                                <RHFTextField name='tenantFirstName' label={translate("tenant_first_name")} placeholder='Ion' />
                                <RHFTextField name='tenantLastName' label={translate("tenant_last_name")} placeholder='Popescu' />
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box display='flex' justifyContent='flex-end' gap={2} flexDirection={isMobile ? "column-reverse" : "row"}>
                    <Button color={"primary"} onClick={onBack} disabled={isLoading}>
                        {translate("back")}
                    </Button>
                    <LoadingButton color={"primary"} variant='contained' type='submit' loading={isLoading} disabled={isLoading}>
                        {translate("next")}
                    </LoadingButton>
                </Box>
            </FormProvider>
        </Box>
    )
}
