import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback } from "react"
import { Alert, Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { addGroupDashboardConfigurationMutation } from "../../../mutations/groups"
import { NewGroupDashboardConfigurationSchema } from "../../../validations/group"

interface AddGroupAccessFormProps {
    onClose: () => void
}

export interface AddGroupDashboardConfigurationRequest {
    name: string
}

const AddGroupDashboardConfigurationForm = ({ onClose }: AddGroupAccessFormProps) => {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { errorMessage, currentGroupId } = useSelector((state) => state.appState)
    const { mutateAsync: addNewGroupDashboardConfiguration, isPending: isAddNewOwnerLoading } = addGroupDashboardConfigurationMutation()

    const methods = useForm<AddGroupDashboardConfigurationRequest>({
        resolver: yupResolver(NewGroupDashboardConfigurationSchema()),
        mode: "onBlur",
        reValidateMode: "onChange",
        defaultValues: {
            name: "",
        },
    })

    const { handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: AddGroupDashboardConfigurationRequest) => {
            if (data == null || currentGroupId == null) return

            await addNewGroupDashboardConfiguration({ groupId: currentGroupId, data })
            onClose()
        },
        [addNewGroupDashboardConfiguration, onClose, currentGroupId],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' gap={isMobile ? 2 : 1} flexDirection={"column"} pt={2}>
                <RHFTextField
                    name='name'
                    placeholder={translate("group_dashboard_configuration_placeholder")}
                    label={translate("group_dashboard_configuration_name")}
                    required
                />
                {errorMessage && (
                    <Box marginTop={2}>
                        <Alert severity={"error"}>{errorMessage}</Alert>
                    </Box>
                )}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <LoadingButton
                        loading={isAddNewOwnerLoading}
                        disabled={isAddNewOwnerLoading}
                        color={"primary"}
                        type={"submit"}
                        fullWidth
                        variant={isMobile ? "contained" : "text"}
                    >
                        {translate("save")}
                    </LoadingButton>
                    {isMobile && (
                        <Button color={"primary"} onClick={onClose} disabled={isAddNewOwnerLoading}>
                            {translate("cancel")}
                        </Button>
                    )}
                </Box>
            </Box>
        </FormProvider>
    )
}

export default AddGroupDashboardConfigurationForm
