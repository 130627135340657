import React from "react"
import { Box } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useIsMobile, useLocales } from "rentzz"
import RHFPropertiesInputs from "./RHFPropertiesInputs"

export interface PropertyAssociationStepProps {
    isAddPropertyOwnerPending: boolean
}

const PropertyAssociationStep = ({ isAddPropertyOwnerPending }: PropertyAssociationStepProps) => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()

    return (
        <Box display='flex' flexDirection='column' gap={2}>
            <RHFPropertiesInputs name={"properties"} />
            <Box display='flex' flexDirection='row' width='100%' justifyContent='end'>
                <LoadingButton
                    variant='contained'
                    color='primary'
                    type={"submit"}
                    fullWidth={isMobile}
                    loading={isAddPropertyOwnerPending}
                    disabled={isAddPropertyOwnerPending}
                >
                    {translate("save")}
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default PropertyAssociationStep
