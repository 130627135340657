import { CustomDataGrid, Label, useLocales } from "rentzz"
import { useDispatch } from "../../../redux/store"
import { RoleName, UserBankAccount, useUserBankAccountsQuery, useUserDataQueryFn } from "../../../queries/userData"
import React, { useCallback, useMemo } from "react"
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { DateTime } from "luxon"
import NoDataPage from "../../../components/NoDataPage"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import CustomDetailPanelToggle from "../../expenses/CustomDetailPanelToggle"
import BankAccountProperties from "./DesktopBankAccountPropertiesList"
import ActionWithTooltip from "../../../utils/ActionWithTooltip"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { DESKTOP_ICON_SIZE, getReverseTextColor } from "../../../utils/helpers"
import DeleteIcon from "@mui/icons-material/Delete"
import CustomRoleBlockedToolbar from "../../../components/CustomRoleBlockedToolbar"

export default function DesktopNomenclatureBankAccounts() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { data: bankAccounts, isLoading: areBankAccountsLoading } = useUserBankAccountsQuery()
    const { data: userData } = useUserDataQueryFn()
    const { data: currencyData } = useCurrencyQueryFn()

    const getPropertiesBankAccountPanel = useCallback((row: GridRowParams<UserBankAccount>) => {
        return <BankAccountProperties currentBankAccount={row} />
    }, [])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            type: "actions",
            width: 120,
            disableReorder: true,
            headerName: translate("tenant-data.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.id }))
                                dispatch(setModalOpen(AppModals.EditBankAccount))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("edit"),
                    key: "edit",
                    hidden: userData?.roles == null,
                }),
                ActionWithTooltip({
                    key: "delete",
                    tooltipText: translate("delete"),
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteBankAccount))
                                dispatch(setEditingItem({ id: params.id }))
                            }}
                            label={translate("delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
            ],
        }),
        [translate, dispatch],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<UserBankAccount>[] = [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => {
                    return <CustomDetailPanelToggle id={params.id} value={params.value} title={"view_properties"} />
                },
            },
            {
                field: "holder",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("holder"),
            },
            {
                field: "bank",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("bank"),
                valueFormatter: (value) => translate(`bank-name-${value}`),
            },
            {
                field: "iban",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("iban"),
            },
            {
                field: "currencyId",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("currency"),
                valueFormatter: (value) => getCurrencyFromId(currencyData, value).code,
            },
            {
                field: "labelId",
                type: "string",
                flex: 1,
                maxWidth: 160,
                headerAlign: "center",
                align: "center",
                headerName: translate("labelDescription"),
                renderCell: (v) => {
                    if (v.value == null) return ""
                    return (
                        <Label
                            style={{
                                textTransform: "none",
                                backgroundColor: `#${v.row.labelColor}`,
                                color: getReverseTextColor(`#${v.row.labelColor}`),
                            }}
                        >
                            {v.row.labelName}
                        </Label>
                    )
                },
            },
            {
                field: "createdAt",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("createdAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate])

    return (
        <>
            <CustomRoleBlockedToolbar
                blockedRoles={[RoleName.FREE]}
                onAddClick={() => dispatch(setModalOpen(AppModals.AddBankAccount))}
                buttonText={"add_bank_account"}
            />
            <CustomDataGrid
                columns={columns}
                gridId={"DesktopNomenclatureBankAccounts"}
                actionsColumn={[actionsColumn]}
                rows={bankAccounts?.items ?? []}
                infoColumn={[]}
                idKey={"id"}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={"mdi:bank-off"} noDataText={"no_bank_accounts"} />,
                }}
                isLoading={areBankAccountsLoading}
                rowHeight={50}
                getDetailPanelContent={getPropertiesBankAccountPanel}
            />
        </>
    )
}
