import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "../../redux/store"
import { useUserGroupsQueryFn } from "../../queries/groups"
import React, { useCallback, useEffect, useMemo } from "react"
import { FormProvider, Iconify, RHFSelect, RHFSwitch, useCopyToClipboard, useLocales } from "rentzz"
import { Box, IconButton, InputAdornment, MenuItem, TextField, Tooltip } from "@mui/material"
import { DESKTOP_ICON_SIZE } from "../../utils/helpers"
import { useTheme } from "@mui/material/styles"
import { useSnackbar } from "notistack"
import { AppModals, setModalOpen } from "../../redux/slices/App"
import { useAtom } from "jotai/react"
import { dashboardConfigurationAtom } from "../../utils/atom"
import { refreshGroupDashboardConfigurationSecrets, updateGroupDashboardConfigPubliclyAvailable } from "../../mutations/groups"

const DashboardPicker = () => {
    const { currentGroupId } = useSelector((state) => state.appState)
    const { data: groups } = useUserGroupsQueryFn()
    const theme = useTheme()
    const { translate } = useLocales()
    const { enqueueSnackbar } = useSnackbar()
    const { copy } = useCopyToClipboard()
    const dispatch = useDispatch()
    const [dashboardPreferences, setDashboardPreferences] = useAtom(dashboardConfigurationAtom)
    const { mutateAsync: refreshSecrets } = refreshGroupDashboardConfigurationSecrets()
    const { mutateAsync: updatePubliclyAvailable } = updateGroupDashboardConfigPubliclyAvailable()
    const currentGroup = useMemo(() => groups?.find((group) => group.id === currentGroupId), [groups, currentGroupId])
    const currentDashboardConfig = useMemo(() => {
        return (
            currentGroup?.dashboardSharingSettings.find((s) => s.id == dashboardPreferences[currentGroup.id]) ??
            currentGroup?.dashboardSharingSettings.at(0)
        )
    }, [currentGroup, dashboardPreferences])
    const accessUrl = useMemo(() => {
        return new URL(
            `https://${window.location.host}/p-dashboard?id=${currentDashboardConfig?.sharingId}&idp=${currentDashboardConfig?.sharingPassword}`,
        ).toString()
    }, [currentDashboardConfig])

    const methods = useForm({
        defaultValues: {
            dashboardId: "",
            isPubliclyShared: false,
        },
    })

    const onCopy = useCallback(
        (text: string) => {
            if (text) {
                enqueueSnackbar(translate("copied"))
                copy(text)
            }
        },
        [copy, enqueueSnackbar, translate],
    )

    const handleGroupSecretsRefresh = useCallback(() => {
        if (currentGroupId && currentDashboardConfig) {
            refreshSecrets({
                groupId: currentGroupId,
                dashboardConfigurationId: currentDashboardConfig.id,
            }).then(() => {
                enqueueSnackbar(translate("secrets_changed_old_not_working"))
            })
        }
    }, [currentGroupId, currentDashboardConfig])

    const handleGroupPubliclyAvailableChange = useCallback(() => {
        if (currentGroupId && currentDashboardConfig) {
            updatePubliclyAvailable({
                groupId: currentGroupId,
                dashboardConfigurationId: currentDashboardConfig.id,
                isPubliclyAvailable: !currentDashboardConfig.isPubliclyShared,
            }).then(() => {
                if (currentDashboardConfig.isPubliclyShared === false) {
                    enqueueSnackbar(translate("link_publicly_accessible"))
                } else {
                    enqueueSnackbar(translate("link_not_publicly_available_anymore"))
                }
            })
        }
    }, [currentGroupId, currentDashboardConfig])

    const { setValue, watch } = methods

    useEffect(() => {
        if (currentGroup && dashboardPreferences) {
            setValue("dashboardId", (dashboardPreferences[currentGroup.id] ?? currentGroup.dashboardSharingSettings.at(0)!.id).toString())
            setValue("isPubliclyShared", currentDashboardConfig?.isPubliclyShared ?? false)
        }
    }, [currentGroup, dashboardPreferences, currentDashboardConfig])

    useEffect(() => {
        const subscription = watch((v, { name }) => {
            if (v.dashboardId != null && currentGroupId != null && name === "dashboardId") {
                if (dashboardPreferences[currentGroupId]?.toString() != v.dashboardId?.toString()) {
                    setDashboardPreferences((prev) => {
                        localStorage.setItem("dashboard_preferences", JSON.stringify({ ...prev, [currentGroupId]: Number(v.dashboardId) }))

                        return { ...prev, [currentGroupId]: Number(v.dashboardId) }
                    })
                }
            }
            if (name === "isPubliclyShared") {
                handleGroupPubliclyAvailableChange()
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, currentGroupId, dashboardPreferences, handleGroupPubliclyAvailableChange])

    if (currentGroup == null) return null

    return (
        <FormProvider methods={methods} onSubmit={console.log}>
            <Box display='flex' flexDirection='row' gap={2} width='100%' justifyContent='space-between'>
                <Box display='flex' width='40%'>
                    <RHFSelect name={"dashboardId"} size='small'>
                        {currentGroup?.dashboardSharingSettings.map((setting) => (
                            <MenuItem key={setting.id} value={setting.id.toString()}>
                                {setting.settingsName}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                    <Tooltip title={translate("create_new_dashboard_configuration")}>
                        <span>
                            <IconButton
                                onClick={(e) => {
                                    e.stopPropagation()
                                    dispatch(setModalOpen(AppModals.AddGroupDashboardConfiguration))
                                }}
                            >
                                <Iconify
                                    icon={"mdi:plus"}
                                    style={{ width: DESKTOP_ICON_SIZE, height: DESKTOP_ICON_SIZE, color: theme.palette.primary.main }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip
                        title={translate(
                            currentGroup.dashboardSharingSettings.length > 1
                                ? "delete_group_dashboard_configuration"
                                : "can_not_delete_last_configuration",
                        )}
                    >
                        <span>
                            <IconButton
                                disabled={currentGroup.dashboardSharingSettings.length === 1}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    dispatch(setModalOpen(AppModals.DeleteGroupDashboardConfiguration))
                                }}
                            >
                                <Iconify
                                    icon={"mdi:delete"}
                                    style={{
                                        width: DESKTOP_ICON_SIZE,
                                        height: DESKTOP_ICON_SIZE,
                                        color: currentGroup.dashboardSharingSettings.length === 1 ? undefined : theme.palette.error.main,
                                    }}
                                />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>

                <Box width={"60%"}>
                    <TextField
                        size={"small"}
                        label={translate("publicly_sharable_link")}
                        value={accessUrl}
                        fullWidth
                        InputProps={{
                            disabled: true,
                            endAdornment: (
                                <InputAdornment position='end'>
                                    <Tooltip title={translate("copy")} arrow>
                                        <IconButton onClick={() => onCopy(accessUrl)}>
                                            <Iconify icon='eva:copy-fill' width={24} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title={translate("change_url")} arrow>
                                        <IconButton onClick={handleGroupSecretsRefresh}>
                                            <Iconify icon='mdi:refresh' width={24} />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        arrow
                                        title={translate(
                                            currentDashboardConfig?.isPubliclyShared
                                                ? "link_publicly_accessible"
                                                : "link_not_publicly_available_anymore",
                                        )}
                                    >
                                        <span>
                                            <RHFSwitch name={"isPubliclyShared"} label={""} />
                                        </span>
                                    </Tooltip>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Box>
        </FormProvider>
    )
}

export default DashboardPicker
