import { LightTooltip, useLocales } from "rentzz"
import { RoleName, useUserDataQueryFn } from "../queries/userData"
import { Button } from "@mui/material"
import React, { ReactElement, ReactNode, useMemo } from "react"
import ChangePlanTooltipComponent from "./ChangePlanTooltipComponent"
import { DateTime } from "luxon"

interface Props {
    onClick: () => void
    isDisabled?: boolean
    buttonText: ReactElement | string
    blockedRoles: RoleName[]
    variant?: "text" | "outlined" | "contained"
    startIcon?: ReactNode
    disabledText?: ReactNode
}

export default function RoleLockedButton({ onClick, isDisabled, startIcon, buttonText, blockedRoles, disabledText, variant = "contained" }: Props) {
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()

    const userRoleName = useMemo(() => {
        if (!user || !user.roles) return RoleName.FREE

        const now = DateTime.now().toMillis()
        const fromTs = user.roles.from instanceof DateTime ? user.roles.from.toMillis() : 0
        const toTs = user.roles.to instanceof DateTime ? user.roles.to.toMillis() : Infinity

        const isActive = fromTs <= now && now <= toTs

        return isActive ? user.roles.roleName : RoleName.FREE
    }, [user])

    if (user == null) return null
    return (
        <LightTooltip
            arrow
            title={isDisabled && disabledText ? disabledText : blockedRoles.includes(userRoleName) ? <ChangePlanTooltipComponent /> : ""}
        >
            <span>
                <Button
                    disabled={blockedRoles.includes(userRoleName) || isDisabled}
                    color='primary'
                    onClick={onClick}
                    variant={variant}
                    sx={{ textTransform: "none" }}
                    startIcon={startIcon}
                >
                    {typeof buttonText === "string" ? translate(buttonText) : buttonText}
                </Button>
            </span>
        </LightTooltip>
    )
}
