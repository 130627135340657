import { Iconify, Label, LoadingIcon, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useEffect, useMemo, useRef } from "react"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { Box, CircularProgress, Fab } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomAddCard from "../../../components/CustomAddCard"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { RoleName, useUserDataQueryFn } from "../../../queries/userData"
import { useInfiniteSignatureRequestsQuery } from "../../../queries/signature"
import { getSigningRequestStatusColor } from "../../expenses/utils"
import { DateTime } from "luxon"

export default function MobileSignatureRequestsList() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: user } = useUserDataQueryFn()
    const { translate } = useLocales()
    const ref = useRef<HTMLDivElement>(null)
    const { modalOpen, currentPropertyId } = useSelector((state) => state.appState)
    const {
        data: signatureRequests,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteSignatureRequestsQuery(currentPropertyId, [{ field: "createdAt", sort: "desc" }])
    const { canWrite } = usePermissions(PermissionType.SignatureRequests)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    if (signatureRequests == null) return <LoadingIcon />

    return (
        <>
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {signatureRequests.pages.some((p) => p.items.length > 0) &&
                    modalOpen == null &&
                    canWrite &&
                    user?.roles.roleName !== RoleName.BRONZE &&
                    user?.roles.roleName !== RoleName.SILVER &&
                    user?.roles.roleName !== RoleName.FREE && (
                        <Fab
                            onClick={() => dispatch(setModalOpen(AppModals.AddSignatureRequest))}
                            sx={{ position: "fixed", bottom: 10, right: 10 }}
                            color='primary'
                            aria-label='add'
                        >
                            <Iconify icon={"mdi:plus"} width={25} height={25} />
                        </Fab>
                    )}
                {signatureRequests.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.items.map((signatureRequest) => (
                            <CustomMobileCard
                                key={signatureRequest.id}
                                item={signatureRequest}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.SignatureRequestDetails))
                                    dispatch(setEditingItem({ id: signatureRequest.id }))
                                }}
                                icon={"mdi:document-sign"}
                                iconColor={theme.palette.primary.main}
                                title={signatureRequest.documentName}
                                firstDate={signatureRequest.createdAt.toLocaleString(DateTime.DATE_SHORT)}
                                subtitle={
                                    <Label color={getSigningRequestStatusColor(signatureRequest.status)} style={{ textTransform: "none" }}>
                                        {translate(`signature_request_status-${signatureRequest.status}`)}
                                    </Label>
                                }
                            />
                        ))}
                    </React.Fragment>
                ))}
                {hasNextPage && (
                    <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                        <CircularProgress />
                    </div>
                )}
                {signatureRequests.pages.some((p) => p.items.length === 0) && canWrite && (
                    <CustomAddCard
                        onCardClick={() => dispatch(setModalOpen(AppModals.AddSignatureRequest))}
                        icon={"mdi:document-sign"}
                        text={"add_document"}
                        isAddDisabled={
                            user?.roles.roleName === RoleName.FREE ||
                            user?.roles.roleName === RoleName.BRONZE ||
                            user?.roles.roleName === RoleName.SILVER
                        }
                        tooltipTitle={"permission_denied_action"}
                    />
                )}
            </Box>
        </>
    )
}
