import { Box, Button, Grid, IconButton, InputAdornment } from "@mui/material"
import React, { useEffect } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { Iconify, RHFAutocomplete, RHFTextField, useLocales } from "rentzz"
import AddIcon from "@mui/icons-material/Add"
import { useTheme } from "@mui/material/styles"
import { DESKTOP_ICON_SIZE } from "../../../utils/helpers"
import { useSelector } from "../../../redux/store"
import { usePermissions } from "../../../hooks/usePermissions"
import { PropertyOwnerRequest } from "./PropertyOwnerConfiguration"
import PercentIcon from "@mui/icons-material/Percent"

export interface RHFPropertiesInputsProps {
    name: "properties"
}

const RHFPropertiesInputs = ({ name }: RHFPropertiesInputsProps) => {
    const { control, getValues } = useFormContext<PropertyOwnerRequest>()
    const { translate } = useLocales()
    const theme = useTheme()
    const { editableProperties } = usePermissions()
    const { currentPropertyId } = useSelector((state) => state.appState)

    const { fields, remove, append } = useFieldArray({
        control,
        name,
    })

    // Check if form already has values but no fields are displayed
    useEffect(() => {
        const formValues = getValues(name);
        
        // If form has properties but fields are empty, make sure they're shown
        if (formValues?.length > 0 && fields.length === 0) {
            formValues.forEach(property => {
                append(property);
            });
        }
    }, [getValues, fields, append]);

    return (
        <Grid container alignItems='center' rowGap={1} pt={2}>
            {fields.map((f, index) => (
                <React.Fragment key={f.id}>
                    <Grid item xs={12} sm={8} alignItems='center'>
                        <Box height={50} pr={2}>
                            <RHFAutocomplete
                                disabled={!!currentPropertyId}
                                autocompleteProps={{
                                    sx: {
                                        width: "100%",
                                    },
                                    noOptionsText: translate("no_value_found"),
                                    limitTags: 3,
                                    size: "small",
                                }}
                                matchId
                                name={`${name}.${index}.propertyId`}
                                options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                                label={translate("property")}
                                freeSolo={false}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={3} pr={2} alignItems='center'>
                        <Box height={50}>
                            <RHFTextField
                                required
                                name={`${name}.${index}.percentage`}
                                label={translate("ownership_percentage")}
                                fullWidth
                                size='small'
                                type='number'
                                inputProps={{ min: 0, max: 100 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={"end"}>
                                            <PercentIcon fontSize={"small"} />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={1} flex={1}>
                        <Box height={50} display='flex' alignItems='center' justifyContent='center' pb={1}>
                            <IconButton onClick={() => remove(index)}>
                                <Iconify
                                    icon={"mdi:delete"}
                                    width={DESKTOP_ICON_SIZE}
                                    height={DESKTOP_ICON_SIZE}
                                    style={{ color: theme.palette.error.main }}
                                />
                            </IconButton>
                        </Box>
                    </Grid>
                </React.Fragment>
            ))}
            <Grid item xs={12} display='flex' justifyContent='center' mt={2}>
                <Button
                    color='primary'
                    startIcon={<AddIcon />}
                    onClick={() =>
                        // @ts-expect-error property id
                        append({
                            percentage: 100,
                        })
                    }
                >
                    {translate("add_property")}
                </Button>
            </Grid>
        </Grid>
    )
}

export default RHFPropertiesInputs
