import { LoadingIcon } from "rentzz"
import { Box, Grid } from "@mui/material"
import React from "react"
import { DashboardChartType } from "../../queries/userData"
import Chart from "../../components/Chart"

interface Props {
    charts: DashboardChartType[]
    isPublic?: boolean
}
export default function ChartCardsRowMobile({ charts, isPublic }: Props) {
    if (!charts) return <LoadingIcon />

    return (
        <>
            {charts?.map((card) => (
                <Grid key={card.dashboardChartId} item xs={12} sm={6} md={charts?.length <= 4 ? 12 / charts?.length : 3}>
                    <Box display={"flex"} flexDirection={"column"} alignItems={"stretch"} width={"100%"} height={"100%"} flexWrap={"wrap"}>
                        <Chart chartId={card.dashboardChartId} isPublic={isPublic} />
                    </Box>
                </Grid>
            ))}
            {(charts?.length ?? 0) > 4 ? <Grid md={12 - (((charts?.length ?? 0) * 3) % 12)} /> : <></>}
        </>
    )
}
