import { useCallback, useMemo } from "react"
import { GridSortModel } from "@mui/x-data-grid-pro"
import { useLayoutContext } from "rentzz"
import * as jsonpatch from "fast-json-patch"
import { useUserDataQueryFn } from "../queries/userData"
import { updateUser } from "../mutations/user"

const useCustomSortModel = (gridId: string, defaultSortModel: GridSortModel) => {
    const layoutData = useLayoutContext()
    const { data: user } = useUserDataQueryFn()
    const { mutate: updateUserData } = updateUser()
    const currentGridSortModel = useMemo(() => {
        if (layoutData?.preferredGridSort && layoutData?.preferredGridSort[gridId] != null) {
            return layoutData?.preferredGridSort[gridId]
        }
        return defaultSortModel
    }, [layoutData, gridId, defaultSortModel])

    const setSortModel = useCallback(
        (sortModel: GridSortModel) => {
            if (user == null) return
            const currentGridOrdering = user?.columnOrderByTablePreference
            const currentGridSort = user?.tableSortPreference
            const operations = jsonpatch.compare(
                { tableSortPreference: JSON.stringify(currentGridSort ?? {}) },
                { tableSortPreference: JSON.stringify({ ...currentGridSort, [gridId]: sortModel }) },
            )
            if (operations.length > 0) {
                updateUserData({
                    newColumnsOrder: { ...(currentGridOrdering ?? {}) },
                    newTableSortPreference: { ...currentGridSort, [gridId]: sortModel },
                    operations: operations.map((o) => ({ ...o, path: `UserPreferences${o.path}` })),
                })
            }
        },
        [user],
    )

    return { sortModel: currentGridSortModel, setSortModel }
}

export default useCustomSortModel
