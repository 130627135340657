import React, { useEffect } from "react"
import { FormProvider, RHFSwitch, useLocales } from "rentzz"
import { PropertyExpense } from "../../../queries/expenses"
import { Box, CircularProgress } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import { useSnackbar } from "notistack"
import { useForm } from "react-hook-form"
import { updateExpenseMutation } from "../../../mutations/expenses"

export interface AutomaticallyGenerateLateFeesExpenseSwitchProps {
    expense: PropertyExpense
}

const AutomaticallyGenerateLateFeesExpenseSwitch = ({ expense }: AutomaticallyGenerateLateFeesExpenseSwitchProps) => {
    const { translate } = useLocales()
    const theme = useTheme()
    const { enqueueSnackbar } = useSnackbar()

    const methods = useForm({
        defaultValues: {
            shouldGenerateLateFees: expense?.shouldGenerateLateFees ?? false,
        },
    })
    const { handleSubmit, watch, setValue } = methods

    const { mutate: updateExpense, isPending } = updateExpenseMutation(false)

    // Single useEffect to handle form changes and API updates
    useEffect(() => {
        const subscription = watch((formValues, { name, type }) => {
            if (name !== "shouldGenerateLateFees" || type !== "change") return
            const newValue = formValues.shouldGenerateLateFees

            updateExpense(
                {
                    expenseId: expense.id,
                    operations: [{ op: "replace", path: "/shouldGenerateLateFees", value: newValue }],
                    propertyId: expense.propertyId,
                },
                {
                    onSuccess: () => {
                        enqueueSnackbar(translate("expenses.settings.lateFeesUpdated"), { variant: "success" })
                        setValue("shouldGenerateLateFees", newValue ?? false, { shouldDirty: false })
                    },
                    onError: () => {
                        setValue("shouldGenerateLateFees", !newValue, { shouldDirty: false })
                    },
                },
            )
        })

        return () => subscription.unsubscribe()
    }, [updateExpense, enqueueSnackbar, translate, watch])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(console.log)}>
            <Box position='relative' display='flex' alignItems='center'>
                {!isPending && (
                    <RHFSwitch
                        name='shouldGenerateLateFees'
                        disabled={isPending}
                        labelPlacement='end'
                        label={translate("expenses.automaticallyGenerateLateFees")}
                        sx={{
                            display: "flex",
                            flexDirection: "row-reverse",
                            justifyContent: "space-between",
                            letterSpacing: 0.5,
                            color: theme.palette.primary.main,
                        }}
                    />
                )}
                {isPending && <CircularProgress size={20} />}
            </Box>
        </FormProvider>
    )
}

export default AutomaticallyGenerateLateFeesExpenseSwitch
