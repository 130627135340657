import AddSignatureRequestGuard from "./AddSignatureRequestGuard"
import DeleteSignatureRequestGuard from "./DeleteSignatureRequestGuard"
import SignatureRequestDetailsSheet from "../../../sections/propertyDetails/signature-requests/SignatureRequestDetailsSheet"

export default function SignatureRequestsGuard() {
    return (
        <>
            <AddSignatureRequestGuard />
            <DeleteSignatureRequestGuard />
            <SignatureRequestDetailsSheet />
        </>
    )
}
