import { FormProvider, RHFCheckbox, RHFSelect, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect, useState } from "react"
import { Alert, Box, Button, Link, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddBankAccountConsentSchema } from "../../../../validations/bankAccounts"
import { useDispatch } from "../../../../redux/store"
import { UserFlags } from "../../../../queries/userData"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { addBankAccountConsentMutation } from "../../../../mutations/bankAccountConsent"

interface Props {
    onClose: () => void
}

export interface AddBankAccountConsentRequest {
    bankCode: number
    acceptTerms: boolean
}

export default function AddBankAccountConsentForm({ onClose }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { mutateAsync: addBankAccountConsent, isPending: isAddingBankAccountConsent } = addBankAccountConsentMutation()
    const allowedBanks = useFeatureValue(UserFlags.SmartAccountsAllowedBanks.toString(), [])
    const [areTermsAccepted, setAreTermsAccepted] = useState(false)

    const onSave = useCallback(
        async (data: AddBankAccountConsentRequest) => {
            await addBankAccountConsent(data)
            onClose()
        },
        [dispatch, onClose],
    )

    const methods = useForm<AddBankAccountConsentRequest>({
        resolver: yupResolver(AddBankAccountConsentSchema()),
        mode: "onChange",
        defaultValues: {
            acceptTerms: false,
        },
    })

    const { handleSubmit, watch } = methods

    useEffect(() => {
        const subscription = watch((value) => {
            setAreTermsAccepted(value.acceptTerms ?? false)
        })
        return () => subscription.unsubscribe()
    }, [watch])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
            <Box display={"flex"} gap={2} paddingTop={1} flexDirection={"column"}>
                <Alert severity='info'>{translate("we_use_smartaccounts")}</Alert>
                <RHFSelect name={"bankCode"} label={translate("bank")} required>
                    {allowedBanks.map((bankId) => (
                        <MenuItem key={bankId} value={bankId}>
                            {translate(`bank-name-${bankId}`)}
                        </MenuItem>
                    ))}
                </RHFSelect>
                <RHFCheckbox
                    name='acceptTerms'
                    defaultChecked={false}
                    label={
                        <>
                            {translate("smartaccounts_agree_with")}{" "}
                            <Link
                                target='_blank'
                                href={"https://www.smartfintech.eu/terms-and-conditions-smartaccounts"}
                                style={{ textTransform: "none" }}
                            >
                                {translate("terms_and_conditions_smartaccounts")}
                            </Link>
                        </>
                    }
                />

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button fullWidth={isMobile} color={"primary"} onClick={onClose} disabled={isAddingBankAccountConsent}>
                        {translate("cancel")}
                    </Button>
                    <LoadingButton
                        fullWidth={isMobile}
                        color={"primary"}
                        type='submit'
                        variant='contained'
                        disabled={isAddingBankAccountConsent || !areTermsAccepted}
                        loading={isAddingBankAccountConsent}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}
