import { Box, CircularProgress, Divider, MenuItem } from "@mui/material"
import { NotificationTemplateVariable, NotificationVariableType, SystemConfiguredVariables } from "../../../queries/userData"
import CheckIcon from "@mui/icons-material/Check"
import React, { useCallback, useEffect, useState } from "react"
import { useTheme } from "@mui/material/styles"
import { FormProvider, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { updateDefaultPaymentNotificationVariable, updateNotificationVariableMutation } from "../../../mutations/notifications"
import { useForm } from "react-hook-form"
import CloseIcon from "@mui/icons-material/Close"
import { useSelector } from "../../../redux/store"
import { notificationPattern } from "./utils"

interface Props {
    variable: NotificationTemplateVariable
    handleNotificationPreview?: () => void
    variableIndex?: number
    notificationId?: number
}

export default function VariableContent({ variable, handleNotificationPreview, variableIndex, notificationId }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const [inputFocused, setInputFocused] = useState<boolean>(false)
    const { mutateAsync: updateVariable, isPending } = updateNotificationVariableMutation()
    const { mutateAsync: updateDefaultVariable, isPending: isDefaultUpdatePending } = updateDefaultPaymentNotificationVariable()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const methods = useForm()
    const { handleSubmit, getValues, watch, reset } = methods
    const onSubmit = useCallback((_: unknown) => {}, [])

    useEffect(() => {
        reset({
            pattern: variable.pattern.replace(notificationPattern, ""),
            type: variable.type,
            value: variable.value,
        })
    }, [variable, reset])

    useEffect(() => {
        const subscription = watch(async (v, { name }) => {
            if (
                (name === "type" || (name === "value" && v.type === NotificationVariableType.SystemConfigured)) &&
                currentRentingPeriodId == null &&
                notificationId != null &&
                variableIndex != null
            ) {
                // we're updating a default variable
                await updateDefaultVariable({
                    notificationId,
                    operations: [
                        {
                            op: "replace",
                            value: v.value,
                            path: `/variables/${variableIndex}/value`,
                        },
                        {
                            op: "replace",
                            value: v.type,
                            path: `/variables/${variableIndex}/type`,
                        },
                    ],
                })
                return
            }
            if (name === "type") {
                if (
                    (v.pattern === variable.pattern.replace(notificationPattern, "") && v.type === variable.type) ??
                    (NotificationVariableType.Unknown && v.value === variable.value)
                )
                    return
                await updateVariable({
                    variable: {
                        pattern: `{{${v.pattern}}}`,
                        value: "",
                        type: v.type,
                    },
                })
                handleNotificationPreview?.()
            }
            if (name === "value" && v.type === NotificationVariableType.SystemConfigured) {
                await updateVariable({
                    variable: {
                        pattern: `{{${v.pattern}}}`,
                        value: v.value,
                        type: v.type,
                    },
                })
                handleNotificationPreview?.()
            }
        })
        return () => subscription.unsubscribe()
    }, [watch, variable, handleNotificationPreview, currentRentingPeriodId, notificationId, variableIndex])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2} paddingRight={1} paddingTop={2} alignItems={"center"}>
                <RHFTextField name={"pattern"} label={translate("variable")} size={"small"} disabled fullWidth />
                <RHFSelect label={translate("variable_type")} name={"type"} disabled={isPending || isDefaultUpdatePending}>
                    <MenuItem value={NotificationVariableType.Unknown.toString()}>{translate("unknown")}</MenuItem>
                    <MenuItem value={NotificationVariableType.UserConfigured.toString()}>{translate("userDefined")}</MenuItem>
                    <MenuItem value={NotificationVariableType.SystemConfigured.toString()}>{translate("system_configured_variable")}</MenuItem>
                </RHFSelect>

                {(variable.type === NotificationVariableType.UserConfigured ||
                    variable.type === NotificationVariableType.Unknown ||
                    (variable.type == null && notificationId != null)) && (
                    <RHFTextField
                        name='value'
                        label={translate("value")}
                        size={"small"}
                        fullWidth
                        onFocus={() => setInputFocused(true)}
                        InputLabelProps={{
                            shrink:
                                (variable.value != null && variable.value !== "") ||
                                inputFocused ||
                                variable.type === NotificationVariableType.Unknown,
                        }}
                        disabled={isPending || variable.type === NotificationVariableType.Unknown || isDefaultUpdatePending}
                        onBlur={async (event) => {
                            setInputFocused(false)
                            if (currentRentingPeriodId == null && notificationId != null && variableIndex != null) {
                                // we're updating a default variable
                                await updateDefaultVariable({
                                    notificationId,
                                    operations: [
                                        {
                                            op: "replace",
                                            value: event.target.value,
                                            path: `/variables/${variableIndex}/value`,
                                        },
                                    ],
                                })
                                return
                            }
                            if (getValues("type") === NotificationVariableType.UserConfigured) {
                                await updateVariable({
                                    variable: {
                                        pattern: `{{${getValues("pattern")}}}`,
                                        value: event.target.value,
                                        type: NotificationVariableType.UserConfigured,
                                    },
                                })
                                handleNotificationPreview?.()
                            }
                        }}
                    />
                )}

                {variable.type === NotificationVariableType.SystemConfigured && (
                    <RHFSelect name='value' label={translate("value")} size={"small"} fullWidth disabled={isPending || isDefaultUpdatePending}>
                        <MenuItem value={SystemConfiguredVariables.None.toString()}>{translate("unknown")}</MenuItem>

                        {(Object.keys(SystemConfiguredVariables) as Array<keyof typeof SystemConfiguredVariables>)
                            .filter((v) => Number.isNaN(Number(v)))
                            .filter((v) => SystemConfiguredVariables[v].toString() !== "0")
                            .map((key) => (
                                <MenuItem key={key} value={SystemConfiguredVariables[key].toString()}>
                                    {translate(`system_configured_variable-${SystemConfiguredVariables[key]}`)}
                                </MenuItem>
                            ))}
                    </RHFSelect>
                )}

                <Box width={isMobile ? undefined : 102} height={22}>
                    {(isPending || isDefaultUpdatePending) && <CircularProgress size={"1rem"} />}

                    {variable.value != null && variable.value !== "" && !isPending && !isDefaultUpdatePending && (
                        <CheckIcon color={"success"} fontSize={"small"} />
                    )}
                    {(variable.value == null || variable.value === "") && !isPending && !isDefaultUpdatePending && (
                        <CloseIcon color={"error"} fontSize={"small"} />
                    )}
                </Box>
            </Box>
            {isMobile && <Divider sx={{ marginTop: 1, backgroundColor: theme.palette.grey["500"] }} />}
        </FormProvider>
    )
}
