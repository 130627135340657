import { useDispatch, useSelector } from "../../../../../redux/store"
import React, { useCallback, useEffect, useMemo, useRef } from "react"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { sum } from "lodash"
import { Iconify, LoadingIcon, useLocales } from "rentzz"
import NoDataPage from "../../../../../components/NoDataPage"
import { Box, CircularProgress, Fab } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import CustomAddCard from "../../../../../components/CustomAddCard"
import { DiaryType, Journal, useInfiniteJournalsQueryFn } from "../../../../../queries/journal"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import CustomMobileCard from "../../../../../components/CustomMobileCard"
import { useTheme } from "@mui/material/styles"
import * as humps from "humps"
import { useAtomValue } from "jotai"
import { journalsFilterState } from "../../../../../utils/atom"
import JournalHeader from "../../../../../guards/alertsGuard/rentingPeriod/journal/JournalHeader"
import { RoleName, useUserDataQueryFn } from "../../../../../queries/userData"

export default function MobileJournalList() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: user } = useUserDataQueryFn()
    const ref = useRef<HTMLDivElement>(null)
    const filterModel = useAtomValue(journalsFilterState)
    const { data: journals, fetchNextPage, hasNextPage } = useInfiniteJournalsQueryFn([{ field: "createdAt", sort: "desc" }], filterModel)
    const { modalOpen, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    if (sum(journals?.pages.flatMap((p) => p.items.length)) === 0 && !canWrite) return <NoDataPage icon={"bi:journal-x"} noDataText={"no_journals"} />

    const getJournalTitle = useCallback(
        (journal: Journal) => {
            if (journal?.isSystemDiaryEntry) {
                if (journal.type === DiaryType.DeliveredMail) return translate("mail_to", humps.camelizeKeys(JSON.parse(journal.parameters)))
                return translate(journal.title)
            } else return journal?.title
        },
        [translate],
    )

    if (journals == null) return <LoadingIcon />

    return (
        <>
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {journals.pages.some((p) => p.items.length > 0) && modalOpen == null && canWrite && user?.roles.roleName !== RoleName.FREE && (
                    <Fab
                        onClick={() => dispatch(setModalOpen(AppModals.AddJournal))}
                        sx={{ position: "fixed", bottom: 10, right: 10 }}
                        color='primary'
                        aria-label='add'
                    >
                        <Iconify icon={"mdi:plus"} width={25} height={25} />
                    </Fab>
                )}
                <JournalHeader />
                {journals.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.items.map((journal) => (
                            <CustomMobileCard
                                key={journal.id}
                                item={journal}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.JournalDetails))
                                    dispatch(setEditingItem({ id: journal.id }))
                                }}
                                icon={"bi:journal-text"}
                                iconColor={theme.palette.primary.main}
                                title={getJournalTitle(journal)}
                            />
                        ))}
                    </React.Fragment>
                ))}
                {hasNextPage && (
                    <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                        <CircularProgress />
                    </div>
                )}
                {journals.pages.some((p) => p.items.length === 0) && canWrite && (
                    <CustomAddCard
                        onCardClick={() => dispatch(setModalOpen(AppModals.AddJournal))}
                        icon={"bi:journal-plus"}
                        text={"add_journal"}
                        isAddDisabled={user?.roles.roleName === RoleName.FREE}
                        tooltipTitle={"permission_denied_action"}
                    />
                )}
            </Box>
        </>
    )
}
