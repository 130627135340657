import Api from "../api/Api"
import { PropertySummary } from "../queries/userData"
import {
    ExportBalanceRequest,
    ExportExpensesRequest,
    ExportIncomeRequest,
    ExportReadingRequest,
    ExportRentingPeriodsRequest,
} from "../sections/expenses/export/ExportForm"
import { nanoid } from "@reduxjs/toolkit"

export interface DownloadMultiFile {
    ids: string[]
    name: string
    extension?: string
}

export interface DownloadInfo {
    downloadId: string
    downloadName: string
    extension?: string
}

export interface DownloadRentingPeriodInfo extends DownloadInfo {
    downloadKey: string
}

export interface DownloadFolder {
    propertyId: number
    downloadName: string
}

export enum ExportType {
    Expense,
    Income,
    Reading,
    Balance,
    RentingPeriod,
}

export const processDownloadData = (data: { blob: Blob; fileName?: string }, defaultName: string, extension = "zip", isPending?: boolean) => {
    // Use fileName from server if provided; otherwise fallback to defaultName
    const url = window.URL.createObjectURL(data.blob)
    const link = document.createElement("a")
    link.href = url
    const fileName = decodeURIComponent(data.fileName ?? nanoid(10)) // Decode URL-encoded characters if any
        .replace(/[/\\?%*:|"<>]/g, "-") // Replace problematic characters
        .replace(/\s+/g, "_") // Replace spaces with underscores
        .replace(/[^\x20-\x7E]/g, "_") // Replace non-ASCII chars
        .replace(/^\.+|\.+$/g, "") // Remove leading/trailing periods
        .replace(/\.{2,}/g, ".") // Replace multiple periods with single
        .substring(0, 200) // Limit filename length
        .trim()

    // Format final filename based on isPending flag
    const downloadName = isPending ? fileName : `${fileName}.${extension}`

    link.setAttribute("download", downloadName)
    // Append to the document and trigger download
    document.body.appendChild(link)
    link.click()

    // Cleanup
    setTimeout(() => {
        link.parentNode?.removeChild(link)
        window.URL.revokeObjectURL(url)
    }, 100)
}

export const downloadFile = async (fileToDownload: DownloadInfo) => {
    const data = await Api.downloadMultiFile([fileToDownload.downloadId])
    processDownloadData(data, fileToDownload.downloadName, fileToDownload.extension)
}

export const exportData = async (
    data: ExportExpensesRequest | ExportIncomeRequest | ExportReadingRequest | ExportBalanceRequest | ExportRentingPeriodsRequest,
    type: ExportType,
) => {
    let result: { blob: Blob; fileName?: string } | null = null
    if (type === ExportType.Expense) {
        result = await Api.downloadExpenseExport(data as ExportExpensesRequest)
    }
    if (type === ExportType.Income) {
        result = await Api.downloadIncomeExport(data as ExportIncomeRequest)
    }
    if (type === ExportType.Reading) {
        result = await Api.downloadReadingExport(data as ExportReadingRequest)
    }
    if (type === ExportType.Balance) {
        result = await Api.downloadTenantBalanceReport(data as ExportBalanceRequest)
    }
    if (type === ExportType.RentingPeriod) {
        result = await Api.downloadRentingPeriodsExport(data as ExportRentingPeriodsRequest)
    }

    return result
}

export const downloadMultiFile = async (fileToDownload: DownloadMultiFile) => {
    const data = await Api.downloadMultiFile(fileToDownload.ids)
    processDownloadData(data, fileToDownload.name, fileToDownload.extension)
}

export const downloadRentingPeriodData = async (fileToDownload: DownloadRentingPeriodInfo) => {
    const data = await Api.downloadRentingPeriodData(fileToDownload.downloadId, fileToDownload.downloadKey)
    processDownloadData(data, fileToDownload.downloadName, fileToDownload.extension)
}

export const getOwnerMaxSize = (
    userProperties: PropertySummary[] | undefined,
    maxPropertySize: number | undefined,
    propertyId: number | undefined,
) => {
    if (userProperties == null || maxPropertySize == null) return
    const property = userProperties.find((p) => p.id === propertyId)

    if (property == null) return 0
    return maxPropertySize - property.currentSize
}

export const blobToBase64 = (blob: Blob): Promise<string> => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    return new Promise((resolve) => {
        reader.onloadend = () => {
            resolve(reader.result as string)
        }
    })
}
