import { DefaultCountry, FormProvider, PersonType, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect, useMemo } from "react"
import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import { PropertyOwnerRepresentativeSchema } from "../../../../../validations/property-owners"
import {
    AddPropertyOwnerRepresentativeRequest,
    IdentifierType,
    useAddPropertyOwnerRepresentativeMutation,
    useUpdatePropertyOwnerRepresentativeMutation,
} from "../../../../../mutations/property-owners"
import { DateTime } from "luxon"
import { PropertyOwnerRepresentative } from "../../../../../queries/property-owners"

// Import step components
import OwnerDetailsStep from "./OwnerDetailsStep"
import RepresentationDetails from "./RepresentationDetails"
import { RepresentativeFormSteps } from "./types"

// Props for the form component
interface Props {
    propertyOwnerId: number
    onClose: () => void
    representative?: PropertyOwnerRepresentative
    isEditing?: boolean
}

// CI fields that need to be reset when switching to Company type
const CI_FIELDS = [
    "identificationDocumentSeries",
    "identificationDocumentNumber",
    "identificationDocumentIssuer",
    "identificationDocumentIssuedDate",
] as const

// Main Form Component
const AddOrEditPropertyOwnerRepresentativeForm = ({ propertyOwnerId, onClose, representative, isEditing }: Props) => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { mutate: addRepresentative, isPending: isAddPending } = useAddPropertyOwnerRepresentativeMutation()
    const { mutate: updateRepresentative, isPending: isUpdatePending } = useUpdatePropertyOwnerRepresentativeMutation()
    const isPending = useMemo(() => isAddPending || isUpdatePending, [isAddPending, isUpdatePending])
    const [activeStep, setActiveStep] = React.useState<RepresentativeFormSteps>(RepresentativeFormSteps.OwnerDetails)

    // Create default values for editing
    const defaultValues = useMemo(() => {
        // If editing, use the existing representative data
        if (isEditing && representative) {
            return {
                identifierType: representative.identifierType || IdentifierType.CI,
                customIdentifierType: representative.customIdentifierType || undefined,
                personType: representative.personType || PersonType.Natural,
                firstName: representative.firstName || "",
                lastName: representative.lastName || "",
                email: representative.email || "",
                phoneNumber: representative.phoneNumber || "",
                identifier: representative.identifier || "",
                country: representative.country || "",
                county: representative.country === DefaultCountry ? Number(representative.county) : representative.county,
                city: representative.country === DefaultCountry ? Number(representative.city) : representative.city,
                street: representative.country === DefaultCountry ? Number(representative.street) : representative.street,
                streetNumber: representative.streetNumber || "",
                postalCode: representative.postalCode || "",
                buildingNumber: representative.buildingNumber || "",
                stair: representative.stair || "",
                floor: representative.floor || "",
                apartment: representative.apartment || "",
                faxNumber: representative.faxNumber || "",
                startDate: representative.startDate?.toISO() || DateTime.now().toISO(),
                endDate: representative.endDate?.toISO() || DateTime.now().plus({ years: 1 }).toISO(),
                identificationDocumentSeries: representative.identificationDocumentSeries || "",
                identificationDocumentNumber: representative.identificationDocumentNumber || "",
                identificationDocumentIssuer: representative.identificationDocumentIssuer || "",
                identificationDocumentIssuedDate: representative.identificationDocumentIssuedDate?.toISO() || DateTime.now().toISO(),
                files: [],
                propertyOwnerId: propertyOwnerId,
            }
        }

        // Default values for new representative
        return {
            personType: PersonType.Natural,
            identifierType: IdentifierType.CI,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            country: DefaultCountry,
            startDate: DateTime.now().toISO(),
            identificationDocumentIssuedDate: DateTime.now().toISO(),
            endDate: DateTime.now().plus({ years: 1 }).toISO(),
            files: [],
            propertyOwnerId: propertyOwnerId,
        }
    }, [isEditing, representative, propertyOwnerId])

    // Create form with validation
    const methods = useForm<AddPropertyOwnerRepresentativeRequest>({
        // @ts-expect-error - Type mismatch between PropertyOwnerRepresentativeSchema and FormData, but works in practice
        resolver: yupResolver(PropertyOwnerRepresentativeSchema),
        defaultValues: defaultValues as AddPropertyOwnerRepresentativeRequest,
    })

    const { handleSubmit, watch, trigger, resetField } = methods
    const personType = watch("personType")

    // Reset CI fields when switching to Company type - simplified using array of fields
    useEffect(() => {
        if (personType === PersonType.Company) {
            CI_FIELDS.forEach((field) => resetField(field))
        }
    }, [personType, resetField])

    // Handle proceeding to the next step - optimized with useCallback
    const handleNext = useCallback(async () => {
        const isOwnerDataValid = await trigger([
            "streetNumber",
            "street",
            "county",
            "country",
            "city",
            "identifier",
            "firstName",
            "lastName",
            "companyName",
        ])
        if (isOwnerDataValid) {
            setActiveStep(RepresentativeFormSteps.AdditionalDetails)
        }
    }, [trigger])

    // Handle going back to the previous step - already optimized with useCallback
    const handlePrevious = useCallback(() => {
        setActiveStep(RepresentativeFormSteps.OwnerDetails)
    }, [])

    // Render the current active step - already optimized with useCallback
    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case RepresentativeFormSteps.OwnerDetails:
                return <OwnerDetailsStep onNext={handleNext} />
            case RepresentativeFormSteps.AdditionalDetails:
                return <RepresentationDetails isPending={isPending} onPrevious={handlePrevious} isEditing={isEditing} />
            default:
                return null
        }
    }, [activeStep, handleNext, isPending, handlePrevious, isEditing])

    // Optimized submission handler with validation check
    const onSubmit = useCallback(
        (data: AddPropertyOwnerRepresentativeRequest) => {
            if (isEditing && representative) {
                // Update existing representative using json patch operations
                updateRepresentative(
                    {
                        ...data,
                        propertyOwnerId,
                        representativeId: representative.id,
                        originalRepresentative: representative,
                        startDate: data.startDate,
                        endDate: data.endDate,
                        identificationDocumentSeries: data.identificationDocumentSeries,
                        identificationDocumentNumber: data.identificationDocumentNumber,
                        identificationDocumentIssuer: data.identificationDocumentIssuer,
                        identificationDocumentIssuedDate: data.identificationDocumentIssuedDate
                            ? DateTime.fromISO(data.identificationDocumentIssuedDate).toISODate()!
                            : undefined,
                    },
                    {
                        onSuccess: onClose,
                    },
                )
            } else {
                // Add new representative
                addRepresentative(
                    {
                        ...data,
                        propertyOwnerId,
                        startDate: data.startDate,
                        endDate: data.endDate,
                        identificationDocumentSeries: data.identificationDocumentSeries,
                        identificationDocumentNumber: data.identificationDocumentNumber,
                        identificationDocumentIssuer: data.identificationDocumentIssuer,
                        identificationDocumentIssuedDate: data.identificationDocumentIssuedDate,
                        files: data.files,
                    },
                    {
                        onSuccess: onClose,
                    },
                )
            }
        },
        [addRepresentative, updateRepresentative, onClose, propertyOwnerId, isEditing, representative],
    )

    // Get step titles - already optimized with useCallback
    const getSteps = useCallback(() => {
        return [
            {
                title: translate("owner_personal_details"),
            },
            {
                title: translate("representative_details"),
            },
        ]
    }, [translate])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: "100%" }}>
                <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                    {getSteps().map((step, index) => (
                        <Step key={step.title} completed={index < activeStep}>
                            <StepLabel>{step.title}</StepLabel>
                            {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                        </Step>
                    ))}
                </Stepper>
                {!isMobile && <Box sx={{ mt: 2 }}>{renderActiveStep()}</Box>}
            </Box>
        </FormProvider>
    )
}

export default AddOrEditPropertyOwnerRepresentativeForm
