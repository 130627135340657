import { CustomFile } from "rentzz"

export enum AddSignatureRequestFormSteps {
    SigningDetails,
    SigningConfiguration,
}

export interface AddSigningRequest {
    propertyId: number
    name: string
    internalId?: string
    isPresigned?: boolean
    rentingPeriodId?: number
    files: CustomFile[]
    recipients: Recipient[]
    acceptTerms: boolean
}

export interface Recipient {
    name: string
    email: string
    language: string
    fields?: RecipientField[]
}

export interface RecipientField {
    x: number
    y: number
    width: number
    height: number
    pageNum: number
}
