import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import FillInventoryProcessForm from "../../../../sections/propertyDetails/Tenants/rentingPeriodDetails/inventory/FillInventoryProcessForm"
import { useRentingPeriodInventoryProcessesQueryFn } from "../../../../queries/inventory"

const FillInventoryProcessGuard = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: allRentingPeriodProcesses } = useRentingPeriodInventoryProcessesQueryFn()
    const ourProcess = useMemo(() => {
        return allRentingPeriodProcesses?.find((i) => i.id === editingItem?.id)
    }, [allRentingPeriodProcesses, editingItem])

    const closeModal = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch, isMobile, modalOpen])

    const onSave = useCallback(async () => {
        closeModal()
    }, [closeModal, modalOpen, dispatch])

    const dialogWidthByNumberOfItems = useMemo(() => {
        if (ourProcess == null) return "md"
        if (ourProcess.sections.length < 3) {
            return "sm"
        }
        if (ourProcess.sections.length < 5) {
            return "md"
        }

        return "lg"
    }, [ourProcess])

    return (
        <>
            <CustomDialog
                title={translate("fill_inventory_process")}
                open={!isMobile && modalOpen === AppModals.FillInventoryProcess}
                onClose={closeModal}
                maxWidth={dialogWidthByNumberOfItems}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <FillInventoryProcessForm onFinish={onSave} />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate("fill_inventory_process")}
                isOpen={isMobile && modalOpen === AppModals.FillInventoryProcess}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <FillInventoryProcessForm onFinish={onSave} />
            </DetailsSheet>
        </>
    )
}

export default FillInventoryProcessGuard
