import { AppContext, FileManagerPage, Page, Type, useLocales, useTypedParams } from "rentzz"
import { useDispatch, useSelector } from "../../redux/store"
import React, { useCallback, useMemo } from "react"
import { getOwnerFilesQuery, getTenantFilesQuery } from "../../queries/files"
import { updateEntityFileNameMutation } from "../../mutations/entityFile"
import { useRentingPeriodDetails } from "../../queries/tenants"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"
import { useSnackbar } from "notistack"
import { useTenantRentingPeriodsQuery, useUserPropertiesQuery } from "../../queries/userData"
import { TableData } from "./Files"
import { PropertySectionsType } from "./PropertySections"
import { useParams } from "react-router"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { DateTime } from "luxon"
import { downloadFile, downloadMultiFile } from "../../utils/actions"
import { KeyboardArrowLeft } from "@mui/icons-material"
import { Box, Button, Typography } from "@mui/material"
import { DESKTOP_ICON_SIZE } from "../../utils/helpers"
import PropertyDetailsPageTabs from "../propertyDetails/PropertyDetailsPageTabs"
import RentingPeriodDetailsPageTabs from "../propertyDetails/Tenants/rentingPeriodDetails/RentingPeriodDetailsPageTabs"
import Api from "../../api/Api"

export default function SectionFiles() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: userRentingPeriods } = useTenantRentingPeriodsQuery()
    const currentlyViewingPropertyId = useTypedParams<number | undefined>("currentFilesPropertyId", Type.Number)
    const params = useParams()
    const { context, currentPropertyId, modalOpen, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)

    const usedPropertyId = useMemo(() => {
        if (currentRentingPeriodId) return rentingPeriodDetails?.propertyId
        if (currentPropertyId) return currentPropertyId
        return currentlyViewingPropertyId
    }, [currentPropertyId, currentRentingPeriodId, rentingPeriodDetails, currentlyViewingPropertyId])
    const tenantRentingPeriodId = useMemo(
        () => userRentingPeriods?.find((r) => r.propertyId === currentPropertyId)?.rentingPeriodId,
        [userRentingPeriods, currentPropertyId],
    )
    const { data: ownerFiles } = getOwnerFilesQuery(usedPropertyId, currentRentingPeriodId)
    const { data: tenantFiles } = getTenantFilesQuery(tenantRentingPeriodId)
    const { mutateAsync: changeFileName, isPending: isNameChangingLoading } = updateEntityFileNameMutation()
    const { canWrite, canDelete } = usePermissions(PermissionType.Files, rentingPeriodDetails?.propertyId ?? currentlyViewingPropertyId)

    const files = useMemo(() => (context === AppContext.Owner ? ownerFiles : tenantFiles), [context, ownerFiles, tenantFiles])

    const currentFiles = useMemo(() => {
        switch (params.section) {
            case PropertySectionsType.Expenses.toString():
                return files?.expenses ?? null
            case PropertySectionsType.Incomes.toString():
                return files?.income ?? null
            case PropertySectionsType.Insurances.toString():
                return files?.insurance ?? null
            case PropertySectionsType.Documents.toString():
                return files?.documents ?? null
            case PropertySectionsType.Contracts.toString():
                return files?.contracts ?? null
            case PropertySectionsType.Meters.toString():
                return files?.meterValues ?? null
            case PropertySectionsType.Tasks.toString():
                return files?.tasks ?? null
            case PropertySectionsType.RevisionObservations.toString():
                return files?.observations ?? null
        }
    }, [params, files])

    const title = useMemo(() => {
        switch (params.section) {
            case PropertySectionsType.Expenses.toString():
                return translate("Expenses")
            case PropertySectionsType.Incomes.toString():
                return translate("income")
            case PropertySectionsType.Insurances.toString():
                return translate("insurance.name")
            case PropertySectionsType.Documents.toString():
                return translate("documents")
            case PropertySectionsType.Contracts.toString():
                return translate("contracts.title")
            case PropertySectionsType.Meters.toString():
                return translate("meters_readings")
            case PropertySectionsType.Tasks.toString():
                return translate("tasks")
            case PropertySectionsType.RevisionObservations.toString():
                return translate("revisions")
        }
    }, [params])

    const buttonText = useMemo(() => {
        switch (params.section) {
            case PropertySectionsType.Documents.toString():
                return translate("add_document")
        }
    }, [params])

    const getOnAdd = useCallback(() => {
        if (params.section === PropertySectionsType.Documents.toString()) {
            dispatch(setModalOpen(AppModals.AddDocument))
        }
    }, [params, dispatch])

    const handleOnFileNameChange = useCallback(
        async (fileId: string, newFileName: string) => {
            await changeFileName({ fileId, newFileName })
        },
        [changeFileName],
    )

    const handleDeleteEntityFile = useCallback(
        async (fileId: string) => {
            if (files == null) return
            const allDocuments = files?.expenses.concat(
                files?.income,
                files?.insurance,
                files?.documents,
                files?.contracts,
                files?.meterValues,
                files.tasks,
                files.observations,
            )
            const currentFileName = allDocuments.find((doc) => doc.id === fileId)?.name
            dispatch(setModalOpen(AppModals.DeleteFile))
            dispatch(setEditingItem({ id: fileId, name: currentFileName }))
        },
        [dispatch, files],
    )

    const onDownload = useCallback(
        async (id: string) => {
            const allFiles = [
                ...(files?.contracts ?? []),
                ...(files?.documents ?? []),
                ...(files?.expenses ?? []),
                ...(files?.insurance ?? []),
                ...(files?.income ?? []),
                ...(files?.meterValues ?? []),
                ...(files?.tasks ?? []),
                ...(files?.observations ?? []),
            ]
            const fileToDownload = allFiles.find((f) => f.id === id)
            if (fileToDownload == null) return
            const key = enqueueSnackbar(translate("downloading"), { variant: "info", key: DateTime.now().toMillis() })

            try {
                if (fileToDownload.extension?.toLowerCase() === "pdf") {
                    const fileUrl = await Api.downloadMultiFile([fileToDownload.id])
                    const url = window.URL.createObjectURL(new Blob([fileUrl.blob]))
                    if (url) {
                        dispatch(
                            setEditingItem({
                                url: url,
                                fileName: fileUrl.fileName,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.PdfViewer))
                    }
                } else {
                    await downloadFile({
                        downloadId: id,
                        downloadName: fileToDownload?.name ?? "unknown",
                        extension: fileToDownload?.extension,
                    })
                }
            } finally {
                closeSnackbar(key)
            }
        },
        [files, enqueueSnackbar, closeSnackbar],
    )

    const getFolderName = useCallback(() => {
        const propertyName = userProperties?.find((property) => property.id === usedPropertyId)?.label
        return `${translate("files")}-${propertyName}-${title}-${DateTime.now().toLocaleString(DateTime.DATE_SHORT)}`
    }, [userProperties, translate, usedPropertyId, title])

    const onMultiDownload = useCallback(
        async (ids: string[]) => {
            const key = enqueueSnackbar(translate("downloading"), { variant: "info", key: DateTime.now().toMillis() })
            try {
                await downloadMultiFile({
                    ids: ids,
                    name: getFolderName(),
                    extension: "zip",
                })
            } finally {
                closeSnackbar(key)
            }
        },
        [files, enqueueSnackbar, closeSnackbar],
    )

    const allDocuments = useMemo(
        () =>
            currentFiles?.map((file) => ({
                id: file.id,
                name: file.name,
                extension: file.extension,
                size: file.size,
                handleEdit: handleOnFileNameChange,
                handleDelete: handleDeleteEntityFile,
                isActionHidden: !canDelete,
                isActionDisabled: context === AppContext.Tenant,
                isEditLoading: isNameChangingLoading,
                isEditVisible: canWrite ?? true,
            })),
        [currentFiles, handleDeleteEntityFile, handleOnFileNameChange, canDelete, context, isNameChangingLoading, canWrite],
    )

    const TABLE_HEAD = useMemo(
        () => [
            { id: "name", label: translate("name") },
            { id: "file_size", label: translate("file_size"), width: 400 },
            { id: "actions", label: translate("actions"), width: 110 },
        ],
        [],
    )
    const EMPTY_TABLE_HEAD = useMemo(() => [{ id: "1" }, { id: "2", width: 400 }, { id: "3", width: 110 }], [])

    return (
        <Page title={translate("documents")} px={2} paddingBottom={2} paddingTop={currentRentingPeriodId || currentRentingPeriodId ? undefined : 1}>
            {context === AppContext.Tenant && (
                <Box display='flex' flexDirection='row' alignItems='center' gap={1} py={1}>
                    <Button onClick={() => history.go(-1)} startIcon={<KeyboardArrowLeft sx={{ fontSize: DESKTOP_ICON_SIZE }} color='primary' />}>
                        <Typography variant={"body2"}>{title}</Typography>
                    </Button>
                </Box>
            )}
            {currentPropertyId && context === AppContext.Owner && <PropertyDetailsPageTabs />}
            {currentRentingPeriodId && context === AppContext.Tenant && <RentingPeriodDetailsPageTabs />}
            <FileManagerPage
                tableData={allDocuments ?? []}
                onSingleDownload={onDownload}
                onDoubleRowClick={console.log}
                onSelectedItemsDownload={onMultiDownload}
                tableType={TableData.SectionDetails}
                emptyTableHeader={EMPTY_TABLE_HEAD}
                tableHeader={TABLE_HEAD}
                addTextButton={canWrite ? buttonText : undefined}
                onAddClick={getOnAdd}
                isFabVisible={modalOpen == null && canWrite}
            />
        </Page>
    )
}
