import { Box, IconButton } from "@mui/material"
import { alpha, useTheme } from "@mui/material/styles"
import AddIcon from "@mui/icons-material/Add"
import { DESKTOP_ICON_SIZE } from "../utils/helpers"
import { Draggable, Droppable } from "@hello-pangea/dnd"
import React, { ReactNode } from "react"
import { DashboardChartType, SummaryCardType } from "../queries/userData"

interface Props {
    items: SummaryCardType[] | DashboardChartType[]
    onAdd: () => void
    droppableId: string
    renderItems: (item: SummaryCardType | DashboardChartType) => ReactNode
    isPublic?: boolean
}
export default function CustomDragAndDropBox({ items, onAdd, renderItems, droppableId, isPublic }: Props) {
    const theme = useTheme()

    return (
        <Box display='flex' position='relative' flexDirection='column' width='100%' px={1} paddingRight={5}>
            <Box position='absolute' right={0} top='50%' sx={{ transform: "translateY(-50%)" }}>
                {!isPublic && (
                    <IconButton
                        onClick={onAdd}
                        sx={{ backgroundColor: theme.palette.primary.main, ":hover": { backgroundColor: alpha(theme.palette.primary.main, 0.75) } }}
                    >
                        <AddIcon sx={{ fontSize: DESKTOP_ICON_SIZE, color: theme.palette.getContrastText(theme.palette.primary.main) }} />
                    </IconButton>
                )}
            </Box>

            <Droppable isDropDisabled={isPublic} droppableId={droppableId} direction={"horizontal"}>
                {(provided) => (
                    <Box
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        display={"flex"}
                        sx={{ overflowX: "auto", overflowY: "hidden" }}
                        gap={1}
                        paddingBottom={1}
                        width='100%'
                        height={"100%"}
                    >
                        {items?.map((card, index) => (
                            <Draggable isDragDisabled={isPublic} key={card.id} draggableId={card.id.toString()} index={index}>
                                {(provided, snapshot) => (
                                    <Box
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={{ ...provided.draggableProps.style, zIndex: snapshot.isDragging ? 10001 : undefined }}
                                    >
                                        {renderItems(card)}
                                    </Box>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </Box>
                )}
            </Droppable>
        </Box>
    )
}
