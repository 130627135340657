import { Iconify, LoadingIcon, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { getGroupAccessList } from "../../../queries/groups"
import React from "react"
import { Box, Fab } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { DateTime } from "luxon"
import CustomAddCard from "../../../components/CustomAddCard"

const MobileGroupAccessList = () => {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, currentGroupId } = useSelector((state) => state.appState)
    const { data: groupAccess } = getGroupAccessList()

    if (groupAccess == null) return <LoadingIcon />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} sx={{ height: "inherit", paddingBottom: 2, paddingTop: 2 }}>
            {groupAccess.length > 0 && modalOpen == null && (
                <Fab
                    onClick={() => {
                        dispatch(setModalOpen(AppModals.AddGroupAccess))
                        dispatch(setEditingItem({ id: currentGroupId }))
                    }}
                    sx={{ position: "fixed", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}
            {groupAccess.map((access) => (
                <CustomMobileCard
                    key={access.userId}
                    item={access}
                    onClick={() => {
                        dispatch(setModalOpen(AppModals.GroupAccessDetails))
                        dispatch(setEditingItem({ id: access.userId }))
                    }}
                    icon={"mdi:account"}
                    iconColor={theme.palette.primary.main}
                    title={access.fullName}
                    subtitle={translate(`managerType-${access.ownershipType}`)}
                    rightFirstItem={access.date.toLocaleString(DateTime.DATE_SHORT)}
                />
            ))}
            {groupAccess.length === 0 && (
                <CustomAddCard
                    onCardClick={() => dispatch(setModalOpen(AppModals.AddGroupAccess))}
                    icon={"mdi:account-add"}
                    text={"add_group_access"}
                />
            )}
        </Box>
    )
}

export default MobileGroupAccessList
