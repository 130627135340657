import { FormProvider, RHFSelect, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import React, { useCallback } from "react"
import { Box, MenuItem } from "@mui/material"
import { InventoryProcessType } from "../../../redux/slices/AddProperty"
import { useDispatch } from "react-redux"
import { useSelector } from "../../../redux/store"
import { ExportInventoryProcessRequest } from "../../../mutations/inventory-types"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { LoadingButton } from "@mui/lab"
import { exportInventoryProcess } from "../../../mutations/inventory"

const InventoryProcessForm = () => {
    const { editingItem } = useSelector((state) => state.appState)
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { mutate, isPending } = exportInventoryProcess()
    const methods = useForm<ExportInventoryProcessRequest>({
        defaultValues: {
            inventoryProcessType: InventoryProcessType.Handover,
        },
    })

    const { handleSubmit } = methods

    const handleOnExport = useCallback(
        async (data: ExportInventoryProcessRequest) => {
            mutate(
                { ...data, rentingPeriodId: editingItem.rentingPeriodId },
                {
                    onSuccess: (result) => {
                        const url = window.URL.createObjectURL(new Blob([result.blob]))
                        dispatch(
                            setEditingItem({
                                fileName: result.fileName,
                                url: url,
                            }),
                        )
                        dispatch(setModalOpen(AppModals.PdfViewer))
                    },
                },
            )
        },
        [editingItem, dispatch, mutate],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(handleOnExport)}>
            <Box display='flex' flexDirection='column' gap={2} py={2}>
                <RHFSelect name='inventoryProcessType' label={translate("inventoryProcessType")}>
                    <MenuItem value={InventoryProcessType.Handover}>{translate("inventory_process_handover")}</MenuItem>
                    <MenuItem value={InventoryProcessType.Takeover}>{translate("inventory_process_takeover")}</MenuItem>
                </RHFSelect>
                <LoadingButton color='primary' loading={isPending} variant='contained' type='submit'>
                    {translate("generate_inventory_process")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}

export default InventoryProcessForm
