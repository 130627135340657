import { InventoryProcessItem } from "../../../../../redux/slices/AddProperty"
import { Box, Typography } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { HourglassFull } from "@mui/icons-material"
import InventoryItemForm from "./InventoryItemForm"

interface Props {
    item: InventoryProcessItem
    inventoryProcessId: number
    inventorySectionId: number
    onPrevious: () => void
    onNext: () => void
    isFirst: boolean
}

const InventoryItemContent = ({ item, isFirst, inventoryProcessId, onNext, inventorySectionId, onPrevious }: Props) => {
    return (
        <Box display='flex' flexDirection='column' gap={2}>
            <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Box display='flex' flexDirection='column'>
                    <Typography variant='body2'>{item.itemName}</Typography>
                    <Typography variant='caption'>{item.itemDescription}</Typography>
                </Box>
                {item.isHandled ? <CheckIcon color='success' /> : <HourglassFull color='warning' />}
            </Box>
            <InventoryItemForm
                item={item}
                inventorySectionId={inventorySectionId}
                inventoryProcessId={inventoryProcessId}
                onPrevious={onPrevious}
                isFirst={isFirst}
                onNext={onNext}
            />
        </Box>
    )
}

export default InventoryItemContent
