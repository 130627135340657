import { Box, CircularProgress, InputAdornment, MenuItem, Paper, Stack, Typography } from "@mui/material"
import { allLangs, FormProvider, Iconify, LightTooltip, RHFAutocomplete, RHFSelect, RHFSwitch, RHFTextField, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useEffect } from "react"
import { useTheme } from "@mui/material/styles"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { useAllUserInvoicingCompanies, useCompanyClients, useCompanySeries } from "../../../../../queries/invoicing-configuration"
import * as jsonpatch from "fast-json-patch"
import { updateRentingPeriodInvoicing, updateRentingPeriodMutation } from "../../../../../mutations/tenants"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../../../queries/userData"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { InvoicingSchema } from "../../../../../validations/invoicing-configuration"
import { LoadingButton } from "@mui/lab"
import InvoicingForRent from "./InvoicingForRent"
import ReinvoicingConfiguration from "./ReinvoicingConfiguration"
import { useSelector } from "../../../../../redux/store"
import LateFeesConfiguration from "./LateFeesConfiguration"

interface InvoicingRequest {
    invoicingLanguage: string
    invoicingCompany: string
    invoicingClientId: number
    invoicingSeries?: string
    defaultInvoicingTemplate?: string
    defaultInvoicingText?: string
    invoicingCurrencyId?: number
    invoiceMaxDays?: number
    invoicingPeriodStart?: number | null
    invoicingPeriodEnd?: number | null
    lateFeesSeries?: number
    lateFeesVAT?: number
    invoicingVAT?: number
    reinvoicingSeries?: string
    reinvoicingVAT?: number
    reinvoicingPercentage?: number
    defaultReinvoicingText?: string
    sendToSPV?: boolean
    removeCIF: boolean
    createInvoice: boolean
    createInvoiceDay?: number
}

export default function Invoicing() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)
    const { mutateAsync: updateRentingPeriodInvoicingAction, isPending: isRentingPeriodInvoicingUpdating } = updateRentingPeriodInvoicing()
    const { mutateAsync: updateRentingPeriodAction, isPending: isRentingPeriodUpdating } = updateRentingPeriodMutation()
    const { data: allUserSeries } = useCompanySeries(rentingPeriodDetails?.invoicingCompany?.id)
    const { data: allUserCompanies } = useAllUserInvoicingCompanies()
    const isLateFeesOn = useFeatureIsOn(UserFlags.ShowLateFeesGenerateButton.toString())
    const { data: allUserClients, isLoading: areAllUserClientsPending } = useCompanyClients(rentingPeriodDetails?.invoicingCompany?.id)
    const defaultInvoicingTemplatesByLanguage = useFeatureValue<Record<string, Record<string, string>>>(
        UserFlags.DefaultInvoicingTemplateByLanguage,
        {},
    )

    const methods = useForm<InvoicingRequest>({
        resolver: yupResolver<InvoicingRequest>(InvoicingSchema),
    })

    const { watch, handleSubmit, reset, setValue } = methods

    const resetForm = useCallback(() => {
        if (rentingPeriodDetails) {
            reset({
                removeCIF: rentingPeriodDetails?.removeCIF,
                invoicingCompany: rentingPeriodDetails?.invoicingCompany?.id.toString(),
                invoicingClientId: rentingPeriodDetails.invoicingClientId,
                invoicingLanguage: rentingPeriodDetails?.invoicingLanguage,
                createInvoiceDay: rentingPeriodDetails?.createInvoiceDay ?? undefined,
                invoicingSeries: rentingPeriodDetails?.invoicingSeries?.id.toString(),
                invoicingCurrencyId: rentingPeriodDetails?.invoicingCurrencyId,
                invoiceMaxDays: rentingPeriodDetails?.invoiceMaxDays,
                defaultInvoicingTemplate: rentingPeriodDetails.defaultInvoicingTemplate,
                defaultInvoicingText: rentingPeriodDetails.defaultInvoicingText,
                invoicingPeriodStart: rentingPeriodDetails.invoicingPeriodStart,
                invoicingPeriodEnd: rentingPeriodDetails.invoicingPeriodEnd,
                reinvoicingSeries: rentingPeriodDetails.reinvoicingSeries?.id.toString(),
                reinvoicingVAT: rentingPeriodDetails.reinvoicingVAT?.id,
                defaultReinvoicingText: rentingPeriodDetails.defaultReinvoicingText ?? "",
                reinvoicingPercentage: rentingPeriodDetails.reinvoicingPercentage ?? 100,
                lateFeesVAT: rentingPeriodDetails.lateFeesVAT?.id,
                lateFeesSeries: rentingPeriodDetails.lateFeesSeries?.id,
                invoicingVAT: rentingPeriodDetails.invoicingVAT?.id,
                sendToSPV: rentingPeriodDetails.sendToSPV ?? false,
                createInvoice: rentingPeriodDetails.createInvoice ?? false,
            })
        }
    }, [rentingPeriodDetails, reset])

    useEffect(() => {
        if (rentingPeriodDetails) {
            resetForm()
        }
    }, [rentingPeriodDetails])

    useEffect(() => {
        const subscription = watch(async (v, { name }) => {
            if (rentingPeriodDetails == null) return
            if (name === "invoicingCompany") {
                // set current company and reset everything related to this company
                const operations = jsonpatch.compare(
                    {
                        invoicingClientId: rentingPeriodDetails.invoicingClientId,
                        invoicingSeriesId: rentingPeriodDetails.invoicingSeries?.id.toString(),
                        invoicingCompanyId: rentingPeriodDetails.invoicingCompany?.id.toString(),
                        reinvoicingSeriesId: rentingPeriodDetails.reinvoicingSeries?.id.toString(),
                        reinvoicingVatId: rentingPeriodDetails.reinvoicingVAT?.id.toString(),
                        lateFeesSeriesId: rentingPeriodDetails.lateFeesSeries?.id.toString(),
                        lateFeesVatId: rentingPeriodDetails.lateFeesVAT?.id.toString(),
                        invoicingVatId: rentingPeriodDetails.invoicingVAT?.id.toString(),
                    },
                    {
                        invoicingClientId: undefined,
                        invoicingSeriesId: undefined,
                        invoicingCompanyId: v.invoicingCompany,
                        reinvoicingSeriesId: undefined,
                        reinvoicingVatId: undefined,
                        lateFeesSeriesId: undefined,
                        lateFeesVatId: undefined,
                        invoicingVatId: undefined,
                    },
                )

                await updateRentingPeriodAction({
                    propertyId: rentingPeriodDetails.propertyId,
                    tenantId: rentingPeriodDetails.id,
                    operations,
                })
            }

            if (name === "invoicingLanguage") {
                const invoicingLanguage = v.invoicingLanguage as string

                setValue("defaultReinvoicingText", defaultInvoicingTemplatesByLanguage.reinvoicing[invoicingLanguage], {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                })
                setValue("defaultInvoicingTemplate", defaultInvoicingTemplatesByLanguage.invoicing[invoicingLanguage], {
                    shouldTouch: true,
                    shouldDirty: true,
                    shouldValidate: true,
                })
            }
        })
        return () => subscription.unsubscribe()
    }, [rentingPeriodDetails, updateRentingPeriodInvoicingAction, defaultInvoicingTemplatesByLanguage, setValue])

    const onSubmit = useCallback(
        async (data: InvoicingRequest) => {
            if (rentingPeriodDetails == null || data == null) return
            const operations = jsonpatch.compare(
                {
                    invoicingClientId: rentingPeriodDetails.invoicingClientId,
                    invoicingLanguage: rentingPeriodDetails.invoicingLanguage,
                    createInvoiceDay: rentingPeriodDetails.createInvoiceDay,
                    defaultInvoicingTemplate: rentingPeriodDetails.defaultInvoicingTemplate,
                    invoicingCurrencyId: rentingPeriodDetails.invoicingCurrencyId,
                    defaultInvoicingText: rentingPeriodDetails.defaultInvoicingText,
                    invoiceMaxDays: rentingPeriodDetails.invoiceMaxDays,
                    invoicingPeriodStart: rentingPeriodDetails.invoicingPeriodStart,
                    invoicingPeriodEnd: rentingPeriodDetails.invoicingPeriodEnd,
                    defaultReinvoicingText: rentingPeriodDetails.defaultReinvoicingText,
                    reinvoicingPercentage: rentingPeriodDetails.reinvoicingPercentage,
                    sendToSPV: rentingPeriodDetails.sendToSPV,
                    createInvoice: rentingPeriodDetails.createInvoice,
                    invoicingSeriesId: rentingPeriodDetails.invoicingSeries?.id,
                    reinvoicingSeriesId: rentingPeriodDetails.reinvoicingSeries?.id,
                    invoicingCompanyId: rentingPeriodDetails.invoicingCompany?.id,
                    reinvoicingVATId: rentingPeriodDetails.reinvoicingVAT?.id,
                    lateFeesSeriesId: rentingPeriodDetails.lateFeesSeries?.id,
                    lateFeesVatId: rentingPeriodDetails.lateFeesVAT?.id,
                    invoicingVatId: rentingPeriodDetails.invoicingVAT?.id,
                    removeCIF: rentingPeriodDetails.removeCIF,
                },
                {
                    ...data,
                    invoicingSeriesId: data.invoicingSeries ? Number(data.invoicingSeries) : undefined,
                    invoicingCompanyId: data.invoicingCompany ? Number(data.invoicingCompany) : undefined,
                    reinvoicingSeriesId: data.reinvoicingSeries ? Number(data.reinvoicingSeries) : undefined,
                    reinvoicingVATId: data.reinvoicingVAT ? Number(data.reinvoicingVAT) : undefined,
                    lateFeesSeriesId: data.lateFeesSeries ? Number(data.lateFeesSeries) : undefined,
                    lateFeesVatId: data.lateFeesVAT ? Number(data.lateFeesVAT) : undefined,
                    invoicingVatId: data.invoicingVAT ? Number(data.invoicingVAT) : undefined,
                    invoicingSeries: undefined,
                    invoicingCompany: undefined,
                    reinvoicingVAT: undefined,
                    reinvoicingSeries: undefined,
                    lateFeesVAT: undefined,
                    lateFeesSeries: undefined,
                    invoicingVAT: undefined,
                    sendToSPV: data.sendToSPV ?? false,
                },
            )

            await updateRentingPeriodAction({
                propertyId: rentingPeriodDetails.propertyId,
                tenantId: rentingPeriodDetails.id,
                operations,
            })
        },
        [rentingPeriodDetails, updateRentingPeriodAction],
    )

    if (rentingPeriodDetails == null || allUserCompanies == null)
        return (
            <Box height={100} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Box>
        )

    return (
        <Paper elevation={3} square={false}>
            <Stack direction={"column"} p={2} gap={2}>
                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                    <Box display={"flex"} alignItems={"center"} justifyContent={"space-between"} gap={2} paddingBottom={2}>
                        <Typography variant='subtitle1' sx={{ color: theme.palette.grey.A700 }}>
                            {translate("invoicing_configuration_renting_period")}
                        </Typography>
                        <Box display={"flex"} gap={2}>
                            {!isMobile && (
                                <>
                                    <RHFSwitch name={"createInvoice"} label={translate("create_invoice_automatically")} labelPlacement={"start"} />
                                    <LightTooltip
                                        title={
                                            rentingPeriodDetails?.invoicingCompany?.supportsSPVSending
                                                ? ""
                                                : translate("company_not_configured_sending_to_SPV")
                                        }
                                        arrow
                                    >
                                        <span>
                                            <RHFSwitch
                                                name={"sendToSPV"}
                                                label={translate("SPV")}
                                                labelPlacement={"start"}
                                                disabled={!rentingPeriodDetails?.invoicingCompany?.supportsSPVSending}
                                            />
                                        </span>
                                    </LightTooltip>

                                    <LightTooltip title={translate("remove_cif_info")} arrow>
                                        <span>
                                            <RHFSwitch name={"removeCIF"} label={translate("remove_cif")} labelPlacement={"start"} />
                                        </span>
                                    </LightTooltip>
                                </>
                            )}

                            <LoadingButton
                                variant={"contained"}
                                color={"primary"}
                                type={"submit"}
                                loading={isRentingPeriodUpdating || isRentingPeriodInvoicingUpdating}
                            >
                                {translate("save")}
                            </LoadingButton>
                        </Box>
                    </Box>
                    {isMobile && (
                        <Box display={"flex"} gap={2} paddingBottom={2}>
                            <RHFSwitch name={"createInvoice"} label={translate("create_invoice_automatically")} labelPlacement={"start"} />
                            <LightTooltip
                                title={
                                    rentingPeriodDetails?.invoicingCompany?.supportsSPVSending
                                        ? ""
                                        : translate("company_not_configured_sending_to_SPV")
                                }
                                arrow
                            >
                                <span>
                                    <RHFSwitch
                                        name={"sendToSPV"}
                                        label={translate("SPV")}
                                        labelPlacement={"start"}
                                        disabled={!rentingPeriodDetails?.invoicingCompany?.supportsSPVSending}
                                    />
                                </span>
                            </LightTooltip>
                        </Box>
                    )}
                    <Box display={"flex"} flexDirection={"column"} gap={2}>
                        <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={2}>
                            <RHFSelect
                                name={"invoicingCompany"}
                                size='small'
                                variant={"outlined"}
                                label={translate("invoicing_company")}
                                disabled={!canWrite || allUserCompanies.length === 0}
                                fullWidth
                                required
                            >
                                {allUserCompanies ? (
                                    allUserCompanies.map((c) => (
                                        <MenuItem key={c.id} value={c.id} sx={{ color: theme.palette.grey.A700 }}>
                                            {c.name}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <CircularProgress />
                                )}
                            </RHFSelect>

                            <RHFAutocomplete
                                name={"invoicingClientId"}
                                textFieldProps={{ size: "small" }}
                                options={allUserClients?.map((p) => ({ id: p.id, label: `${p.cif} - ${p.name}` })) ?? []}
                                matchId
                                label={translate("invoicing_client")}
                                loading={areAllUserClientsPending}
                                autocompleteProps={{
                                    size: "small",
                                }}
                                required
                                disabled={
                                    !canWrite || allUserSeries == null || allUserSeries.length === 0 || rentingPeriodDetails.invoicingCompany == null
                                }
                            />
                            <RHFSelect
                                name={"invoicingLanguage"}
                                size='small'
                                variant={"outlined"}
                                label={translate("invoicing_language")}
                                disabled={!canWrite}
                                fullWidth
                                required
                            >
                                {allLangs?.map((c) => (
                                    <MenuItem key={c.value} value={c.value} sx={{ color: theme.palette.grey.A700 }}>
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFTextField
                                name={"createInvoiceDay"}
                                size={"small"}
                                label={translate("createInvoiceDay")}
                                type={"number"}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position={"end"}>
                                            <LightTooltip title={translate("createInvoiceDayTooltip")} arrow>
                                                <Iconify icon={"mdi:information"} width={18} height={18} color={theme.palette.info.main} />
                                            </LightTooltip>
                                        </InputAdornment>
                                    ),
                                    inputProps: { min: 0, max: 31 },
                                }}
                                required
                                disabled={!canWrite}
                            />
                        </Box>
                        <Box display={"flex"} alignItems={"center"} gap={1}>
                            <Typography variant='body2' fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                                {translate("rent_invoicing")}
                            </Typography>
                        </Box>
                        <InvoicingForRent />
                    </Box>

                    <Box display={"flex"} alignItems={"center"} gap={1} paddingY={2}>
                        <Typography variant='body2' fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                            {translate("expense_reinvoicing")}
                        </Typography>
                    </Box>
                    <ReinvoicingConfiguration />

                    {isLateFeesOn && (
                        <>
                            <Box display={"flex"} alignItems={"center"} gap={1} paddingY={2}>
                                <Typography variant='body2' fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate("late_fees")}
                                </Typography>
                            </Box>
                            <LateFeesConfiguration />
                        </>
                    )}
                </FormProvider>
            </Stack>
        </Paper>
    )
}
