import { useTheme } from "@mui/material/styles"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import PropertyOwnerConfiguration from "../../../../../sections/nomenclature/property-owners/PropertyOwnerConfiguration"
import { usePropertyOwnerByIdQuery } from "../../../../../queries/property-owners"

export default function AddOrEditPropertyOwner() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const propertyOwner = usePropertyOwnerByIdQuery(editingItem?.id)

    const handleClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={
                    !isMobile &&
                    (modalOpen === AppModals.AddPropertyOwner ||
                        modalOpen === AppModals.AssociatePropertyToPropertyOwner ||
                        modalOpen === AppModals.EditPropertyOwnerData)
                }
                onClose={handleClose}
                maxWidth={"md"}
                title={modalOpen === AppModals.AssociatePropertyToPropertyOwner ? translate("associate_a_property") : translate("add_property_owner")}
                titleAlign={"center"}
            >
                <PropertyOwnerConfiguration
                    onClose={handleClose}
                    defaultValues={propertyOwner}
                    isAssociatingProperties={modalOpen === AppModals.AssociatePropertyToPropertyOwner}
                    defaultProperties={propertyOwner?.properties}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={
                    isMobile &&
                    (modalOpen === AppModals.AddPropertyOwner ||
                        modalOpen === AppModals.AssociatePropertyToPropertyOwner ||
                        modalOpen === AppModals.EditPropertyOwnerData)
                }
                onClose={handleClose}
                isLoading={false}
                title={modalOpen === AppModals.AssociatePropertyToPropertyOwner ? translate("associate_a_property") : translate("add_property_owner")}
                options={[]}
                headerColor={theme.palette.primary.main}
            >
                <PropertyOwnerConfiguration
                    onClose={handleClose}
                    defaultValues={propertyOwner}
                    isAssociatingProperties={modalOpen === AppModals.AssociatePropertyToPropertyOwner}
                    defaultProperties={propertyOwner?.properties}
                />
            </DetailsSheet>
        </>
    )
}
