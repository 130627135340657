import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../../redux/store"
import { DetailsSheet, useLocales } from "rentzz"
import React, { SyntheticEvent, useMemo } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { renderActionSheetInfo } from "../../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { getInvoicingClientByIdQueryFn } from "../../../../queries/invoicing-configuration"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../../utils/helpers"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import { useCitiesQueryFn, useCountiesQueryFn } from "../../../../queries"

export default function InvoicingClientDetailsSheet() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currentInvoicingClient } = getInvoicingClientByIdQueryFn(editingItem?.invoicingClientId)
    const { data: allCounties } = useCountiesQueryFn()
    const { data: allCities } = useCitiesQueryFn(
        currentInvoicingClient?.isFromExternalSystem
            ? undefined
            : allCounties?.find((c) => c.countyId === Number(currentInvoicingClient?.county))?.countyId,
    )

    const { currentCounty, currentCity } = useMemo(() => {
        const countyName = allCounties?.find((c) => c.countyId === Number(currentInvoicingClient?.county))?.name ?? ""
        const cityName = allCities?.find((c) => c.cityId === Number(currentInvoicingClient?.city))?.name ?? ""
        return { currentCounty: countyName, currentCity: cityName }
    }, [currentInvoicingClient, allCounties, allCities])

    const options = useMemo((): CustomSheetOption[] => {
        if (currentInvoicingClient == null) return []

        return [
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                onClick: () => {
                    dispatch(setEditingItem({ invoicingClientId: currentInvoicingClient.id }))
                    dispatch(setModalOpen(AppModals.EditInvoicingClient))
                },
                hidden: currentInvoicingClient?.isFromExternalSystem,
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                onClick: () => {
                    dispatch(setEditingItem({ invoicingClientId: currentInvoicingClient.id }))
                    dispatch(setModalOpen(AppModals.DeleteInvoicingClient))
                },
                hidden: currentInvoicingClient?.isFromExternalSystem,
            },
        ]
    }, [currentInvoicingClient, dispatch])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.InvoicingClientDetailsSheet}
            onClose={(event: MouseEvent | TouchEvent | PointerEvent | SyntheticEvent<object, Event> | undefined) => {
                event?.stopPropagation()
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={false}
            title={currentInvoicingClient?.name}
            headerColor={theme.palette.primary.main}
            options={options}
        >
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.invoicingCompanyName")}: ${currentInvoicingClient?.invoicingCompanyName}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.name")}: ${currentInvoicingClient?.name}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.cif")}: ${currentInvoicingClient?.cif}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.county")}: ${currentInvoicingClient?.isFromExternalSystem ? currentInvoicingClient?.county : currentCounty}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.city")}: ${currentInvoicingClient?.isFromExternalSystem ? currentInvoicingClient?.city : currentCity}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.email")}: ${currentInvoicingClient?.email}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.phone")}: ${currentInvoicingClient?.phone ?? "-"}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.country")}: ${currentInvoicingClient?.country ?? "-"}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.address")}: ${currentInvoicingClient?.address ?? "-"}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.bank")}: ${currentInvoicingClient?.bank ?? "-"}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                `${translate("invoicing-clients.iban")}: ${currentInvoicingClient?.iban ?? "-"}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "",
                currentInvoicingClient?.isTaxPayer
                    ? `${translate("invoicing-clients.taxPayerStatus")}: ${translate("invoicing-clients.isTaxPayer")}`
                    : `${translate("invoicing-clients.taxPayerStatus")}: ${translate("invoicing-clients.isNotTaxPayer")}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:calendar-month",
                `${translate("createdAt")}: ${currentInvoicingClient?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
