import { DetailsSheet, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import { getInventorySectionByIdQueryFn } from "../../../queries/inventory"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"

export default function InventorySectionDetailsSheet() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const inventorySection = getInventorySectionByIdQueryFn(editingItem?.inventorySectionId)

    const options = useMemo((): CustomSheetOption[] => {
        return [
            {
                icon: <EditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditInventorySection))
                },
                hidden: !inventorySection?.canWrite,
            },
            {
                icon: <RemoveRedEyeIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "view_inventory_items",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.InventorySectionItemList))
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            canDelete: inventorySection?.canDelete,
                            canWrite: inventorySection?.canWrite,
                        }),
                    )
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !inventorySection?.canDelete,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteInventorySection))
                },
            },
        ]
    }, [inventorySection, dispatch, context])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.InventorySectionDetails}
            onClose={() => {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            }}
            isLoading={false}
            title={inventorySection?.sectionName}
            options={options}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:calendar-edit-outline",
                    `${translate("createdAt")}: ${inventorySection?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:user",
                    `${translate("createdBy")}: ${inventorySection?.createdByUser}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

                {renderActionSheetInfo(
                    "mdi:calendar-edit-outline",
                    `${translate("lastModifiedAt")}: ${inventorySection?.lastModifiedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:user",
                    `${translate("lastModifiedBy")}: ${inventorySection?.lastModifiedByUser}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
