import { Box, CircularProgress, Fab } from "@mui/material"
import React, { useEffect, useMemo, useRef } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import CustomAddCard from "../../../../components/CustomAddCard"
import { useInfiniteRecurringExpensesQueryFn } from "../../../../queries/expenses"
import RecurringExpenseCustomCard from "./RecurringExpenseCustomCard"
import { AppContext, Iconify, LoadingIcon } from "rentzz"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import { sum } from "lodash"
import NoDataPage from "../../../../components/NoDataPage"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"

interface RecurringExpensesListMobileProps {
    getAmount: (value: number, currencyId?: number) => string
}

const RecurringExpensesListMobile = ({ getAmount }: RecurringExpensesListMobileProps) => {
    const dispatch = useDispatch()
    const ref = useRef<HTMLDivElement>(null)
    const { modalOpen, context } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const {
        data: recurringExpenses,
        fetchNextPage,
        hasNextPage,
        isLoading,
    } = useInfiniteRecurringExpensesQueryFn([{ field: "createdAt", sort: "desc" }])
    const { canWrite } = usePermissions(PermissionType.RecurringExpense)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    if (recurringExpenses == null || userProperties == null) return <LoadingIcon />

    if (sum(recurringExpenses?.pages.flatMap((p) => p.items.length)) === 0 && !canWrite && context === AppContext.Owner)
        return <NoDataPage icon={"mdi:tag-off"} noDataText={"no_recurring_expenses"} />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
            {recurringExpenses.pages.some((p) => p.items.length > 0) && modalOpen == null && canWrite && (
                <Fab
                    sx={{ position: "fixed", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                    onClick={() => dispatch(setModalOpen(AppModals.AddRecurringExpense))}
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}
            {recurringExpenses.pages.map((group, i) => (
                <Box
                    key={i}
                    paddingTop={recurringExpenses.pages.some((p) => p.items.length === 0) ? 0 : 2}
                    gap={1}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    {group.items.map((recurringExpense) => (
                        <RecurringExpenseCustomCard
                            key={recurringExpense.id}
                            getAmount={getAmount}
                            expense={recurringExpense}
                            onClick={() => {
                                dispatch(setEditingItem({ id: recurringExpense.id }))
                                dispatch(setModalOpen(AppModals.RecurringExpenseDetails))
                            }}
                        />
                    ))}
                </Box>
            ))}
            {hasNextPage && (
                <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                    <CircularProgress />
                </div>
            )}
            {isLoading && (
                <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", height: 300 }}>
                    <CircularProgress />
                </div>
            )}
            {recurringExpenses.pages.some((p) => p.items.length === 0) && canWrite && (
                <CustomAddCard
                    onCardClick={() => dispatch(setModalOpen(AppModals.AddRecurringExpense))}
                    isAddDisabled={userProperties?.length === 0}
                    tooltipTitle={userProperties?.length === 0 ? "recurringExpenses.no_property" : undefined}
                    icon={"mdi:tag-plus"}
                    text={"recurringExpenses.add_recurring_expense"}
                />
            )}
        </Box>
    )
}

export default RecurringExpensesListMobile
