import { Box } from "@mui/material"
import HelpComponent from "./HelpComponent"
import RoleLockedButton from "./RoleLockedButton"
import { RoleName } from "../queries/userData"
import React, { ReactElement, ReactNode } from "react"

interface CustomToolbarProps {
    onAddClick: () => void
    buttonText: string
    blockedRoles: RoleName[]
    leftSide?: ReactElement
    isDisabled?: boolean
    disabledText?: ReactNode
}
export default function CustomRoleBlockedToolbar({
    onAddClick,
    buttonText,
    blockedRoles,
    isDisabled,
    disabledText,
    leftSide = undefined,
}: CustomToolbarProps) {
    return (
        <Box display={"flex"} flexDirection={"column"}>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: leftSide == null ? "flex-end" : "space-between",
                    gap: 2,
                    marginBottom: 2,
                    alignItems: "center",
                }}
            >
                {leftSide}
                <HelpComponent helpPage={"https://rentzz.app/docs/"}>
                    <RoleLockedButton
                        onClick={onAddClick}
                        buttonText={buttonText}
                        disabledText={disabledText}
                        isDisabled={isDisabled}
                        blockedRoles={blockedRoles}
                    />
                </HelpComponent>
            </Box>
        </Box>
    )
}
