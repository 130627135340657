import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { useTheme } from "@mui/material/styles"
import { Box, Button, Typography } from "@mui/material"
import * as jsonpatch from "fast-json-patch"
import { LoadingButton } from "@mui/lab"
import { useDispatch, useSelector } from "../../../redux/store"
import { getNoteByIdQueryFn } from "../../../queries/notes"
import { updateNoteMutation } from "../../../mutations/notes"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"

export default function ChangeTenantVisibility() {
    const { translate } = useLocales()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const currentNote = getNoteByIdQueryFn(editingItem?.id, [{ field: "addedAt", sort: "desc" }])
    const { mutateAsync: updateNote, isPending: isUpdatingNote } = updateNoteMutation()

    const updateNoteVisibility = useCallback(async () => {
        if (currentNote == null) return

        const operations = jsonpatch.compare(
            { isVisibleToTenant: currentNote?.isVisibleToTenant },
            { isVisibleToTenant: !currentNote?.isVisibleToTenant },
        )
        await updateNote({
            noteId: currentNote.noteId,
            propertyId: currentNote.propertyId,
            operations: operations,
        })
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [updateNote, currentNote])

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.NoteStatus}
                onClose={() => {
                    dispatch(setEditingItem(undefined))
                    dispatch(setModalOpen(undefined))
                }}
                isLoading={currentNote == null}
                title={translate("notes.edit_visibility")}
                headerColor={currentNote?.isVisibleToTenant ? theme.palette.error.main : theme.palette.primary.main}
            >
                <Box display={"flex"} flexDirection={"column"} paddingTop={2}>
                    <Typography textAlign={"center"}>
                        {currentNote?.isVisibleToTenant ? translate("notes.tenant_visibility_off") : translate("notes.tenant_visibility_on")}
                    </Typography>
                    <Box
                        sx={{
                            alignItems: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            padding: 2,
                            gap: 2,
                        }}
                    >
                        <LoadingButton
                            onClick={updateNoteVisibility}
                            variant='contained'
                            color={currentNote?.isVisibleToTenant ? "error" : "primary"}
                            loading={isUpdatingNote}
                            disabled={isUpdatingNote}
                            fullWidth
                        >
                            {translate(currentNote?.isVisibleToTenant ? translate("stop") : translate("yes"))}
                        </LoadingButton>
                        <Button
                            onClick={() => {
                                dispatch(setModalOpen(AppModals.NoteDetails))
                            }}
                            variant={"text"}
                            color={"primary"}
                            fullWidth
                            disabled={isUpdatingNote}
                        >
                            {translate("cancel")}
                        </Button>
                    </Box>
                </Box>
            </DetailsSheet>

            <CustomDialog
                isFullScreen={false}
                title={translate("notes.edit_visibility")}
                onClose={() => {
                    dispatch(setEditingItem(undefined))
                    dispatch(setModalOpen(undefined))
                }}
                open={!isMobile && modalOpen === AppModals.NoteStatus}
                maxWidth='xs'
                onAccept={updateNoteVisibility}
                acceptText={currentNote?.isVisibleToTenant ? translate("stop") : translate("yes")}
                closeText={translate("cancel")}
                variantCloseButton={currentNote?.isVisibleToTenant ? "contained" : "outlined"}
                acceptButtonColor={currentNote?.isVisibleToTenant ? "error" : "primary"}
                closeButtonColor={"primary"}
                isDisabled={isUpdatingNote}
                isLoading={isUpdatingNote}
            >
                <Box display={"flex"} justifyContent={"center"} paddingY={2}>
                    <Typography>
                        {currentNote?.isVisibleToTenant ? translate("notes.tenant_visibility_off") : translate("notes.tenant_visibility_on")}
                    </Typography>
                </Box>
            </CustomDialog>
        </>
    )
}
