import { useDispatch, useSelector } from "../../../../redux/store"
import { CustomDialog } from "rentzz"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import * as React from "react"
import CustomPDFViewer from "../../../../components/CustomPDFViewer"

export default function GeneralDocumentPreview() {
    const dispatch = useDispatch()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)

    return (
        <CustomDialog
            open={modalOpen === AppModals.GeneralDocumentPreview || modalOpen === AppModals.NotificationPreview}
            isFullScreen={true}
            onClose={() => {
                dispatch(setModalOpen(undefined))
            }}
        >
            <CustomPDFViewer height={"100%"} width='100%' url={editingItem?.pdfUrl} />
        </CustomDialog>
    )
}
