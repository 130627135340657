import { useDispatch, useSelector } from "../../../redux/store"
import { useTheme } from "@mui/material/styles"
import { DetailsSheet, useLocales } from "rentzz"
import React, { SyntheticEvent, useMemo } from "react"
import DeleteIcon from "@mui/icons-material/Delete"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { useUserDataQueryFn, useUserLabelsQuery } from "../../../queries/userData"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"

export default function LabelDetailsSheet() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { data: labels, isLoading: areLabelsLoading } = useUserLabelsQuery("all")
    const { data: userData } = useUserDataQueryFn()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)

    const currentLabel = useMemo(() => labels?.find((label) => label.id === editingItem?.id), [labels, editingItem])

    const options = useMemo((): CustomSheetOption[] => {
        if (currentLabel == null) return []
        return [
            {
                icon: <ModeEditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                hidden: userData?.roles == null && !currentLabel.canEdit,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditLabel))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: userData?.roles == null && !currentLabel.canEdit,
                onClick: () => dispatch(setModalOpen(AppModals.DeleteLabel)),
            },
        ]
    }, [currentLabel, dispatch, userData])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.LabelDetails}
            onClose={(event: MouseEvent | TouchEvent | PointerEvent | SyntheticEvent<object, Event> | undefined) => {
                event?.stopPropagation()
                dispatch(setEditingItem(undefined))
                dispatch(setModalOpen(undefined))
            }}
            isLoading={areLabelsLoading}
            title={currentLabel?.value}
            options={options}
            headerColor={`#${currentLabel?.color}`}
        >
            {renderActionSheetInfo(
                "mdi:info",
                `${translate("label_type")}: ${translate(`label_type-${currentLabel?.type}`)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:calendar-month",
                `${translate("createdAt")}: ${currentLabel?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
            {renderActionSheetInfo(
                "mdi:calendar-clock",
                `${translate("lastModifiedAt")}: ${currentLabel?.lastModifiedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                theme.palette.primary.main,
                theme.palette.grey["600"],
            )}
        </DetailsSheet>
    )
}
