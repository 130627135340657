import { CustomDataGrid, Label, useLocales } from "rentzz"
import React, { useMemo } from "react"
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { DateTime } from "luxon"
import { useUserConsentBankAccountsQuery } from "../../../../queries/bankAccountConsents"
import { UserBankAccount, UserBankAccountConsent } from "../../../../queries/userData"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../../queries/currency"
import { getReverseTextColor } from "../../../../utils/helpers"
import CustomDetailPanelToggle from "../../../expenses/CustomDetailPanelToggle"
import NoDataPage from "../../../../components/NoDataPage"
import { Box, Card, CardContent } from "@mui/material"
import Header from "../../../expenses/expense-details/Header"
import { useTheme } from "@mui/material/styles"

interface Props {
    currentConsent: GridRowParams<UserBankAccountConsent>
}

export default function DesktopConsentAssociatedBankAccounts({ currentConsent }: Props) {
    const { translate } = useLocales()
    const { data: associatedBankAccounts, isLoading: areAssociatedBankAccountsLoading } = useUserConsentBankAccountsQuery(Number(currentConsent?.id))
    const { data: currencyData } = useCurrencyQueryFn()
    const theme = useTheme()

    const columns = useMemo(() => {
        const columns: GridColDef<UserBankAccount>[] = [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => {
                    return <CustomDetailPanelToggle id={params.id} value={params.value} title={"associated_bank_accounts"} />
                },
            },
            {
                field: "holder",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("holder"),
            },
            {
                field: "bank",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("bank"),
                valueFormatter: (value) => translate(`bank-name-${value}`),
            },
            {
                field: "iban",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("iban"),
            },
            {
                field: "currencyId",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("currency"),
                valueFormatter: (value) => getCurrencyFromId(currencyData, value).code,
            },
            {
                field: "labelId",
                type: "string",
                flex: 1,
                maxWidth: 160,
                headerAlign: "center",
                align: "center",
                headerName: translate("labelDescription"),
                renderCell: (v) => {
                    if (v.value == null) return ""
                    return (
                        <Label
                            style={{
                                textTransform: "none",
                                backgroundColor: `#${v.row.labelColor}`,
                                color: getReverseTextColor(`#${v.row.labelColor}`),
                            }}
                        >
                            {v.row.labelName}
                        </Label>
                    )
                },
            },
            {
                field: "createdAt",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("createdAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate])

    return (
        <Box display={"flex"} justifyContent={"center"} paddingY={2}>
            <Card
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    width: "90%",
                    height: "100%",
                }}
            >
                <CardContent
                    sx={{
                        justifyContent: "center",
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        flexDirection: "column",
                        color: theme.palette.grey["700"],
                        alignSelf: "center",
                        gap: 2,
                        paddingBottom: 0,
                        "&.MuiCardContent-root:last-child": { paddingBottom: 0 },
                    }}
                >
                    <>
                        <Header currentItem={currentConsent.row} itemType='associatedBankAccount' />
                        <CustomDataGrid
                            columns={columns}
                            gridId={"DesktopConsentAssociatedBankAccounts"}
                            actionsColumn={[]}
                            rows={associatedBankAccounts ?? []}
                            infoColumn={[]}
                            idKey={"id"}
                            components={{
                                noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={"mdi:bank-off"} noDataText={"no_bank_accounts"} />,
                            }}
                            isLoading={areAssociatedBankAccountsLoading}
                            rowHeight={50}
                            gridHeight={270}
                            hidePagination={associatedBankAccounts && associatedBankAccounts.length < 4}
                        />
                    </>
                </CardContent>
            </Card>
        </Box>
    )
}
