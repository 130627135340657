import { Box, Button, Grid, IconButton, MenuItem } from "@mui/material"
import React from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { Iconify, RHFSelect, RHFTextField, useLocales } from "rentzz"
import AddIcon from "@mui/icons-material/Add"
import { DESKTOP_ICON_SIZE } from "../utils/helpers"
import { useTheme } from "@mui/material/styles"

export interface RHFRecipientsInputProps {
    name: string
}

const RHFRecipientsInput = ({ name }: RHFRecipientsInputProps) => {
    const { control } = useForm()
    const { translate, allLangs } = useLocales()
    const theme = useTheme()

    const { fields, remove, append } = useFieldArray({
        control,
        name,
    })

    return (
        <Grid container alignItems='center' columnGap={2} rowGap={2}>
            {fields.map((f, index) => (
                <React.Fragment key={f.id}>
                    <Grid item xs={12} sm={4} alignItems='center'>
                        <Box height={50}>
                            <RHFTextField required name={`${name}.${index}.name`} label={translate("name")} fullWidth />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={4} alignItems='center'>
                        <Box height={50}>
                            <RHFTextField required name={`${name}.${index}.email`} label={translate("email")} fullWidth />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={2} alignItems='center'>
                        <Box height={50}>
                            <RHFSelect required name={`${name}.${index}.language`} label={translate("language")} fullWidth>
                                {allLangs?.map((c) => (
                                    <MenuItem key={c.value} value={c.value} sx={{ color: theme.palette.grey.A700 }}>
                                        {c.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={1} flex={1}>
                        <Box height={50} display='flex' alignItems='center' justifyContent='center' pb={1}>
                            <IconButton onClick={() => remove(index)}>
                                <Iconify
                                    icon={"mdi:delete"}
                                    width={DESKTOP_ICON_SIZE}
                                    height={DESKTOP_ICON_SIZE}
                                    style={{ color: theme.palette.error.main }}
                                />
                            </IconButton>
                        </Box>
                    </Grid>
                </React.Fragment>
            ))}
            <Grid xs={12}>
                <Button
                    color='primary'
                    startIcon={<AddIcon />}
                    onClick={() =>
                        append({
                            name: "",
                            email: "",
                            language: "",
                        })
                    }
                >
                    {translate("add_signature_request_party")}
                </Button>
            </Grid>
        </Grid>
    )
}

export default RHFRecipientsInput
