import { CustomDataGrid, Label, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { RoleName } from "../../../../../queries/userData"
import React, { useMemo } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import ActionWithTooltip from "../../../../../utils/ActionWithTooltip"
import DeleteIcon from "@mui/icons-material/Delete"
import { DESKTOP_ICON_SIZE } from "../../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { DateTime } from "luxon"
import CustomRoleBlockedToolbar from "../../../../../components/CustomRoleBlockedToolbar"
import NoDataPage from "../../../../../components/NoDataPage"
import { useRentingPeriodInventoryProcessesQueryFn } from "../../../../../queries/inventory"
import { InventoryProcess } from "../../../../../redux/slices/AddProperty"
import { getInventoryProcessStatusColor, getInventoryProcessTypeColor } from "../../../../expenses/utils"
import { PlayArrow } from "@mui/icons-material"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import useCustomSortModel from "../../../../../hooks/useCustomSortModel"

const DesktopInventoryProcessesList = () => {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { sortModel, setSortModel } = useCustomSortModel("DesktopInventoryProcessesList", [{ field: "createdAt", sort: "desc" }])
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { data: rentingPeriodInventoryProcesses, isLoading: areRentingPeriodInventoryProcessesLoading } =
        useRentingPeriodInventoryProcessesQueryFn()

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            type: "actions",
            minWidth: 80,
            flex: 0.1,
            disableReorder: true,
            headerName: translate("tenant-data.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    key: "continue",
                    tooltipText: translate("inventory_process_fill"),
                    element: (
                        <GridActionsCellItem
                            color={"primary"}
                            icon={<PlayArrow sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.FillInventoryProcess))
                                dispatch(setEditingItem({ id: params.id }))
                            }}
                            label={translate("delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
                ActionWithTooltip({
                    key: "delete",
                    tooltipText: translate("delete"),
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteInventoryProcess))
                                dispatch(setEditingItem({ id: params.id }))
                            }}
                            label={translate("delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
            ],
        }),
        [translate, dispatch],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<InventoryProcess>[] = [
            {
                field: "createdAt",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("createdAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "status",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("status"),
                renderCell: (params) => {
                    return (
                        <Label color={getInventoryProcessStatusColor(params.row.status)} style={{ textTransform: "none" }}>
                            {translate(`inventory_process_status-${params.row.status}`)}
                        </Label>
                    )
                },
            },
            {
                field: "type",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("inventory_process_type"),
                renderCell: (params) => {
                    return (
                        <Label color={getInventoryProcessTypeColor(params.row.type)} style={{ textTransform: "none" }}>
                            {translate(`inventory-process-type-${params.row.type}`)}
                        </Label>
                    )
                },
            },
            {
                field: "lastModifiedAt",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("lastModifiedAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate])

    return (
        <>
            <CustomRoleBlockedToolbar
                onAddClick={() => dispatch(setModalOpen(AppModals.StartInventoryProcess))}
                buttonText={"start_inventory_process"}
                blockedRoles={[RoleName.FREE, RoleName.BRONZE]}
                isDisabled={!rentingPeriodDetails?.canAddInventoryProcess}
                disabledText={!rentingPeriodDetails?.canAddInventoryProcess ? translate("make_sure_you_have_inventory_sections") : undefined}
            />
            <CustomDataGrid
                columns={columns}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                gridId={"DesktopInventoryProcessesList"}
                actionsColumn={[actionsColumn]}
                rows={rentingPeriodInventoryProcesses ?? []}
                infoColumn={[]}
                idKey={"id"}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={""} noDataText={"no_inventory_processes"} />,
                }}
                isLoading={areRentingPeriodInventoryProcessesLoading}
                rowHeight={50}
            />
        </>
    )
}

export default DesktopInventoryProcessesList
