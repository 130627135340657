import { CustomFile, FormProvider, Furniture, Page, RHFPhoneNumber, RHFSelect, RHFTextField, TVType, useLocales } from "rentzz"
import React, { useEffect, useState } from "react"
import { Box, Button, MenuItem, Typography } from "@mui/material"
import Gallery from "./Gallery"
import { useNavigate } from "react-router-dom"
import LivePreview from "../livePreview/LivePreview"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import Details from "./details/Details"
import { usePropertyDetailsQueryFn } from "../../../../queries"
import { useUserDataQueryFn, useUserPropertiesQuery } from "../../../../queries/userData"
import { AdDataSchema } from "../../../../validations/addNewAd"
import Address from "./Address"
import { LoadingButton } from "@mui/lab"
import { Partition } from "./details/utils"

export interface AdDataRequest {
    title: string
    county: string
    city: string
    address: string
    photos: CustomFile[]
    rentPrice: number
    currencyId: number
    description?: string
    phone: string
    propertyId: number
    size: number
    partition?: Partition
    floor?: string
    furniture?: Furniture
    rooms?: number
    bathrooms?: number
    tv?: TVType
    hasHeater: boolean
    hasAC: boolean
    hasFridge: boolean
    hasDishwasher: boolean
    hasWashingMachine: boolean
    hasHood: boolean
    hasElevator: boolean
    hasDoubleGlazing: boolean
    hasInsulation: boolean
}

export default function NewAdWizard() {
    const { translate } = useLocales()
    const navigate = useNavigate()
    const { data: userData } = useUserDataQueryFn()
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: user } = useUserDataQueryFn()
    const [selectedPropertyId, setSelectedPropertyId] = useState<number | undefined>(undefined)
    const { data: propertyDetails } = usePropertyDetailsQueryFn(selectedPropertyId)

    const methods = useForm<AdDataRequest>({
        resolver: yupResolver<AdDataRequest>(AdDataSchema),
        mode: "onChange",
        defaultValues: {
            title: "",
            city: propertyDetails?.city || "",
            county: propertyDetails?.county || "",
            // address: `${propertyDetails?.street},${propertyDetails?.streetNumber},${},${},${}` || "",
            rentPrice: 0,
            currencyId: userData?.currency.id,
            size: propertyDetails?.size,
            partition: propertyDetails?.isDetached ? Partition.DETACHED : undefined,
            floor: propertyDetails?.floor?.toString(),
            furniture: propertyDetails?.furniture,
            rooms: propertyDetails?.rooms,
            bathrooms: undefined,
            tv: propertyDetails?.tvType,
            hasHeater: propertyDetails?.hasHeater || false,
            hasAC: propertyDetails?.hasAC || false,
            hasFridge: false,
            hasDishwasher: propertyDetails?.hasDishwasher || false,
            hasWashingMachine: false,
            hasHood: false,
            hasElevator: false,
            hasDoubleGlazing: false,
            hasInsulation: false,
            description: "",
            photos: [],
        },
    })

    const { setValue, watch, trigger } = methods

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "propertyId") {
                setSelectedPropertyId(value.propertyId)
            }
            if (name === "county") {
                setValue("city", "")
            }
        })

        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        if (!propertyDetails) return
        setValue("county", propertyDetails.county ?? "")
        setValue("city", propertyDetails.city ?? "")
        setValue("size", Number(propertyDetails.size))
        setValue("floor", propertyDetails.floor?.toString() ?? "0")
        setValue("furniture", propertyDetails.furniture ?? "")
        setValue("rooms", propertyDetails.rooms ?? undefined)
        setValue("tv", propertyDetails.tvType ?? "")
        setValue("hasHeater", propertyDetails.hasHeater ?? "")
        setValue("hasAC", propertyDetails.hasAC ?? "")
        setValue("hasDishwasher", propertyDetails.hasDishwasher ?? "")
        // setValue("phone", user?.phoneNumber ?? "")
        setValue("partition", propertyDetails.isDetached ? Partition.DETACHED : undefined)
        trigger()
    }, [propertyDetails, setValue, userData, user])

    return (
        <Page title={translate("marketing.name")} paddingX={2} paddingBottom={4} width={"100%"} height={"100%"} display={"flex"} gap={3}>
            <Box display={"flex"} flexDirection={"column"} width={"55%"}>
                <Box sx={{ height: window.innerHeight - 130, overflow: "auto", margin: "auto", paddingRight: 1 }}>
                    <Box display={"flex"} justifyContent={"space-between"} paddingBottom={3}>
                        <Button color={"primary"} variant={"contained"} onClick={() => navigate("/marketing")}>
                            {translate("back")}
                        </Button>
                        <Typography textAlign={"center"} fontWeight={"bold"} fontSize={22}>
                            {translate("marketing.new_ad")}
                        </Typography>
                        <Box />
                    </Box>
                    <FormProvider methods={methods}>
                        <Box display={"flex"} flexDirection={"column"} gap={2}>
                            <RHFSelect name={"propertyId"} size='small' label={translate("property")} required>
                                {userProperties?.map((property) => (
                                    <MenuItem key={property.id} value={property.id} style={{ textTransform: "none" }}>
                                        {property.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFTextField
                                name={"title"}
                                size='small'
                                label={translate("marketing.title")}
                                required
                                disabled={selectedPropertyId === undefined}
                            />
                            <Address isDisabled={selectedPropertyId === undefined} />
                            <Gallery isDisabled={selectedPropertyId === undefined} />
                            <Details isDisabled={selectedPropertyId === undefined} />
                            <RHFTextField
                                name={"description"}
                                label={translate("property_description")}
                                multiline
                                rows={6}
                                disabled={selectedPropertyId === undefined}
                            />
                            <RHFPhoneNumber
                                name='phone'
                                label={translate("phone")}
                                required
                                style={{ color: "red" }}
                                size={"small"}
                                disabled={selectedPropertyId === undefined}
                            />
                            <Box display={"flex"} justifyContent={"flex-end"} paddingY={3}>
                                <LoadingButton color={"primary"} variant={"contained"} sx={{ textTransform: "none", width: "50%" }}>
                                    {translate("create_ad")}
                                </LoadingButton>
                            </Box>
                        </Box>
                    </FormProvider>
                </Box>
            </Box>

            <LivePreview watch={watch} />
        </Page>
    )
}
