import { FormProvider, useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect, useState } from "react"
import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import { AddSignatureSchema } from "../../../validations/signature"
import { AddSignatureRequestFormSteps, AddSigningRequest } from "./types"
import SigningDetails from "./SigningDetails"
import PDFSignaturePicker from "./PDFSignaturePicker"
import { addSignatureRequestMutation } from "../../../mutations/signature_request"
import DocumentTypePicker from "./DocumentTypePicker"
import { useAtom } from "jotai/index"
import { signingRequestTypeAtom } from "../../../utils/atom"
import { Computer } from "@mui/icons-material"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../queries/userData"

interface Props {
    onClose: () => void
}

export default function AddSignatureRequestForm({ onClose }: Props) {
    const [activeStep, setActiveStep] = useState<AddSignatureRequestFormSteps>(AddSignatureRequestFormSteps.SigningDetails)
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { mutate, isPending } = addSignatureRequestMutation()
    const isSignatureRequestsOn = useFeatureIsOn(UserFlags.SignatureRequests.toString())
    const [documentType, setDocumentType] = useAtom(signingRequestTypeAtom)

    useEffect(() => {
        if (!isSignatureRequestsOn) setDocumentType("presigned")
    }, [isSignatureRequestsOn])

    const methods = useForm<AddSigningRequest>({
        resolver: yupResolver<AddSigningRequest>(AddSignatureSchema(documentType)),
        mode: "onChange",
        defaultValues: {
            propertyId: currentPropertyId,
            files: [],
            name: "",
            isPresigned: false,
            internalId: "",
            recipients: [],
            acceptTerms: false,
        },
    })

    const handleClose = useCallback(() => {
        setDocumentType(undefined)
        onClose()
    }, [onClose, setDocumentType])

    const { handleSubmit, trigger, getValues } = methods

    const getSteps = useCallback(() => {
        return [
            {
                title: translate("signing_details"),
            },
            {
                title: translate("signing_configuration"),
            },
        ]
    }, [translate])

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case AddSignatureRequestFormSteps.SigningDetails:
                return (
                    <SigningDetails
                        isLoading={isPending}
                        onClose={handleClose}
                        onNext={async () => {
                            const res = await trigger(["files", "recipients"])
                            if (res) {
                                if (documentType === "presigned") {
                                    mutate(
                                        { data: { ...getValues(), isPresigned: true } },
                                        {
                                            onSuccess: () => {
                                                handleClose()
                                            },
                                        },
                                    )
                                } else {
                                    setActiveStep(AddSignatureRequestFormSteps.SigningConfiguration)
                                }
                            }
                        }}
                    />
                )
            case AddSignatureRequestFormSteps.SigningConfiguration:
                return (
                    <PDFSignaturePicker
                        isLoading={isPending}
                        onSend={async () => {
                            const res = await trigger(["acceptTerms"])
                            if (res) {
                                mutate(
                                    { data: { ...getValues(), isPresigned: false } },
                                    {
                                        onSuccess: () => {
                                            handleClose()
                                        },
                                    },
                                )
                            }
                        }}
                    />
                )
        }
    }, [activeStep, trigger, documentType, isPending])

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(console.log)}>
            {documentType == null && <DocumentTypePicker onClose={handleClose} onChange={setDocumentType} />}
            {documentType != null && (
                <>
                    {documentType === "signRequest" && isMobile ? (
                        <Box display='flex' flexDirection='column' gap={2} height={"100%"} alignItems='center' justifyContent='center'>
                            <Box>
                                <Computer color='primary' sx={{ fontSize: 80 }} />
                            </Box>
                            <Box display='flex' justifyContent='center' textAlign='center' px={2}>
                                {translate("available_only_pc")}
                            </Box>
                        </Box>
                    ) : documentType === "signRequest" ? (
                        <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                            <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                                {getSteps().map((step, index) => {
                                    return (
                                        <Step key={step.title} completed={index < activeStep}>
                                            <StepLabel>{step.title}</StepLabel>
                                            {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                                        </Step>
                                    )
                                })}
                            </Stepper>
                            {!isMobile && renderActiveStep()}
                        </Box>
                    ) : (
                        renderActiveStep()
                    )}
                </>
            )}
        </FormProvider>
    )
}
