import { Box, CircularProgress } from "@mui/material"
import { RHFSwitch, useIsMobile, useLocales, varFade } from "rentzz"
import { m } from "framer-motion"
import React, { useEffect, useMemo } from "react"
import { useTheme } from "@mui/material/styles"
import { useFormContext } from "react-hook-form"
import { AppNotifications } from "../../../redux/slices/AddProperty"
import { useUserDataQueryFn } from "../../../queries/userData"
import { allPossibleOwnerNotifications } from "./utils"

interface Props {
    expanded?: boolean
    isDisabled: boolean
}

export default function OwnerNotificationsForm({ expanded, isDisabled }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const { data: userData, isLoading } = useUserDataQueryFn()

    const { reset } = useFormContext<{
        email: Record<AppNotifications, boolean>
        push: Record<AppNotifications, boolean>
    }>()

    useEffect(() => {
        const pushNotificationsStatus = {} as Record<AppNotifications, boolean>
        const emailNotificationsStatus = {} as Record<AppNotifications, boolean>
        const pushOffNotifications = allPossibleOwnerNotifications.filter(
            (notification) => !userData?.enabledPushNotifications?.includes(notification),
        )
        const emailOffNotifications = allPossibleOwnerNotifications.filter(
            (notification) => !userData?.enabledEmailNotifications?.includes(notification),
        )

        userData?.enabledPushNotifications?.forEach((notification: AppNotifications) => (pushNotificationsStatus[notification] = true))
        userData?.enabledEmailNotifications?.forEach((notification: AppNotifications) => (emailNotificationsStatus[notification] = true))

        pushOffNotifications?.forEach((notification: AppNotifications) => (pushNotificationsStatus[notification] = false))
        emailOffNotifications?.forEach((notification: AppNotifications) => (emailNotificationsStatus[notification] = false))

        reset({ email: emailNotificationsStatus, push: pushNotificationsStatus })
    }, [userData, reset])

    const notifications = useMemo(() => {
        if (isMobile && expanded) return allPossibleOwnerNotifications.slice(8)
        return allPossibleOwnerNotifications
    }, [isMobile, expanded, allPossibleOwnerNotifications])

    return (
        <>
            {isLoading ? (
                <Box display={"flex"} alignItems={"center"} justifyContent={"center"} height={200}>
                    <CircularProgress size={"1.5rem"} />
                </Box>
            ) : (
                <>
                    {isMobile && (
                        <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
                            <Box style={{ display: "flex", gap: 2, flexDirection: "column", width: "88%" }}>
                                {allPossibleOwnerNotifications.slice(0, 8).map((notification) => (
                                    <RHFSwitch
                                        key={notification}
                                        name={`email.${notification.toString()}`}
                                        label={translate(`notification.${notification}`)}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                            letterSpacing: 0.5,
                                            textTransform: "uppercase",
                                            color: theme.palette.primary.dark,
                                            ".MuiTypography-root ": {
                                                fontSize: 12,
                                                fontWeight: 800,
                                            },
                                            height: 70,
                                        }}
                                    />
                                ))}
                            </Box>
                            <Box style={{ display: "flex", gap: 2, flexDirection: "column" }}>
                                {allPossibleOwnerNotifications.slice(0, 8).map((notification) => (
                                    <RHFSwitch
                                        key={notification}
                                        name={`push.${notification.toString()}`}
                                        label={""}
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row-reverse",
                                            justifyContent: "space-between",
                                            letterSpacing: 0.5,
                                            textTransform: "uppercase",
                                            color: theme.palette.primary.dark,
                                            ".MuiTypography-root ": {
                                                fontSize: 12,
                                                fontWeight: 800,
                                            },
                                            height: 70,
                                            ".MuiFormControlLabel-roo": { alignContent: "center" },
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                    )}
                    <Box display={"flex"} width={"100%"} justifyContent={"space-between"}>
                        <m.div variants={varFade().in} style={{ display: "flex", gap: 2, flexDirection: "column", width: "87%" }}>
                            {notifications.map((notification) => (
                                <RHFSwitch
                                    key={notification}
                                    disabled={isDisabled}
                                    name={`email.${notification.toString()}`}
                                    label={translate(`notification.${notification}`)}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        justifyContent: "space-between",
                                        letterSpacing: 0.5,
                                        textTransform: "uppercase",
                                        color: theme.palette.primary.dark,
                                        ".MuiTypography-root ": {
                                            fontSize: 12,
                                            fontWeight: 800,
                                            height: isMobile ? 70 : undefined,
                                            alignContent: "center",
                                        },
                                    }}
                                />
                            ))}
                        </m.div>
                        <m.div variants={varFade().in} style={{ display: "flex", gap: 2, flexDirection: "column" }}>
                            {notifications.map((notification) => (
                                <RHFSwitch
                                    key={notification}
                                    name={`push.${notification.toString()}`}
                                    label={""}
                                    disabled={isDisabled}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row-reverse",
                                        justifyContent: "space-between",
                                        letterSpacing: 0.5,
                                        textTransform: "uppercase",
                                        color: theme.palette.primary.dark,
                                        ".MuiTypography-root ": {
                                            fontSize: 12,
                                            fontWeight: 800,
                                            alignContent: "center",
                                        },
                                        height: isMobile ? 70 : undefined,
                                    }}
                                />
                            ))}
                        </m.div>
                    </Box>
                </>
            )}
        </>
    )
}
