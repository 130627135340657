import StartInventoryProcessGuard from "./StartInventoryProcessGuard"
import DeleteInventoryProcessGuard from "./DeleteInventoryProcessGuard"
import FillInventoryProcessGuard from "./FillInventoryProcessGuard"
import InventoryProcessDetailsSheet from "./InventoryProcessDetailsSheet"

const InventoryProcessGuard = () => {
    return (
        <>
            <StartInventoryProcessGuard />
            <DeleteInventoryProcessGuard />
            <FillInventoryProcessGuard />
            <InventoryProcessDetailsSheet />
        </>
    )
}

export default InventoryProcessGuard
