import { CustomDataGrid, useIsMobile, useLocales } from "rentzz"
import * as React from "react"
import { useMemo, useState } from "react"
import { OwnerPropertyMeter, PropertyFileType, PropertyMeterValue, TenantPropertyMeter } from "../../../../redux/slices/AddProperty"
import { DateTime } from "luxon"
import CustomToolbar from "./Toolbar"
import { useMeterValuesHistoryQueryFn } from "../../../../queries/meters"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import ActionWithTooltip from "../../../../utils/ActionWithTooltip"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { useDispatch, useSelector } from "../../../../redux/store"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { DESKTOP_ICON_SIZE } from "../../../../utils/helpers"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import useCustomSortModel from "../../../../hooks/useCustomSortModel"

interface Props {
    getValue: (value: number) => string
    currentMeter:
        | (TenantPropertyMeter & { canWrite: boolean; canDelete: boolean })
        | (OwnerPropertyMeter & { canWrite: boolean; canDelete: boolean })
        | undefined
}

export default function DesktopMetersValuesHistory({ getValue, currentMeter }: Props) {
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    })
    const { sortModel, setSortModel } = useCustomSortModel("DesktopMetersValuesHistory", [{ field: "createdAt", sort: "desc" }])
    const { translate } = useLocales()
    const { data: readingsList, isFetching } = useMeterValuesHistoryQueryFn(undefined, paginationModel.page, paginationModel.pageSize, sortModel)
    const { editingItem } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()
    const dispatch = useDispatch()

    const columns = useMemo(
        (): GridColDef<PropertyMeterValue>[] => [
            {
                field: "createdAt",
                width: 100,
                headerAlign: "center",
                align: "center",
                flex: isMobile ? undefined : 0.5,
                type: "string",
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
                headerName: translate("index_sent_date"),
            },
            {
                field: "createdBy",
                width: 140,
                headerAlign: "center",
                align: "center",
                flex: isMobile ? undefined : 0.5,
                type: "string",
                sortable: false,
                headerName: translate("index_sent_by"),
            },
            {
                field: "previousValue",
                width: 100,
                headerAlign: "center",
                align: "center",
                flex: isMobile ? undefined : 0.5,
                type: "number",
                headerName: translate("previous_index"),
                valueFormatter: (value) => {
                    return getValue(value)
                },
            },
            {
                field: "currentValue",
                width: 90,
                headerAlign: "center",
                align: "center",
                flex: isMobile ? undefined : 0.5,
                type: "number",
                headerName: translate("new_index"),
                valueFormatter: (value) => {
                    return getValue(value)
                },
            },
        ],
        [translate, getValue, isMobile],
    )

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            resizable: false,
            flex: 0.2,
            minWidth: 150,
            type: "actions",
            disableReorder: true,
            headerName: translate("actions"),
            getActions: (params: GridRowParams<PropertyMeterValue>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ ...editingItem, readingId: params.row.id }))
                                dispatch(setModalOpen(AppModals.EditReading))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    hidden: !params.row.canEdit,
                    tooltipText: "",
                    key: "edit",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        ...editingItem,
                                        readingId: params.row.id,
                                        type: PropertyFileType.MeterValue,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={`${translate("see_files")}`}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("see_files"),
                    key: "see_files",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()

                                dispatch(
                                    setEditingItem({
                                        id: currentMeter?.id,
                                        meterValueId: params.row.id,
                                        value: params.row.currentValue,
                                        propertyId: currentMeter?.propertyId,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.DeleteMeterValue))
                            }}
                            label={`${translate("delete")}`}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("delete"),
                    key: "delete",
                    hidden: !params.row.canEdit,
                }),
            ],
        }),
        [translate, dispatch, editingItem, currentMeter, readingsList],
    )

    return (
        <CustomDataGrid
            gridId={"DesktopMetersValuesHistory"}
            sortModel={sortModel}
            sortingMode='server'
            onSortModelChange={setSortModel}
            paginationMode={"server"}
            rowsLoadingMode={"client"}
            columns={columns}
            rows={readingsList?.items ?? []}
            gridHeight={400}
            isLoading={isFetching}
            components={{
                toolbar:
                    currentMeter?.canWrite || editingItem?.canWrite
                        ? () => (
                              <CustomToolbar
                                  lastValue={readingsList ? readingsList.items[0]?.currentValue : null}
                                  meter={currentMeter}
                                  currentPropertyId={currentMeter?.propertyId}
                              />
                          )
                        : null,
            }}
            idKey='id'
            totalCount={readingsList?.count}
            actionsColumn={[actionsColumn]}
            onPaginationModelChange={setPaginationModel}
        />
    )
}
