import { useTheme } from "@mui/material/styles"
import React, { useCallback, useMemo } from "react"
import { DESKTOP_ICON_SIZE } from "../../../../../utils/helpers"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import { Box } from "@mui/material"
import { DetailsSheet, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { renderActionSheetInfo } from "../../../../../guards/alertsGuard/utils"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { useUserPropertiesQuery } from "../../../../../queries/userData"
import { usePropertyOwnerDetailsQuery } from "../../../../../queries/property-owners"

export default function PropertyOwnerPropertyDetailsSheetGuard() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: propertyOwnerDetails } = usePropertyOwnerDetailsQuery(editingItem?.propertyOwnerId)
    const property = useMemo(() => {
        return userProperties?.find((p) => p.id === editingItem?.propertyId)
    }, [userProperties, editingItem])
    const propertyOwnership = useMemo(() => {
        return propertyOwnerDetails?.properties.find((p) => p.propertyId === property?.id)
    }, [propertyOwnerDetails, editingItem])

    const handleClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const handleOpenPercentageSheet = useCallback(() => {
        if (property && propertyOwnership && editingItem.propertyOwnerId) {
            dispatch(setModalOpen(AppModals.PropertyOwnershipPercentageDetails))
            dispatch(
                setEditingItem({
                    propertyId: property.id,
                    propertyOwnerId: editingItem.propertyOwnerId,
                    currentPercentage: propertyOwnership.ownershipPercentage,
                    propertyName: property.label,
                }),
            )
        }
    }, [dispatch, property, editingItem, propertyOwnership])

    const handleDelete = useCallback(() => {
        if (property && editingItem.propertyOwnerId) {
            dispatch(setModalOpen(AppModals.DeletePropertyFromPropertyOwner))
            dispatch(
                setEditingItem({
                    id: editingItem.propertyOwnerId,
                    propertyId: property.id,
                }),
            )
        }
    }, [dispatch, property, editingItem])

    // Define options for the details sheet with the actions
    const options: CustomSheetOption[] = useMemo(
        () => [
            {
                icon: <EditIcon color='primary' sx={{ fontSize: DESKTOP_ICON_SIZE }} />,
                title: "edit_percentage",
                onClick: handleOpenPercentageSheet,
            },
            {
                icon: <DeleteIcon color='error' sx={{ fontSize: DESKTOP_ICON_SIZE }} />,
                title: "delete",
                onClick: handleDelete,
            },
        ],
        [handleDelete, handleOpenPercentageSheet],
    )

    if (!property || !propertyOwnership) return null

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.PropertyOwnerPropertyDetails}
            onClose={handleClose}
            isLoading={false}
            title={property.label}
            subtitle={property.internalId}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:percent",
                    `${translate("ownership_percentage")}: ${propertyOwnership.ownershipPercentage}%`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
