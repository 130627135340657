import { useQueryClient } from "@tanstack/react-query"
import { useCallback } from "react"

export enum SimpleQueryIDs {
    Currencies = "currencies",
    Units = "units",
    Counties = "counties",
    UserData = "userData",
    UserLabels = "userLabels",
    UserProperties = "user-properties",
    TenantRentingPeriods = "tenant-renting-periods",
    TenantRequests = "tenants-request",
    UserSubscription = "user-subscription",
    LoginAction = "login-action",
    ReloginAction = "relogin-action",
    BillingDetails = "billing-details",
    PromoCode = "promo-code",
    Contracts = "user-contracts",
    HiddenFeatures = "hidden-features",
    RecurringExpense = "recurring-expense",
    PendingExpense = "pending-expense",
    ManagerRequest = "manager-request",
    ExpenseProviders = "expense-providers",
    MetersProviders = "meters-providers",
    Invoices = "invoices",
    RentingPeriodSummaryData = "renting-period-summary-data",
    ExpensesNumber = "expenses-number",
    IncomesNumber = "incomes-number",
    OblioCompanies = "oblio-companies",
    UserProviders = "user-providers",
    UserTaskSections = "user-sections",
    UserInvoicingConfigurations = "user-invoicing-configurations",
    UserInvoicingCompanies = "user-invoicing-companies",
    AvailableSummaryCards = "available-summary-cards",
    AvailableCharts = "available-charts",
    PropertyOwners = "property-owners",
    UserNotifications = "user-notifications",
    Groups = "groups",
    NotificationsTemplates = "notifications-templates",
    GeneralDocuments = "general-documents",
    SoonToBeExpiredTenants = "soon-to-be-expired",
    CardPaymentDetails = "card-payment-details",
    ProvidersWithoutExpenseThisMonth = "providers-without-expense-this-month",
}

export enum ComplexQueryIDs {
    Tenants = "tenants",
    TenantsPagination = "tenants-pagination",
    Property = "property",
    PropertyOwnerDetails = "property-owner-details",
    Insurances = "insurances",
    Expenses = "expenses",
    Incomes = "incomes",
    Meters = "property-meters",
    MetersValuesHistory = "property-meters-values-history",
    SummaryCard = "summary-card",
    DashboardChart = "dashboard-chart",
    DashboardTable = "dashboard-table",
    OwnerPropertyFiles = "owner-property-files",
    TenantPropertyFiles = "tenant-property-files",
    CountyCities = "county-cities",
    CityAreas = "city-areas",
    ContractTemplates = "contract-templates",
    EntityFiles = "entity-files",
    RentingPeriodDetails = "renting-period-details",
    Notes = "notes",
    Events = "events",
    RecurringExpenses = "recurring-expenses",
    ExpenseIncomes = "expense-incomes",
    IncomeExpenses = "income-expenses",
    ActiveUsers = "active-users",
    ConfiguredExpenseProviders = "configured-expense-providers",
    Expense = "expense",
    Insurance = "insurance",
    Income = "income",
    ExpenseSummary = "expense-summary",
    IncomeSummary = "income-summary",
    ExpenseSummaryReport = "expense-summary-report",
    IncomeSummaryReport = "income-summary-report",
    Search = "search",
    PendingMeterValues = "pending-meter-values",
    SectionTasks = "section-tasks",
    TaskComments = "task-comments",
    TaskPossibleUsers = "task-possible-users",
    TenantTasks = "tenant-tasks",
    TenantTaskComments = "tenant-tasks-comments",
    InvoicingConfigurationCompanies = "invoicing-configuration-companies",
    InvoicingConfigurationUsers = "invoicing-configuration-users",
    InvoicingConfigurationSeries = "invoicing-configuration-series",
    InvoicingConfigurationClients = "invoicing-configuration-clients",
    InvoicingConfigurationVATs = "invoicing-configuration-vat",
    DryRunInvoices = "dry-run-invoices",
    QueuedInvoiceStatus = "queued-invoice-status",
    Journal = "journal",
    CurrencyExchange = "currency-exchange",
    ProviderDetails = "provider-details",
    Revisions = "revisions",
    TenantRevisions = "tenant-revisions",
    NotificationDetails = "notification-details",
    GeneralDocumentDetails = "general-document-details",
    RentingPeriodNotificationPreview = "renting-period-notification-preview",
    GroupSummaryCards = "group-summary-cards",
    GroupSummaryCardDetails = "group-summary-card-details",
    RentingPeriod = "renting-period",
    BankAccounts = "bank-accounts",
    BankAccountConsents = "bank-account-consents",
    ConsentBankAccounts = "consent-bank-accounts",
    GroupRentingPeriods = "group-renting-periods",
    ActiveUser = "active-user",
    PropertyRentingPeriods = "property-renting-periods",
    RentingPeriodsReport = "renting-periods-report",
    PropertyAvailability = "property-availability",
    RevisionObservations = "revision-observations",
    TenantRevisionObservations = "tenant-revision-observations",
    PropertyPhotos = "property-photos",
    BankStatements = "bank-statements",
    BankStatementTransactionDetails = "bank-statement-transaction-details",
    BankStatementTransactionPossibleExpenses = "bank-statement-transaction-possible-expenses",
    GroupAccess = "group-access",
    UserInvoicingClients = "user-invoicing-clients",
    ExpenseLateFees = "expense-late-fees",
    GroupDashboardConfig = "group-dashboard-config",
    PublicDashboardConfiguration = "public-dashboard-configuration",
    PossiblePropertyManagers = "possible-property-managers",
    InventorySections = "inventory-sections",
    InventorySectionItems = "inventory-section-items",
    SignatureRequests = "signature-requests",
    RentingPeriodInventoryProcesses = "renting-period-inventory-processes",
    RentingPeriodInventoryProcessSectionItems = "renting-period-inventory-process-section-items",
    SignCodeDetails = "sign-code-details",
}

interface ComplexQueryInvalidateRequest {
    query: ComplexQueryIDs
    params: unknown
}

export const useQueryInvalidator = () => {
    const queryClient = useQueryClient()

    const invalidateQueries = useCallback(
        async (ids: (SimpleQueryIDs | ComplexQueryInvalidateRequest | ComplexQueryIDs)[], removeData = false) => {
            const expensesInvalidationPromises = ids.map((id) => {
                if (Object.keys(id).includes("params")) {
                    const casted = id as ComplexQueryInvalidateRequest
                    if (removeData) queryClient.removeQueries({ queryKey: [casted.query, { ...(casted.params as any) }] })
                    else return queryClient.invalidateQueries({ queryKey: [casted.query, { ...(casted.params as any) }] })
                } else {
                    if (removeData) queryClient.removeQueries({ queryKey: [id] })
                    else return queryClient.invalidateQueries({ queryKey: [id] })
                }
            })

            await Promise.all(expensesInvalidationPromises)
        },
        [queryClient],
    )

    return { invalidateQueries }
}
