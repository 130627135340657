import { CustomFile, FormProvider, RHFDatePicker, RHFSelect, RHFTextField, RHFUnitInput, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useEffect } from "react"
import { Box, Button, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { AddInventorySectionItemSchema } from "../../../validations/inventory"
import { InventoryItem, InventoryItemStatus } from "../../../redux/slices/AddProperty"
import { DateTime } from "luxon"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { useUserDataQueryFn } from "../../../queries/userData"

export interface AddInventorySectionItemRequest {
    itemName: string
    id?: number
    itemDescription: string
    status: InventoryItemStatus
    purchaseDate: string
    files: CustomFile[]
    currencyId: number
    purchaseValue?: number
}

interface Props {
    onClose: () => void
    onSave: (data: AddInventorySectionItemRequest) => void
    isLoading: boolean
    defaultValues?: InventoryItem
}

export default function AddOrEditInventorySectionItemForm({ onClose, onSave, isLoading, defaultValues }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { data: currencyData } = useCurrencyQueryFn()
    const { data: userData } = useUserDataQueryFn()

    const methods = useForm<AddInventorySectionItemRequest>({
        resolver: yupResolver<AddInventorySectionItemRequest>(AddInventorySectionItemSchema),
        mode: "onChange",
    })

    const { handleSubmit, reset } = methods

    useEffect(() => {
        if (defaultValues) {
            reset({
                ...defaultValues,
                purchaseDate: defaultValues.purchaseDate.toISODate() ?? undefined,
                files: [],
                currencyId: defaultValues?.currencyId ?? userData?.currency.id,
            })
        }

        if (defaultValues == null) {
            reset({
                purchaseDate: DateTime.now().toISODate(),
                files: [],
                status: InventoryItemStatus.Used,
                currencyId: userData?.currency.id,
            })
        }
    }, [defaultValues, reset, userData])

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                        <RHFTextField
                            name={"itemName"}
                            label={translate("inventory_item_name")}
                            placeholder={translate("inventory_section_item_name_placeholder")}
                            size={"small"}
                            required
                        />
                        <RHFSelect name='status' label={translate("inventory_item_status")} required>
                            {Object.keys(InventoryItemStatus)
                                .filter((key) => Number(key) || key === "0")
                                .map((status) => (
                                    <MenuItem key={status} value={status}>
                                        {translate(`inventory-item-status-${status}`)}
                                    </MenuItem>
                                ))}
                        </RHFSelect>
                    </Box>
                    <Box display={"flex"} flexDirection={isMobile ? "column" : "row"} gap={2}>
                        <RHFUnitInput
                            label={translate("purchase_price")}
                            unitName={"currencyId"}
                            unitType={"currency"}
                            name={"purchaseValue"}
                            data={currencyData ?? []}
                            size={"small"}
                        />
                        <RHFDatePicker name='purchaseDate' label={translate("purchase_date")} required />
                    </Box>
                    <RHFTextField
                        name={"itemDescription"}
                        label={translate("inventory_item_description")}
                        placeholder={translate("inventory_section_item_description_placeholder")}
                        size={"small"}
                        multiline={true}
                        rows={3}
                        required
                    />
                    {defaultValues == null && <RHFUpload name={"files"} icon={"mdi:file-document-plus-outline"} multiple={true} />}

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
