import { useTheme } from "@mui/material/styles"
import React, { useCallback, useMemo } from "react"
import { DESKTOP_ICON_SIZE } from "../../../../../utils/helpers"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { Box } from "@mui/material"
import { DefaultCountry, DetailsSheet, LoadingIcon, PersonType, useCitiesQuery, useCountiesQuery, useLocales, useStreetsQuery } from "rentzz"
import { usePropertyOwnerDetailsQuery } from "../../../../../queries/property-owners"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { renderActionSheetInfo } from "../../../../../guards/alertsGuard/utils"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { PropertyFileType } from "../../../../../redux/slices/AddProperty"
import Api from "../../../../../api/Api"
import { AccountType, useUserDataQueryFn } from "../../../../../queries/userData"

export default function RepresentativeDetailsSheetGuard() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: propertyOwnerDetails, isLoading } = usePropertyOwnerDetailsQuery(editingItem?.id)
    const { data: userData } = useUserDataQueryFn()
    const { data: counties } = useCountiesQuery(Api)
    // Use representativeId from editingItem
    const representativeId = editingItem?.representativeId as string | undefined

    // Find the representative by ID - memoized to prevent unnecessary re-renders
    const representative = useMemo(() => {
        if (!representativeId || !propertyOwnerDetails?.representatives) return undefined
        return propertyOwnerDetails.representatives.find((rep) => rep.id === representativeId)
    }, [representativeId, propertyOwnerDetails])
    const { data: cities } = useCitiesQuery(Api, representative?.county, representative?.country)
    const { data: streets } = useStreetsQuery(Api, representative?.city, representative?.country)

    const handleClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const handleEdit = useCallback(() => {
        if (representativeId && editingItem?.id) {
            dispatch(setModalOpen(AppModals.AddPropertyOwnerRepresentative))
            dispatch(
                setEditingItem({
                    id: editingItem.id,
                    representativeId: representativeId,
                    isEditing: true,
                }),
            )
        }
    }, [dispatch, representativeId, editingItem])

    const handleDelete = useCallback(() => {
        if (representativeId && editingItem?.id) {
            dispatch(setModalOpen(AppModals.DeletePropertyOwnerRepresentative))
            dispatch(
                setEditingItem({
                    id: editingItem.id,
                    representativeId: representativeId,
                }),
            )
        }
    }, [dispatch, representativeId, editingItem])

    const handleOpenFiles = useCallback(() => {
        if (representative) {
            dispatch(
                setEditingItem({
                    id: representative.id,
                    type: PropertyFileType.Representative,
                    entityId: representative.id,
                    title: `${representative.firstName} ${representative.lastName}`,
                    canWrite: userData?.accountType === AccountType.WHITELABEL_ADMIN_USER,
                    canDelete: userData?.accountType === AccountType.WHITELABEL_ADMIN_USER,
                }),
            )
            dispatch(setModalOpen(AppModals.EntityFileManager))
        }
    }, [dispatch, representative, userData])

    // Define options for the details sheet with the actions - memoized to prevent recreation on each render
    const options = useMemo<CustomSheetOption[]>(
        () => [
            {
                icon: <EditIcon color='primary' sx={{ fontSize: DESKTOP_ICON_SIZE }} />,
                title: "edit",
                onClick: handleEdit,
            },
            {
                icon: <CloudDownloadIcon color='info' sx={{ fontSize: DESKTOP_ICON_SIZE }} />,
                title: "files",
                onClick: handleOpenFiles,
            },
            {
                icon: <DeleteIcon color='error' sx={{ fontSize: DESKTOP_ICON_SIZE }} />,
                title: "delete",
                onClick: handleDelete,
            },
        ],
        [handleEdit, handleOpenFiles, handleDelete],
    )

    const formattedAddress = useMemo(() => {
        if (!representative) return ""

        const addressParts = [
            representative.country === DefaultCountry
                ? (streets?.find((s) => s.id.toString() == representative.street)?.name ?? "")
                : representative.street,
            representative.streetNumber,
            representative.buildingNumber && `${translate("building")} ${representative.buildingNumber}`,
            representative.stair && `${translate("stair")} ${representative.stair}`,
            representative.floor && `${translate("floor")} ${representative.floor}`,
            representative.apartment && `${translate("apt")} ${representative.apartment}`,
            representative.postalCode && `${translate("postal_code")}: ${representative.postalCode}`,
        ].filter(Boolean)

        return addressParts.join(", ")
    }, [representative, translate, streets])

    if (isLoading) return <LoadingIcon />
    if (!representative) return null

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.RepresentativeDetails}
            onClose={handleClose}
            isLoading={isLoading}
            title={`${representative.firstName} ${representative.lastName}`}
            subtitle={translate(representative.personType === PersonType.Natural ? "individual" : "company")}
            options={options}
            headerColor={theme.palette.primary.main}
        >
            <Box p={1}>
                {representative.personType === PersonType.Company &&
                    representative.companyName &&
                    renderActionSheetInfo(
                        "mdi:domain",
                        `${translate("companyName")}: ${representative.companyName}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {representative.identifier &&
                    renderActionSheetInfo(
                        "mdi:id-card",
                        `${translate(representative.personType === PersonType.Company ? "companyRegistrationNumber" : "cnp")}: ${representative.identifier}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}

                {/* Contact info section */}
                {representative.email &&
                    renderActionSheetInfo(
                        "mdi:email",
                        `${translate("email")}: ${representative.email}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {representative.phoneNumber &&
                    renderActionSheetInfo(
                        "mdi:phone",
                        `${translate("phone")}: ${representative.phoneNumber}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {representative.faxNumber &&
                    renderActionSheetInfo(
                        "mdi:fax",
                        `${translate("property_owners.representatives.fax")}: ${representative.faxNumber}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}

                {/* Location section */}
                {representative.country &&
                    renderActionSheetInfo(
                        "mdi:map-marker",
                        `${translate("country")}: ${representative.country}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {representative.county &&
                    renderActionSheetInfo(
                        "mdi:map",
                        `${translate("county")}: ${representative.country === DefaultCountry ? counties?.find((c) => c.id.toString() == representative.county)?.name : representative.county}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {representative.city &&
                    renderActionSheetInfo(
                        "mdi:city",
                        `${translate("city")}: ${representative.country === DefaultCountry ? cities?.find((c) => c.id.toString() == representative.city)?.name : representative.city}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {(representative.street || representative.streetNumber) &&
                    renderActionSheetInfo(
                        "mdi:road-variant",
                        `${translate("address")}: ${formattedAddress}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}

                {/* Representation period section */}
                {renderActionSheetInfo(
                    "mdi:calendar-range",
                    `${translate("period")}: ${representative.startDate.toFormat("dd.MM.yyyy")} - ${representative.endDate.toFormat("dd.MM.yyyy")}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

                {/* ID details for natural persons */}
                {representative.personType === PersonType.Natural &&
                    representative.identificationDocumentSeries &&
                    renderActionSheetInfo(
                        "mdi:card-account-details",
                        `${translate("property_owners.representatives.ci_details")}: ${representative.identificationDocumentSeries} ${representative.identificationDocumentNumber}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {representative.personType === PersonType.Natural &&
                    representative.identificationDocumentIssuer &&
                    renderActionSheetInfo(
                        "mdi:card-bulleted",
                        `${translate("property_owners.representatives.ci_issuer")}: ${representative.identificationDocumentIssuer}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {representative.personType === PersonType.Natural &&
                    representative.identificationDocumentIssuedDate &&
                    renderActionSheetInfo(
                        "mdi:calendar-check",
                        `${translate("property_owners.representatives.ci_issued_date")}: ${representative.identificationDocumentIssuedDate.toFormat("dd.MM.yyyy")}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}

                {/* System metadata section */}
                {renderActionSheetInfo(
                    "mdi:calendar-clock",
                    `${translate("createdAt")}: ${representative.createdAt.toFormat("dd.MM.yyyy")}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-edit",
                    `${translate("lastModifiedAt")}: ${representative.lastModifiedAt.toFormat("dd.MM.yyyy")}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
