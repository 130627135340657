import React from "react"
import { useDispatch, useSelector } from "../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { AppModals, setModalOpen } from "../redux/slices/App"
import { Box } from "@mui/material"
import CustomPDFViewer from "./CustomPDFViewer"

export default function PDFViewer() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { editingItem, modalOpen } = useSelector((state) => state.appState)
    const isMobile = useIsMobile()

    return (
        <>
            <CustomDialog
                open={!isMobile && modalOpen === AppModals.PdfViewer}
                maxWidth={"md"}
                title={""}
                isFullScreen={false}
                onClose={() => {
                    dispatch(setModalOpen(editingItem?.previousModal))
                }}
            >
                <Box height={750}>
                    <CustomPDFViewer url={editingItem?.url} fileName={editingItem?.fileName} height={750} width={"100%"} />
                </Box>
            </CustomDialog>
            <DetailsSheet
                isLoading={false}
                title={translate("preview")}
                isOpen={isMobile && modalOpen === AppModals.PdfViewer}
                onClose={() => dispatch(setModalOpen(editingItem?.previousModal))}
            >
                <Box height={"75vh"}>
                    <CustomPDFViewer url={editingItem?.url} fileName={editingItem?.fileName} height={"75vh"} width={"100%"} />
                </Box>
            </DetailsSheet>
        </>
    )
}
