import { DetailsSheet, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useCallback, useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box, Typography } from "@mui/material"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import { getInventorySectionByIdQueryFn, getInventorySectionItemByIdQueryFn } from "../../../queries/inventory"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { PropertyFileType } from "../../../redux/slices/AddProperty"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"

export default function MobileInventorySectionItemDetailsSheet() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: currencyData } = useCurrencyQueryFn()
    const intl = useIntl()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const inventoryItem = getInventorySectionItemByIdQueryFn(editingItem?.inventorySectionId, editingItem?.inventoryItemId)
    const inventorySection = getInventorySectionByIdQueryFn(editingItem?.inventorySectionId)
    const getAmount = useCallback(
        (value: number, currencyId?: number) => {
            if (currencyData == null || currencyId == null) return ""

            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData],
    )

    const options = useMemo((): CustomSheetOption[] => {
        return [
            {
                icon: <EditIcon color={"primary"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.edit",
                onClick: () => {
                    dispatch(setModalOpen(AppModals.EditInventorySectionItem))
                },
            },
            {
                icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            ...editingItem,
                            id: inventoryItem?.id,
                            type: PropertyFileType.InventoryItem,
                            name: inventoryItem?.itemName,
                            previousModal: AppModals.InventorySectionItemDetails,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !inventoryItem?.canDelete,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteInventorySectionItem))
                },
            },
        ]
    }, [inventoryItem, dispatch, context, inventorySection])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.InventorySectionItemDetails}
            onClose={() => {
                dispatch(setModalOpen(AppModals.InventorySectionItemList))
            }}
            isLoading={false}
            title={inventoryItem?.itemName}
            options={options}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:info",
                    `${translate("inventory_item_name")}: ${inventoryItem?.itemName}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:info",
                    <Typography variant='caption' sx={{ whiteSpace: "pre-wrap" }}>
                        {translate("inventory_item_description")}: {inventoryItem?.itemDescription}
                    </Typography>,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {inventoryItem?.purchaseValue != null &&
                    renderActionSheetInfo(
                        "mdi:cash",
                        `${getAmount(inventoryItem?.purchaseValue ?? 0, inventoryItem?.currencyId)}`,
                        theme.palette.primary.main,
                        theme.palette.grey["600"],
                    )}
                {renderActionSheetInfo(
                    "mdi:info",
                    `${translate("inventory_item_status")}: ${translate(`inventory-item-status-${inventoryItem?.status}`)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:calendar-edit-outline",
                    `${translate("purchase_date")}: ${inventoryItem?.purchaseDate?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:user",
                    `${translate("createdBy")}: ${inventoryItem?.createdByUser}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
