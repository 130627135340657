import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import AddOrEditNewLabel from "../../../../../sections/nomenclature/labels/AddOrEditNewLabel"
import React, { useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { useUserLabelsQuery } from "../../../../../queries/userData"
import { useTheme } from "@mui/material/styles"

export default function AddOrEditLabelGuard() {
    const theme = useTheme()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: labels } = useUserLabelsQuery("all")

    const currentLabel = useMemo(() => labels?.find((l) => l.id === editingItem?.id), [labels, editingItem])

    const isDialogVisible = useMemo(() => {
        if (!isMobile && (modalOpen === AppModals.AddLabel || modalOpen === AppModals.EditLabel)) return true
        return false
    }, [isMobile, modalOpen])

    const handleClose = useCallback(() => {
        if (isMobile) {
            if (modalOpen === AppModals.EditLabel) {
                dispatch(setModalOpen(AppModals.LabelDetails))
            }
            if (modalOpen === AppModals.AddLabel) {
                dispatch(setModalOpen(undefined))
            }
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [modalOpen, dispatch])

    return (
        <>
            <CustomDialog
                isFullScreen={false}
                open={isDialogVisible}
                onClose={handleClose}
                maxWidth={"xs"}
                title={translate(currentLabel ? "editLabel" : "addLabel")}
            >
                <AddOrEditNewLabel onPopUpClose={handleClose} defaultValues={currentLabel} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && (modalOpen === AppModals.AddLabel || modalOpen === AppModals.EditLabel)}
                onClose={handleClose}
                isLoading={false}
                title={translate(currentLabel ? "editLabel" : "addLabel")}
                options={[]}
                headerColor={currentLabel ? `#${currentLabel?.color}` : theme.palette.primary.main}
            >
                <AddOrEditNewLabel onPopUpClose={handleClose} defaultValues={currentLabel} />
            </DetailsSheet>
        </>
    )
}
