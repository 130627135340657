import { useIsMobile, useLocales } from "rentzz"
import React, { useEffect, useMemo, useState } from "react"
import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import { useRentingPeriodInventoryProcessesQueryFn } from "../../../../../queries/inventory"
import { useSelector } from "../../../../../redux/store"
import InventorySectionStep from "./InventorySectionStep"

interface Props {
    onFinish: () => void
}

export default function FillInventoryProcessForm({ onFinish }: Props) {
    const isMobile = useIsMobile()
    const { editingItem } = useSelector((state) => state.appState)
    const { data: allRentingPeriodProcesses } = useRentingPeriodInventoryProcessesQueryFn()
    const ourProcess = useMemo(() => {
        return allRentingPeriodProcesses?.find((i) => i.id === editingItem?.id)
    }, [allRentingPeriodProcesses, editingItem])
    const [activeStep, setActiveStep] = useState<{ id: number; index: number } | undefined>()
    const { translate } = useLocales()

    useEffect(() => {
        if (ourProcess) {
            const firstSectionWithoutAllItemsFiles =
                ourProcess.sections.findIndex((i) => i.isHandled !== true) === -1
                    ? ourProcess.sections.length
                    : ourProcess.sections.findIndex((i) => i.isHandled !== true)
            setActiveStep({ id: ourProcess.sections[firstSectionWithoutAllItemsFiles]?.id ?? -1, index: firstSectionWithoutAllItemsFiles })
        }
    }, [ourProcess])

    return (
        <>
            <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                <Stepper activeStep={activeStep?.index} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                    {[...(ourProcess?.sections ?? []), { id: -1, sectionName: translate("inventory_process_summary"), sections: [] }].map(
                        (step, index) => {
                            return (
                                <Step key={step.id} completed={index < (activeStep?.index ?? 0) && step.id !== -1}>
                                    <StepLabel>{step.sectionName}</StepLabel>
                                    {isMobile && (
                                        <StepContent>
                                            <InventorySectionStep
                                                isFirstSection={index === 0}
                                                onPrevious={() =>
                                                    setActiveStep({
                                                        id: ourProcess?.sections?.at(index - 1)?.id ?? 0,
                                                        index: index - 1,
                                                    })
                                                }
                                                onNext={() => {
                                                    if (index === (ourProcess?.sections?.length ?? 0) + 1) onFinish()
                                                    else if (activeStep?.index === (ourProcess?.sections?.length ?? 0) - 1) {
                                                        setActiveStep({
                                                            id: -1,
                                                            index: ourProcess?.sections.length ?? 0,
                                                        })
                                                    } else {
                                                        setActiveStep({
                                                            id: ourProcess?.sections?.at(index + 1)?.id ?? 0,
                                                            index: index + 1,
                                                        })
                                                    }
                                                }}
                                                id={step.id}
                                            />
                                        </StepContent>
                                    )}
                                </Step>
                            )
                        },
                    )}
                </Stepper>
                {!isMobile && activeStep && ourProcess && (
                    <InventorySectionStep
                        isFirstSection={activeStep.index === 0}
                        onPrevious={() =>
                            setActiveStep({
                                id: ourProcess?.sections?.at(activeStep.index - 1)?.id ?? 0,
                                index: activeStep.index - 1,
                            })
                        }
                        onNext={() => {
                            if (activeStep?.index === ourProcess?.sections?.length + 1) onFinish()
                            else if (activeStep?.index === ourProcess?.sections?.length - 1) {
                                setActiveStep({
                                    id: -1,
                                    index: ourProcess?.sections.length,
                                })
                            } else {
                                setActiveStep({
                                    id: ourProcess?.sections?.at(activeStep.index + 1)?.id ?? 0,
                                    index: activeStep.index + 1,
                                })
                            }
                        }}
                        id={activeStep?.id}
                    />
                )}
            </Box>
        </>
    )
}
