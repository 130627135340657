import { useInfiniteQuery, useQuery } from "@tanstack/react-query"
import Api from "../api/Api"
import { ComplexQueryIDs, SimpleQueryIDs } from "../hooks/useQueryInvalidator"
import { DateTime } from "luxon"
import { AppContext } from "rentzz"
import { useSelector } from "../redux/store"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "./userData"
import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { sum } from "lodash"

export enum InvoiceProvider {
    Oblio,
    Smartbill,
    Factureaza,
}

export interface InvoicingConfigurationBase {
    id: number
    invoiceProvider: InvoiceProvider
    user: string
    password: string
}

export interface InvoicingConfigurationDto extends InvoicingConfigurationBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfiguration extends InvoicingConfigurationBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export enum AccessType {
    CREATOR,
    READ_WRITE,
}

export interface InvoicingConfigurationUserBase {
    id: number
    fullName: string
    accessType: AccessType
    email: string
    userId: string
}

export interface InvoicingConfigurationUserDto extends InvoicingConfigurationUserBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfigurationUser extends InvoicingConfigurationUserBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface InvoicingConfigurationCompanyBase {
    id: number
    cif: string
    name: string
    isActive: boolean
    invoicingConfigurationId: number
    isVatPayer: boolean
}

export interface InvoicingConfigurationCompanyDto extends InvoicingConfigurationCompanyBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfigurationCompany extends InvoicingConfigurationCompanyBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface InvoicingConfigurationClientBase {
    id: number
    name: string
    invoicingCompanyName: string
    cif: string
    isFromExternalSystem: boolean
    invoicingCompanyId: number
    isTaxPayer: boolean
    address?: string
    city?: string
    county?: string
    country?: string
    clientCode?: string
    regCom?: string
    contact?: string
    phone?: string
    email?: string
    bank?: string
    iban?: string
}

export interface InvoicingConfigurationClientDto extends InvoicingConfigurationClientBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfigurationClient extends InvoicingConfigurationClientBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface InvoicingConfigurationSeriesBase {
    id: number
    name: string
}

export interface InvoicingConfigurationSeriesDto extends InvoicingConfigurationSeriesBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfigurationSeries extends InvoicingConfigurationSeriesBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}

export interface InvoicingConfigurationVAT {
    id: number
    name: string
    percent: number
}

export interface InvoicingConfigurationClientBase {
    id: number
    name: string
}

export interface InvoicingConfigurationClientDto extends InvoicingConfigurationClientBase {
    createdAt: string
    lastModifiedAt: string
}

export interface InvoicingConfigurationClient extends InvoicingConfigurationClientBase {
    createdAt: DateTime
    lastModifiedAt: DateTime
}
export enum GeneratedStatus {
    OK,
    NotConfigured,
    SkippedDueToDeactivation,
}

export interface InvoicingDryRunBase {
    propertyName: string
    clientName: string
    value?: number
    currencyId?: number
    propertyId: number
    rentingPeriodId: number
    isInvoicingOn: boolean
    productName: string
    productDescription: string
    status: GeneratedStatus
}

export interface InvoicingDryRunDto extends InvoicingDryRunBase {
    date?: string
    dueDate?: string
    rentingPeriodStartDate: string
    rentingPeriodEndDate: string
}
export interface InvoicingDryRun extends InvoicingDryRunBase {
    date?: DateTime
    dueDate?: DateTime
    rentingPeriodStartDate: DateTime
    rentingPeriodEndDate: DateTime
}

export interface PendingInvoiceStatusBase {
    status: InvoiceStatus
    downloadUrl?: string
    seriesName?: string
    invoiceNumber?: string
}

export interface PendingInvoiceStatusDto extends PendingInvoiceStatusBase {
    invoiceDate: string
}

export interface PendingInvoiceStatusResponse extends PendingInvoiceStatusBase {
    invoiceDate: DateTime
}

export enum InvoiceStatus {
    Pending,
    Failed,
    Success,
    SPVFailed,
}

export const useAllInvoicingConfigurationsQuery = () => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchUserInvoicingConfigurations,
        queryKey: [SimpleQueryIDs.UserInvoicingConfigurations],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn,
    })
}

export const useInvoicingConfigurationsCompaniesQuery = (id: number) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchUserInvoicingConfigurationCompanies,
        queryKey: [ComplexQueryIDs.InvoicingConfigurationCompanies, { id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn,
    })
}

export const useInvoicingConfigurationsUsersQuery = (id: number) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchUserInvoicingConfigurationUsers,
        queryKey: [ComplexQueryIDs.InvoicingConfigurationUsers, { id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn,
    })
}

export const useAllUserInvoicingCompanies = () => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchAllUserInvoicingCompanies,
        queryKey: [SimpleQueryIDs.UserInvoicingCompanies],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn,
    })
}

export const useAllUserInvoicingClients = (page: number, pageSize: number, sortingColumns: GridSortModel, filters: GridFilterModel) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchAllUserInvoicingClients,
        queryKey: [
            ComplexQueryIDs.UserInvoicingClients,
            {
                page,
                pageSize,
                sortingColumns,
                filters,
            },
        ],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn,
        placeholderData: (prevQuery) => {
            if (prevQuery == null)
                return {
                    items: [],
                    count: 0,
                }

            return {
                items: prevQuery.items,
                count: prevQuery.count,
            }
        },
    })
}

export const useInfiniteInvoicingClientsQueryFn = (sortingColumns?: GridSortModel, filters?: GridFilterModel) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.UserInvoicingClients, { sortingColumns, filters }],
        queryFn: Api.fetchInfiniteInvoicingClients,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn,
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}

export const getInvoicingClientByIdQueryFn = (invoicingClientId?: number) => {
    return useQuery({
        queryFn: Api.fetchInvoicingClientById,
        queryKey: [
            ComplexQueryIDs.UserInvoicingClients,
            {
                invoicingClientId,
            },
        ],
        staleTime: Infinity,
        enabled: !!invoicingClientId,
    })
}

export const useCompanySeries = (id?: number) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchCompanySeries,
        queryKey: [ComplexQueryIDs.InvoicingConfigurationSeries, { id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn && !!id,
    })
}

export const useCompanyVATs = (id?: number) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchCompanyVATs,
        queryKey: [ComplexQueryIDs.InvoicingConfigurationVATs, { id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn && !!id,
    })
}

export const useCompanyClients = (id?: number) => {
    const { context } = useSelector((state) => state.appState)
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())

    return useQuery({
        queryFn: Api.fetchCompanyClients,
        queryKey: [ComplexQueryIDs.InvoicingConfigurationClients, { id }],
        staleTime: Infinity,
        enabled: context === AppContext.Owner && areInvoicingConfigurationOn && !!id,
    })
}

export const useInvoicingDryRunInvoices = (companyId?: number) => {
    const isDryRunActive = useFeatureIsOn(UserFlags.DryRunInvoicing.toString())

    return useQuery({
        queryFn: Api.fetchDryRunInvoices,
        queryKey: [ComplexQueryIDs.DryRunInvoices, { id: companyId }],
        staleTime: Infinity,
        enabled: isDryRunActive && !!companyId,
    })
}

export const useQueuedInvoiceStatus = (queryId?: string) => {
    return useQuery({
        queryFn: Api.fetchPendingInvoiceStatus,
        queryKey: [ComplexQueryIDs.QueuedInvoiceStatus, { id: queryId }],
        staleTime: Infinity,
        enabled: !!queryId,
        refetchInterval: (query) => {
            const lastData = query.state.data
            if (lastData == null) return 5000
            if (lastData.status !== InvoiceStatus.Pending) return false

            return 5000
        },
    })
}
