import { Box, CircularProgress, MenuItem, Paper, Select, Stack, Typography } from "@mui/material"
import { PropertyAutoAcceptType, UserFlags } from "../../../queries/userData"
import ManagersTable from "../General/management/managers/ManagersTable"
import { useTheme } from "@mui/material/styles"
import { Page, useLocales } from "rentzz"
import { useSelector } from "../../../redux/store"
import { usePropertyDetailsQueryFn } from "../../../queries"
import { updatePropertyDetailsMutation } from "../../../mutations"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useCallback } from "react"
import { SelectChangeEvent } from "@mui/material/Select/SelectInput"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function Configurations() {
    const theme = useTheme()
    const { translate } = useLocales()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const { data: currentProperty } = usePropertyDetailsQueryFn(currentPropertyId)
    const { mutate, isPending: isUpdating } = updatePropertyDetailsMutation(currentPropertyId)
    const { canWrite } = usePermissions(PermissionType.Properties)
    const isOnlineExpensesOn = useFeatureIsOn(UserFlags.ExpenseOnlinePayment.toString())
    const isRedirectingUserToProviderPaymentPage = useFeatureIsOn(UserFlags.RedirectUserToProviderPaymentPage.toString())

    const onAutoAcceptChange = useCallback((ev: SelectChangeEvent<PropertyAutoAcceptType>) => {
        mutate({ operations: [{ path: "/paymentAutoAcceptType", op: "replace", value: ev.target.value }] })
    }, [])

    const onAcceptOnlinePaymentsChange = useCallback((ev: SelectChangeEvent<string>) => {
        mutate({ operations: [{ path: "/acceptOnlineCardPayments", op: "replace", value: ev.target.value }] })
    }, [])

    return (
        <Page title={translate("configurations")} propertyName={currentProperty?.name} paddingTop={1} paddingBottom={2}>
            <Box display={"flex"} flexDirection={"column"} gap={2} paddingBottom={5}>
                <Paper elevation={3} square={false}>
                    <Stack direction='row' alignItems='center' justifyContent='space-between' p={2}>
                        <Typography variant='h6' sx={{ color: theme.palette.grey.A700 }}>
                            {translate("property-auto-accept-payment")}
                        </Typography>
                        {!isUpdating && (
                            <Select
                                value={currentProperty?.paymentAutoAcceptType}
                                onChange={onAutoAcceptChange}
                                size='small'
                                sx={{ color: theme.palette.grey.A700 }}
                                disabled={!canWrite}
                            >
                                <MenuItem value={PropertyAutoAcceptType.None} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate(`property-auto-accept-type-${PropertyAutoAcceptType.None}`)}
                                </MenuItem>
                                <MenuItem value={PropertyAutoAcceptType.OnlyWithFiles} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate(`property-auto-accept-type-${PropertyAutoAcceptType.OnlyWithFiles}`)}
                                </MenuItem>
                                <MenuItem value={PropertyAutoAcceptType.All} sx={{ color: theme.palette.grey.A700 }}>
                                    {translate(`property-auto-accept-type-${PropertyAutoAcceptType.All}`)}
                                </MenuItem>
                            </Select>
                        )}
                        {isUpdating && <CircularProgress size={"2rem"} />}
                    </Stack>
                </Paper>
                {isOnlineExpensesOn && !isRedirectingUserToProviderPaymentPage && (
                    <Paper elevation={3} square={false}>
                        <Stack direction='row' alignItems='center' justifyContent='space-between' p={2}>
                            <Typography variant='h6' sx={{ color: theme.palette.grey.A700 }}>
                                {translate("property-accept-utilities-card-payments")}
                            </Typography>
                            {!isUpdating && (
                                <Select
                                    value={`${currentProperty?.acceptOnlineCardPayments}`}
                                    onChange={onAcceptOnlinePaymentsChange}
                                    size='small'
                                    sx={{ color: theme.palette.grey.A700 }}
                                    disabled={!canWrite}
                                >
                                    <MenuItem value={"false"} sx={{ color: theme.palette.grey.A700 }}>
                                        {translate("no")}
                                    </MenuItem>
                                    <MenuItem value={"true"} sx={{ color: theme.palette.grey.A700 }}>
                                        {translate("yes")}
                                    </MenuItem>
                                </Select>
                            )}
                            {isUpdating && <CircularProgress size={"2rem"} />}
                        </Stack>
                    </Paper>
                )}

                <ManagersTable />
            </Box>
        </Page>
    )
}
