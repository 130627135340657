import { ComplexQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"

export const addBankAccountConsentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.createBankAccountConsent,
        onSettled: async (data, error) => {
            await invalidateQueries([ComplexQueryIDs.BankAccountConsents])
            if (data && !error) {
                window.open(data, "_blank")
            }
        },
    })
}

export const deleteBankAccountConsentMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: Api.deleteBankAccountConsent,
        onSettled: async () => {
            await invalidateQueries([ComplexQueryIDs.BankAccountConsents])
        },
    })
}
