import { Box, Typography } from "@mui/material"
import { alpha, useTheme } from "@mui/material/styles"
import React, { ReactNode } from "react"
import { Iconify, IKanbanTask } from "rentzz"
import { BankStatement } from "../queries/bank-statements/types"
import { GeneralDocument } from "../queries/generalDocuments"
import { GroupAccess, UserGroup } from "../queries/groups"
import { Journal } from "../queries/journal"
import { NotificationTemplate, UserBankAccount, UserBankAccountConsent } from "../queries/userData"
import {
    BankAccount,
    ConfiguredExpenseProvider,
    InventoryItem,
    InventoryProcess,
    InventorySection,
    PropertyIncome,
    PropertyRevision,
    RevisionObservation,
    SignatureRequest,
    TenantRevision,
} from "../redux/slices/AddProperty"
import CustomCard from "./CustomCard"

interface CustomMobileCardProps {
    item:
        | PropertyIncome
        | IKanbanTask
        | Journal
        | PropertyRevision
        | UserGroup
        | TenantRevision
        | { id: number; name: string; county: string | undefined; city: string | undefined }
        | UserBankAccount
        | NotificationTemplate
        | GeneralDocument
        | RevisionObservation
        | ConfiguredExpenseProvider
        | BankStatement
        | GroupAccess
        | InventorySection
        | InventoryItem
        | UserBankAccountConsent
        | InventoryProcess
        | SignatureRequest
        | BankAccount
    onClick?: (event: React.MouseEvent<HTMLElement>) => void
    icon: string
    iconColor: string
    title: string
    rightFirstItem?: ReactNode
    rightSecondItem?: string
    subtitle?: string | ReactNode
    onDelete?: () => void
    firstDate?: string
    secondDate?: string
}
export default function CustomMobileCard({
    item,
    onClick,
    icon,
    iconColor,
    title,
    subtitle,
    rightSecondItem,
    rightFirstItem,
    onDelete,
    firstDate,
    secondDate,
}: CustomMobileCardProps) {
    const theme = useTheme()

    return (
        <CustomCard key={"id" in item ? item.id : item.userId} onClick={onClick} isCardClickable={false} px={1} noHover={true}>
            {firstDate && (
                <Box display={"flex"} justifyContent={"space-between"} marginTop={-2} marginBottom={2}>
                    <Typography variant={"caption"} color={"text.secondary"}>
                        {firstDate}
                    </Typography>
                    <Typography variant={"caption"} color={"text.secondary"}>
                        {secondDate}
                    </Typography>
                </Box>
            )}

            {onDelete && (
                <Box display={"flex"} justifyContent={"flex-end"} marginTop={-2}>
                    <Iconify icon={"mdi:delete"} sx={{ color: theme.palette.error.main }} onClick={onDelete} />
                </Box>
            )}

            <Box display={"flex"} flexDirection={"row"} justifyContent={"space-between"}>
                <Box display={"flex"} flexDirection={"row"} gap={1} alignItems={"center"}>
                    <Iconify
                        icon={icon}
                        width={35}
                        height={35}
                        style={{
                            borderWidth: 1,
                            borderColor: alpha(iconColor, 0.1),
                            borderStyle: "solid",
                            borderRadius: 50,
                            padding: 4,
                            color: iconColor,
                            backgroundColor: alpha(iconColor, 0.1),
                        }}
                    />
                    <Box display={"flex"} flexDirection={"column"} minWidth={60}>
                        <Typography fontWeight={"bold"} fontSize={14}>
                            {title}
                        </Typography>

                        <Typography
                            fontSize={12}
                            sx={{ overflow: "hidden", WebkitBoxOrient: "vertical", WebkitLineClamp: 1, textAlign: "left", display: "-webkit-box" }}
                        >
                            {subtitle}
                        </Typography>
                    </Box>
                </Box>

                <Box display={"flex"} flexDirection={"column"} alignContent={"center"} justifyContent={"center"}>
                    <Typography textAlign={"center"} fontWeight={"bold"} fontSize={14}>
                        {rightFirstItem}
                    </Typography>
                    <Typography textAlign={"center"} fontSize={12} style={{ color: theme.palette.grey["500"] }}>
                        {rightSecondItem}
                    </Typography>
                </Box>
            </Box>
        </CustomCard>
    )
}
