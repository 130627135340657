import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import { useCallback, useMemo } from "react"
import { useTheme } from "@mui/material/styles"
import AddOrEditPropertyOwnerRepresentativeForm from "../../../../../sections/nomenclature/property-owners/details/General/AddPropertyOwnerRepresentativeForm"
import { usePropertyOwnerDetailsQuery } from "../../../../../queries/property-owners"

export default function AddPropertyOwnerRepresentativeGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: propertyOwnerDetails } = usePropertyOwnerDetailsQuery(editingItem?.id)

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    // Find the representative from the property owner's representatives array
    const representative = useMemo(() => {
        if (editingItem?.isEditing && editingItem?.representativeId && propertyOwnerDetails) {
            return propertyOwnerDetails.representatives.find(rep => rep.id === editingItem.representativeId);
        }
        return undefined;
    }, [editingItem, propertyOwnerDetails]);

    // Get the title based on whether we're editing or adding
    const title = useMemo(() => editingItem?.isEditing 
        ? translate("property_owners.representatives.edit_representative") 
        : translate("property_owners.representatives.add_representative")
    ,[editingItem, translate])

    // Wrapping content in useCallback to prevent unnecessary re-renders
    const renderContent = useCallback(() => (
        <AddOrEditPropertyOwnerRepresentativeForm
            propertyOwnerId={editingItem?.id}
            onClose={onClose}
            representative={representative}
            isEditing={editingItem?.isEditing}
        />
    ), [editingItem, onClose, representative]);

    return (
        <>
            <CustomDialog
                title={title}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.AddPropertyOwnerRepresentative}
                maxWidth="md"
                isFullScreen={false}
                titleAlign="center"
            >
                {renderContent()}
            </CustomDialog>

            <DetailsSheet
                title={title}
                isOpen={isMobile && modalOpen === AppModals.AddPropertyOwnerRepresentative}
                onClose={onClose}
                isLoading={false}
                headerColor={theme.palette.primary.main}
            >
                {renderContent()}
            </DetailsSheet>
        </>
    )
} 