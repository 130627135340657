import { InventoryItemStatus, InventoryProcessItem } from "../../../../../redux/slices/AddProperty"
import { FormProvider, RHFSelect, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { Box, Button, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback } from "react"
import { updateInventoryProcessSectionItemMutation } from "../../../../../mutations/inventory"
import * as jsonpatch from "fast-json-patch"

interface InventoryItemFormProps {
    item: InventoryProcessItem
    inventoryProcessId: number
    inventorySectionId: number
    isFirst: boolean
    onPrevious: () => void
    onNext: () => void
}

interface InventoryItemFormState {
    status: InventoryItemStatus
    observation?: string
}

const InventoryItemForm = ({ item, isFirst, onPrevious, inventoryProcessId, inventorySectionId, onNext }: InventoryItemFormProps) => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { mutateAsync: updateInventoryItem, isPending: isUpdatingInventoryItem } = updateInventoryProcessSectionItemMutation()
    const methods = useForm<InventoryItemFormState>({
        defaultValues: {
            status: item.status,
            observation: item.observation,
        },
    })
    const { handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: InventoryItemFormState) => {
            await updateInventoryItem({
                inventoryProcessId,
                inventorySectionId,
                inventoryItemId: item.id,
                operations: jsonpatch.compare({ status: item.status, observation: item.observation, isHandled: false }, { ...data, isHandled: true }),
            })
            onNext()
        },
        [inventorySectionId, inventoryProcessId, item, onNext],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                <RHFSelect name='status' label={translate("inventory_item_status")} required>
                    {Object.keys(InventoryItemStatus)
                        .filter((key) => Number(key) || key === "0")
                        .map((status) => (
                            <MenuItem key={status} value={status}>
                                {translate(`inventory-item-status-${status}`)}
                            </MenuItem>
                        ))}
                </RHFSelect>
                <RHFTextField name='observation' multiline rows={3} label={translate("inventory_process_observation")} />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    {!isFirst && (
                        <Button color={"primary"} onClick={onPrevious} disabled={isUpdatingInventoryItem}>
                            {translate("previous")}
                        </Button>
                    )}
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color={"primary"}
                        loading={isUpdatingInventoryItem}
                        disabled={isUpdatingInventoryItem}
                    >
                        {translate("next")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}

export default InventoryItemForm
