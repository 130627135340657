import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { useUserBankAccountsQuery, useUserDataQueryFn } from "../../../queries/userData"
import { Iconify, LoadingIcon } from "rentzz"
import { Box, Fab } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomAddCard from "../../../components/CustomAddCard"
import React from "react"
import CustomMobileCard from "../../../components/CustomMobileCard"

export default function MobileNomenclatureBankAccounts() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: bankAccounts, isLoading: areBankAccountsLoading } = useUserBankAccountsQuery()
    const { data: userData } = useUserDataQueryFn()
    const { modalOpen } = useSelector((state) => state.appState)

    if (bankAccounts == null || areBankAccountsLoading) return <LoadingIcon />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
            {bankAccounts.count !== 0 && modalOpen == null && userData?.roles != null && (
                <Fab
                    onClick={() => dispatch(setModalOpen(AppModals.AddBankAccount))}
                    sx={{ position: "fixed", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}

            <Box display={"flex"} flexDirection={"column"} gap={1}>
                {bankAccounts.items.map((account) => (
                    <CustomMobileCard
                        key={account.id}
                        item={account}
                        onClick={() => {
                            dispatch(setModalOpen(AppModals.BankAccountDetails))
                            dispatch(setEditingItem({ id: account.id }))
                        }}
                        icon={"mdi:bank"}
                        iconColor={theme.palette.primary.main}
                        title={account.holder}
                        subtitle={account.iban}
                    />
                ))}
            </Box>

            {bankAccounts.count === 0 && (
                <CustomAddCard
                    onCardClick={() => dispatch(setModalOpen(AppModals.AddBankAccount))}
                    icon={"mdi:add-circle-outline"}
                    text={"add_bank_account"}
                />
            )}
        </Box>
    )
}
