import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import InvoicingContent from "../../../../../sections/nomenclature/invoicing-configuration/InvoicingContent"
import { useSetAtom } from "jotai/index"
import { invoicingTypeAtom } from "../../../../../utils/atom"

export default function InvoicingGuard() {
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const theme = useTheme()
    const { modalOpen } = useSelector((state) => state.appState)
    const setInvoicingType = useSetAtom(invoicingTypeAtom)

    const onClose = useCallback(() => {
        if (isMobile) {
            dispatch(setModalOpen(AppModals.InvoicingCompanyDetailsSheet))
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
        setInvoicingType(undefined)
    }, [dispatch, isMobile])

    return (
        <>
            <CustomDialog
                maxWidth={"xl"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.InvoiceType}
                onClose={onClose}
                isFullScreen={false}
            >
                <InvoicingContent onClose={onClose} />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.InvoiceType}
                onClose={onClose}
                isLoading={false}
                options={[]}
                title={translate("invoicing")}
                headerColor={theme.palette.primary.main}
            >
                <InvoicingContent onClose={onClose} />
            </DetailsSheet>
        </>
    )
}
