import { useMutation, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { DashboardChartType, SummaryCardType } from "../queries/userData"
import Api from "../api/Api"
import { useSelector } from "../redux/store"
import { AddGroupRequest } from "../sections/groups/AddGroupForm"
import * as jsonpatch from "fast-json-patch"
import { UserGroup } from "../queries/groups"
import { ManagerType } from "../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { AddGroupDashboardConfigurationRequest } from "../sections/groups/groupSection/AddGroupDashboardConfigurationForm"

export const updateGroupSummaryCardMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({
            summaryCardId,
            newPosition,
            dashboardConfigId,
        }: {
            summaryCardId: number
            newPosition: number
            summaryCards: SummaryCardType[]
            dashboardConfigId: number
        }) => Api.updateGroupSummaryCard(summaryCardId, newPosition, currentGroupId!, dashboardConfigId),
        onMutate: ({ summaryCards }) => {
            queryClient.setQueryData([SimpleQueryIDs.Groups], (oldData): UserGroup[] => {
                const converted = oldData as UserGroup[]
                return converted.map((group) => {
                    if (group.id === currentGroupId) {
                        return { ...group, summaryCards: [...summaryCards] }
                    }
                    return group
                })
            })
        },
        onSettled: async (_, error) => {
            if (error) {
                await invalidateQueries([ComplexQueryIDs.GroupSummaryCards, SimpleQueryIDs.Groups])
            }
        },
    })
}

export const updateGroupChartMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({
            chartId,
            newPosition,
            dashboardConfigId,
        }: {
            chartId: number
            newPosition: number
            charts: DashboardChartType[]
            dashboardConfigId: number
        }) => Api.updateGroupChart(chartId, newPosition, currentGroupId!, dashboardConfigId),
        onMutate: ({ charts }) => {
            queryClient.setQueryData([SimpleQueryIDs.Groups], (oldData): UserGroup[] => {
                const converted = oldData as UserGroup[]
                return converted.map((group) => {
                    if (group.id === currentGroupId) {
                        return { ...group, dashboardCharts: [...charts] }
                    }
                    return group
                })
            })
        },
        onSettled: async (_, error) => {
            if (error) {
                await invalidateQueries([ComplexQueryIDs.GroupSummaryCards, SimpleQueryIDs.Groups])
            }
        },
    })
}

export const deleteGroupSummaryCardMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ summaryCardId, dashboardConfigId }: { summaryCardId: number; summaryCards: SummaryCardType[]; dashboardConfigId: number }) =>
            Api.deleteGroupSummaryCard({ groupId: currentGroupId!, summaryCardId, dashboardConfigId }),
        onMutate: ({ summaryCards }) => {
            queryClient.setQueryData([SimpleQueryIDs.Groups], (oldData): UserGroup[] => {
                const converted = oldData as UserGroup[]
                return converted.map((group) => {
                    if (group.id === currentGroupId) {
                        return { ...group, summaryCards: [...summaryCards] }
                    }
                    return group
                })
            })
        },
        onSettled: async (_, error) => {
            if (error) {
                await invalidateQueries([SimpleQueryIDs.Groups])
            }
        },
    })
}

export const deleteGroupChartMutation = () => {
    const queryClient = useQueryClient()
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ chartId, dashboardConfigId }: { chartId: number; charts: DashboardChartType[]; dashboardConfigId: number }) =>
            Api.deleteGroupChart({ groupId: currentGroupId!, chartId, dashboardConfigId }),
        onMutate: ({ charts }) => {
            queryClient.setQueryData([SimpleQueryIDs.Groups], (oldData): UserGroup[] => {
                const converted = oldData as UserGroup[]
                return converted.map((group) => {
                    if (group.id === currentGroupId) {
                        return { ...group, dashboardCharts: [...charts] }
                    }
                    return group
                })
            })
        },
        onSettled: async (_, error) => {
            if (error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const addGroupSummaryCardMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ summaryCardId, dashboardConfigurationId }: { summaryCardId: number; dashboardConfigurationId: number }) =>
            Api.addGroupSummaryCard(summaryCardId, currentGroupId!, dashboardConfigurationId),
        onSettled: async (_, error, __) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.SummaryCard, SimpleQueryIDs.Groups, ComplexQueryIDs.GroupDashboardConfig])
            }
        },
    })
}

export const addGroupChartMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()
    const { currentGroupId } = useSelector((state) => state.appState)

    return useMutation({
        mutationFn: ({ chartId, dashboardConfigurationId }: { chartId: number; dashboardConfigurationId: number }) =>
            Api.addGroupChart(chartId, currentGroupId!, dashboardConfigurationId),
        onSettled: async (_, error, __) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.SummaryCard, SimpleQueryIDs.Groups, ComplexQueryIDs.GroupDashboardConfig])
            }
        },
    })
}

export const updateGroupPropertyPercentageMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ percentage, groupId, propertyId }: { percentage: number; groupId: number; propertyId: number }) =>
            Api.updatePropertyPercentage({
                percentage,
                groupId,
                propertyId,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups])
            }
        },
    })
}

export const deleteGroupMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId }: { groupId: number }) => Api.deleteGroup(groupId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const addGroupMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data }: { data: AddGroupRequest }) => Api.addGroup(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const addGroupDashboardConfigurationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ data, groupId }: { data: AddGroupDashboardConfigurationRequest; groupId: number }) =>
            Api.addGroupDashboardConfiguration(data, groupId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const deleteGroupDashboardConfigurationMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ dashboardConfigurationId, groupId }: { dashboardConfigurationId: number; groupId: number }) =>
            Api.deleteGroupDashboardConfiguration(dashboardConfigurationId, groupId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const refreshGroupDashboardConfigurationSecrets = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ dashboardConfigurationId, groupId }: { dashboardConfigurationId: number; groupId: number }) =>
            Api.refreshGroupDashboardConfigurationSecrets(dashboardConfigurationId, groupId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const updateGroupDashboardConfigPubliclyAvailable = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({
            dashboardConfigurationId,
            groupId,
            isPubliclyAvailable,
        }: {
            dashboardConfigurationId: number
            groupId: number
            isPubliclyAvailable: boolean
        }) => Api.updateGroupDashboardConfigurationPubliclyAvailable(dashboardConfigurationId, groupId, isPubliclyAvailable),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const updateGroupMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId, operations }: { groupId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateGroup({
                groupId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups])
            }
        },
    })
}

export const updateGroupPropertiesMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId, propertyId, operationType }: { groupId: number; propertyId: number; operationType: number }) =>
            Api.updateGroupProperties({
                groupId,
                propertyId,
                operationType,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods])
            }
        },
    })
}

export const addGroupAccessMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId, email, accessType }: { groupId: number; accessType: ManagerType; email: string }) =>
            Api.addGroupAccess({
                groupId,
                accessType,
                email,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods, ComplexQueryIDs.GroupAccess])
            }
        },
    })
}

export const deleteGroupAccessMutation = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ groupId, userId }: { groupId: number; userId: string }) =>
            Api.deleteGroupAccess({
                groupId,
                userId,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.Groups, ComplexQueryIDs.GroupRentingPeriods, ComplexQueryIDs.GroupAccess])
            }
        },
    })
}
