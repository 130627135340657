import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import * as jsonpatch from "fast-json-patch"
import AddOrEditInventorySectionForm, { AddInventorySectionRequest } from "../../../sections/propertyDetails/inventory/AddOrEditInventorySectionForm"
import { addInventorySectionMutation, updateInventorySectionMutation } from "../../../mutations/inventory"
import { getInventorySectionByIdQueryFn } from "../../../queries/inventory"

export default function AddOrEditInventorySectionGuard() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: addInventorySection, isPending: isAddingInventorySection } = addInventorySectionMutation()
    const { mutateAsync: updateInventorySection, isPending: isUpdatingInventorySection } = updateInventorySectionMutation()
    const inventorySection = getInventorySectionByIdQueryFn(editingItem?.inventorySectionId)

    const closeModal = useCallback(() => {
        if (isMobile) {
            if (modalOpen === AppModals.AddInventorySection) {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            } else {
                dispatch(setModalOpen(AppModals.InventorySectionDetails))
            }
        } else {
            dispatch(setModalOpen(undefined))
            dispatch(setEditingItem(undefined))
        }
    }, [dispatch, modalOpen, isMobile])

    const onSave = useCallback(
        async (data: AddInventorySectionRequest) => {
            if (inventorySection != null && editingItem != null) {
                const operations = jsonpatch.compare(inventorySection, data)

                if (!data.id) return
                await updateInventorySection({
                    inventorySectionId: data.id,
                    operations,
                })
            } else {
                await addInventorySection({ data })
            }
            closeModal()
        },
        [addInventorySection, editingItem, updateInventorySection, inventorySection, closeModal],
    )

    return (
        <>
            <CustomDialog
                title={translate(modalOpen === AppModals.AddInventorySection ? "add_inventory_section" : "edit_inventory_section")}
                open={!isMobile && (modalOpen === AppModals.AddInventorySection || modalOpen === AppModals.EditInventorySection)}
                onClose={closeModal}
                maxWidth={"sm"}
                titleAlign={"center"}
                isFullScreen={false}
            >
                <AddOrEditInventorySectionForm
                    onClose={closeModal}
                    onSave={onSave}
                    isLoading={isAddingInventorySection || isUpdatingInventorySection}
                    defaultValues={inventorySection}
                />
            </CustomDialog>

            <DetailsSheet
                isLoading={false}
                title={translate(modalOpen === AppModals.AddInventorySection ? "add_inventory_section" : "edit_inventory_section")}
                isOpen={isMobile && (modalOpen === AppModals.AddInventorySection || modalOpen === AppModals.EditInventorySection)}
                onClose={closeModal}
                headerColor={theme.palette.primary.main}
            >
                <Box p={2}>
                    <AddOrEditInventorySectionForm
                        onClose={closeModal}
                        onSave={onSave}
                        isLoading={isAddingInventorySection || isUpdatingInventorySection}
                        defaultValues={inventorySection}
                    />
                </Box>
            </DetailsSheet>
        </>
    )
}
