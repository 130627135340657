import { RHFTextField, RHFUpload, useIsMobile, useLocales } from "rentzz"
import { Box, Button } from "@mui/material"
import { useFormContext } from "react-hook-form"
import React, { useCallback } from "react"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { useUserDataQueryFn } from "../../../queries/userData"
import { AddSigningRequest } from "./types"
import RHFRecipientsInput from "../../../components/RHFRecipientsInput"
import { signingRequestTypeAtom } from "../../../utils/atom"
import { useAtomValue } from "jotai"
import { LoadingButton } from "@mui/lab"
import RHFPropertyRentingPeriodPicker from "../../../components/RHFPropertyRentingPeriodPicker"
import { useSelector } from "../../../redux/store"

interface InvitationProps {
    onClose: () => void
    onNext: () => void
    isLoading: boolean
}

export default function SigningDetails({ onClose, onNext, isLoading }: InvitationProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { editableProperties } = usePermissions(PermissionType.SignatureRequests)
    const { data: userData } = useUserDataQueryFn()
    const { currentPropertyId } = useSelector((state) => state.appState)
    const documentType = useAtomValue(signingRequestTypeAtom)

    const methods = useFormContext<AddSigningRequest>()

    const { getValues } = methods

    const getMaxSize = useCallback(() => {
        if (userData == null || editableProperties == null) return 0

        const property = editableProperties.find((p) => p.id === getValues().propertyId)

        if (property == null) return 0

        if (userData.features?.maxPropertySize) {
            return userData.features?.maxPropertySize - property.currentSize
        }
    }, [userData, editableProperties])

    return (
        <>
            <Box display='flex' width='100%' marginTop={1} gap={2} justifyContent='space-around'>
                <Box display='flex' flexDirection='column' width={"100%"} gap={2} marginY={2}>
                    <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={2}>
                        <RHFTextField name='name' label={translate("document_name")} required />
                        <RHFTextField name='internalId' label={translate("document_internal_id")} />
                    </Box>
                    {documentType === "signRequest" && <RHFRecipientsInput name={"recipients"} />}
                    {documentType === "presigned" && (
                        <RHFPropertyRentingPeriodPicker
                            name={"rentingPeriodId"}
                            disabled={false}
                            isRequired={true}
                            isMandatory={false}
                            propertyId={currentPropertyId}
                        />
                    )}
                    <RHFUpload name={"files"} maxFiles={1} multiple maxSize={getMaxSize()} icon={"mdi:file-document-plus-outline"} />
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: isMobile ? "column-reverse" : "row",
                    justifyContent: "flex-end",
                    padding: 0,
                    paddingTop: 2,
                    gap: 2,
                }}
            >
                <Button color={"primary"} onClick={onClose}>
                    {translate("cancel")}
                </Button>
                <LoadingButton loading={isLoading} color={"primary"} onClick={onNext} variant='contained'>
                    {translate("next")}
                </LoadingButton>
            </Box>
        </>
    )
}
