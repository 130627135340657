import { Box } from "@mui/material"
import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { PresentationFile } from "../../queries"
import Api from "../../api/Api"
import { deletePropertyPhotoMutation, updatePropertyCoverPhotoMutation } from "../../mutations"
import { CustomCarousel } from "rentzz"

export interface FileUrl {
    fileId: string
    url: string
}

interface Props {
    files?: PresentationFile[]
}
export default function PhotosCarousel({ files }: Props) {
    const [filesUrl, setFileUrl] = useState<FileUrl[]>([])

    const blobToBase64 = useCallback((blob: any) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onloadend = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
            reader.readAsDataURL(blob)
        })
    }, [])

    useEffect(() => {
        if (files && files.length > 0) {
            const fetchFiles = async () => {
                try {
                    const filesUrl = await Promise.all(
                        files.map(async (f) => {
                            const blob = await Api.downloadMultiFile([f.id])
                            const base64DataUrl = await blobToBase64(blob.blob)
                            return { fileId: f.id, url: base64DataUrl as string }
                        }),
                    )
                    setFileUrl(filesUrl)
                } catch (error) {
                    console.error("Error fetching files", error)
                }
            }

            fetchFiles()
        }
    }, [files])

    return (
        <>
            <Box display={"flex"} padding={2} justifyContent={"center"}>
                <img alt={""} src={filesUrl.length > 0 ? filesUrl[0].url : ""} height={245} />
            </Box>
            <CustomCarousel photos={filesUrl} handleUpdateCover={updatePropertyCoverPhotoMutation} handleDelete={deletePropertyPhotoMutation} />
        </>
    )
}
