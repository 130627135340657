import { Iconify, Label, LoadingIcon, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React from "react"
import { useTenantRevisionsQuery } from "../../../queries/revision"
import { Box, Fab, Typography } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { RevisionStatus } from "../../../redux/slices/AddProperty"
import { DateTime } from "luxon"
import NoDataPage from "../../../components/NoDataPage"
import { UserFlags } from "../../../queries/userData"
import { useFeatureIsOn } from "@growthbook/growthbook-react"

export default function MobileTenantRevisionsList() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: revisions } = useTenantRevisionsQuery()
    const { modalOpen } = useSelector((state) => state.appState)
    const canTenantAddRevisions = useFeatureIsOn(UserFlags.CanTenantAddRevisions.toString())

    if (revisions == null) return <LoadingIcon />

    if (revisions?.length === 0) {
        return (
            <>
                {modalOpen == null && canTenantAddRevisions && (
                    <Fab
                        onClick={() => dispatch(setModalOpen(AppModals.AddRevision))}
                        sx={{ position: "fixed", bottom: 10, right: 10 }}
                        color='primary'
                        aria-label='add'
                    >
                        <Iconify icon={"mdi:plus"} width={25} height={25} />
                    </Fab>
                )}
                <NoDataPage icon={"mdi:wrench-clock"} noDataText={"no_revisions"} />
            </>
        )
    }

    return (
        <>
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {revisions && revisions.length > 0 && (
                    <Typography variant={"subtitle1"} textAlign={"center"} fontWeight={"bold"} sx={{ color: theme.palette.grey.A700 }}>
                        {translate("revisions")}
                    </Typography>
                )}
                {revisions.map((revision) => (
                    <CustomMobileCard
                        key={revision.id}
                        item={revision}
                        onClick={() => {
                            dispatch(setModalOpen(AppModals.RevisionDetails))
                            dispatch(setEditingItem({ revisionId: revision.id }))
                        }}
                        icon={"mdi:wrench"}
                        iconColor={theme.palette.primary.main}
                        title={revision.name}
                        rightFirstItem={
                            <Label color={revision.status === RevisionStatus.Done ? "primary" : "warning"} style={{ textTransform: "none" }}>
                                {translate(revision.status === RevisionStatus.Done ? "completed" : "pending")}
                            </Label>
                        }
                        rightSecondItem={revision.tenantChosenDate?.toLocaleString(DateTime.DATE_SHORT)}
                    />
                ))}
            </Box>
        </>
    )
}
