import { GridFilterModel, GridSortModel } from "@mui/x-data-grid-pro"
import { useSelector } from "../redux/store"
import { PermissionType, usePermissions } from "../hooks/usePermissions"
import { useInfiniteQuery, useQuery, useQueryClient } from "@tanstack/react-query"
import { ComplexQueryIDs } from "../hooks/useQueryInvalidator"
import { AppContext } from "rentzz"
import Api from "../api/Api"
import { InventoryItem, InventorySection } from "../redux/slices/AddProperty"
import { PaginatedData } from "./expenses"
import { sum } from "lodash"

export const useInventorySectionsQueryFn = (
    propertyId: number | undefined,
    page: number,
    pageSize: number,
    sortingColumns: GridSortModel,
    filterModel: GridFilterModel,
) => {
    const { context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.InventorySections)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.InventorySections,
            {
                propertyId,
                page,
                pageSize,
                sortingColumns,
                filterModel,
            },
        ],
        queryFn: Api.fetchInventorySections,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!propertyId,
        select: (data) => {
            return {
                count: data.count,
                items: data.items.map((i) => ({
                    ...i,
                    canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                })),
            }
        },
    })
}

export const useInfiniteInventorySectionsQuery = (propertyId: number | undefined, sortingColumns?: GridSortModel) => {
    const { context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.InventorySections)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.InventorySections, { propertyId, sortingColumns }],
        queryFn: Api.fetchInfiniteInventorySections,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!propertyId,
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(i.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(i.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}

export const useInfiniteInventorySectionsItemsQuery = (inventorySectionId: number | undefined, sortingColumns?: GridSortModel) => {
    const { context } = useSelector((state) => state.appState)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.InventorySections)
    const inventorySection = getInventorySectionByIdQueryFn(inventorySectionId)

    return useInfiniteQuery({
        initialPageParam: 0,
        queryKey: [ComplexQueryIDs.InventorySectionItems, { inventorySectionId, sortingColumns }],
        queryFn: Api.fetchInfiniteInventorySectionItems,
        staleTime: Infinity,
        enabled: context === AppContext.Owner && !!inventorySectionId,
        select: (data) => {
            return {
                pages: data.pages.map((e) => {
                    return {
                        count: e.count,
                        items: e.items.map((i) => ({
                            ...i,
                            canWrite: editablePropertiesIds?.includes(inventorySection!.propertyId) ?? false,
                            canDelete: deletablePropertiesIds?.includes(inventorySection!.propertyId) ?? false,
                        })),
                    }
                }),
                pageParams: data.pageParams,
            }
        },
        getNextPageParam: (_, pages) => {
            const allItems = sum(pages.flatMap((p) => p.items.length))
            if (allItems === pages[0].count) {
                return undefined
            }
            return allItems
        },
    })
}

export const getInventorySectionByIdQueryFn = (
    inventorySectionId?: number,
): (InventorySection & { canWrite: boolean; canDelete: boolean }) | undefined => {
    const queryClient = useQueryClient()
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.InventorySections)

    const revisionsPages = queryClient.getQueriesData({
        queryKey: [ComplexQueryIDs.InventorySections],
        stale: false,
    })

    const arrayOfRevisions = (
        (revisionsPages?.flatMap((revisionWithKey) => revisionWithKey[1]) as { pages: PaginatedData<InventorySection> }[]).flatMap((i) =>
            i?.pages != null ? i.pages : i,
        ) as PaginatedData<InventorySection>[]
    )?.flatMap((r) => r?.items) as (InventorySection & {
        canWrite: boolean
        canDelete: boolean
    })[]

    const revision = arrayOfRevisions?.find((revision) => revision?.id == inventorySectionId)

    if (revision) {
        return {
            ...revision,
            canWrite: editablePropertiesIds?.includes(revision.propertyId) ?? false,
            canDelete: deletablePropertiesIds?.includes(revision.propertyId) ?? false,
        }
    } else return revision ?? undefined
}

export const useInventorySectionItemsQueryFn = (inventorySectionId: number, page: number, pageSize: number, sortingColumns: GridSortModel) => {
    const { context } = useSelector((state) => state.appState)
    const inventorySection = getInventorySectionByIdQueryFn(inventorySectionId)
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.InventorySections)

    return useQuery({
        queryKey: [
            ComplexQueryIDs.InventorySectionItems,
            {
                inventorySectionId,
                page,
                pageSize,
                sortingColumns,
            },
        ],
        select: (data) => {
            return {
                count: data.count,
                items: data.items.map((i) => ({
                    ...i,
                    canWrite: editablePropertiesIds?.includes(inventorySection!.propertyId) ?? false,
                    canDelete: deletablePropertiesIds?.includes(inventorySection!.propertyId) ?? false,
                })),
            }
        },
        queryFn: Api.fetchInventorySectionItems,
        staleTime: Infinity,
        enabled: !!inventorySectionId && context === AppContext.Owner,
    })
}

export const getInventorySectionItemByIdQueryFn = (
    inventorySectionId?: number,
    inventoryItemId?: number,
): (InventoryItem & { canWrite: boolean; canDelete: boolean }) | undefined => {
    const inventorySection = getInventorySectionByIdQueryFn(inventorySectionId)
    const queryClient = useQueryClient()
    const { editablePropertiesIds, deletablePropertiesIds } = usePermissions(PermissionType.InventoryItems)

    const revisionsPages = queryClient.getQueriesData({
        queryKey: [ComplexQueryIDs.InventorySectionItems],
        stale: false,
    })

    const arrayOfRevisions = (
        (revisionsPages?.flatMap((revisionWithKey) => revisionWithKey[1]) as { pages: PaginatedData<InventoryItem> }[]).flatMap((i) =>
            i?.pages != null ? i.pages : i,
        ) as PaginatedData<InventoryItem>[]
    )?.flatMap((r) => r?.items) as (InventoryItem & {
        canWrite: boolean
        canDelete: boolean
    })[]

    const revision = arrayOfRevisions?.find((revision) => revision?.id == inventoryItemId)

    if (revision) {
        return {
            ...revision,
            canWrite: editablePropertiesIds?.includes(inventorySection!.propertyId) ?? false,
            canDelete: deletablePropertiesIds?.includes(inventorySection!.propertyId) ?? false,
        }
    } else return revision ?? undefined
}

export const useRentingPeriodInventoryProcessesQueryFn = () => {
    const { context, currentRentingPeriodId } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.RentingPeriodInventoryProcesses, { currentRentingPeriodId }],
        queryFn: Api.fetchInventoryProcesses,
        staleTime: Infinity,
        enabled: !!currentRentingPeriodId && context === AppContext.Owner,
    })
}

export const useRentingPeriodInventoryProcessSectionStepsQueryFn = (inventoryProcessId?: number, inventorySectionId?: number) => {
    const { context, currentRentingPeriodId } = useSelector((state) => state.appState)

    return useQuery({
        queryKey: [ComplexQueryIDs.RentingPeriodInventoryProcesses, { currentRentingPeriodId, inventoryProcessId, inventorySectionId }],
        queryFn: Api.fetchInventorySectionProcessItems,
        staleTime: Infinity,
        enabled: !!currentRentingPeriodId && !!inventorySectionId && !!inventoryProcessId && context === AppContext.Owner,
    })
}
