import { useDispatch } from "../../../../redux/store"
import { Box, CircularProgress, Fab } from "@mui/material"
import { AppModals, setModalOpen } from "../../../../redux/slices/App"
import React, { useEffect, useMemo, useRef } from "react"
import RentingPeriodCard from "./RentingPeriodCard"
import { useInfiniteRentingPeriodsQueryFn } from "../../../../queries/tenants"
import { Iconify, LoadingScreen } from "rentzz"
import { PermissionType, usePermissions } from "../../../../hooks/usePermissions"
import NoDataPage from "../../../../components/NoDataPage"
import { rentingPeriodFilterState } from "../../../../utils/atom"
import { useAtomValue } from "jotai"
import { sum } from "lodash"
import CustomAddCard from "../../../../components/CustomAddCard"
import { useUserPropertiesQuery } from "../../../../queries/userData"
import HeaderRentingPeriods from "../HeaderRentingPeriods"

interface MobileTenantsListProps {
    getAmount: (value: number, currencyId?: number) => string
}

export default function MobileTenantsList({ getAmount }: MobileTenantsListProps) {
    const ref = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()
    const filterModel = useAtomValue(rentingPeriodFilterState)
    const { data: userProperties } = useUserPropertiesQuery()

    const {
        data: rentingPeriods,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteRentingPeriodsQueryFn([{ field: "moveInDate", sort: "desc" }], filterModel)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    if (rentingPeriods == null) return <LoadingScreen />
    if (sum(rentingPeriods?.pages.flatMap((p) => p.items.length)) === 0 && !canWrite)
        return <NoDataPage icon={"mdi:account-off"} noDataText={"no_tenants"} />

    return (
        <Box gap={1} display={"flex"} flexDirection={"column"} pb={3} style={{ height: "inherit" }}>
            <HeaderRentingPeriods />
            {sum(rentingPeriods?.pages.flatMap((p) => p.items.length)) !== 0 && (
                <Fab
                    onClick={() => dispatch(setModalOpen(AppModals.CreateRentingPeriod))}
                    sx={{ position: "fixed", bottom: 10, right: 10 }}
                    color='primary'
                    aria-label='add'
                >
                    <Iconify icon={"mdi:plus"} width={25} height={25} />
                </Fab>
            )}

            {rentingPeriods.pages.map((group, i) => (
                <Box
                    key={i}
                    paddingTop={rentingPeriods.pages.some((p) => p.items.length === 0) ? 0 : 1}
                    gap={1}
                    display={"flex"}
                    flexDirection={"column"}
                >
                    {group.items.map((rentingPeriod) => (
                        <RentingPeriodCard key={rentingPeriod.id} rentingPeriod={rentingPeriod} getAmount={getAmount} />
                    ))}
                </Box>
            ))}
            {hasNextPage && (
                <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                    <CircularProgress />
                </div>
            )}

            {rentingPeriods.pages.some((p) => p.items.length === 0) && canWrite && (
                <CustomAddCard
                    onCardClick={() => dispatch(setModalOpen(AppModals.CreateRentingPeriod))}
                    icon={"mdi:account-plus"}
                    text={"tenant-data.addTenant"}
                    isAddDisabled={userProperties?.length === 0}
                    tooltipTitle={"no_property_no_tenant"}
                />
            )}
        </Box>
    )
}
