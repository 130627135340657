import { CustomDataGrid, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useMemo, useRef, useState } from "react"
import { Box, Button, Switch } from "@mui/material"
import { setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { dispatch, useSelector } from "../../../redux/store"
import { invoicingTypeAtom } from "../../../utils/atom"
import { useSetAtom } from "jotai"
import NoDataPage from "../../../components/NoDataPage"
import { GridColDef } from "@mui/x-data-grid-pro"
import { useSnackbar } from "notistack"
import { LoadingButton } from "@mui/lab"
import { DateTime } from "luxon"
import { GenerateQueuedInvoiceResponse, startGeneratingQueuedInvoices } from "../../../mutations/invoicingConfiguration"
import { useUserPropertiesQuery } from "../../../queries/userData"
import GeneratedInvoiceCell from "./GeneratedInvoiceCell"

export default function InvoicingView() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const setInvoicingType = useSetAtom(invoicingTypeAtom)
    const { enqueueSnackbar } = useSnackbar()
    const { mutateAsync } = startGeneratingQueuedInvoices()
    const [generatedInvoices, setGeneratedInvoices] = useState<GenerateQueuedInvoiceResponse[]>([])
    const [isGeneratingInvoices, setIsGeneratingInvoices] = useState(false)
    const isCreatingInvoices = useRef(false)
    const isSendingInvoicesToTenant = useRef(true)
    const { data: userProperties } = useUserPropertiesQuery()

    const closeModal = useCallback(() => {
        setInvoicingType(undefined)
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [modalOpen, dispatch, isMobile])

    const columns = useMemo(() => {
        const columns: GridColDef<GenerateQueuedInvoiceResponse>[] = [
            {
                field: "propertyId",
                type: "string",
                minWidth: 120,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("propertyName"),
                valueGetter: (value) => userProperties?.find((p) => p.id === value)?.label,
            },
            {
                field: "rentingPeriodStartDate",
                type: "string",
                width: 220,
                headerAlign: "center",
                align: "center",
                sortable: false,
                headerName: translate("dry_run_invoicing_lease_period"),
                valueGetter: (_, row) =>
                    row.rentingPeriodStartDate.toLocaleString(DateTime.DATE_SHORT) +
                    " - " +
                    row.rentingPeriodEndDate.toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "invoiceDate",
                type: "date",
                width: 140,
                headerAlign: "center",
                align: "center",
                headerName: translate("date"),
                valueFormatter: (value) => DateTime.fromISO(value).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "queryId",
                type: "string",
                minWidth: 200,
                headerAlign: "center",
                align: "center",
                headerName: translate("invoice_name"),
                renderCell: ({ row }) => <GeneratedInvoiceCell data={row} />,
            },
        ]

        return columns
    }, [translate, userProperties])

    const onGenerateInvoicesClick = useCallback(async () => {
        setGeneratedInvoices([])
        setIsGeneratingInvoices(true)
        const result = await mutateAsync({
            invoicingCompanyId: editingItem.invoiceCompanyId,
            invoicingConfigurationId: editingItem.invoicingConfigurationId,
            isTest: !isCreatingInvoices.current,
            addAsPending: !isSendingInvoicesToTenant.current,
        })
        setGeneratedInvoices(result)
    }, [enqueueSnackbar])

    return (
        <>
            {!isGeneratingInvoices && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        {translate("generate_invoices_as_pending")}
                        <Switch
                            defaultChecked={true}
                            onChange={(_, v) => (isSendingInvoicesToTenant.current = v)}
                            title={translate("generate_invoices_as_pending")}
                        />
                    </Box>
                    <Box display='flex' flexDirection='row' alignItems='center'>
                        {translate("is_testing_invoice")}
                        <Switch
                            defaultChecked={false}
                            onChange={(_, v) => (isCreatingInvoices.current = v)}
                            title={translate("is_testing_invoice")}
                        />
                    </Box>
                    <LoadingButton onClick={onGenerateInvoicesClick} loading={isGeneratingInvoices} color='primary' variant='contained'>
                        {translate("generate_invoices")}
                    </LoadingButton>
                </Box>
            )}
            <CustomDataGrid
                gridId='InvoicingView'
                columns={columns}
                rows={generatedInvoices ?? []}
                idKey={"propertyName"}
                getRowId={(row) => row.propertyId}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={""} noDataText={"no_invoices"} />,
                }}
                rowHeight={50}
                gridHeight={400}
            />
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingX: 0,
                    paddingTop: 2,
                    gap: 2,
                    flexDirection: isMobile ? "column-reverse" : "row",
                }}
            >
                <Button color={"primary"} onClick={closeModal}>
                    {translate("cancel")}
                </Button>
            </Box>
        </>
    )
}
