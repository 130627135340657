import { ComplexQueryIDs, SimpleQueryIDs, useQueryInvalidator } from "../hooks/useQueryInvalidator"
import { useMutation } from "@tanstack/react-query"
import Api from "../api/Api"
import * as jsonpatch from "fast-json-patch"
import { InvoiceProvider } from "../queries/invoicing-configuration"
import { ManagerType } from "../sections/propertyDetails/General/management/managers/AddNewManagerInvitationForm"
import { DateTime } from "luxon"

export interface ToggleInvoicingCompanyRequest {
    invoicingConfigurationId: number
    invoicingCompanyId: number
    active: boolean
}

export interface UpdateInvoicingConfigurationCompaniesRequest {
    invoicingConfigurationId: number
    invoicingCompanyId?: number
}

export interface AddNewInvoicingConfigurationRequest {
    invoiceProvider: InvoiceProvider
    user: string
    password: string
    configurationCIF: string
}

export interface GenerateQueuedInvoiceBase {
    propertyId: number
    queryId?: string
    message?: string
}

export interface GenerateQueuedInvoiceDto extends GenerateQueuedInvoiceBase {
    invoiceDate?: string
    rentingPeriodStartDate: string
    rentingPeriodEndDate: string
}

export interface GenerateQueuedInvoiceResponse extends GenerateQueuedInvoiceBase {
    invoiceDate?: DateTime
    rentingPeriodStartDate: DateTime
    rentingPeriodEndDate: DateTime
}

export const toggleInvoicingConfigurationCompany = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ invoicingConfigurationId, invoicingCompanyId, active }: ToggleInvoicingCompanyRequest) =>
            active
                ? Api.activateInvoicingCompany(invoicingConfigurationId, invoicingCompanyId)
                : Api.deactivateInvoicingCompany(invoicingConfigurationId, invoicingCompanyId),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InvoicingConfigurationCompanies, SimpleQueryIDs.UserInvoicingCompanies])
            }
        },
    })
}

export const deleteInvoicingConfiguration = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (id: number) => Api.deleteInvoicingConfiguration(id),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserInvoicingConfigurations])
            }
        },
    })
}

export const addNewInvoicingConfiguration = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: (data: AddNewInvoicingConfigurationRequest) => Api.addNewInvoicingConfigurationRequest(data),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([
                    SimpleQueryIDs.UserInvoicingConfigurations,
                    ComplexQueryIDs.UserInvoicingClients,
                    SimpleQueryIDs.UserInvoicingCompanies,
                ])
            }
        },
    })
}

export const updateInvoicingConfiguration = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ invoicingConfigurationId, operations }: { invoicingConfigurationId: number; operations: jsonpatch.Operation[] }) =>
            Api.updateInvoicingConfiguration({
                invoicingConfigurationId,
                operations,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([SimpleQueryIDs.UserInvoicingConfigurations, ComplexQueryIDs.InvoicingConfigurationCompanies])
            }
        },
    })
}

export const updateInvoicingConfigurationCompany = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ invoicingConfigurationId, invoicingCompanyId }: UpdateInvoicingConfigurationCompaniesRequest) =>
            Api.updateInvoicingConfigurationCompany(invoicingConfigurationId, invoicingCompanyId!),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InvoicingConfigurationCompanies])
            }
        },
    })
}

export const addNewInvoicingConfigurationAccess = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ invoicingConfigurationId, email, accessType }: { invoicingConfigurationId: number; accessType: ManagerType; email: string }) =>
            Api.addInvoicingConfigurationAccess({
                invoicingConfigurationId,
                accessType,
                email,
            }),
        onSettled: async (_, error) => {
            if (!error) {
                await invalidateQueries([ComplexQueryIDs.InvoicingConfigurationUsers])
            }
        },
    })
}

export const startGeneratingQueuedInvoices = () => {
    return useMutation({
        mutationFn: (data: { invoicingConfigurationId: number; invoicingCompanyId: number; isTest: boolean; addAsPending: boolean }) =>
            Api.generateQueuedInvoices(data),
    })
}

export const stopInvoiceGeneration = () => {
    return useMutation({
        mutationFn: (data: { queryId: string }) => Api.stopGeneratedInvoice(data),
    })
}

export const deleteInvoicingConfigurationAccess = () => {
    const { invalidateQueries } = useQueryInvalidator()

    return useMutation({
        mutationFn: ({ invoicingConfigurationId, userId }: { invoicingConfigurationId: number; userId: string }) =>
            Api.deleteInvoicingConfigurationAccess(invoicingConfigurationId, userId),
        onSettled: async () => {
            await invalidateQueries([ComplexQueryIDs.InvoicingConfigurationUsers])
        },
    })
}
