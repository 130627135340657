import { MenuItem } from "@mui/material"
import { RHFSelect, useLocales } from "rentzz"
import React from "react"
import { usePropertyRentingPeriods } from "../queries/tenants"
import { DateTime } from "luxon"

export interface RHFPropertyRentingPeriodPickerProps {
    name: string
    propertyId?: number
    disabled: boolean
    isMandatory: boolean
    isRequired?: boolean
}

const RHFPropertyRentingPeriodPicker = ({ name, propertyId, disabled, isMandatory, isRequired }: RHFPropertyRentingPeriodPickerProps) => {
    const { translate } = useLocales()
    const { data: availableRentingPeriods } = usePropertyRentingPeriods(propertyId)

    return (
        <RHFSelect name={name} size='small' disabled={disabled} required={isRequired} label={translate("renting_period")}>
            {!isMandatory && (
                <MenuItem value={-1} style={{ textTransform: "none" }}>
                    {translate("no_renting_period_associated")}
                </MenuItem>
            )}
            {availableRentingPeriods?.map((property) => (
                <MenuItem key={property.id} value={property.id} style={{ textTransform: "none" }}>
                    {property.moveInDate.toLocaleString(DateTime.DATE_SHORT)} - {property.moveOutDate.toLocaleString(DateTime.DATE_SHORT)}
                </MenuItem>
            ))}
        </RHFSelect>
    )
}

export default RHFPropertyRentingPeriodPicker
