import { CustomTabs, useIsMobile, useLocales } from "rentzz"
import React, { useCallback, useEffect } from "react"
import { JournalFilteredOptions, journalPageAtom, journalsFilterState } from "../../../../utils/atom"
import { useAtom } from "jotai/index"
import { Tab } from "@mui/material"
import { a11yProps } from "../../../../utils/A11yProps"
import { filterOptions } from "./utils"

export default function JournalHeader() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const [filterModel, setFilterModel] = useAtom(journalsFilterState)
    const [journalPage, setJournalPage] = useAtom(journalPageAtom)

    const handleChange = useCallback(
        (event: React.SyntheticEvent, newValue: JournalFilteredOptions) => {
            switch (newValue) {
                case JournalFilteredOptions.All:
                    setFilterModel({ items: [] })
                    break
                case JournalFilteredOptions.AddedByOwner:
                    setFilterModel({ items: [{ field: "type", operator: "eq", value: "'UserAdded'" }] })
                    break
                case JournalFilteredOptions.AddedBySystem:
                    setFilterModel({ items: [{ field: "type", operator: "ne", value: "'UserAdded'" }] })
                    break
            }
        },
        [setFilterModel],
    )

    useEffect(() => {
        if (filterModel.items?.length === 0) {
            setJournalPage(JournalFilteredOptions.All)
            return
        }
        const journalType = filterModel.items.find((j) => j.operator)?.operator
        if (journalType === "eq") setJournalPage(JournalFilteredOptions.AddedByOwner)
        if (journalType === "ne") setJournalPage(JournalFilteredOptions.AddedBySystem)
    }, [filterModel, setJournalPage])

    return (
        <CustomTabs
            value={journalPage}
            onChange={handleChange}
            sx={{
                px: isMobile ? 0 : 2,
            }}
            allowScrollButtonsMobile
            scrollButtons='auto'
            variant='scrollable'
        >
            {filterOptions.map((opt) => (
                <Tab
                    key={opt}
                    value={opt}
                    iconPosition='end'
                    label={translate(`journalFilter.${opt}`)}
                    style={{
                        textTransform: "none",
                    }}
                    {...a11yProps(0)}
                />
            ))}
        </CustomTabs>
    )
}
