import { array, number, object, string } from "yup"
import { DateTime } from "luxon"

export const AddInventorySectionSchema = object().shape({
    id: number().optional(),
    propertyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    sectionName: string().required("required").min(3, "min_3_characters"),
})

export const AddInventorySectionItemSchema = object().shape({
    id: number().optional(),
    itemName: string().required("required").min(3, "min_3_characters"),
    itemDescription: string().required("required").min(3, "min_3_characters"),
    status: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    currencyId: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .required("required"),
    purchaseValue: number()
        .transform((value) => (isNaN(value) || value === null || value === undefined ? 0 : value))
        .optional(),
    purchaseDate: string()
        .required("required")
        .test((date: string | undefined) => {
            if (date == null) return false
            const newDate = DateTime.fromISO(date)

            return newDate.isValid
        }),
    files: array().required("required"),
})
