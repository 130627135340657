import { useSelector } from "../../../../../redux/store"
import { useRentingPeriodInventoryProcessesQueryFn, useRentingPeriodInventoryProcessSectionStepsQueryFn } from "../../../../../queries/inventory"
import React, { useEffect, useMemo, useState } from "react"
import { Box, Step, StepContent, StepLabel, Stepper } from "@mui/material"
import InventoryItemContent from "./InventoryItemContent"
import InventoryProcessSummaryStep from "./InventoryProcessSummaryStep"

export interface InventorySectionStepProps {
    id: number
    onNext: () => void
    onPrevious: () => void
    isFirstSection: boolean
}

const InventorySectionStep = ({ id, onNext, onPrevious, isFirstSection }: InventorySectionStepProps) => {
    const { editingItem } = useSelector((state) => state.appState)
    const { data: allRentingPeriodProcesses } = useRentingPeriodInventoryProcessesQueryFn()
    const ourProcess = useMemo(() => {
        return allRentingPeriodProcesses?.find((i) => i.id === editingItem?.id)
    }, [allRentingPeriodProcesses, editingItem])
    const ourSection = useMemo(() => {
        return ourProcess?.sections.find((i) => i.id === id)
    }, [id, ourProcess])
    const { data: items } = useRentingPeriodInventoryProcessSectionStepsQueryFn(ourProcess?.id, ourSection?.id)
    const [activeStep, setActiveStep] = useState<{ id: number; index: number } | undefined>()

    useEffect(() => {
        if (ourProcess && activeStep === undefined && items) {
            const firstItemNotHandled =
                items.findIndex((i) => i.isHandled !== true) === -1 ? items.length - 1 : items.findIndex((i) => i.isHandled !== true)
            setActiveStep({ id: items[firstItemNotHandled]?.id, index: firstItemNotHandled })
        }
    }, [ourProcess, activeStep, items])

    useEffect(() => {
        if (id != null) {
            setActiveStep(undefined)
        }
    }, [id])

    if (id === -1) {
        return (
            <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                <InventoryProcessSummaryStep onPrevious={onPrevious} />
            </Box>
        )
    }

    return (
        <>
            <Box display={"flex"} flexDirection={"column"} width={"100%"}>
                <Stepper activeStep={activeStep?.index} orientation={"vertical"}>
                    {items?.map((step, index) => {
                        return (
                            <Step key={step.id} completed={index < (activeStep?.index ?? 0)}>
                                <StepLabel>{step.itemName}</StepLabel>
                                <StepContent>
                                    <InventoryItemContent
                                        item={step}
                                        inventoryProcessId={ourProcess?.id ?? 0}
                                        inventorySectionId={ourSection?.id ?? 0}
                                        isFirst={index === 0 && isFirstSection}
                                        onPrevious={() => {
                                            if (index === 0) {
                                                onPrevious()
                                            }
                                            setActiveStep({
                                                id: items?.at(index - 1)?.id ?? 0,
                                                index: index - 1,
                                            })
                                        }}
                                        onNext={() => {
                                            if (index === items?.length - 1) onNext()
                                            else {
                                                setActiveStep({
                                                    id: items?.at(index + 1)?.id ?? 0,
                                                    index: index + 1,
                                                })
                                            }
                                        }}
                                    />
                                </StepContent>
                            </Step>
                        )
                    })}
                </Stepper>
            </Box>
        </>
    )
}

export default InventorySectionStep
