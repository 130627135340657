import { PersonType, RHFDatePicker, RHFRadioGroup, RHFTextField, RHFUpload, useIsMobile, useLocales } from "rentzz"
import React, { useMemo } from "react"
import { Box, Button } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { useFormContext, useWatch } from "react-hook-form"
import { AddPropertyOwnerRepresentativeRequest, IdentifierType } from "../../../../../mutations/property-owners"

// Props for the Representation Details step
export interface RepresentationDetailsProps {
    isPending: boolean
    onPrevious: () => void
    isEditing?: boolean
}

// CI fields configuration for better maintainability
const CI_FIELD_CONFIG = [
    {
        row: 1,
        fields: [
            { name: "identificationDocumentSeries", labelKey: "property_owners.representatives.series" },
            { name: "identificationDocumentNumber", labelKey: "property_owners.representatives.number" },
        ],
    },
    {
        row: 2,
        fields: [
            { name: "identificationDocumentIssuer", labelKey: "property_owners.representatives.issuer" },
            { name: "identificationDocumentIssuedDate", labelKey: "property_owners.representatives.issued_date", isDate: true },
        ],
    },
]

// Representation Details Component
const RepresentationDetails = ({ isPending, onPrevious, isEditing = false }: RepresentationDetailsProps) => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const { control } = useFormContext<AddPropertyOwnerRepresentativeRequest>()

    // Watch the personType to conditionally render CI fields
    const personType = useWatch({
        control,
        name: "personType",
    })

    // Watch the identifierType to conditionally render custom identifier field
    const identifierType = useWatch({
        control,
        name: "identifierType",
    })

    // Memoize the person type check to prevent unnecessary recalculations
    const isNaturalPerson = useMemo(() => personType == PersonType.Natural, [personType])

    // Determine if using custom identifier type
    const isUsingCustom = useMemo(() => identifierType == IdentifierType.Custom, [identifierType])

    // Radio options for identifier type
    const identifierTypeOptions = useMemo(
        () => [
            { value: IdentifierType.CI, label: translate("property_owners.representatives.id_type_ci") },
            { value: IdentifierType.Passport, label: translate("property_owners.representatives.id_type_passport") },
            { value: IdentifierType.Custom, label: translate("property_owners.representatives.id_type_custom") },
        ],
        [translate],
    )

    // Render CI fields based on configuration
    const renderCIFields = useMemo(() => {
        if (!isNaturalPerson) return null

        return CI_FIELD_CONFIG.map((rowConfig, rowIndex) => (
            <Box key={`ci-row-${rowIndex}`} display='flex' flexDirection={isMobile ? "column" : "row"} gap={2}>
                {rowConfig.fields.map((field) =>
                    field.isDate ? (
                        <RHFDatePicker key={field.name} name={field.name} label={translate(field.labelKey)} required />
                    ) : (
                        <RHFTextField key={field.name} name={field.name} label={translate(field.labelKey)} required />
                    ),
                )}
            </Box>
        ))
    }, [isNaturalPerson, isMobile, translate])

    return (
        <Box display='flex' flexDirection='column' gap={2}>
            {/* Date fields */}
            <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={2}>
                <RHFDatePicker name='startDate' label={translate("property_owners.representatives.start_date")} required />
                <RHFDatePicker name='endDate' label={translate("property_owners.representatives.end_date")} required />
            </Box>

            {/* Identifier Type Selection - only shown for natural persons */}
            {isNaturalPerson && (
                <Box display='flex' flexDirection={"column"} gap={2} alignItems='center' justifyContent='center'>
                    <RHFRadioGroup name='identifierType' options={identifierTypeOptions} />

                    {/* Custom Identifier Type field - only shown when Custom is selected */}
                    {isUsingCustom && (
                        <RHFTextField
                            name='customIdentifierType'
                            label={translate("property_owners.representatives.custom_identifier_type")}
                            required
                        />
                    )}
                </Box>
            )}

            {/* CI fields - only shown for natural persons using CI identifier */}
            {renderCIFields}

            {/* File Upload using RHFUpload - only shown when adding a new representative */}
            {!isEditing && <RHFUpload name='files' multiple icon='mdi:file-document-plus-outline' />}

            {/* Form actions */}
            <Box display='flex' gap={2} justifyContent='flex-end'>
                <Button onClick={onPrevious} color='inherit'>
                    {translate("previous")}
                </Button>
                <LoadingButton type='submit' variant='contained' color='primary' loading={isPending}>
                    {translate("save")}
                </LoadingButton>
            </Box>
        </Box>
    )
}

export default RepresentationDetails
