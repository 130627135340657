import { FormProvider, RHFSelect, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import React from "react"
import { Box, Button, MenuItem } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { InventoryProcessType } from "../../../../../redux/slices/AddProperty"

interface Props {
    onClose: () => void
    onSave: (data: StartInventoryProcessRequest) => void
    isLoading: boolean
}

export interface StartInventoryProcessRequest {
    type: InventoryProcessType
}

export default function StartInventoryProcessForm({ onSave, onClose, isLoading }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()

    const methods = useForm<StartInventoryProcessRequest>({
        mode: "onChange",
        defaultValues: {
            type: InventoryProcessType.Handover,
        },
    })

    const { handleSubmit } = methods

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSave)}>
                <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                    <RHFSelect name={"type"} label={translate("inventory_process_type")} required>
                        {Object.keys(InventoryProcessType)
                            .filter((key) => Number(key) || key === "0")
                            .map((type) => (
                                <MenuItem key={type} value={type}>
                                    {translate(`inventory-process-type-${type}`)}
                                </MenuItem>
                            ))}
                    </RHFSelect>

                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            paddingX: 0,
                            paddingTop: 2,
                            gap: 2,
                            flexDirection: isMobile ? "column-reverse" : "row",
                        }}
                    >
                        <Button color={"primary"} onClick={onClose} disabled={isLoading}>
                            {translate("cancel")}
                        </Button>
                        <LoadingButton type='submit' variant='contained' color={"primary"} loading={isLoading} disabled={isLoading}>
                            {translate("submit")}
                        </LoadingButton>
                    </Box>
                </Box>
            </FormProvider>
        </>
    )
}
