import { DetailsSheet, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useMemo } from "react"
import { CustomSheetOption } from "rentzz/lib/components/DetailsSheet"
import { MOBILE_ICON_SIZE } from "../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import { Box } from "@mui/material"
import { renderActionSheetInfo } from "../../../guards/alertsGuard/utils"
import { DateTime } from "luxon"
import DeleteIcon from "@mui/icons-material/Delete"
import { getSignatureRequestByIdQueryFn } from "../../../queries/signature"
import { getSigningRequestStatusColor } from "../../expenses/utils"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { PropertyFileType } from "../../../redux/slices/AddProperty"

export default function SignatureRequestDetailsSheet() {
    const { translate } = useLocales()
    const theme = useTheme()
    const dispatch = useDispatch()
    const { modalOpen, editingItem, context } = useSelector((state) => state.appState)
    const signatureRequest = getSignatureRequestByIdQueryFn(editingItem?.id)

    const options = useMemo((): CustomSheetOption[] => {
        return [
            {
                icon: <CloudDownloadIcon color={"info"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "see_files",
                onClick: () => {
                    dispatch(
                        setEditingItem({
                            id: signatureRequest?.id,
                            type: PropertyFileType.SignatureRequest,
                            canWrite: false,
                        }),
                    )
                    dispatch(setModalOpen(AppModals.EntityFileManager))
                },
            },
            {
                icon: <DeleteIcon color={"error"} sx={{ fontSize: MOBILE_ICON_SIZE }} />,
                title: "grid_actions.delete",
                hidden: !signatureRequest?.canDelete,
                onClick: () => {
                    dispatch(setModalOpen(AppModals.DeleteSignatureRequest))
                },
            },
        ]
    }, [signatureRequest, dispatch, context])

    return (
        <DetailsSheet
            isOpen={modalOpen === AppModals.SignatureRequestDetails}
            onClose={() => {
                dispatch(setModalOpen(undefined))
                dispatch(setEditingItem(undefined))
            }}
            isLoading={false}
            title={signatureRequest?.documentName}
            options={options}
        >
            <Box p={1}>
                {renderActionSheetInfo(
                    "mdi:info",
                    `${translate("document_internal_id")}: ${signatureRequest?.internalId}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
                {renderActionSheetInfo(
                    "mdi:info",
                    translate(`signature_request_status-${signatureRequest?.status}`),
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                    true,
                    "",
                    getSigningRequestStatusColor(signatureRequest?.status),
                )}

                {renderActionSheetInfo(
                    "mdi:calendar-edit-outline",
                    `${translate("createdAt")}: ${signatureRequest?.createdAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}

                {renderActionSheetInfo(
                    "mdi:calendar-edit-outline",
                    `${translate("lastModifiedAt")}: ${signatureRequest?.lastModifiedAt?.toLocaleString(DateTime.DATE_SHORT)}`,
                    theme.palette.primary.main,
                    theme.palette.grey["600"],
                )}
            </Box>
        </DetailsSheet>
    )
}
