import { DashboardChartType, SummaryCardType } from "../../queries/userData"
import { LoadingIcon } from "rentzz"
import { useDispatch } from "../../redux/store"
import React, { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../redux/slices/App"
import { DashboardItemType } from "../user/dashboard-preview/DashboardItemsPreview"
import CustomDragAndDropBox from "../../components/CustomDragAndDropBox"
import Chart from "../../components/Chart"

interface Props {
    charts: DashboardChartType[]
    isPublic?: boolean
}
const ChartsRow = ({ charts, isPublic }: Props) => {
    const dispatch = useDispatch()

    const handleAddChart = useCallback(() => {
        dispatch(setModalOpen(AppModals.DashboardItemsPreview))
        dispatch(setEditingItem({ itemType: DashboardItemType.Chart }))
    }, [dispatch])

    const renderCard = useCallback(
        (card: SummaryCardType | DashboardChartType) => <Chart chartId={(card as DashboardChartType).dashboardChartId} isPublic={isPublic} />,
        [isPublic],
    )
    if (!charts) return <LoadingIcon />

    return <CustomDragAndDropBox items={charts ?? []} onAdd={handleAddChart} renderItems={renderCard} droppableId={"chart-row"} isPublic={isPublic} />
}

export default ChartsRow
