import {
    Annotation,
    Inject,
    Magnification,
    Navigation,
    PdfViewerComponent,
    Print,
    Toolbar,
    ToolbarSettingsModel,
} from "@syncfusion/ej2-react-pdfviewer"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { AddSigningRequest, Recipient } from "./types"
import { blobToBase64 } from "../../../utils/actions"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../queries/userData"
import { Box, Button, Link } from "@mui/material"
import { CustomStampSettings } from "@syncfusion/ej2-pdfviewer/src/pdfviewer/pdfviewer"
import AddIcon from "@mui/icons-material/Add"
import { RHFCheckbox, useLocales } from "rentzz"
import { groupBy } from "lodash"
import { LoadingButton } from "@mui/lab"

export interface PDFSignaturePickerProps {
    onSend: () => void
    isLoading: boolean
}

const PDFSignaturePicker = ({ onSend, isLoading }: PDFSignaturePickerProps) => {
    const pdfRef = useRef<PdfViewerComponent>(null)
    const methods = useFormContext<AddSigningRequest>()
    const { getValues, setValue, watch } = methods
    const backendPath = useFeatureValue(UserFlags.BackendPath, "")
    const isWhitelabel = useFeatureIsOn(UserFlags.WhiteLabel as string)
    const { translate } = useLocales()
    const [areTermsAccepted, setAreTermsAccepted] = useState(false)

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === "acceptTerms") {
                setAreTermsAccepted(value.acceptTerms ?? false)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        if (isWhitelabel) {
            setAreTermsAccepted(true)
            setValue("acceptTerms", true)
        }
    }, [isWhitelabel])

    useEffect(() => {
        const item = URL.createObjectURL(getValues("files").at(0)!)
        fetch(item)
            .then((res) => res.blob())
            .then((blob) => blobToBase64(blob))
            .then((r) => {
                pdfRef.current?.load(r, "")
            })

        return () => pdfRef.current?.unload()
    }, [])

    const handleNewSignature = useCallback((r: Recipient) => {
        const canvas = document.createElement("canvas")
        const ctx = canvas.getContext("2d")
        // Fill the entire canvas with a background color (e.g., light blue)
        ctx!.fillStyle = "lightblue"
        ctx!.fillRect(0, 0, canvas.width, canvas.height)

        // Set text properties: color, font, and alignment
        ctx!.fillStyle = "darkblue"
        ctx!.font = "30px Arial"
        ctx!.textAlign = "center"
        ctx!.textBaseline = "middle"

        // Add the text to the canvas (centered in this case)
        ctx!.fillText(r.email, canvas.width / 2, canvas.height / 2)

        const dataUrl = canvas.toDataURL()
        canvas.remove()

        pdfRef.current?.annotation.addAnnotation("Stamp", {
            offset: { x: 0, y: 0 },
            pageNumber: pdfRef.current.currentPageNumber,
            subject: r.email,
            author: r.email,
            height: 60,
            width: 150,
            customStamps: [
                {
                    customStampName: r.email,
                    customStampImageSource: dataUrl,
                },
            ],
            allowedInteractions: ["Move"],
        } as CustomStampSettings)
    }, [])

    const handleSendToSign = useCallback(() => {
        const signaturesByUser = groupBy(pdfRef.current?.annotations, "subject")
        const allUsers = getValues("recipients")
        Object.keys(signaturesByUser).forEach((mail) => {
            const index = allUsers.findIndex((r) => r.email === mail)
            const userSignatures = signaturesByUser[mail]
            userSignatures.forEach((signature, idx) => {
                setValue(`recipients.${index}.fields.${idx}`, {
                    x: signature.bounds!.x!,
                    y: signature.bounds!.y!,
                    height: signature.bounds!.height!,
                    width: signature.bounds!.width!,
                    pageNum: signature.pageIndex!,
                })
            })
        })

        onSend()
    }, [onSend])

    const toolbarItems = useMemo((): ToolbarSettingsModel => {
        return {
            toolbarItems: ["PageNavigationTool", "MagnificationTool", "DownloadOption", "PrintOption"],
        }
    }, [])

    return (
        <Box display='flex' flexDirection='row' pt={2} gap={2} className='pdf-signature-annotation-hidden'>
            <Box width='40%' display='flex' flexDirection='column' justifyContent='space-between' gap={2}>
                <Box display='flex' flexDirection='column'>
                    {getValues("recipients").map((r) => (
                        <Box display='flex' key={r.email} flexDirection='row' alignItems='center' justifyContent='space-between'>
                            <Box display='flex' flexDirection='column' gap={1}>
                                <Box>{r.name}</Box>
                                <Box>{r.email}</Box>
                            </Box>
                            <Button variant='contained' color='primary' startIcon={<AddIcon />} onClick={() => handleNewSignature(r)}>
                                {translate("signature")}
                            </Button>
                        </Box>
                    ))}
                </Box>
                <Box display='flex' flexDirection='column' gap={2}>
                    <RHFCheckbox
                        name='acceptTerms'
                        defaultChecked={false}
                        label={
                            <>
                                {translate("agree_with")}{" "}
                                <Link
                                    target='_blank'
                                    href={"https://www.esemneaza.ro/politica-de-confidentialitate"}
                                    style={{ textTransform: "lowercase" }}
                                >
                                    {translate("privacy_policy")}
                                </Link>{" "}
                                {translate("and")}{" "}
                                <Link target='_blank' href={"https://www.esemneaza.ro/termeni-si-conditii"} style={{ textTransform: "lowercase" }}>
                                    {translate("terms_and_conditions")}
                                </Link>{" "}
                                {translate("of_esemneaza_service")}
                            </>
                        }
                    />
                    <LoadingButton loading={isLoading} disabled={!areTermsAccepted} variant='contained' color={"primary"} onClick={handleSendToSign}>
                        {translate("send_to_sign")}
                    </LoadingButton>
                </Box>
            </Box>
            <Box width='60%' display='flex'>
                <PdfViewerComponent
                    ref={pdfRef}
                    toolbarSettings={toolbarItems}
                    style={{ height: "100%", width: "100%" }}
                    serviceUrl={`${backendPath}/pdf-viewer`}
                    enableCommentPanel={false}
                    ajaxRequestSettings={{
                        ajaxHeaders: [],
                        withCredentials: true,
                    }}
                >
                    <Inject services={[Toolbar, Magnification, Navigation, Annotation, Print]} />
                </PdfViewerComponent>
            </Box>
        </Box>
    )
}

export default PDFSignaturePicker
