import { DefaultCountry, FormProvider, Page, PersonalData, PersonType, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import * as jsonpatch from "fast-json-patch"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
    IdentifierType,
    useAddPropertyOwnerMutation,
    usePropertyOwnerAssociateProperty,
    useUpdatePropertyOwnerMutation,
} from "../../../mutations/property-owners"
import { useUserDataQueryFn } from "../../../queries/userData"
import { PropertyOwnerConfigSchema } from "../../../validations/property-owners"
import { Step, StepContent, StepLabel, Stepper } from "@mui/material"
import { AddPropertyOwnerSteps } from "./utils"
import OwnerDetailsStep from "./OwnerDetailsStep"
import PropertyAssociationStep from "./PropertyAssociationStep"
import { useSelector } from "../../../redux/store"
import { DateTime } from "luxon"

export interface PropertyOwnerRequest extends PersonalData {
    properties: {
        propertyId: number
        percentage: number
    }[]
    // Add identifier fields
    identifierType?: IdentifierType
    customIdentifierType?: string
    identificationDocumentSeries?: string
    identificationDocumentNumber?: string
    identificationDocumentIssuer?: string
    identificationDocumentIssuedDate?: string
}

interface Props {
    onClose: () => void
    isAssociatingProperties: boolean
    defaultValues?: PersonalData & {
        id?: string
        identifierType?: IdentifierType
        customIdentifierType?: string
        identificationDocumentSeries?: string
        identificationDocumentNumber?: string
        identificationDocumentIssuer?: string
        identificationDocumentIssuedDate?: string
    }
    defaultProperties?: { propertyId: number; ownershipPercentage: number }[]
}

export default function PropertyOwnerConfiguration({ onClose, isAssociatingProperties, defaultValues, defaultProperties }: Props) {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { editingItem } = useSelector((state) => state.appState)
    const { data: userData } = useUserDataQueryFn()
    const { mutateAsync: addPropertyOwner, isPending: isAddPropertyOwnerPending } = useAddPropertyOwnerMutation()
    const { mutateAsync: updatePropertyOwner } = useUpdatePropertyOwnerMutation()
    const [activeStep, setActiveStep] = useState<AddPropertyOwnerSteps>(
        isAssociatingProperties ? AddPropertyOwnerSteps.AssociatedProperties : AddPropertyOwnerSteps.OwnerDetails,
    )
    const { mutateAsync: associatePropertyToPropertyOwner, isPending: isPropertyAssociating } = usePropertyOwnerAssociateProperty()

    // Format the default properties if provided
    const formattedDefaultProperties = useMemo(
        () =>
            defaultProperties
                ? defaultProperties.map((p) => ({
                      propertyId: p.propertyId,
                      percentage: p.ownershipPercentage,
                  }))
                : [],
        [defaultProperties],
    )

    const methods = useForm<PropertyOwnerRequest>({
        resolver: yupResolver<PropertyOwnerRequest>(PropertyOwnerConfigSchema(isAssociatingProperties, defaultValues != null)),
        defaultValues: {
            personType: PersonType.Company,
            // Initialize the form with default properties if available
            properties: formattedDefaultProperties,
        },
    })
    const { handleSubmit, trigger, reset, control } = methods

    // This effect will run when defaultValues or defaultProperties change
    useEffect(() => {
        if (defaultValues) {
            // For editing an existing property owner's details
            reset({
                ...defaultValues,
                // @ts-expect-error when it's RO, it should be treated as a string
                county: defaultValues.country === DefaultCountry ? Number(defaultValues.county) : defaultValues.county,
                // @ts-expect-error when it's RO, it should be treated as a string
                city: defaultValues.country === DefaultCountry ? Number(defaultValues.city) : defaultValues.city,
                // @ts-expect-error when it's RO, it should be treated as a string
                street: defaultValues.country === DefaultCountry ? Number(defaultValues.street) : defaultValues.street,
                properties: formattedDefaultProperties, // Use formatted properties
                // Include identifier fields from defaultValues if they exist
                identifierType: defaultValues.identifierType || IdentifierType.CI,
                customIdentifierType: defaultValues.customIdentifierType,
                identificationDocumentSeries: defaultValues.identificationDocumentSeries,
                identificationDocumentNumber: defaultValues.identificationDocumentNumber,
                identificationDocumentIssuer: defaultValues.identificationDocumentIssuer,
                identificationDocumentIssuedDate: defaultValues.identificationDocumentIssuedDate,
            })
        } else if (isAssociatingProperties) {
            // For associating properties
            reset({
                properties: formattedDefaultProperties, // Use formatted properties
            })
        } else {
            // For adding a new property owner
            reset({
                personType: PersonType.Natural,
                country: DefaultCountry,
                email: userData?.email,
                firstName: userData?.firstName,
                lastName: userData?.lastName,
                properties: [],
                // Set default values for identifier fields
                identifierType: IdentifierType.CI,
                customIdentifierType: "",
                identificationDocumentSeries: "",
                identificationDocumentNumber: "",
                identificationDocumentIssuer: "",
                identificationDocumentIssuedDate: DateTime.now().toISODate(),
            })
        }
    }, [defaultValues, defaultProperties, isAssociatingProperties, formattedDefaultProperties])

    const getSteps = useCallback(() => {
        return [
            {
                title: translate("property_owner_details"),
            },
            {
                title: translate("associate_properties_to_property_owner"),
            },
        ]
    }, [translate])

    const renderActiveStep = useCallback(() => {
        switch (activeStep) {
            case AddPropertyOwnerSteps.OwnerDetails:
                return (
                    <OwnerDetailsStep
                        shouldSubmit={defaultValues != null}
                        onNext={async () => {
                            const result = await trigger([
                                "streetNumber",
                                "street",
                                "county",
                                "country",
                                "city",
                                "identifier",
                                "firstName",
                                "lastName",
                                "companyName",
                                // Add validation for new identifier fields
                                "identifierType",
                                "customIdentifierType",
                                "identificationDocumentSeries",
                                "identificationDocumentNumber",
                                "identificationDocumentIssuer",
                                "identificationDocumentIssuedDate",
                            ])
                            if (result) {
                                setActiveStep(AddPropertyOwnerSteps.AssociatedProperties)
                            }
                        }}
                    />
                )
            case AddPropertyOwnerSteps.AssociatedProperties:
                return <PropertyAssociationStep isAddPropertyOwnerPending={isAddPropertyOwnerPending || isPropertyAssociating} />
        }
    }, [activeStep, setActiveStep, isAddPropertyOwnerPending, isPropertyAssociating, defaultValues, control])

    const onSubmit = useCallback(
        async (data: PropertyOwnerRequest) => {
            try {
                if (defaultValues != null && defaultValues.id && !isAssociatingProperties) {
                    const operations = jsonpatch.compare(
                        {
                            ...defaultValues,
                            id: undefined,
                            lastModifiedAt: undefined,
                            properties: [],
                            identificationDocumentIssuedDate: defaultValues.identificationDocumentIssuedDate,
                        },
                        {
                            ...data,
                            id: undefined,
                            lastModifiedAt: undefined,
                            properties: [],
                            identificationDocumentIssuedDate: DateTime.now().toISODate(),
                        },
                    )
                    if (operations.length > 0) {
                        await updatePropertyOwner({ propertyOwnerId: defaultValues.id, operations })
                    }
                } else if (isAssociatingProperties) {
                    // Use Promise.all to wait for all property associations to complete
                    await Promise.all(
                        data.properties.map((property) =>
                            associatePropertyToPropertyOwner({
                                propertyOwnerId: editingItem?.id,
                                propertyId: property.propertyId,
                                ownershipPercentage: property.percentage,
                            }),
                        ),
                    )
                } else {
                    await addPropertyOwner({ data })
                }

                // Add a small delay to ensure data is refreshed before closing
                setTimeout(() => {
                    onClose()
                }, 100)
            } catch (error) {
                console.error("Error submitting property owner data:", error)
            }
        },
        [addPropertyOwner, associatePropertyToPropertyOwner, isAssociatingProperties, editingItem],
    )

    return (
        <Page
            title={translate("nomenclature")}
            propertyName={translate("property_owner_page_title")}
            paddingX={isMobile ? 1 : undefined}
            paddingBottom={isMobile ? 2 : undefined}
        >
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                {!isAssociatingProperties && !defaultValues && (
                    <Stepper activeStep={activeStep} alternativeLabel={!isMobile} orientation={isMobile ? "vertical" : "horizontal"}>
                        {getSteps().map((step, index) => {
                            return (
                                <Step key={step.title} completed={index < activeStep}>
                                    <StepLabel>{step.title}</StepLabel>
                                    {isMobile && <StepContent>{renderActiveStep()}</StepContent>}
                                </Step>
                            )
                        })}
                    </Stepper>
                )}
                {(!isMobile || isAssociatingProperties || (isMobile && defaultValues != null)) && renderActiveStep()}
            </FormProvider>
        </Page>
    )
}
