import { LoadingIcon, Page, useIsMobile, useLocales, useResponsive } from "rentzz"
import { Box, CircularProgress, Divider, TextField, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { alpha, useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback, useEffect, useState } from "react"
import { useDispatch } from "../../../redux/store"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import NoDataPage from "../../../components/NoDataPage"
import { orderBy } from "lodash"
import { useGeneralDocumentDetailsQuery } from "../../../queries/generalDocuments"
import Api from "../../../api/Api"
import { useParams } from "react-router"
import CustomPDFViewer from "../../../components/CustomPDFViewer"
import { notificationPattern } from "../notifications/utils"

export default function GeneralDocumentConfiguration() {
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const mdUp = useResponsive("up", "md")
    const dispatch = useDispatch()
    const [url, setUrl] = useState<string>("")
    const [isPreviewLoading, setIsPreviewLoading] = useState<boolean>(false)
    const { generalDocumentId } = useParams()
    const { data: generalDocumentDetails } = useGeneralDocumentDetailsQuery(Number(generalDocumentId))
    const [currentVariables, setCurrentVariables] = useState<{ [pattern: string]: string }>({})

    const handleGeneralDocumentPreview = useCallback(async () => {
        setIsPreviewLoading(true)
        const documentPreview = await Api.generateGeneralDocumentPdfRequestConfiguration(Number(generalDocumentId), currentVariables)
        const url = window.URL.createObjectURL(new Blob([documentPreview.blob]))
        if (isMobile) {
            dispatch(setEditingItem({ pdfUrl: url }))
        }
        setUrl(url)
        setIsPreviewLoading(false)
    }, [setIsPreviewLoading, setUrl, isMobile, generalDocumentId, currentVariables])

    useEffect(() => {
        handleGeneralDocumentPreview()
    }, [handleGeneralDocumentPreview])

    const handleBlur = async (pattern: string, event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setCurrentVariables((prev) => ({
            ...prev,
            [pattern]: event.target.value,
        }))
    }

    if (generalDocumentDetails == null) return <LoadingIcon />

    return (
        <Page title={translate("payment-notifications-title")} paddingTop={1} sx={{ color: theme.palette.grey.A700 }}>
            <Box paddingRight={isMobile ? 2 : undefined} display={"flex"} justifyContent={"flex-end"}>
                {isMobile && (
                    <LoadingButton
                        onClick={async () => {
                            await handleGeneralDocumentPreview()
                            dispatch(setModalOpen(AppModals.GeneralDocumentPreview))
                        }}
                        variant='contained'
                        color='primary'
                        loading={isPreviewLoading}
                        disabled={isPreviewLoading || false}
                    >
                        {translate("load_preview")}
                    </LoadingButton>
                )}
            </Box>

            <Box
                display='flex'
                width={"100%"}
                height={"100%"}
                flexDirection={mdUp ? "row" : "column"}
                paddingTop={2}
                paddingX={isMobile ? 2 : undefined}
                paddingBottom={isMobile ? 8 : undefined}
                gap={4}
                sx={{ color: theme.palette.grey.A700 }}
            >
                <Box
                    width={mdUp ? "50%" : "100%"}
                    height={"100%"}
                    sx={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        gap: 2,
                    }}
                >
                    <Box
                        width={"100%"}
                        display={"flex"}
                        flexDirection={"column"}
                        gap={2}
                        height={isMobile ? undefined : window.innerHeight - 320}
                        sx={{ overflowY: "auto" }}
                    >
                        {generalDocumentDetails?.variables.length === 0 ? (
                            <NoDataPage icon={"mdi:success-circle-outline"} noDataText={"no_variables"} />
                        ) : (
                            <>
                                <Box>
                                    <Typography fontWeight={"bold"}>{translate("configure_variables")}</Typography>
                                    <Divider sx={{ marginTop: 1, backgroundColor: theme.palette.grey["500"] }} />
                                </Box>

                                {orderBy(generalDocumentDetails?.variables, "pattern").map((variable, index) => (
                                    <Box key={index} display={"flex"} gap={2}>
                                        <TextField
                                            size={"small"}
                                            label={translate("variable")}
                                            defaultValue={variable.pattern.replace(notificationPattern, "")}
                                            sx={{ width: isMobile ? "100%" : "50%" }}
                                            inputProps={{ min: 0 }}
                                            disabled
                                        />
                                        <TextField
                                            size={"small"}
                                            label={translate("value")}
                                            defaultValue={""}
                                            sx={{ width: isMobile ? "100%" : "50%" }}
                                            inputProps={{ min: 0 }}
                                            onBlur={(event) => handleBlur(variable.pattern, event)}
                                        />
                                    </Box>
                                ))}
                            </>
                        )}
                    </Box>
                </Box>

                {!isMobile && (
                    <Box width={mdUp ? "50%" : "100%"} height={window.innerHeight - 160} position='relative'>
                        {isPreviewLoading && (
                            <Box
                                sx={{
                                    position: "absolute",
                                    zIndex: 1000,
                                    top: 0,
                                    left: 0,
                                    backgroundColor: alpha(theme.palette.grey.A700, 0.4),
                                    width: "100%",
                                    height: "100%",
                                    opacity: 0.5,
                                }}
                            />
                        )}

                        <Box width='100%' height='100%' display='flex' alignItems='center' justifyContent='center'>
                            {url && <CustomPDFViewer height='100%' width='100%' url={url} />}

                            {isPreviewLoading && (
                                <Box
                                    sx={{
                                        position: "absolute",
                                        zIndex: 1000,
                                        top: "50%",
                                        left: "50%",
                                        transform: "translate(-50%, -50%)",
                                    }}
                                >
                                    <Box width='100%' display='flex' alignItems='center' justifyContent='center' height={300}>
                                        <CircularProgress size={"2rem"} />
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    </Box>
                )}
            </Box>
        </Page>
    )
}
