import { Page, useIsMobile, useLocales } from "rentzz"
import { useSelector } from "../../../../../redux/store"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import MobileInventoryProcessesList from "./MobileInventoryProcessesList"
import DesktopInventoryProcessesList from "./DesktopInventoryProcessesList"

export default function InventoryPage() {
    const isMobile = useIsMobile()
    const { translate } = useLocales()
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    return (
        <Page
            title={translate("inventory_processes")}
            propertyName={rentingPeriodDetails?.internalId ? translate(rentingPeriodDetails?.internalId) : ""}
            paddingBottom={2}
            paddingTop={isMobile ? 1 : 0}
        >
            {isMobile ? <MobileInventoryProcessesList /> : <DesktopInventoryProcessesList />}
        </Page>
    )
}
