import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteContent from "../../../../../components/DeleteContent"
import { deletePropertyOwnerMutation } from "../../../../../mutations/property-owners"

export default function DeletePropertyOwner() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deletePropertyOwner, isPending: isDeletingPropertyOwner } = deletePropertyOwnerMutation()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch, isMobile])

    const onDelete = useCallback(async () => {
        if (editingItem?.id == null) return
        await deletePropertyOwner(editingItem.id)
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [editingItem, deletePropertyOwner, dispatch])

    return (
        <>
            <CustomDialog
                title={translate("delete")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeletePropertyOwner}
                onClose={onClose}
                isFullScreen={false}
                variantCloseButton={"text"}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_property_owner_content")}
                    isLoading={isDeletingPropertyOwner}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeletePropertyOwner}
                onClose={onClose}
                isLoading={false}
                title={translate("delete")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_property_owner_content")}
                    isLoading={isDeletingPropertyOwner}
                />
            </DetailsSheet>
        </>
    )
}
