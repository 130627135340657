import { CustomDataGrid, Label, useLocales } from "rentzz"
import { useDispatch } from "../../../../redux/store"
import { RoleName, UserBankAccountConsent } from "../../../../queries/userData"
import React, { useCallback, useMemo, useState } from "react"
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { AppModals, setEditingItem, setModalOpen } from "../../../../redux/slices/App"
import { DateTime } from "luxon"
import NoDataPage from "../../../../components/NoDataPage"
import CustomDetailPanelToggle from "../../../expenses/CustomDetailPanelToggle"
import ActionWithTooltip from "../../../../utils/ActionWithTooltip"
import { DESKTOP_ICON_SIZE } from "../../../../utils/helpers"
import DeleteIcon from "@mui/icons-material/Delete"
import CustomRoleBlockedToolbar from "../../../../components/CustomRoleBlockedToolbar"
import { getBankAccountConsentStatusColor } from "./utils"
import useCustomSortModel from "../../../../hooks/useCustomSortModel"
import { useUserBankAccountConsentsQuery } from "../../../../queries/bankAccountConsents"
import DesktopConsentAssociatedBankAccounts from "./DesktopConsentAssociatedBankAccounts"

export default function DesktopBankAccountsConsents() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const { sortModel, setSortModel } = useCustomSortModel("DesktopBankAccountsConsents", [{ field: "createdAt", sort: "desc" }])
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    })
    const { data: bankAccountConsents, isLoading: areBankAccountConsentsLoading } = useUserBankAccountConsentsQuery(
        paginationModel.page,
        paginationModel.pageSize,
        sortModel,
    )

    const getConsentBankAccounts = useCallback((row: GridRowParams<UserBankAccountConsent>) => {
        return <DesktopConsentAssociatedBankAccounts currentConsent={row} />
    }, [])

    const actionsColumn = useMemo(
        (): GridColDef => ({
            field: "actions",
            type: "actions",
            minWidth: 80,
            flex: 0.1,
            disableReorder: true,
            headerName: translate("tenant-data.actions"),
            getActions: (params: GridRowParams) => [
                ActionWithTooltip({
                    key: "delete",
                    tooltipText: translate("delete"),
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setModalOpen(AppModals.DeleteBankAccountConsent))
                                dispatch(setEditingItem({ id: params.id }))
                            }}
                            label={translate("delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                }),
            ],
        }),
        [translate, dispatch],
    )

    const columns = useMemo(() => {
        const columns: GridColDef<UserBankAccountConsent>[] = [
            {
                ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
                renderCell: (params) => {
                    return <CustomDetailPanelToggle id={params.id} value={params.value} title={"view_consent_bank_accounts"} />
                },
            },
            {
                field: "bankCode",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("bank"),
                valueFormatter: (value) => translate(`bank-name-${value}`),
            },
            {
                field: "consentStatus",
                minWidth: 160,
                headerAlign: "center",
                align: "center",
                flex: 0.2,
                headerName: translate("consentStatus"),
                renderCell: (v) => {
                    return (
                        <Label color={getBankAccountConsentStatusColor(v.value)} style={{ textTransform: "none" }}>
                            {translate(`consentStatus-${v.value as number}`)}
                        </Label>
                    )
                },
            },
            {
                field: "createdAt",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("createdAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "lastModifiedAt",
                type: "date",
                minWidth: 100,
                flex: 0.2,
                headerAlign: "center",
                align: "center",
                headerName: translate("lastModifiedAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [translate])

    return (
        <>
            <CustomRoleBlockedToolbar
                blockedRoles={[RoleName.FREE, RoleName.BRONZE, RoleName.SILVER]}
                onAddClick={() => dispatch(setModalOpen(AppModals.AddBankAccountConsent))}
                buttonText={"add_bank_account_consent"}
            />
            <CustomDataGrid
                columns={columns}
                gridId={"DesktopBankAccountsConsents"}
                actionsColumn={[actionsColumn]}
                rows={bankAccountConsents?.items ?? []}
                infoColumn={[]}
                idKey={"id"}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={"mdi:sync-off"} noDataText={"no_bank_account_consent"} />,
                }}
                isLoading={areBankAccountConsentsLoading}
                rowHeight={50}
                getDetailPanelContent={getConsentBankAccounts}
                onSortModelChange={setSortModel}
                onPaginationModelChange={setPaginationModel}
            />
        </>
    )
}
