import { ConsentStatus } from "../../../../queries/userData"

export const getBankAccountConsentStatusColor = (value: ConsentStatus | undefined) => {
    switch (value) {
        case ConsentStatus.Received:
            return "warning"
        case ConsentStatus.Rejected:
            return "error"
        case ConsentStatus.Valid:
            return "success"
        case ConsentStatus.Expired:
            return "info"
    }
}
