import { useDispatch, useSelector } from "../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import * as React from "react"
import { useCallback } from "react"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import DeleteContent from "../../../components/DeleteContent"
import { createReversalInvoiceFromExpenseMutation } from "../../../mutations/expenses"
import { useSingleExpenseQuery } from "../../../queries/expenses"

export default function ConfirmReversalCreationExpenseGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: reversalInvoiceExpense, isPending: isReversalInvoiceExpenseLoading } = createReversalInvoiceFromExpenseMutation()
    const { data: currentExpense } = useSingleExpenseQuery(editingItem?.id)

    const onClose = useCallback(() => {
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [dispatch])

    const createReversalInvoice = useCallback(async () => {
        if (currentExpense == null) return
        await reversalInvoiceExpense({
            expenseId: currentExpense?.id,
        })

        onClose()
    }, [currentExpense, reversalInvoiceExpense, onClose])

    return (
        <>
            <CustomDialog
                title={translate("create_reversal_invoice")}
                maxWidth={"md"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.CreateReversalInvoice}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={createReversalInvoice}
                    deleteText={translate("create_reversal_invoice_content", {
                        expenseName: currentExpense?.name,
                    })}
                    isLoading={isReversalInvoiceExpenseLoading}
                    submitText={"create_reversal_invoice"}
                    buttonColor={"primary"}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.CreateReversalInvoice}
                onClose={onClose}
                isLoading={false}
                title={translate("create_reversal_invoice")}
                options={[]}
                headerColor={theme.palette.warning.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={createReversalInvoice}
                    deleteText={translate("create_reversal_invoice_content", {
                        expenseName: currentExpense?.name,
                    })}
                    isLoading={isReversalInvoiceExpenseLoading}
                    submitText={"create_reversal_invoice"}
                    buttonColor={"primary"}
                />
            </DetailsSheet>
        </>
    )
}
