import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import React, { useCallback } from "react"
import { Box, Button } from "@mui/material"
import { setModalOpen } from "../../../redux/slices/App"
import { useDispatch } from "../../../redux/store"
import { AddPropertyPage, setActiveStep, setInternalId, setLabelId, setPropertyName } from "../../../redux/slices/AddProperty"
import { PropertyNameSchema } from "../../../validations/propertyName"
import RHFLabelInput from "../../../components/RHFLabelInput"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { LabelType, UserFlags, useUserPropertiesQuery } from "../../../queries/userData"

type AddPropertyNameRequest = {
    propertyName: string
    labelId?: number
    internalId?: string
}

export default function AddPropertyName() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const isMobile = useIsMobile()
    const { data: userProperties } = useUserPropertiesQuery()
    const isInternalIdOn = useFeatureIsOn(UserFlags.PropertyInternalId.toString())

    const methods = useForm<AddPropertyNameRequest>({
        resolver: yupResolver(PropertyNameSchema),
        mode: "onBlur",
        defaultValues: {
            propertyName: "",
            labelId: undefined,
            internalId: "",
        },
    })

    const { handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: AddPropertyNameRequest) => {
            dispatch(setPropertyName(data.propertyName))
            dispatch(setLabelId(data.labelId))
            dispatch(setInternalId(data.internalId))
            dispatch(setActiveStep(AddPropertyPage.Details))
        },
        [dispatch],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display='flex' flexDirection='column' gap={2} marginTop={3}>
                <Box display='flex' mt={1} marginX={5}>
                    <RHFTextField name='propertyName' label={translate("property_name")} required />
                </Box>
                {isInternalIdOn && (
                    <Box display='flex' marginX={5}>
                        <RHFTextField name='internalId' label={translate("property_internal_id_header")} />
                    </Box>
                )}
                {(userProperties?.length ?? 0) > 0 && (
                    <Box display='flex' marginX={5}>
                        <RHFLabelInput name={"labelId"} type={LabelType.Property} />
                    </Box>
                )}
                <Box display='flex' flexDirection={isMobile ? "column-reverse" : "row"} justifyContent='flex-end' alignItems='center' gap={2}>
                    <Button fullWidth={isMobile} size='medium' color='primary' onClick={() => dispatch(setModalOpen(undefined))}>
                        {translate("cancel")}
                    </Button>
                    <Button fullWidth={isMobile} type='submit' size='medium' variant='contained' color={"primary"}>
                        {translate("next")}
                    </Button>
                </Box>
            </Box>
        </FormProvider>
    )
}
