import { CustomDialog, DetailsSheet, ExpenseAssignee, useIsMobile, useLocales } from "rentzz"
import { AppModals, setEditingItem, setIsRedirectPaymentButtonPressed, setModalOpen } from "../../../redux/slices/App"
import { Box, Button, CircularProgress, Typography } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import React, { useCallback } from "react"
import { useDispatch, useSelector } from "../../../redux/store"
import { useSingleExpenseQuery } from "../../../queries/expenses"
import { useTheme } from "@mui/material/styles"
import { getCurrencyFromId, useCurrencyQueryFn } from "../../../queries/currency"
import { useIntl } from "react-intl"
import { redirectToPaymentsPageMutation } from "../../../mutations/expenses"
import CardPaymentDetails from "../../../sections/expenses/online-payment/CardPaymentDetails"
import { useFeatureIsOn } from "@growthbook/growthbook-react"
import { UserFlags } from "../../../queries/userData"

export default function ExpenseOnlinePaymentGuard() {
    const dispatch = useDispatch()
    const theme = useTheme()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const intl = useIntl()
    const { data: currencyData } = useCurrencyQueryFn()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { data: currentExpense, isPending } = useSingleExpenseQuery(editingItem?.id)
    const { mutateAsync: redirectToPaymentPage, isPending: isRedirectPageLoading } = redirectToPaymentsPageMutation()
    const isRedirectingUserToProviderPaymentPage = useFeatureIsOn(UserFlags.RedirectUserToProviderPaymentPage.toString())

    const onClose = useCallback(() => {
        if (editingItem?.choosePayment) {
            return dispatch(setModalOpen(AppModals.TenantPayment))
        }
        if (isMobile) {
            dispatch(setModalOpen(AppModals.ExpenseDetails))
        } else {
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        }
    }, [dispatch, isMobile, editingItem])

    const getAmount = useCallback(
        (value: number | undefined, currencyId?: number) => {
            if (currencyData == null || !currencyId) return ""
            return intl.formatNumber(value as number, {
                style: "currency",
                currency: getCurrencyFromId(currencyData, currencyId).code,
            })
        },
        [currencyData],
    )

    const onSubmit = useCallback(async () => {
        if (currentExpense == null) return
        await redirectToPaymentPage({ expenseId: currentExpense.id })
        dispatch(setIsRedirectPaymentButtonPressed(true))
        dispatch(setEditingItem(undefined))
        dispatch(setModalOpen(undefined))
    }, [currentExpense, redirectToPaymentPage, onClose, dispatch])

    return (
        <>
            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.ExpensePayment}
                onClose={onClose}
                isLoading={currentExpense == null}
                title={translate("pay_this_expense")}
                headerColor={currentExpense?.assignee === ExpenseAssignee.ME ? theme.palette.primary.main : editingItem?.backgroundColor}
            >
                <Box display={"flex"} flexDirection={"column"} paddingTop={2}>
                    {!isRedirectingUserToProviderPaymentPage && <CardPaymentDetails />}
                    <Typography textAlign={"center"}>
                        {translate("pay_this_expense_details", {
                            expense: currentExpense?.name,
                            value: getAmount(currentExpense?.value, currentExpense?.currencyId),
                        })}
                    </Typography>
                    <Box
                        sx={{
                            alignItems: "center",
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            paddingY: 2,
                            gap: 2,
                        }}
                    >
                        <LoadingButton
                            onClick={onSubmit}
                            variant='contained'
                            color={"primary"}
                            loading={isRedirectPageLoading}
                            disabled={isRedirectPageLoading}
                            fullWidth
                        >
                            {translate("pay")}
                        </LoadingButton>
                        <Button onClick={onClose} variant={"text"} color={"primary"} fullWidth disabled={isRedirectPageLoading}>
                            {translate("cancel")}
                        </Button>
                    </Box>
                </Box>
            </DetailsSheet>

            <CustomDialog
                isFullScreen={false}
                title={translate("pay_this_expense")}
                onClose={onClose}
                open={!isMobile && modalOpen === AppModals.ExpensePayment}
                maxWidth='sm'
                onAccept={onSubmit}
                acceptText={translate("pay")}
                closeText={translate("cancel")}
                variantCloseButton={"text"}
                acceptButtonColor={"primary"}
                closeButtonColor={"primary"}
                isDisabled={isRedirectPageLoading}
                isLoading={isRedirectPageLoading}
            >
                {isPending ? (
                    <Box display={"flex"} justifyContent={"center"} height={300}>
                        <CircularProgress size={"2rem"} sx={{ alignSelf: "center" }} />
                    </Box>
                ) : (
                    <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} paddingTop={1} paddingBottom={2} gap={1}>
                        {!isRedirectingUserToProviderPaymentPage && <CardPaymentDetails />}
                        <Typography textAlign={"center"}>
                            {translate("pay_this_expense_details", {
                                expense: currentExpense?.name,
                                value: getAmount(currentExpense?.value, currentExpense?.currencyId),
                            })}
                        </Typography>
                    </Box>
                )}
            </CustomDialog>
        </>
    )
}
