import { Iconify, LoadingIcon } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { useDispatch, useSelector } from "../../../redux/store"
import React, { useEffect, useMemo, useRef } from "react"
import { PermissionType, usePermissions } from "../../../hooks/usePermissions"
import { Box, CircularProgress, Fab } from "@mui/material"
import { AppModals, setEditingItem, setModalOpen } from "../../../redux/slices/App"
import CustomAddCard from "../../../components/CustomAddCard"
import CustomMobileCard from "../../../components/CustomMobileCard"
import { DateTime } from "luxon"
import { RoleName, useUserDataQueryFn } from "../../../queries/userData"
import { useInfiniteInventorySectionsQuery } from "../../../queries/inventory"

export default function MobileInventorySectionsList() {
    const theme = useTheme()
    const dispatch = useDispatch()
    const { data: user } = useUserDataQueryFn()
    const ref = useRef<HTMLDivElement>(null)
    const { modalOpen, currentPropertyId } = useSelector((state) => state.appState)
    const {
        data: inventorySections,
        fetchNextPage,
        hasNextPage,
    } = useInfiniteInventorySectionsQuery(currentPropertyId, [{ field: "createdAt", sort: "desc" }])
    const { canWrite } = usePermissions(PermissionType.InventorySections)

    const observer = useMemo(
        () =>
            new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting && hasNextPage) {
                    fetchNextPage()
                }
            }),
        [hasNextPage],
    )

    useEffect(() => {
        if (ref?.current) {
            observer.observe(ref.current)
        }
        return () => {
            observer.disconnect()
        }
    }, [ref, observer])

    if (inventorySections == null) return <LoadingIcon />

    return (
        <>
            <Box gap={1} display={"flex"} flexDirection={"column"} style={{ height: "inherit", paddingBottom: 55 }}>
                {inventorySections.pages.some((p) => p.items.length > 0) &&
                    modalOpen == null &&
                    canWrite &&
                    user?.roles.roleName !== RoleName.BRONZE &&
                    user?.roles.roleName !== RoleName.FREE && (
                        <Fab
                            onClick={() => dispatch(setModalOpen(AppModals.AddInventorySection))}
                            sx={{ position: "fixed", bottom: 10, right: 10 }}
                            color='primary'
                            aria-label='add'
                        >
                            <Iconify icon={"mdi:plus"} width={25} height={25} />
                        </Fab>
                    )}
                {inventorySections.pages.map((group, i) => (
                    <React.Fragment key={i}>
                        {group.items.map((inventorySection) => (
                            <CustomMobileCard
                                key={inventorySection.id}
                                item={inventorySection}
                                onClick={() => {
                                    dispatch(setModalOpen(AppModals.InventorySectionDetails))
                                    dispatch(setEditingItem({ inventorySectionId: inventorySection.id }))
                                }}
                                icon={"mdi:box-outline"}
                                iconColor={theme.palette.primary.main}
                                title={inventorySection.sectionName}
                                subtitle={inventorySection.createdAt.toLocaleString(DateTime.DATE_SHORT)}
                            />
                        ))}
                    </React.Fragment>
                ))}
                {hasNextPage && (
                    <div ref={ref} style={{ justifyContent: "center", width: "100%", display: "flex", paddingTop: 10 }}>
                        <CircularProgress />
                    </div>
                )}
                {inventorySections.pages.some((p) => p.items.length === 0) && canWrite && (
                    <CustomAddCard
                        onCardClick={() => dispatch(setModalOpen(AppModals.AddInventorySection))}
                        icon={"mdi:box-add-outline"}
                        text={"add_inventory_section"}
                        isAddDisabled={user?.roles.roleName === RoleName.FREE || user?.roles.roleName === RoleName.BRONZE}
                        tooltipTitle={"permission_denied_action"}
                    />
                )}
            </Box>
        </>
    )
}
