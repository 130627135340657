import { useForm } from "react-hook-form"
import { useSelector } from "../../../redux/store"
import { useSingleRentingPeriodQuery } from "../../../queries/tenants"
import React, { useCallback, useEffect } from "react"
import { FormProvider, useLocales } from "rentzz"
import RHFLabelInput from "../../../components/RHFLabelInput"
import { LabelType } from "../../../queries/userData"
import { LoadingButton } from "@mui/lab"
import { updateRentingPeriodMutation } from "../../../mutations/tenants"
import { Box } from "@mui/material"

interface RentingPeriodLabelForm {
    labelId?: number | null
}

export interface EditingPeriodLabelFormProps {
    onClose: () => void
}

const EditRentingPeriodLabelForm = ({ onClose }: EditingPeriodLabelFormProps) => {
    const { translate } = useLocales()
    const { editingItem } = useSelector((state) => state.appState)
    const { data: currentRentingPeriod } = useSingleRentingPeriodQuery(editingItem?.id)
    const methods = useForm<RentingPeriodLabelForm>({
        defaultValues: {
            labelId: currentRentingPeriod?.labelId,
        },
    })
    const { mutate: updateRentingPeriod, isPending: isLoading } = updateRentingPeriodMutation()
    const { handleSubmit, setValue } = methods

    useEffect(() => {
        setValue("labelId", currentRentingPeriod?.labelId)
    }, [currentRentingPeriod])

    const handleLabelUpdate = useCallback(
        (data: RentingPeriodLabelForm) => {
            updateRentingPeriod(
                {
                    tenantId: editingItem.id,
                    operations: [
                        {
                            op: "replace",
                            path: "/labelId",
                            value: data.labelId,
                        },
                    ],
                },
                {
                    onSuccess: () => onClose(),
                },
            )
        },
        [editingItem, updateRentingPeriod],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(handleLabelUpdate)}>
            <Box display='flex' flexDirection={"column"} mt={2} gap={2}>
                <RHFLabelInput name={"labelId"} type={LabelType.RentingPeriod} />

                <LoadingButton color={"primary"} variant='contained' type={"submit"} loading={isLoading} disabled={isLoading}>
                    {translate("save")}
                </LoadingButton>
            </Box>
        </FormProvider>
    )
}

export default EditRentingPeriodLabelForm
