import { CustomDataGrid, LightTooltip, useLocales } from "rentzz"
import { useDispatch, useSelector } from "../../../../../redux/store"
import React, { useMemo, useState } from "react"
import { GridActionsCellItem, GridColDef, GridRowParams } from "@mui/x-data-grid-pro"
import { RoleName, useUserPropertiesQuery } from "../../../../../queries/userData"
import ActionWithTooltip from "../../../../../utils/ActionWithTooltip"
import { Typography } from "@mui/material"
import { DateTime } from "luxon"
import { DESKTOP_ICON_SIZE } from "../../../../../utils/helpers"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import CloudDownloadIcon from "@mui/icons-material/CloudDownload"
import { PropertyFileType } from "../../../../../redux/slices/AddProperty"
import ModeEditIcon from "@mui/icons-material/ModeEdit"
import DeleteIcon from "@mui/icons-material/Delete"
import NoDataPage from "../../../../../components/NoDataPage"
import { DiaryType, Journal, useJournalsQuery } from "../../../../../queries/journal"
import { PermissionType, usePermissions } from "../../../../../hooks/usePermissions"
import { useRentingPeriodDetails } from "../../../../../queries/tenants"
import TooltipRender from "./TooltipRender"
import * as humps from "humps"
import { useAtomValue } from "jotai"
import { journalsFilterState } from "../../../../../utils/atom"
import CustomRoleBlockedToolbar from "../../../../../components/CustomRoleBlockedToolbar"
import useCustomSortModel from "../../../../../hooks/useCustomSortModel"

export default function DesktopJournalList() {
    const { translate } = useLocales()
    const dispatch = useDispatch()
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 20,
        page: 0,
    })
    const { sortModel, setSortModel } = useCustomSortModel("DesktopJournalList", [{ field: "createdAt", sort: "desc" }])
    const filterModel = useAtomValue(journalsFilterState)
    const { currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: journals, isFetching } = useJournalsQuery(paginationModel.page, paginationModel.pageSize, sortModel, filterModel)
    const { data: userProperties } = useUserPropertiesQuery()
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { canWrite } = usePermissions(PermissionType.RentingPeriods, rentingPeriodDetails?.propertyId)

    const columns = useMemo(() => {
        const columns: GridColDef<Journal>[] = [
            {
                field: "parameters",
                width: 60,
                headerAlign: "center",
                align: "center",
                sortable: false,
                filterable: false,
                type: "string",
                headerName: "",
                renderCell: TooltipRender,
            },
            {
                field: "title",
                flex: 0.5,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("title"),
                valueGetter: (value, row) => {
                    if (row.type === DiaryType.DeliveredMail) {
                        return translate("mail_to", humps.camelizeKeys(JSON.parse(row.parameters)))
                    }
                    return row.isSystemDiaryEntry ? translate(value) : value
                },
            },
            {
                field: "description",
                flex: 0.5,
                minWidth: 120,
                headerAlign: "center",
                align: "center",
                type: "string",
                headerName: translate("description"),
                renderCell: (v) => (
                    <LightTooltip
                        title={
                            v.row.type === DiaryType.DeliveredMail
                                ? JSON.parse(v.row.parameters)["Title"]
                                : v.row.isSystemDiaryEntry
                                  ? translate(v.value)
                                  : v.value
                        }
                        arrow
                    >
                        <Typography fontSize={14} sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                            {v.row.type === DiaryType.DeliveredMail && JSON.parse(v.row.parameters)["Title"]}
                            {v.row.type !== DiaryType.DeliveredMail && (v.row.isSystemDiaryEntry ? translate(v.value) : v.value)}
                        </Typography>
                    </LightTooltip>
                ),
            },
            {
                field: "createdBy",
                type: "string",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("diary.createdBy"),
                filterable: true,
            },
            {
                field: "createdAt",
                type: "date",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("notes.addedAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
            {
                field: "lastModifiedBy",
                type: "string",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("diary.lastModifiedBy"),
                filterable: true,
            },
            {
                field: "lastModifiedAt",
                type: "date",
                minWidth: 100,
                flex: 0.5,
                headerAlign: "center",
                align: "center",
                headerName: translate("diary.lastModifiedAt"),
                filterable: true,
                valueFormatter: (value) => (value as DateTime).toLocaleString(DateTime.DATE_SHORT),
            },
        ]

        return columns
    }, [userProperties, translate])

    const actionsColumn: GridColDef<Journal> = useMemo(
        () => ({
            field: "actions",
            resizable: false,
            type: "actions",
            disableReorder: true,
            width: 120,
            headerName: translate("expenses.actions"),
            getActions: (params: GridRowParams<Journal & { canWrite: boolean; canDelete: boolean }>) => [
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color='primary'
                            icon={<ModeEditIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.id }))
                                dispatch(setModalOpen(AppModals.EditJournal))
                            }}
                            label={translate("grid_actions.edit")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: translate("edit"),
                    key: "edit",
                    hidden: !params.row.canWrite,
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"info"}
                            icon={<CloudDownloadIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={async (event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(
                                    setEditingItem({
                                        id: params.id,
                                        type: PropertyFileType.Journal,
                                        name: params.row.title,
                                        canWrite: params.row.canWrite,
                                        canDelete: params.row.canDelete,
                                    }),
                                )
                                dispatch(setModalOpen(AppModals.EntityFileManager))
                            }}
                            label={translate("see_files")}
                            sx={{ p: 1 }}
                        />
                    ),
                    hidden: params.row.isSystemDiaryEntry && params.row.type !== DiaryType.DeliveredMail,
                    tooltipText: translate("see_files"),
                    key: "download",
                }),
                ActionWithTooltip({
                    element: (
                        <GridActionsCellItem
                            color={"error"}
                            icon={<DeleteIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} />}
                            onClick={(event: React.MouseEvent<HTMLElement>) => {
                                event.stopPropagation()
                                dispatch(setEditingItem({ id: params.row.id }))
                                dispatch(setModalOpen(AppModals.DeleteJournal))
                            }}
                            label={translate("grid_actions.delete")}
                            sx={{ p: 1 }}
                        />
                    ),
                    tooltipText: "",
                    key: "delete",
                    hidden: !params.row.canDelete,
                }),
            ],
        }),
        [translate, dispatch],
    )

    return (
        <>
            {canWrite && (
                <CustomRoleBlockedToolbar
                    onAddClick={() => dispatch(setModalOpen(AppModals.AddJournal))}
                    buttonText={"add_journal"}
                    blockedRoles={[RoleName.FREE]}
                />
            )}
            <CustomDataGrid
                paginationMode={"server"}
                isLoading={isFetching}
                columns={columns}
                gridId={"DesktopJournalList"}
                rows={journals?.items ?? []}
                components={{
                    noRowsOverlay: () => <NoDataPage onAddNewItem={undefined} icon={"bi:journal-x"} noDataText={"no_journals"} />,
                }}
                idKey={"id"}
                sortModel={sortModel}
                onSortModelChange={setSortModel}
                sortingMode='server'
                totalCount={journals?.count}
                actionsColumn={[actionsColumn]}
                onPaginationModelChange={setPaginationModel}
                getRowId={(row) => row.id}
            />
        </>
    )
}
