import { FormProvider, RHFTextField, useIsMobile, useLocales } from "rentzz"
import { updateInventoryProcessMutation } from "../../../../../mutations/inventory"
import { useForm } from "react-hook-form"
import React, { useCallback } from "react"
import * as jsonpatch from "fast-json-patch"
import { Box, Button } from "@mui/material"
import { InventoryProcessStatus } from "../../../../../redux/slices/AddProperty"
import { LoadingButton } from "@mui/lab"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { setEditingItem, setModalOpen } from "../../../../../redux/slices/App"

export interface InventoryProcessSummaryStepProps {
    onPrevious?: () => void
}

interface InventoryProcessSummaryFormState {
    observation?: string
}

const InventoryProcessSummaryStep = ({ onPrevious }: InventoryProcessSummaryStepProps) => {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const dispatch = useDispatch()
    const { editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: updateInventoryItem, isPending: isUpdatingInventoryItem } = updateInventoryProcessMutation()
    const methods = useForm<InventoryProcessSummaryFormState>({
        defaultValues: {
            observation: "",
        },
    })
    const { handleSubmit } = methods

    const onSubmit = useCallback(
        async (data: InventoryProcessSummaryFormState) => {
            await updateInventoryItem({
                inventoryProcessId: editingItem.id,
                operations: jsonpatch.compare({ status: undefined, observation: undefined }, { ...data, status: InventoryProcessStatus.Completed }),
            })
            dispatch(setEditingItem(undefined))
            dispatch(setModalOpen(undefined))
        },
        [editingItem],
    )

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box display={"flex"} flexDirection={"column"} gap={2} marginTop={2}>
                <RHFTextField name='observation' multiline rows={3} label={translate("inventory_process_observation")} />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        paddingX: 0,
                        paddingTop: 2,
                        gap: 2,
                        flexDirection: isMobile ? "column-reverse" : "row",
                    }}
                >
                    <Button color={"primary"} onClick={onPrevious} disabled={isUpdatingInventoryItem}>
                        {translate("previous")}
                    </Button>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        color={"primary"}
                        loading={isUpdatingInventoryItem}
                        disabled={isUpdatingInventoryItem}
                    >
                        {translate("submit")}
                    </LoadingButton>
                </Box>
            </Box>
        </FormProvider>
    )
}

export default InventoryProcessSummaryStep
