import { GridRenderCellParams } from "@mui/x-data-grid-pro"
import { RecurringExpense } from "../../../queries/expenses"
import { Box, Divider, Typography } from "@mui/material"
import { allLangs, ExpenseAssignee, Label, LightTooltip, useLocales } from "rentzz"
import { DateTime } from "luxon"
import InfoIcon from "@mui/icons-material/Info"
import { DESKTOP_ICON_SIZE, getReverseTextColor } from "../../../utils/helpers"
import CancelIcon from "@mui/icons-material/Cancel"
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked"
import { getAssigneeLabelColor, getRecurringTypeLabelColor, getRecurringTypeLabelText } from "../utils"
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive"
import NotificationsOffIcon from "@mui/icons-material/NotificationsOff"
import ReceiptIcon from "@mui/icons-material/Receipt"
import React, { useCallback } from "react"
import { useCurrencyQueryFn } from "../../../queries/currency"
import { LabelType, UserFlags, useUserLabelsQuery, useUserProvidersQuery } from "../../../queries/userData"
import { useFeatureValue } from "@growthbook/growthbook-react"
import { PaymentType } from "../../../redux/slices/AddProperty"

export interface RecurringTypeProps {
    v: GridRenderCellParams<RecurringExpense>
}

const RecurringType = ({ v }: RecurringTypeProps) => {
    const { translate, currentLang } = useLocales()
    const { data: currencies } = useCurrencyQueryFn()
    const item: RecurringExpense | null = v.api.getRow(v.id!)
    const { data: labels } = useUserLabelsQuery(LabelType.Expense)
    const { data: providers } = useUserProvidersQuery()
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)

    const getLabelColor = useCallback(
        (value: number) => {
            return labels?.find((label) => label.id === value)?.color
        },
        [labels],
    )

    return (
        <Box gap={2} display='flex' alignItems='center'>
            <LightTooltip
                title={
                    <Box display='flex' flexDirection='column' justifyContent='center'>
                        <Typography variant='caption'>{translate("recurring_expenses.createdAt")}</Typography>
                        <Typography variant='caption'>{(item?.createdAt as DateTime).toLocaleString(DateTime.DATE_SHORT)}</Typography>
                        <Divider sx={{ marginY: 1 }} />
                        <Typography variant='caption'>{translate("recurring_expenses.modifiedAt")}</Typography>
                        <Typography variant='caption'>{(item?.modifiedAt as DateTime).toLocaleString(DateTime.DATE_SHORT)}</Typography>
                        <Divider sx={{ marginY: 1 }} />
                        <Typography variant='caption'>{translate("recurring_expenses.paymentType")}</Typography>
                        <Typography variant='caption'>
                            {translate(
                                v.row.paymentType === PaymentType.Expense
                                    ? `payment-subtype-${v.row.paymentSubtype}`
                                    : `income-table.incomeType-${v.row.paymentType}`,
                            )}
                        </Typography>
                        {item?.labelId && (
                            <>
                                <Divider sx={{ marginY: 1 }} />
                                <Typography variant='caption'>{translate("labelDescription")}</Typography>
                                <Label
                                    style={{
                                        textTransform: "none",
                                        backgroundColor: `#${getLabelColor(item?.labelId)}`,
                                        color: getReverseTextColor(`#${getLabelColor(item?.labelId)}`),
                                    }}
                                >
                                    {labels?.find((label) => label.id === item?.labelId)?.value}
                                </Label>
                            </>
                        )}
                        {item?.associatedProviderId && (
                            <>
                                <Divider sx={{ marginY: 1 }} />
                                <Typography variant='caption'>{translate("provider")}</Typography>
                                <Typography variant='caption'>{providers?.find((p) => p.id === item.associatedProviderId)?.name}</Typography>
                            </>
                        )}
                        {item?.assignee != null && (
                            <>
                                <Divider sx={{ marginY: 1 }} />
                                <Typography variant='caption'>{translate("expenses.assignee")}</Typography>
                                <Label color={getAssigneeLabelColor(item.assignee)} style={{ textTransform: "none" }}>
                                    {translate(`expenses.assignee-${item.assignee as number}${customPaidBy ? "-" + customPaidBy : ""}`)}
                                </Label>
                            </>
                        )}
                    </Box>
                }
                arrow
            >
                <InfoIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"info"} />
            </LightTooltip>
            <LightTooltip title={translate(`recurring-isActive-${item?.isActive}`)} arrow>
                {item?.isActive === false ? (
                    <CancelIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"error"} />
                ) : (
                    <RadioButtonCheckedIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"success"} />
                )}
            </LightTooltip>
            <LightTooltip title={translate(`recurring-type-${v.value}`)} arrow>
                <Label
                    color={getRecurringTypeLabelColor(v.value)}
                    style={{
                        textTransform: "none",
                    }}
                >
                    {translate(getRecurringTypeLabelText(v.value, currentLang.value, false))}
                </Label>
            </LightTooltip>
            {item?.assignee === ExpenseAssignee.TENANT && (
                <LightTooltip title={translate(`recurring-should-notify-${item.shouldNotifyOtherParty}`)} arrow>
                    {item.shouldNotifyOtherParty ? (
                        <NotificationsActiveIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"primary"} />
                    ) : (
                        <NotificationsOffIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"warning"} />
                    )}
                </LightTooltip>
            )}
            {item?.invoiceCompany && (
                <LightTooltip
                    title={
                        <Box display='flex' flexDirection='column' justifyContent='center'>
                            <Typography variant='caption'>{translate("invoicing_data")}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("invoicing_company")}</Typography>
                            <Typography variant='caption'>{item.invoiceCompany}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("invoicing_client")}</Typography>
                            <Typography variant='caption'>{item.invoiceClient}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("invoicing_series")}</Typography>
                            <Typography variant='caption'>{item.invoicingSeries}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("invoicing_language")}</Typography>
                            <Typography variant='caption'>{allLangs.find((lang) => lang.value === item.invoiceLanguage)?.label}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("renting_period_invoicing_value")}</Typography>
                            <Typography variant='caption'>{item.value}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("invoicing_currency")}</Typography>
                            <Typography variant='caption'>{currencies?.find((c) => c.id === item.invoiceCurrencyId)?.code}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("TVA")}</Typography>
                            <Typography variant='caption'>{item.invoiceTVA}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("invoicing_product_name")}</Typography>
                            <Typography variant='caption'>{item.invoiceProductName}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("invoicing_product_description")}</Typography>
                            <Typography variant='caption'>{item.invoiceProductDescription}</Typography>
                            <Divider style={{ marginTop: 8, marginBottom: 8 }} />
                            <Typography variant='caption'>{translate("invoicing_period")}</Typography>
                            <Typography variant='caption'>
                                {item.invoicingPeriodStart} - {item.invoicingPeriodEnd}
                            </Typography>
                        </Box>
                    }
                    arrow
                >
                    <ReceiptIcon sx={{ fontSize: DESKTOP_ICON_SIZE }} color={"info"} />
                </LightTooltip>
            )}
        </Box>
    )
}

export default RecurringType
