import {
    CustomFile,
    ExpenseAssignee,
    LightTooltip,
    RHFAutocomplete,
    RHFCheckbox,
    RHFDatePicker,
    RHFSelect,
    RHFTextField,
    RHFUnitInput,
    RHFUpload,
    useIsMobile,
    useLocales,
} from "rentzz"
import { Alert, Box, Button, Collapse, MenuItem, MenuItemProps } from "@mui/material"
import React, { useCallback, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { DateTime } from "luxon"
import { useRentingPeriodDetails } from "../../queries/tenants"
import { LabelType, UserFlags, useUserDataQueryFn } from "../../queries/userData"
import { useCurrencyQueryFn } from "../../queries/currency"
import { useSelector } from "../../redux/store"
import { PropertyExpense } from "../../queries/expenses"
import { PermissionType, usePermissions } from "../../hooks/usePermissions"
import { PaymentSubtype, PaymentType } from "../../redux/slices/AddProperty"
import { useFeatureIsOn, useFeatureValue } from "@growthbook/growthbook-react"
import RHFProviderInput from "../../components/RHFProviderInput"
import RHFLabelInput from "../../components/RHFLabelInput"
import RHFPaymentTypeInput from "../../components/RHFPaymentTypeInput"
import { ArrowDownward, ArrowUpward } from "@mui/icons-material"
import { DESKTOP_ICON_SIZE } from "../../utils/helpers"
import RHFPropertyRentingPeriodPicker from "../../components/RHFPropertyRentingPeriodPicker"

interface AddExpenseFormProps {
    isAddExpenseLoading: boolean
    defaultValues: (PropertyExpense & { prefilledExpense?: boolean; invoicingData?: unknown }) | undefined | null
    onClose: () => void
}

export interface AddExpenseBase {
    id?: number
    name: string
    assignee: number
    description?: string
    value: number
    date: string
    dueDate: string
    files: CustomFile[]
    currencyId: number
    propertyId?: number
    labelId?: number
    associatedProviderId?: number
    shouldNotifyOtherParty?: boolean
    rentingPeriodId?: number
    groupId?: number
    shouldCreateInvoice?: boolean
    invoicingData?: unknown
    // TODO Ioana: change to provider invoice currency or something similar
    ___?: number
}

export interface AddExpenseRequest extends AddExpenseBase {
    paymentType: PaymentType
    paymentSubtype?: PaymentSubtype
}

export interface AddExpenseFormData extends AddExpenseBase {
    paymentType: string | PaymentType
}

export default function AddExpenseForm({ defaultValues }: AddExpenseFormProps) {
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const [hasDescription, setHasDescription] = useState<boolean>(false)
    const { data: user } = useUserDataQueryFn()
    const { data: currencies } = useCurrencyQueryFn()
    const { currentPropertyId, errorMessage, currentGroupId, currentRentingPeriodId } = useSelector((state) => state.appState)
    const { data: rentingPeriodDetails } = useRentingPeriodDetails(currentRentingPeriodId)
    const { editableProperties } = usePermissions(PermissionType.Expenses, rentingPeriodDetails?.propertyId)
    const customPaidBy = useFeatureValue<string | null>(UserFlags.CustomPaidBy, null)
    const defaultAssignee = useFeatureValue<ExpenseAssignee>(UserFlags.DefaultAssignee, ExpenseAssignee.ME)
    const [assigneeType, setAssigneeType] = useState<ExpenseAssignee>(defaultValues?.assignee ?? defaultAssignee)
    const { reset, watch, trigger, getValues, resetField } = useFormContext<AddExpenseRequest>()
    const areInvoicingConfigurationOn = useFeatureIsOn(UserFlags.InvoicingConfigurations.toString())
    const [isCreatingInvoice, setIsCreatingInvoice] = useState(false)

    useEffect(() => {
        if (!hasDescription) {
            resetField("description")
        }
    }, [hasDescription, resetField])

    useEffect(() => {
        if (rentingPeriodDetails) setAssigneeType(ExpenseAssignee.TENANT)
        if (defaultValues) {
            reset({
                ...defaultValues,
                assignee: defaultValues.assignee,
                date: defaultValues.date?.toISO() ?? undefined,
                dueDate: defaultValues.dueDate?.toISO() ?? undefined,
                files: defaultValues.prefilledExpense ? (defaultValues.files as unknown as CustomFile[]) : [],
                shouldNotifyOtherParty: true,
                shouldCreateInvoice: false,
                invoicingData: defaultValues.invoicingData,
                groupId: currentGroupId,
                ___: currencies?.find((c) => c.code.toLowerCase() === "ron")?.id,
                // @ts-expect-error payment type should be composed if using subtype
                paymentType:
                    defaultValues.paymentType === PaymentType.Expense
                        ? `${PaymentType.Expense}_${defaultValues.paymentSubtype}`
                        : defaultValues.paymentType,
            })
        }

        if (defaultValues == null) {
            // @ts-expect-error payment type should be composed if using subtype
            reset({
                assignee: rentingPeriodDetails ? ExpenseAssignee.TENANT : defaultAssignee,
                date: DateTime.now().toISO() ?? undefined,
                dueDate: DateTime.now().plus({ day: 1 }).toISO() ?? undefined,
                currencyId: user?.currency.id,
                files: [],
                propertyId: currentGroupId ? undefined : (currentPropertyId ?? rentingPeriodDetails?.propertyId ?? (editableProperties ?? [])[0]?.id),
                shouldNotifyOtherParty: true,
                shouldCreateInvoice: false,
                paymentType: `${PaymentType.Expense}_${PaymentSubtype.None}`,
                rentingPeriodId: rentingPeriodDetails?.id ?? undefined,
                groupId: currentGroupId,
                ___: currencies?.find((c) => c.code.toLowerCase() === "ron")?.id,
            })
        }
    }, [defaultValues, currentPropertyId, user, editableProperties, rentingPeriodDetails, defaultAssignee, currentGroupId])

    useEffect(() => {
        const subscription = watch((v) => {
            setAssigneeType(v.assignee ?? defaultAssignee)
            // TODO: hack
            trigger("date")
            trigger("propertyId")
            setIsCreatingInvoice(v.shouldCreateInvoice ?? false)
        })
        return () => subscription.unsubscribe()
    }, [trigger, defaultAssignee])

    const renderTenant = useCallback(
        (tooltipTitle: string, menuItemText: string, other: MenuItemProps) => {
            if (tooltipTitle.length > 0) {
                return (
                    <LightTooltip arrow title={translate(tooltipTitle)}>
                        <span>
                            <MenuItem {...other} disabled style={{ textTransform: "none" }}>
                                {translate(menuItemText)}
                            </MenuItem>
                        </span>
                    </LightTooltip>
                )
            }

            return (
                <MenuItem {...other} style={{ textTransform: "none" }}>
                    {translate(menuItemText)}
                </MenuItem>
            )
        },
        [translate],
    )

    const getMaxSize = useCallback(() => {
        if (user == null || editableProperties == null) return 0

        const property = editableProperties.find((p) => p.id === getValues().propertyId)

        if (property == null) return 0

        if (user.features?.maxPropertySize) {
            return user.features?.maxPropertySize - property.currentSize
        }
    }, [user, editableProperties, getValues])

    return (
        <>
            <Box display='flex' flexDirection='column' gap={2} marginY={2}>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFTextField name='name' label={translate("name")} placeholder={translate("examples.expense_name")} size='small' required />
                    <RHFUnitInput
                        data={currencies ?? []}
                        unitName={"currencyId"}
                        unitType={"currency"}
                        name='value'
                        type={"number"}
                        inputMode={"decimal"}
                        autoCapitalize={"off"}
                        size='small'
                        label={translate("amount")}
                        required
                    />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFDatePicker
                        name='date'
                        label={translate("date")}
                        required
                        disableFuture
                        minDate={rentingPeriodDetails != null ? rentingPeriodDetails.moveInDate : undefined}
                    />
                    <RHFDatePicker name='dueDate' label={translate("due_date")} required />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <LightTooltip title={rentingPeriodDetails != null ? translate("only_expenses_assigned_to_tenant") : ""} arrow>
                        <span style={{ width: "100%" }}>
                            <RHFSelect
                                name={"assignee"}
                                size='small'
                                variant={"outlined"}
                                label={translate("assignee")}
                                required
                                disabled={rentingPeriodDetails != null}
                            >
                                {rentingPeriodDetails == null && (
                                    <MenuItem value={0} style={{ textTransform: "none" }}>
                                        {translate(`expenses.assignee-0${customPaidBy ? "-" + customPaidBy : ""}`)}
                                    </MenuItem>
                                )}
                                {renderTenant("", "expenses.assignee-1", {
                                    value: 1,
                                })}
                            </RHFSelect>
                        </span>
                    </LightTooltip>
                    <RHFPaymentTypeInput name='paymentType' />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    <RHFProviderInput name={"associatedProviderId"} />
                    <RHFLabelInput name={"labelId"} type={LabelType.Expense} />
                </Box>
                <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                    {!currentGroupId && (
                        <LightTooltip
                            title={
                                currentPropertyId != null ||
                                (defaultValues != null && defaultValues.propertyId != null) ||
                                rentingPeriodDetails != null
                                    ? translate("can_not_edit_field")
                                    : ""
                            }
                            arrow
                        >
                            <span style={{ width: "100%" }}>
                                <RHFAutocomplete
                                    autocompleteProps={{
                                        sx: {
                                            width: "100%",
                                        },
                                        noOptionsText: translate("no_value_found"),
                                    }}
                                    matchId
                                    freeSolo={false}
                                    name={"propertyId"}
                                    options={editableProperties?.map((property) => ({ id: property.id, label: property.label })) ?? []}
                                    label={translate("property")}
                                    required={currentPropertyId == null}
                                    disabled={
                                        currentPropertyId != null ||
                                        (defaultValues != null && defaultValues.propertyId != null) ||
                                        rentingPeriodDetails != null
                                    }
                                />
                            </span>
                        </LightTooltip>
                    )}
                    {!currentGroupId && (
                        <RHFPropertyRentingPeriodPicker
                            name={"rentingPeriodId"}
                            isMandatory={getValues("assignee") === ExpenseAssignee.TENANT}
                            disabled={getValues("assignee") === ExpenseAssignee.ME || defaultValues != null || currentRentingPeriodId != null}
                            propertyId={getValues("propertyId")}
                        />
                    )}
                </Box>
                <Button variant='text' fullWidth={false} disableTouchRipple={true} onClick={() => setHasDescription((prev) => !prev)}>
                    <Box display='flex' flexDirection='row' gap={2}>
                        {translate("add_description")}
                        {hasDescription ? (
                            <ArrowUpward sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                        ) : (
                            <ArrowDownward sx={{ fontSize: DESKTOP_ICON_SIZE }} />
                        )}
                    </Box>
                </Button>
                <Collapse in={hasDescription}>
                    <Box display='flex' gap={2} flexDirection={isMobile ? "column" : "row"} justifyContent='space-between'>
                        <RHFTextField label={translate("expense_description")} rows={3} fullWidth multiline={true} name={"description"} />
                    </Box>
                </Collapse>
                <Box display='flex' flexDirection={"column"} gap={1}>
                    {assigneeType === ExpenseAssignee.TENANT && (!defaultValues || defaultValues.prefilledExpense) && (
                        <RHFCheckbox name={"shouldNotifyOtherParty"} label={translate("notify_tenant")} />
                    )}

                    {assigneeType === ExpenseAssignee.TENANT &&
                        (!defaultValues || defaultValues.prefilledExpense) &&
                        areInvoicingConfigurationOn &&
                        !currentGroupId && <RHFCheckbox name={"shouldCreateInvoice"} label={translate("should_create_invoice")} />}
                </Box>
                {isCreatingInvoice && (
                    <Box display='flex' flexDirection={isMobile ? "column" : "row"} gap={1}>
                        <RHFDatePicker
                            name='invoicingData.providerInvoiceDate'
                            label={translate("provider_invoice_date")}
                            required
                            disableFuture
                            minDate={rentingPeriodDetails != null ? rentingPeriodDetails.moveInDate : undefined}
                        />
                        <RHFTextField
                            name='invoicingData.providerInvoiceNumber'
                            label={translate("provider_invoice_number")}
                            placeholder={"1234"}
                            size='small'
                            required
                        />
                        <RHFUnitInput
                            data={(currencies ?? []).filter((c) => c.code.toLowerCase() === "ron")}
                            unitName={"___"}
                            unitType={"currency"}
                            name='invoicingData.providerInvoiceAmount'
                            type={"number"}
                            inputMode={"decimal"}
                            autoCapitalize={"off"}
                            size='small'
                            label={translate("provider_invoice_amount")}
                            required
                        />
                    </Box>
                )}
            </Box>

            {defaultValues == null && <RHFUpload name={"files"} multiple maxSize={getMaxSize()} icon={"mdi:file-document-plus-outline"} />}
            {errorMessage && (
                <Box marginTop={2}>
                    <Alert severity={"error"}>{errorMessage}</Alert>
                </Box>
            )}
        </>
    )
}
