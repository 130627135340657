import * as React from "react"
import { useCallback } from "react"
import { useDispatch, useSelector } from "../../../../../redux/store"
import { CustomDialog, DetailsSheet, useIsMobile, useLocales } from "rentzz"
import { useTheme } from "@mui/material/styles"
import { AppModals, setEditingItem, setModalOpen } from "../../../../../redux/slices/App"
import DeleteContent from "../../../../../components/DeleteContent"
import { deleteBankAccountConsentMutation } from "../../../../../mutations/bankAccountConsent"

export default function DeleteBankAccountConsentGuard() {
    const dispatch = useDispatch()
    const { translate } = useLocales()
    const isMobile = useIsMobile()
    const theme = useTheme()
    const { modalOpen, editingItem } = useSelector((state) => state.appState)
    const { mutateAsync: deleteBankAccountConsent, isPending: isBankAccountConsentLoading } = deleteBankAccountConsentMutation()

    const onClose = useCallback(() => {
        dispatch(setModalOpen(undefined))
        dispatch(setEditingItem(undefined))
    }, [dispatch])

    const onDelete = useCallback(async () => {
        if (editingItem == null) return
        await deleteBankAccountConsent(editingItem.id)

        onClose()
    }, [editingItem, deleteBankAccountConsent])

    return (
        <>
            <CustomDialog
                title={translate("delete")}
                maxWidth={"xs"}
                showCloseIcon
                open={!isMobile && modalOpen === AppModals.DeleteBankAccountConsent}
                onClose={onClose}
                isFullScreen={false}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_bankAccount_consent_content")}
                    isLoading={isBankAccountConsentLoading}
                />
            </CustomDialog>

            <DetailsSheet
                isOpen={isMobile && modalOpen === AppModals.DeleteBankAccountConsent}
                onClose={onClose}
                isLoading={false}
                title={translate("delete")}
                options={[]}
                headerColor={theme.palette.error.main}
            >
                <DeleteContent
                    onClose={onClose}
                    onAccept={onDelete}
                    deleteText={translate("delete_bankAccount_consent_content")}
                    isLoading={isBankAccountConsentLoading}
                />
            </DetailsSheet>
        </>
    )
}
